define('mycare-ember/utils/function-helpers', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {

        /**
         * execute if f is function, or return f as it
         * @param {*} f f to execute against
         * @param {*} context set closure context for the given function
         * @param {*} args arguments to pass to function
         * @return {*}
         */
        executeOrGetValue: function executeOrGetValue(f, context) {
            var result = null;
            if (typeof f === 'function') {
                for (var _len = arguments.length, args = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
                    args[_key - 2] = arguments[_key];
                }

                result = f.apply(context || this, args);
            } else {
                result = f;
            }
            return result;
        },
        callOrIgnore: function callOrIgnore(f) {
            if (!Ember.isNone(f)) {
                for (var _len2 = arguments.length, args = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
                    args[_key2 - 1] = arguments[_key2];
                }

                f.apply(null, args);
            }
        },
        callOrIgnoreWithContext: function callOrIgnoreWithContext(f, context) {
            if (!Ember.isNone(f)) {
                for (var _len3 = arguments.length, args = Array(_len3 > 2 ? _len3 - 2 : 0), _key3 = 2; _key3 < _len3; _key3++) {
                    args[_key3 - 2] = arguments[_key3];
                }

                f.apply(context, args);
            }
        }
    };
});