define('mycare-ember/mixins/wizard', ['exports', 'mycare-ember/utils/function-helpers'], function (exports, _functionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    exports.default = Ember.Mixin.create({

        _steps: [],
        _enabledSteps: Ember.A(),

        currentWizardStep: null,
        wizardId: null,

        /**
         * Wizard backup key for local storage
         * @return {string} key for localStroage access
         */
        wizardDataBackupKey: Ember.computed('wizardId', function () {
            return 'wizardDataBackup-' + this.get('wizardId');
        }),

        /**
         * Check if wizard has next step
         * @return {boolean} true if wizard has another step, otherwise false
         */
        hasNextWizardStep: Ember.computed('_steps', 'currentWizardStep', function () {
            return this.get('_steps').get('length') > this.get('currentWizardStep').index;
        }),

        /**
         * Check if wizard has previous step
         * @return {boolean} true if wizard has previous step, otherwise false
         */
        hasPrevWizardStep: Ember.computed('_steps', 'currentWizardStep', function () {
            return this.get('currentWizardStep').index !== 1;
        }),

        /**
         * Check if wizard should show the footer for the current step
         * @return {boolean} true if wizard should show the footer, otherwise false
         */
        isShowWizardFooter: Ember.computed('currentWizardStep', function () {
            var currentStep = this.get('currentWizardStep');
            if (!currentStep) {
                return false;
            }
            var isShowFooter = _functionHelpers.default.executeOrGetValue(currentStep.isShowFooter, null, []);
            return isShowFooter === undefined || isShowFooter === true;
        }),

        /**
         * Check if wizard should show the header for the current step
         * @return {boolean} true if wizard should show the header, otherwise false
         */
        isShowWizardHeader: Ember.computed('currentWizardStep', function () {
            var currentStep = this.get('currentWizardStep');
            if (!currentStep) {
                return false;
            }
            var isShowHeader = _functionHelpers.default.executeOrGetValue(currentStep.isShowHeader, null, []);
            return isShowHeader === undefined || isShowHeader === true;
        }),

        isShowPrevButton: Ember.computed('currentWizardStep', function () {
            var currentStep = this.get('currentWizardStep');
            if (!currentStep) {
                return false;
            }
            var isShowHeader = _functionHelpers.default.executeOrGetValue(currentStep.isShowPrevButton, null, []);
            return isShowHeader === undefined || isShowHeader === true;
        }),
        /**
         * Get total number of enabled wizard steps
         * @return {number} total number of enabled and counted wizard steps
         */
        wizardStepCount: Ember.computed('_steps.[]', function () {
            var enabledSteps = this._calculateEnabledSteps(this.get('_steps'));
            return enabledSteps.reject(function (s) {
                return s.isCount === false;
            }).get('length');
        }),

        /**
         * Update enabled steps and count them after new wizard step has entered.
         * This is to ensure that all of the isEnabled properties on each wizard step is recalculated after the
         * previous step has passed. This prevents from stale data that is used to calculat isEnabled initally and it might
         * have been changed since them.
         * @return {undefined}
         * @private this is only for internal use
         */
        _updateEnabledStep: function () {
            var steps = this.get('_steps');
            var enabledSteps = this._calculateEnabledSteps(steps);
            this.set('_enabledSteps', enabledSteps);
            this.set('wizardData.stepCount', enabledSteps.reject(function (s) {
                return s.isCount === false;
            }).get('length'));
        }.observes('currentWizardStep'),

        /**
         * Calculate enabled wizard steps
         * @param {array} stepInfos array of stepInfos to calculate
         * @private this is only for internal use
         * @return {array} array of only enabled stepInfos
         */
        _calculateEnabledSteps: function _calculateEnabledSteps(stepInfos) {
            var _this = this;

            return Ember.A(stepInfos).filter(function (info) {
                var isEnabled = _functionHelpers.default.executeOrGetValue(info.isEnabled, info.controller, _this.get('wizardData'));
                return isEnabled === undefined || isEnabled === true;
            });
        },


        /**
         * Reset wizard data
         * @return {undefined}
         */
        resetWizard: function resetWizard() {
            var self = this;
            self.get('_steps').clear();
            self.set('currentWizardStep', null);
            self.set('_enabledSteps', 0);
            if (Ember.isEmpty(self.get('wizardData.state'))) {
                self.set('wizardData.state', {});
            }
        },

        /**
         * Register wizard steps
         * @param {array} stepInfos array of step to be registered
         * @param {object} data data to be registered with
         * @return {undefined}
         */
        registerWizardSteps: function registerWizardSteps(stepInfos, data) {
            var self = this;
            if (Ember.isEmpty(self.get('wizardId'))) {
                throw new Error('"WizardId" needs to be specified');
            }
            self.resetWizard();

            var steps = self._calculateEnabledSteps(stepInfos).toArray();
            var enabledSteps = _.sortBy(steps, function (s) {
                return s.order;
            });
            enabledSteps.forEach(function (info) {
                self.registerWizardStep(info, data, this.indexOf(info) + 1);
            }, enabledSteps);
            data.set('stepCount', self.get('wizardStepCount'));
        },

        /**
         * Register wizard step
         * @param {object} stepInfo step to be registered
         * @param {object} data data to be registered with
         * @param {number} index index of the step
         * @return {undefined}
         */
        registerWizardStep: function registerWizardStep(stepInfo, data, index) {
            var self = this;
            if (Ember.isEmpty(stepInfo.name)) {
                throw new Error('StepInfo requires "name" to be specified');
            }
            if (Ember.isEmpty(stepInfo.controller)) {
                throw new Error('StepInfo requires "name" to be specified');
            }

            var isExists = !Ember.isEmpty(self.get('_steps').filter(function (info) {
                return info.get('name') === stepInfo.name || info.get('index') === stepInfo.index;
            }));

            if (isExists) {
                console.warn('Step with given name , "' + stepInfo.index + ' - ' + stepInfo.name + '" is already registered');
            } else {
                stepInfo.index = index;
                var stepInfoObj = Ember.Object.create(stepInfo);
                self.get('_steps').pushObject(stepInfoObj);
                var controller = stepInfoObj.get('controller');
                if (controller.onWizardInit) {
                    controller.onWizardInit.call(controller, stepInfo, data);
                }
                if (Ember.isEmpty(self.get('wizardData.state.' + stepInfo.name))) {
                    self.set('wizardData.state.' + stepInfo.name, Ember.Object.create({
                        visited: false,
                        completed: false
                    }));
                }
            }
        },

        /**
         * Update state of the given step
         * @param {string} stepName
         * @param {object} state
         */
        updateStepState: function updateStepState(stepName, state) {
            this.set('wizardData.state.' + stepName, state);
        },


        /**
         * Get default fallback step if no step is found or disabled
         * @return {*}
         */
        getFallbackStep: function getFallbackStep() {
            var self = this;
            var result = self.get('_steps').filter(function (stepInfo) {
                return stepInfo.get('isFallback') === true;
            });
            return Ember.isEmpty(result) ? self.get('_steps').objectAt(0) : result.objectAt(0);
        },


        /**
         * Get wizard step by given name
         * @param {string} name name of the step
         * @return {object} step with given name
         */
        getWizardStep: function getWizardStep(name) {
            var self = this;
            var result = self.get('_steps').filter(function (stepInfo) {
                return stepInfo.get('name') === name;
            });
            return Ember.isEmpty(result) ? null : result.objectAt(0);
        },

        /**
         * Get wizard step by given index
         * @param {number} index index of the step
         * @return {object} step with given index
         */
        getWizardStepAt: function getWizardStepAt(index) {
            var self = this;
            var result = self.get('_steps').sort(function (step) {
                return step.get('order');
            }).reverse().filter(function (stepInfo) {
                return stepInfo.get('index') === index;
            });
            return Ember.isEmpty(result) ? null : result.objectAt(0);
        },

        /**
         * Clean up all wizard data including backup data and restart from clean slate
         * @return {undefined}
         */
        cleanupWizard: function cleanupWizard() {
            localStorage.removeItem(this.get('wizardDataBackupKey'));
            this.get('_steps').forEach(function (step) {
                _functionHelpers.default.callOrIgnoreWithContext(step.get('controller').onWizardCleanup, step.get('controller'));
            });
            this.resetWizard();
        },


        /**
         * Backup wizard data to local storage. By default, wizard state would be automatically backup
         * along with the given data
         * @param {object} backupData data to be backed up
         */
        backupWizardData: function backupWizardData(backupData) {
            var existingData = JSON.parse(localStorage.getItem(this.get('wizardDataBackupKey'))) || {
                state: this.get('wizardData.state')
            };
            if (backupData) {
                /* jshint ignore:start */
                existingData = _extends({}, existingData, backupData);
                /* jshint ignore:end */
            }
            localStorage.setItem(this.get('wizardDataBackupKey'), JSON.stringify(existingData));
        },

        /**
         * Set current wizard step to given step name
         * @param {string} stepName name of the step
         * @param {function} callback callback after setting the step to current one
         */
        setCurrentWizardStep: function setCurrentWizardStep(stepName, callback) {
            var self = this;
            var currentStep = this.get('currentWizardStep') || this.getWizardStep(stepName) || this.getFallbackStep();
            self._shouldEnter(currentStep.get('name'), stepName, function (stepInfo) {
                self.set('currentWizardStep', stepInfo);
                self.set('wizardData.state.' + stepInfo.get('name') + '.visited', true);
                self.backupWizardData({
                    state: self.get('wizardData.state')
                });
                callback(stepInfo);
            });
        },

        /**
         * Check if wizard should be transitioned to next setup
         * @param {string} prevStepName current or previous step name
         * @param {string} nextStepName step to be transition to
         * @param {function} callback callback if successful
         * @private this is for internal use only
         */
        _shouldEnter: function _shouldEnter(prevStepName, nextStepName, callback) {
            var self = this;
            if (nextStepName) {
                var nextStepInfo = self.getWizardStep(nextStepName) || self.getFallbackStep();
                var nextController = nextStepInfo.get('controller');
                var willWizardEnter = nextController.willWizardEnter;
                if (willWizardEnter) {
                    Ember.RSVP.resolve(willWizardEnter.call(nextController, nextStepInfo, self.get('wizardData'))).then(function (enteredResult) {
                        self._shouldEnter(nextStepName, enteredResult, callback);
                    });
                } else {
                    callback(nextStepInfo);
                }
            } else {
                callback(self.getWizardStep(prevStepName) || self.getFallbackStep());
            }
        },

        /**
         * Check if given step is enabled
         * @param {string} stepName name of the step
         * @return {boolean} true if enabled, otherwise false
         */
        isEnabled: function isEnabled(stepName) {
            return !Ember.isEmpty(this._calculateEnabledSteps(this.get('_steps')).filter(function (step) {
                return step.get('name') === stepName;
            }));
        },

        /**
         * Get wizard data
         * @return {object} wizard data
         */
        getWizardData: function getWizardData() {
            return this.get('wizardData');
        },

        actions: {
            /**
             * Transit to another step
             * @param {boolean} isNext true if transiting to next step, otherwise false for previous step
             */
            transitStep: function transitStep(isNext) {
                var _this2 = this;

                var self = this;
                var currentStep = this.get('currentWizardStep');
                var nextStep = null;
                if (isNext) {
                    self.set('wizardData.isLoading', true);
                }

                var executeRoute = function executeRoute(nextStep) {
                    if (nextStep) {
                        self.set('wizardData.state.' + currentStep.get('name'), {
                            visited: true,
                            completed: isNext
                        });
                        self.setCurrentWizardStep(nextStep.get('name'), function (successfulStep) {
                            if (isNext) {
                                self.set('wizardData.isLoading', false);
                            }

                            self.transitionToRoute(successfulStep.get('routeName'));
                        });
                    } else {
                        if (isNext) {
                            self.set('wizardData.isLoading', false);
                        }
                    }
                };

                var stepCallback = isNext ? currentStep.get('controller').onNext : currentStep.get('controller').onPrev;
                if (stepCallback && typeof stepCallback === 'function') {
                    Ember.RSVP.resolve(stepCallback.call(currentStep.get('controller'), currentStep)).then(function (result) {
                        if (result === undefined || typeof result === 'boolean' && result === true) {
                            var incStep = 0;
                            do {
                                incStep += 1;
                                nextStep = self.getWizardStepAt(isNext ? currentStep.index + incStep : currentStep.index - incStep);
                            } while (_this2.isEnabled(nextStep.name) === false);
                        } else if (typeof result === 'string') {
                            nextStep = self.getWizardStep(result);
                        }
                        executeRoute(nextStep);
                    });
                } else {
                    nextStep = self.getWizardStep(stepCallback);
                    if (!nextStep) {
                        throw new Error('StepInfo requires "name" to be specified');
                    }
                    executeRoute(nextStep);
                }
            }
        }
    });
});