define("mycare-ember/mixins/defer", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    getDeferWithToken: function getDeferWithToken() {
      var access_token = localStorage.getItem('tokenKey');
      Ember.$.ajaxPrefilter(function (options) {
        options.headers = {
          "content-Type": "application/json"
        };
        if (access_token) {
          options.headers.Authorization = 'bearer ' + access_token;
        }
      });
      return Ember.RSVP.defer();
    },

    getDeferNoToken: function getDeferNoToken() {
      Ember.$.ajaxPrefilter(function (options) {
        options.headers = {
          "content-Type": "application/json"
        };
      });
      return Ember.RSVP.defer();
    },

    getDeferWithStandardHeader: function getDeferWithStandardHeader() {
      Ember.$.ajaxPrefilter(function (options) {
        options.headers = {};
      });
      return Ember.RSVP.defer();
    }
  });
});