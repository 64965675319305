define("mycare-ember/seekers/manage/index/index/controller", ["exports", "mycare-ember/mixins/mixpanel"], function (exports, _mixpanel) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_mixpanel.default, {
        auth: Ember.inject.controller('auth'),
        queryParams: ['reload'],
        reload: false,
        //new view attributes
        isWorker: false,
        isShowOldViewButton: false,
        hasActivities: false,

        timesheets: [],
        unSubmittedTimesheets: [],
        unSubmittedtimesheetSortAttrs: ['scheduledStartsOn:asc'],
        sortedUnSubmittedTimesheets: Ember.computed.sort('unSubmittedTimesheets', 'unSubmittedtimesheetSortAttrs'),

        manageNoteController: Ember.inject.controller('partials/manage/timesheet/modal-timesheet-entry-notes'),
        visitsController: Ember.inject.controller('seekers/manage/schedule-workers/index/index'),

        timeSheetService: Ember.inject.service('timesheet'),
        upcomingVisitCount: 0,
        doReloadUpcomingVisits: false,

        isSupportingMutiplePeople: function () {
            return this.get('auth.numOfSupportingPeople') >= 2;
        }.property(),

        // new view methods
        setup: function setup() {
            var self = this;
            self.getUnSubmittedTimesheets();

            self.store.findRecord('person', self.get('auth.profileId')).then(function (person) {
                self.set('profilePerson', person);
                self.getTodayTimesheets();
            });
        },

        getTodayTimesheets: function getTodayTimesheets() {
            var self = this;
            self.get('timesheets').clear();
            self.set('isLoadingEvent', true);
            var startOfToday = moment(self.get('selectedDate')).startOf('day').utc().toISOString(); // set to 12:00 am today
            var endOfToday = moment(self.get('selectedDate')).endOf('day').utc().toISOString(); // set to 23:59 pm today
            var profileId = self.get('auth.profileId');
            self.get('timeSheetService.getTimeSheetsAndEventsByRangeAsync').perform({
                profileId: profileId,
                startDate: startOfToday,
                endDate: endOfToday,
                isWorker: self.get('isWorker'),
                reload: this.get('reload')
            }).then(function (timesheets) {
                self.set('timesheets', timesheets.sortBy('scheduledStartsOn'));
                self.set('isLoadingEvent', false);
                self.set('reload', false);
            });
        },

        getUnSubmittedTimesheets: function getUnSubmittedTimesheets() {
            var self = this;
            var profileId = self.get('auth.profileId');
            var queryObj = {
                personId: profileId,
                isWorker: self.get('isWorker'),
                statuses: ['Submitted', 'Review', 'Pending'],
                reload: this.get('reload')
            };

            self.store.query('timesheet', queryObj).then(function (timesheets) {
                self.set('isLoading', false);
                var unSubmittedTimesheets = timesheets.filter(function (timesheet) {
                    return moment(timesheet.get('scheduledStartsOn')).utc().isBefore(moment().startOf('day').utc());
                });
                self.set('unSubmittedTimesheets', unSubmittedTimesheets);
                self.set('reload', false);
            });
        },

        watchTimesheetActivities: function () {
            var self = this;
            var timesheets = self.get('timesheets');
            var totalActivities = 0;
            timesheets.forEach(function (timesheet) {
                var activityLength = timesheet.get('activities.length');
                totalActivities = totalActivities + activityLength;
            });
            if (totalActivities > 0) {
                self.set('hasActivities', true);
            }
        }.observes('timesheets.@each.activities.[]'),

        watchTimesheetNotes: function () {
            var self = this;
            var timesheets = self.get('timesheets');
            var totalNotes = 0;
            timesheets.forEach(function (timesheet) {
                var noteLength = timesheet.get('notes.length');
                totalNotes = totalNotes + noteLength;
            });
            if (totalNotes > 0) {
                self.set('hasNotes', true);
            } else {
                self.set('hasNotes', false);
            }
        }.observes('timesheets.@each.notes.[]'),

        hasNoVisits: function () {
            return this.get('upcomingVisitCount') + this.get('unSubmittedTimesheets.length') + this.get('timesheets.length') === 0;
        }.property('upcomingVisitCount', 'timesheets.length', 'unSubmittedTimesheets.length'),

        actions: {
            toAddVisits: function toAddVisits() {
                var self = this;
                self.get('visitsController').set('selectedDate', '');
                self.transitionToRoute('seekers.manage.schedule-workers.index.visits.all');
                Ember.run.later(function () {
                    self.get('visitsController').showNewTimesheet();
                }, 200);
            },

            toOldView: function toOldView() {
                var self = this;
                self.transitionToRoute('seekers.manage.index.current');
            },

            upcomingVisitsUpdatedEvent: function upcomingVisitsUpdatedEvent() {
                this.getTodayTimesheets();
            }
        }
    });
});