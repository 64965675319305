define('mycare-ember/components/mc-booking/visit-breakdown-totalhours/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({

        _wizardService: Ember.inject.service('utils/booking-wizard'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('contract', undefined);
            this.initVar('bookType', undefined);
            this.initVar('totalHours', 0);
            this.initVar('totalVisits', 0);
        },


        rate: function () {
            return this.get('bookType') === 'hourly' ? this.get('contract.hourlyRate') || 0 : this.get('contract.nightlyRate') || 0;
        }.property('bookType', 'contract.hourlyRate', 'contract.nightlyRate', 'totalHours'),

        mycareFee: function () {
            var bookType = this.get('bookType');
            if (this.get('session.authData.isCurrentlySeeker')) {
                return Number(this.get('rate')) * Number(this.get('contract.getServiceFee')) * Number(this.get(bookType === 'hourly' ? 'totalHours' : 'totalVisits'));
            } else {
                return Number(this.get('rate')) * Number(this.get('contract.getWorkerFee')) * Number(this.get(bookType === 'hourly' ? 'totalHours' : 'totalVisits'));
            }
        }.property('rate', 'contract.{getServiceFee,getWorkerFee}', 'totalHours'),

        taxFee: function () {
            return (this.get('totalWithoutFee') - this.get('mycareFee')) * this.get('contract.taxRate');
        }.property('totalWithoutFee', 'mycareFee', 'contract.taxRate'),

        totalWithoutFee: function () {
            if (this.get('bookType') === 'hourly') {
                return Number(this.get('rate') || 0) * Number(this.get('totalHours'));
            } else {
                return Number(this.get('rate') || 0) * Number(this.get('totalVisits'));
            }
        }.property('rate', 'mycareFee', 'totalHours', 'bookType'),

        total: function () {
            if (this.get('session.authData.isCurrentlySeeker')) {
                return Number(this.get('totalWithoutFee')) + Number(this.get('totalFees'));
            } else {
                return Number(this.get('totalWithoutFee')) - Number(this.get('totalFees')) - Number(this.get('taxFee'));
            }
        }.property('totalWithoutFee', 'totalFees')
    });
});