define("mycare-ember/components/recipient-picker/component", ["exports", "mycare-ember/utils/mc-component"], function (exports, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        doSelection: function () {
            var self = this;
            var selectedRecipient = self.get('selectedRecipient');
            if (!Ember.isEmpty(selectedRecipient)) {
                self.get('selectedValues').pushObject(selectedRecipient);
                self.get('recipients').removeObject(selectedRecipient);
                self.set('selectedRecipient', null);
            }
        }.observes('selectedRecipient'),

        actions: {
            removeItem: function removeItem(id) {
                var self = this;
                self.get('selectedValues').forEach(function (item) {
                    if (item.get('id') === id) {
                        self.get('selectedValues').removeObject(item);
                        self.get('recipients').pushObject(item);
                    }
                });
            }
        }
    });
});