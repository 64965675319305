define("mycare-ember/seekers/findhelp/profile/index/support/controller", ["exports", "mycare-ember/mixins/scroll_top", "mycare-ember/utils/common", "mycare-ember/mixins/support-type-mixin", "mycare-ember/mixins/form-checker"], function (exports, _scroll_top, _common, _supportTypeMixin, _formChecker) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_formChecker.default, _supportTypeMixin.default, _scroll_top.default, {
        percent: 0,
        auth: Ember.inject.controller('auth'),
        isLoading: false,
        _supportTypeModal: _common.default.createGuid(),
        _paymentOptionModal: _common.default.createGuid(),
        formData: {},
        selectedPaymentOptions: {},
        initialPaymentOptions: {},
        hasOtherSupportTypeError: false,

        setup: function setup() {
            this.setFormData(this.get('model.person'));
        },
        setFormData: function setFormData(person) {
            this.set('formData', {
                supportTypes: person.get('supportTypes'),
                isGovernmentPay: person.get('isGovernmentPay'),
                isPrivatePay: person.get('isPrivatePay')
            });
        },


        textForYourselfOrOrganiser: function () {
            return this.get('auth.loggedInUserId') === this.get('model.person.id') ? 'yourself' : this.get('model.person.firstName');
        }.property('model'),

        actions: {
            showSupportTypeModal: function showSupportTypeModal() {
                // this.get()
                this.get('eventbus').publish("type-of-support-modal:on:open:" + this.get('_supportTypeModal'));
            },
            showPaymentOptionModal: function showPaymentOptionModal() {
                // this.get()
                this.get('eventbus').publish("payment-option-modal:on:open:" + this.get('_paymentOptionModal'));
            },
            onUpdatePerson: function onUpdatePerson(person) {
                this.setFormData(person);
            }
        }
    });
});