define('mycare-ember/carers/my-account/index/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        auth: Ember.inject.controller('auth'),
        isNoAccount: false,
        setup: function () {
            var self = this;
            self.store.findRecord('person', self.get('auth.profileId')).then(function (person) {
                self.set('profilePerson', person);
            });
        }.on('init'),

        actions: {
            saveAccount: function saveAccount() {
                var self = this;
                if (self.get('isNoAccount') === true) {
                    var account = self.store.createRecord('bank-account', {
                        bank: self.get('bank'),
                        branch: self.get('branch'),
                        account: self.get('account'),
                        suffix: self.get('suffix')
                    });
                    account.save().then(function () {});
                }
            }
        }
    });
});