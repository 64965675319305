define('mycare-ember/components/mc-booking/basic-info-breakdown-modal/component', ['exports', 'mycare-ember/utils/mc-modal-component'], function (exports, _mcModalComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend({
        init: function init() {
            this.set('_componentId', 'mc-booking/basic-info-breakdown-modal');
            this._super.apply(this, arguments);
            this.set('_contract', undefined);
            this.set('_bookType', undefined);
        },
        _onBeforeOpen: function _onBeforeOpen(_ref) {
            var contract = _ref.contract,
                bookType = _ref.bookType;

            this.set('_contract', contract);
            this.set('_bookType', bookType);
        }
    });
});