define("mycare-ember/mixins/circle-new", ["exports", "mycare-ember/mixins/mixpanel"], function (exports, _mixpanel) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_mixpanel.default, {
        newcircle: function newcircle(imageUrl, fileName, circleTitle, circleDescription, owners, finishAction, errorAction) {
            var self = this;
            self.store.createRecord('image', {
                url: imageUrl,
                fileName: fileName,
                uploadedOn: moment.utc(new Date()).toISOString()
            }).save().then(function (image) {
                self.store.createRecord('circle', {
                    title: circleTitle,
                    description: circleDescription,
                    createdOn: moment.utc(new Date()).toISOString(),
                    image: image,
                    person: owners.objectAt(0),
                    owners: owners.map(function (o) {
                        return o.get('id');
                    })
                }).save().then(function (circle) {
                    self.mixpanelCreateSupportCircle(circle);
                    if (finishAction) {
                        finishAction();
                    }
                }, function () {
                    if (errorAction) {
                        errorAction();
                    }
                });
            });
        }
    });
});