define("mycare-ember/seekers/manage/schedule-workers/index/schedule-detail/invoice/route", ["exports", "mycare-ember/mixins/protected_route", "mycare-ember/mixins/scroll_top"], function (exports, _protected_route, _scroll_top) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_protected_route.default, _scroll_top.default, {

        renderTemplate: function renderTemplate() {
            this.render('partials/manage/timesheet/invoice', {
                into: 'seekers/manage'
            });
        },
        setupController: function setupController(controller, model) {

            var self = this;
            controller = self.controllerFor('partials/manage/timesheet/invoice');
            controller.set('isLoading', true);
            self.store.query('invoice', { id: model.timesheet.get('id'), isContractor: false }).then(function (invoices) {
                controller.set('model', invoices.objectAt(0));
                controller.set('timesheet', model);
                controller.set('isLoading', false);
            });
        }
    });
});