define('mycare-ember/mixins/lineCharts/baseLineChart', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({

        setChart: function setChart(labels) {
            var self = this;
            Ember.$('#' + self.get('chartId')).remove(); // this is my <canvas> element
            Ember.$("#" + self.get('chartParentId')).append('<canvas id="' + self.get('chartId') + '"><canvas>');
            var ctx = document.getElementById(self.get('chartId'));
            ctx.width = 400;
            ctx.height = 600;
            var myChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: labels ? labels : self.getSelectedMonthArray(),
                    datasets: []
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                }
            });

            return myChart;
        },

        getDataQuery: function getDataQuery(reportType) {
            var self = this;
            self._super();
            self.setMonthAndYear();
            var store = self.get('targetObject.store');
            var thisYear = self.get('monthYear.year');
            var thisMonth = self.get('monthYear.month');
            if (Ember.isEmpty(self.get('localTime')) === false) {
                var thisMoment = moment(self.get('localTime'));
                thisYear = thisMoment.year();
                thisMonth = thisMoment.month() + 1;
                this.set('monthYear', { month: thisMonth, year: thisYear });
            }
            return store.query('report', { 'reportType': reportType, 'year': thisYear, 'month': thisMonth });
        },

        setMonthAndYear: function setMonthAndYear() {
            var dateTimeString = moment(this.get('localTime')).format('M/YYYY');
            var dateTimeArray = dateTimeString.split("/");
            // startDateTime is the filter start time
            var startDateTime = moment().add(-10, 'month');
            var month = isNaN(dateTimeArray[0]) ? startDateTime.month() : dateTimeArray[0];
            var year = dateTimeArray[1] ? dateTimeArray[1] : startDateTime.year();

            this.set('monthYear', Ember.Object.create({ month: month, year: year }));
        },

        hexToRgb: function hexToRgb(hex, a) {
            // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
            var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
            hex = hex.replace(shorthandRegex, function (m, r, g, b) {
                return r + r + g + g + b + b;
            });

            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? 'rgba(' + parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16) + ', ' + a + ')' : null;
        },

        getSelectedMonthArray: function getSelectedMonthArray() {
            var self = this;
            if (self.get('monthYear') !== undefined) {
                self.setMonthYear();
                var monthArray = moment.monthsShort();
                var selectedMonthIndex = self.get('monthYear.month') - 1;
                var firstHalf = monthArray.slice(0, selectedMonthIndex);
                var year = self.get('monthYear.year').toString().substring(2);
                firstHalf.forEach(function (month, index) {
                    month = month + " '" + (parseInt(year) + 1);
                    firstHalf[index] = month;
                });
                var lastHalf = monthArray.slice(selectedMonthIndex);
                lastHalf.forEach(function (month, index) {
                    month = month + " '" + parseInt(year);
                    lastHalf[index] = month;
                });
                return lastHalf.concat(firstHalf);
            }
        },
        setMonthYear: function setMonthYear() {
            var self = this;
            var dataPicker = Ember.$('#' + this.get('reportDivId')).find('input');
            dataPicker.combodate('setValue', moment(self.get('monthYear.month') + "/" + self.get('monthYear.year'), "M/YYYY").format('MMM/YYYY'));
        },
        buildChart: function buildChart(dataset, labels) {
            var self = this;
            var myChart = self.setChart(labels);
            var colorScale = d3.scale.linear().domain([0, dataset.length]).range([{ color: "#22a0c8", opacity: 0.6 }, { color: "#5c068b", opacity: 0.6 }]);
            dataset.forEach(function (dataObject, index) {
                myChart.data.datasets.push({
                    label: dataObject.label,
                    data: dataObject.data,
                    border: 1,
                    fill: false,
                    type: dataObject.type ? dataObject.type : "bar",
                    borderColor: self.hexToRgb(colorScale(index).color, colorScale(index).opacity),
                    backgroundColor: self.hexToRgb(colorScale(index).color, colorScale(index).opacity),
                    hidden: dataObject.hidden
                });
            });
            myChart.update();
        }
    });
});