define("mycare-ember/components/mc-mobile-timepicker/component", ["exports", "mycare-ember/utils/mc-component", "mycare-ember/utils/function-helpers"], function (exports, _mcComponent, _functionHelpers) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('isWorker', false);
            this.initVar('minuteInterval', 5);
            this.initVar('initialValue', moment());
            this.initVar('onChanged', undefined);

            this.set('_selectedTime', undefined);
        },


        hours: function () {
            return _.range(1, 13);
        }.property(),

        minutes: function () {
            return _.range(0, 60, this.get('minuteInterval')).map(this._makeAtLest2Digits);
        }.property(),

        _watchInitialValue: function () {
            this._setInitialDate();
        }.observes('initialValue'),

        _setInitialDate: function _setInitialDate() {
            var initiValue = moment(this.get('initialValue'));
            var minute = initiValue.minute();
            var hour = initiValue.hour();
            var minuteInterval = this.get('minuteInterval');
            if (hour >= 12) {
                this.$('select.hour').drum('setIndex', _.findIndex(this.get('hours'), function (h) {
                    return h === hour - 12;
                }));
                this.$('select.am_pm').drum('setIndex', 1);
            } else {
                this.$('select.hour').drum('setIndex', _.findIndex(this.get('hours'), function (h) {
                    return h === hour;
                }));
                this.$('select.am_pm').drum('setIndex', 0);
            }
            var minuteIndex = _.findIndex(this.get('minutes'), function (min) {
                return Number(min) === Math.ceil(minute / minuteInterval) * minuteInterval;
            });
            this.$('select.minute').drum('setIndex', minuteIndex);
            this.set('_selectedTime', this.get('initialValue'));
        },
        _makeAtLest2Digits: function _makeAtLest2Digits(i) {
            return ("0" + i).slice(-2);
        },
        _setValue: function _setValue() {
            var hour = this.$('select.hour').val();
            hour = hour > 12 ? hour - 12 : hour;
            var minute = this.$('select.minute').val();
            var am_pm = this.$('select.am_pm').val();
            _functionHelpers.default.callOrIgnore(this.get('onChanged'), moment(hour + ":" + minute + " " + am_pm, 'LT').toISOString());
        },
        didInsertElement: function didInsertElement() {
            var _this = this;

            // Hammer.plugins.fakeMultitouch();
            var options = {
                interactive: false,
                panelCount: 12,
                focusColor: this.get('isWorker') ? '#009BDE' : '#81368B',
                onChange: function onChange() {
                    _this._setValue();
                }
            };
            this.$('select.hour').drum(options);
            this.$('select.minute').drum(options);
            this.$('select.am_pm').drum(options);

            this._setInitialDate();
            this._setValue();
        }
    });
});