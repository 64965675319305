define('mycare-ember/mixins/manage/account/reports', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({

        store: Ember.inject.service(),
        sortProps: ['actualStartsOn:desc'],
        sortedTimesheets: Ember.computed.sort('timesheets', 'sortProps'),
        totalPayment: 0,
        selectedFromDate: '',
        selectedToDate: '',
        httpService: Ember.inject.service('utils/http'),

        addWorkerTax: function () {
            this.get('timesheets').forEach(function (timesheet) {
                timesheet.set('totalWorkerAfterTax', timesheet.get('totalBeforeTaxWorker') - timesheet.get('totalTaxWorker'));
            });
        }.observes('timesheets.length'),

        setup: function setup() {
            var _this = this;

            var self = this;
            var peoplePromise = self.get('store').query('person', {
                status: 'contracted',
                isWorker: self.get('isWorker'),
                id: self.get('profileId')
            }, { reload: true }).then(function (clients) {
                if (self.isDestroyed) {
                    self.set('people', clients);
                }
            });

            var accountPromise = self.get('store').findRecord('person', self.get('profileId')).then(function (person) {
                self.set('accounts', person.get('accounts'));
                self.set('selectedAccount', person.get('accounts').objectAt(0));
                self.set('isShowAccount', person.get('accounts.length') > 1);
            });
            Ember.RSVP.all([peoplePromise, accountPromise]).then(function () {
                if (self.isDestroyed) {
                    return;
                }
                self._initStartTimeAndEndTime();
                self.set('isComponentReady', true);
                self.set('startDate', moment(self.get('selectedFromDate'), 'DD/MM/YYYY'));
                self.set('endDate', moment(self.get('selectedToDate'), 'DD/MM/YYYY'));
                self.getReports(self.get('startDate').startOf('day'), self.get('endDate').startOf('day'), _this.get('selectedPerson'));
            });
        },

        downloadCsv: function downloadCsv(from, to, clientId, contractorId) {
            var _this2 = this;

            var apiUrl = this.get('env').getApp('API_HOST');
            var url = apiUrl + '/api/timesheets?contractorPersonId=' + contractorId + '&clientPersonId=' + clientId + '&endsOn=' + to + '&startsOn=' + from + '&generateCsv=true';
            var filename = 'mycare-report-' + moment().format('DD-MM-YYYY') + '.csv';
            this.get('httpService.withTokenAsync').perform(function (client) {
                return client.get(url);
            }).then(function (response) {
                var file = new File([response.data], filename, {
                    lastModified: new Date(0), // optional - default = now
                    type: "application/csv" // optional - default = ''
                });
                var fr = new FileReader();
                fr.readAsText(file);
                _this2.set('fileUrl', URL.createObjectURL(file));
                _this2.set('fileName', filename);
            });
        },


        actions: {
            print: function print() {
                window.print();
            },
            openDetail: function openDetail(timesheetId) {
                this.set('openTimesheetId', timesheetId);
                if (Ember.$('.' + timesheetId).hasClass('in')) {
                    if (timesheetId === this.get('openTimesheetId')) {
                        this.set('openTimesheetId', null);
                    }
                    return;
                }
                Ember.$('.transaction-table .collapse').each(function () {
                    var $this = $(this);
                    if ($this.attr('id') !== String(timesheetId)) {
                        $this.removeClass('in');
                    }
                });
                return false;
            }
        },

        watchSelectedChanges: function () {
            var self = this;
            if (self.get('isComponentReady') === true) {
                self.set('startDate', moment(self.get('selectedFromDate'), 'DD/MM/YYYY'));
                self.set('endDate', moment(self.get('selectedToDate'), 'DD/MM/YYYY'));
                self.getReports(self.get('startDate').startOf('day'), self.get('startDate').startOf('day'), self.get('selectedPerson'));
            }
        }.observes('selectedFromDate', 'selectedToDate', 'selectedPerson', 'selectedAccount'),

        getReports: function getReports(startsOn, endsOn, selectedPerson) {
            var self = this;
            var contractorPersonId = null;
            if (Ember.isEmpty(selectedPerson) === false) {
                contractorPersonId = selectedPerson.get('id');
            }
            self.set('isLoading', true);
            var queryObject = {
                accountId: self.get('selectedAccount.id'),
                // clientPersonId: self.get('profileId'),
                // contractorPersonId: contractorPersonId,
                clientPersonId: contractorPersonId,
                contractorPersonId: self.get('profileId'),

                startsOn: startsOn.utc().toISOString(),
                endsOn: endsOn.utc().toISOString()
            };
            if (Ember.isEmpty(selectedPerson)) {
                delete queryObject['clientPersonId'];
            }

            self.get('store').query('timesheet', queryObject, { reload: true }).then(function (timesheets) {
                if (self.isDestroyed) {
                    return;
                }
                self.set('thisReport', self.get('store').peekRecord('account-report', 1));
                self.set('timesheets', timesheets);
                if (!self.get('env').isMobile()) {
                    if (selectedPerson) {
                        self.downloadCsv(startsOn.utc().toISOString(), endsOn.utc().toISOString(), selectedPerson.get('id'), self.get('profileId'));
                    } else {
                        self.downloadCsv(startsOn.utc().toISOString(), endsOn.utc().toISOString(), null, self.get('profileId'));
                    }
                }
                self.set('isLoading', false);
            });
        },

        setTotalPayment: function setTotalPayment(timesheets) {
            var self = this;
            self.set('totalPayment', 0);
            timesheets.forEach(function (timesheet) {
                self.set('totalPayment', self.get('totalPayment') + timesheet.get('estimateWorkerNetPay'));
            });
        },
        _initStartTimeAndEndTime: function _initStartTimeAndEndTime() {
            var self = this;
            if (Ember.isEmpty(self.get('selectedFromDate')) && Ember.isEmpty(self.get('selectedToDate'))) {
                self.set('selectedFromDate', moment().startOf('month').format('DD/MM/YYYY'));
                self.set('selectedToDate', moment().endOf('month').format('DD/MM/YYYY'));
            }
        }
    });
});