define('mycare-ember/browse/index/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        beforeModel: function beforeModel() {
            var self = this;
            if ((this.get('session.authData.isWorker') || this.get('session.authData.isWorkerOrganiser')) && this.get('session.authData.currentProfileRole') === self.get('env').getApp('WORKER_PROFILE_TYPE')) {
                self.transitionTo('browse.jobs');
            } else {
                self.transitionTo('browse.profiles');
            }
        }
    });
});