define("mycare-ember/components/mc-feedback-form/component", ["exports", "mycare-ember/utils/common", "mycare-ember/utils/mc-component"], function (exports, _common, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        _feedbackService: Ember.inject.service('feedback'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('modalId', _common.default.createGuid());
            this.initVar('feedbackErrors', {});
        },
        didInsertElement: function didInsertElement() {
            var _this = this;

            this.$("." + this.get('modalId')).on('hidden.bs.modal', function () {
                _this.get('eventbus').publish('mc-feedback-form:event:closed');
            });
        },
        didReceiveAttrs: function didReceiveAttrs() {
            var _this2 = this;

            var eventbus = this.get('eventbus');
            eventbus.subscribe('mc-feedback-form:on:open', function (message) {
                if (_this2.isDestroyed) {
                    return;
                }
                if (!Ember.isNone(message)) {
                    _this2.set('feedbackString', message);
                } else {
                    _this2.set('feedbackErrors', {});
                }
                _this2.open();
            }, false);

            eventbus.subscribe('mc-feedback-form:on:hide', function () {
                _this2.hide();
            }, false);

            eventbus.subscribe('mc-feedback-form:on:send', function (_ref) {
                var id = _ref.id,
                    message = _ref.message;

                if (_this2.isDestroyed) {
                    return;
                }
                Ember.Logger.assert(!Ember.isNone(id), 'id is required for mc-feedback-form send event');
                Ember.Logger.assert(!Ember.isNone(message), 'id is required for mc-feedback-form send event');
                _this2.set('feedbackString', message);
                _this2._send().then(function () {
                    if (_this2.isDestroyed) {
                        return;
                    }
                    _this2.get('eventbus').publish("mc-feedback-form:event:sent:" + id);
                });
            }, false);
        },
        open: function open() {
            if (this.isDestroyed) {
                return;
            }
            var $modal = this.$("." + this.get('modalId'));
            if (Ember.isNone($modal) || this.isDestroyed === true) {
                return;
            }
            $modal.modal('show');
        },
        hide: function hide() {
            if (this.isDestroyed) {
                return;
            }
            var $modal = this.$("." + this.get('modalId'));
            if (Ember.isNone($modal) || this.isDestroyed === true) {
                return;
            }
            $modal.modal('hide');
        },


        watchMessage: function () {
            if (this.isDestroyed) {
                return;
            }
            if (!this.get('feedbackString')) {
                this.set('feedbackErrors.message', 'Please enter your feedback.');
            } else {
                this.set('feedbackErrors.message', null);
            }
        }.observes('feedbackString'),

        _send: function _send() {
            var self = this;
            if (self.validateForm()) {
                self.set('isSending', true);
                return self.get('_feedbackService.sendFeedbackAsync').perform({ content: self.get('feedbackString') }).then(function () {
                    self.set('isSending', false);
                    self.hide();
                    self.set('feedbackString', '');
                });
            }
            return Ember.RSVP.resolve();
        },


        actions: {
            sendFeedback: function sendFeedback() {
                this._send();
            }
        },

        validateForm: function validateForm() {
            if (this.isDestroyed) {

                return;
            }
            this.set('feedbackErrors', {});
            var isValid = true;
            if (!this.get('feedbackString')) {
                isValid = false;
                this.set('feedbackErrors.message', 'Please enter your feedback.');
            }
            return isValid;
        }
    });
});