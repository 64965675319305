define("mycare-ember/index/controller", ["exports", "mycare-ember/mixins/browser-detection", "mycare-ember/utils/common"], function (exports, _browserDetection, _common) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_browserDetection.default, {
        auth: Ember.inject.controller('auth'),
        errors: Ember.computed.alias('auth.errors'),
        showPassword: false,
        loginModalChannelId: _common.default.createGuid(),
        signupModalChannelId: _common.default.createGuid(),

        setup: function setup() {
            var self = this;
            var prevLoginEmail = localStorage.getItem(this.get('env').getApp('PREVIOUS_LOGIN_EMAIL'));
            if (prevLoginEmail) {
                self.set('auth.login.email', prevLoginEmail);
            }
        },

        imageId: function () {
            return new Date().getSeconds() % 4;
        }.property(),

        logoUrl: function () {
            return this.get('env').getApp('LOGO_URL');
        }.property(),

        actions: {
            showLoginForm: function showLoginForm() {
                this.get('eventbus').publish("mc-auth/login:on:open:" + this.get('loginModalChannelId'));
            },
            showSignupForm: function showSignupForm() {
                this.get('eventbus').publish("mc-auth/login:on:open:" + this.get('signupModalChannelId'));
            },
            togglePassword: function togglePassword() {
                this.toggleProperty('showPassword');
            },


            login: function login() {
                this.get('auth').authenticate();
            },

            toForgotPassword: function toForgotPassword() {
                this.get('auth.errors').clear();
                this.transitionToRoute('forgot-password');
            }
        }
    });
});