define('mycare-ember/account/reset-password/route', ['exports', 'mycare-ember/mixins/code-email-reciever'], function (exports, _codeEmailReciever) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_codeEmailReciever.default, {
        beforeModel: function beforeModel(transition) {
            this.processRecieveData(transition, 'account/reset-password');
        }
    });
});