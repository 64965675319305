define("mycare-ember/helpers/availability-message", ["exports", "moment"], function (exports, _moment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.availabilityMessage = undefined;


    function availabilityMessage(data) {

        var day = data[0];
        var contractor = data[1];
        if (!contractor) {
            return;
        }
        if (!day.isFullNotAvailable && !day.isPartNotAvailable) {
            return contractor.get('firstName') + " is available";
        } else if (day.isFullNotAvailable) {
            return contractor.get('firstName') + " is not available";
        } else if (day.isPartNotAvailable) {
            return contractor.get('firstName') + " is available for part of the day";
        } else {
            return contractor.get('firstName') + " is available";
        }
    }

    exports.availabilityMessage = availabilityMessage;
    exports.default = Ember.Helper.helper(availabilityMessage);
});