define('mycare-ember/models/bank-account', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        bank: _emberData.default.attr('string'),
        branch: _emberData.default.attr('string'),
        accountNumber: _emberData.default.attr('string'),
        suffix: _emberData.default.attr('string'),
        account: _emberData.default.belongsTo('account', { async: true }),
        preferredBy: _emberData.default.belongsTo('account', { async: true, inverse: 'preferredBankAccount' }),
        bankAccountName: _emberData.default.attr('string'),
        bankAccountNumber: function () {
            return this.get('bank') + '-' + this.get('branch') + '-' + this.get('accountNumber') + '-' + this.get('suffix');
        }.property('bank', 'branch', 'account', 'suffix')
    });
});