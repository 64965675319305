define("mycare-ember/components/mc-payment/mobile-view/component", ["exports", "mycare-ember/utils/mc-component"], function (exports, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        tagName: '',
        _accountService: Ember.inject.service('account'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('isPayment', false);
            this.initVar('accountId', undefined);

            this.checkPaymentType();
        },
        checkPaymentType: function checkPaymentType() {
            var _this = this;

            this.get('_accountService.getTransactionTypeByIdAsync').perform({ id: 3 }).then(function (transactionType) {
                Ember.RSVP.resolve(_this.get('model.transactionType')).then(function (t) {
                    var bool = t.get('name').toLowerCase() === transactionType.get('name').toLowerCase();
                    _this.set('isPayment', bool);
                });
            });
        }
    });
});