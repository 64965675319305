define("mycare-ember/mixins/payment-process", ["exports", "mycare-ember/mixins/circle-new", "mycare-ember/mixins/mixpanel"], function (exports, _circleNew, _mixpanel) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_circleNew.default, _mixpanel.default, {
        auth: Ember.inject.controller('auth'),
        subscriptionItem: null,
        isPaymentCompleted: false,

        checkSubStatus: function () {}.property(),

        processPayment: function processPayment(isCreateComponents, selectedSubscriptionType, afterPaymentSuccessRoute, emberObject, routeObject) {
            var self = this;
            // enable the payment button when modal close
            Ember.$('#modal-payment').on('hidden.bs.modal', function () {
                self.set('isBuildingPaymentGateway', false);
                self.set('isStop', true);
            });
            self.set('isPaymentFalse', false);
            self.set('isBuildingPaymentGateway', true);
            return self.store.findRecord('person', self.get('auth.currentPersonId'), { reload: true }).then(function (subscriber) {
                return self.getSubscriptionType(selectedSubscriptionType).then(function (subscriptionType) {
                    var subscription = self.store.createRecord('subscription', {
                        type: subscriptionType,
                        subscribedPeople: [subscriber.get('id')]
                    });
                    subscription.save().then(function (sub) {
                        var timer;
                        self.set('isStop', false);
                        self.set('subscriptionItem', sub);
                        Ember.$('#modal-payment').modal('show');
                        var poll = function poll() {
                            if (self.get('isStop') === false) {
                                Ember.run.schedule('sync', self, function () {
                                    //Set authorization header to next ajax request.
                                    Ember.$.ajaxPrefilter(function (options) {
                                        options.headers = {
                                            Authorization: 'bearer ' + localStorage.getItem(self.get('env').getApp('AUTH_TOKEN_KEY'))
                                        };
                                    });
                                    Ember.$.ajax({
                                        url: self.get('env').getApp('API_HOST') + '/api/subscriptions/' + sub.get('id'),
                                        success: function success(data, textStatus, xhr) {
                                            if (xhr.status === 200) {
                                                self.set('isPaymentCompleted', true);
                                                self.set('isBuildingPaymentGateway', false);
                                                subscriber.set('isValidSubscriber', true);
                                                clearTimeout(timer);
                                                if (isCreateComponents) {
                                                    self.buildComponents(subscriber);
                                                }
                                                if (afterPaymentSuccessRoute) {
                                                    self.transitionToRoute(afterPaymentSuccessRoute, emberObject, routeObject);
                                                    return;
                                                }
                                                self.afterPaymentSuccess(subscriber);
                                                self.mixpanelPayment(subscriber, subscription.get('price'), subscription.get('type.typeName'));
                                            } else if (xhr.status === 400) {
                                                clearTimeout(timer);
                                                self.set('isPaymentCompleted', true);
                                                self.set('isBuildingPaymentGateway', false);
                                                self.afterPaymentFail();
                                            } else {
                                                self.set('isPaymentCompleted', false);
                                                setTimeout(poll, 1000);
                                            }
                                        },
                                        error: function error(response) {
                                            clearTimeout(timer);
                                            self.afterPaymentFail();
                                            self.set('isBuildingPaymentGateway', false);
                                            if (response.status === 400) {
                                                self.set('isPaymentCompleted', true);
                                            }
                                        }
                                    });
                                });
                            } else {
                                clearTimeout(timer);
                            }
                        };
                        timer = setTimeout(poll, 1000);
                    }, function () {
                        self.set('isBuildingPaymentGateway', false);
                    });
                });
            });
        },

        getSubscriptionType: function getSubscriptionType(subscriptionType) {

            var self = this;

            return self.store.findAll('subscription-type').then(function (subscriptionTypes) {

                if (Ember.isEmpty(subscriptionType)) {
                    subscriptionType = 'premium';
                }
                for (var i = 0; i < subscriptionTypes.get('length'); i++) {
                    var selectedType = subscriptionTypes.objectAt(i);
                    if (selectedType.get('typeName').toLowerCase() === subscriptionType.toLowerCase()) {
                        return selectedType;
                    }
                }
            });
        },

        buildComponents: function buildComponents(person, afterFunction) {
            var self = this;
            self.store.query('circle', { personId: person.get('id') }).then(function (circles) {
                if (circles.get('length') === 0) {
                    var owners = [person];
                    self.newcircle('', 'default.png', person.get('firstName') + "'s support circle", 'Keep friends and family informed in ' + person.get('firstName') + "'s support circle", owners, afterFunction);
                }
            });
        },

        afterPaymentSuccess: function afterPaymentSuccess(subscriber) {
            var self = this;
            Ember.$('.modal').modal('hide');
            self.send('switchProfile', 'organiser', subscriber.get('id'), subscriber.get('firstName'));
        },

        lookAround: function lookAround(subscriber) {
            var self = this;
            Ember.$('.modal').modal('hide');
            self.send('switchProfile', 'organiser', subscriber.get('id'), subscriber.get('firstName'));
        },

        afterPaymentFail: function afterPaymentFail() {
            var self = this;
            self.set('isPaymentFalse', true);
            Ember.$('.modal').modal('hide');
        }
    });
});