define('mycare-ember/components/ui/mc-image/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({

        tagName: '',

        init: function init() {
            this._super.apply(this, arguments);

            this.initVar('title', undefined);
            this.initVar('alt', undefined);
            this.initVar('src', undefined);
            this.initVar('imageType', 'default');

            this.setImageUrl();
        },


        watchReload: function () {
            this.setImageUrl();
        }.observes('src', 'imageType'),

        setImageUrl: function setImageUrl() {
            if (!Ember.isNone(this.get('src'))) {
                this.set('_imageUrl', this.get('src'));
                return null;
            }
            switch (this.get('imageType')) {
                case 'woman':
                    this.set('_imageUrl', this.get('env').getApp('PROFILE_DEFAULT_IMAGE_WOMAN'));
                    break;
                case 'circle':
                    this.set('_imageUrl', this.get('env').getApp('CIRCLE_DEFAULT_IMAGE'));
                    break;
                case 'hero':
                    this.set('_imageUrl', '/img/super-worker.svg');
                    break;
                case 'verified':
                    this.set('_imageUrl', '/img/verified-id.svg');
                    break;
                default:
                    this.set('_imageUrl', this.get('env').getApp('PROFILE_DEFAULT_IMAGE'));
                    break;
            }
        }
    });
});