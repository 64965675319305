define("mycare-ember/components/mc-worker-profile/services/component", ["exports", "mycare-ember/utils/mc-component"], function (exports, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        _referenceModelService: Ember.inject.service('reference-models'),
        _modalHelper: Ember.inject.service('utils/modal-helper'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('worker', undefined);
            this.initVar('mode', undefined);
            this.initVar('_services', []);
        },
        willSetupOnce: function willSetupOnce() {
            var _this = this;

            this._super.apply(this, arguments);
            this.get('_referenceModelService.getServicesAsync').perform().then(function (services) {
                _this.set('_services', services);
            });
        },


        actions: {
            onSelected: function onSelected(values) {
                if (this.isDestroyed) {
                    return;
                }
                this.set('worker.services', values);
                this.get('worker').save();
            }
        }

    });
});