define('mycare-ember/services/utils/validation', ['exports', 'mycare-ember/utils/base-service', 'mycare-ember/utils/common', 'ember-changeset-validations/validators'], function (exports, _baseService, _common, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _baseService.default.extend({
        validatePresence: function validatePresence(isRequired) {
            return (0, _validators.validatePresence)(isRequired);
        },
        validateAccountIrdNumber: function validateAccountIrdNumber() {
            return function (key, newValue) {
                var errorMessage = 'Please enter correct IRD number';
                var irdNumber = (newValue || "").replace(/-/g, '');
                if (Ember.isEmpty(irdNumber) || irdNumber.length < 8 || irdNumber.length > 9 || !_common.default.isNumber(irdNumber) || irdNumber.match(/^(\d){8,9}$/) === null) {
                    return errorMessage;
                }
                if (!irdNumber.match(/^(?=.*?[1-9])\d+(\.\d+)?$/)) {
                    return errorMessage;
                }
                return true;
            };
        },
        validateAccountBank: function validateAccountBank() {
            return function (key, newValue) {
                var errorMessage = 'Please enter correct bank number';
                if (_common.default.isNumber(newValue) === false || newValue.length !== 2) {
                    return errorMessage;
                }
                if (!newValue.match(/^(?=.*?[1-9])\d+(\.\d+)?$/)) {
                    return errorMessage;
                }
                return true;
            };
        },
        validateAccountBankBranch: function validateAccountBankBranch() {
            return function (key, newValue) {
                var errorMessage = 'Please enter correct branch number';
                if (_common.default.isNumber(newValue) === false || newValue.length !== 4) {
                    return errorMessage;
                }
                if (!newValue.match(/^(?=.*?[1-9])\d+(\.\d+)?$/)) {
                    return errorMessage;
                }
                return true;
            };
        },
        validateAccountBankNumber: function validateAccountBankNumber() {
            return function (key, newValue) {
                var errorMessage = 'Please enter correct bank number';
                if (_common.default.isNumber(newValue) === false || newValue.length !== 7) {
                    return errorMessage;
                }
                if (!newValue.match(/^(?=.*?[1-9])\d+(\.\d+)?$/)) {
                    return errorMessage;
                }
                return true;
            };
        },
        validateAccountBankSuffix: function validateAccountBankSuffix() {
            return function (key, newValue) {
                var errorMessage = 'Please enter correct suffix number';
                if (_common.default.isNumber(newValue) === false || newValue.length < 2) {
                    return errorMessage;
                }
                return true;
            };
        }
    });
});