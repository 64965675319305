define('mycare-ember/components/mc-worker-profile/hourly-rates/component', ['exports', 'mycare-ember/utils/common', 'mycare-ember/utils/mc-component'], function (exports, _common, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        sortedExperiences: Ember.computed.sort('worker.experiences', '_sortProps'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('worker', null);
            this.initVar('editorChannelId', _common.default.createGuid());
            this.initVar('_sortProps', ['startedOn:desc']);
        },


        actions: {
            addHourlyRate: function addHourlyRate() {
                this.get('eventbus').publish('mc-worker-profile/hourly-rates/editor:on:open:' + this.get('editorChannelId'), null, 'add');
            },
            onEdit: function onEdit(experience) {
                this.get('eventbus').publish('mc-worker-profile/hourly-rates/editor:on:open:' + this.get('editorChannelId'), experience, 'edit');
            }
        }
    });
});