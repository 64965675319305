define("mycare-ember/seekers/findhelp/job-listings/list/route", ["exports", "mycare-ember/mixins/seeker_only", "mycare-ember/utils/mc-protected-route", "ember-concurrency"], function (exports, _seeker_only, _mcProtectedRoute, _emberConcurrency) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcProtectedRoute.default.extend(_seeker_only.default, {

        jobListingService: Ember.inject.service('job-listing'),
        _profileService: Ember.inject.service('profile'),

        model: function model() {
            return this.get('_profileService.getPersonByIdAsync').perform({ personId: this.get('session.authData.currentPersonId') });
        }
    }

    // setupControllerAsync: task(function *(){
    //     var hasJobListing = yield this.get('jobListingService.hasAnyListingAsync')
    //         .perform({ personId: this.get('session.authData.currentPersonId')});
    //     if(!hasJobListing){
    //         localStorage.removeItem(this.get('env').getApp('NEW_LISTING'));
    //         this.transitionTo('seekers.findhelp.job-listings.create');
    //     }
    // }),
    );
});