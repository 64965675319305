define('mycare-ember/mixins/code-email-reciever', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        _redirectService: Ember.inject.service('utils/redirect'),

        processRecieveData: function processRecieveData(transition, controllerName) {
            var email = transition.queryParams.email;
            var code = transition.queryParams.code;
            var type = transition.queryParams.type;
            if (type === 'existingSeeker') {
                localStorage.setItem(this.get('env').getApp('WORKER_INVITE_SIGN_UP_STAGE'), "true");
                localStorage.setItem(this.get('env').getApp('WORKER_SIGN_UP_STAGE'), "true");
                localStorage.setItem('currentProfileRole', "worker");
                this.get('_redirectService').store(this.get('env').getApp('REDIRECT_BEFORELOGIN_KEY'), 'register.worker-register.index');
                this.transitionTo('index');
                return;
            }
            var resetPasswordObject = {
                "email": email, "code": code
            };
            var controller = this.controllerFor(controllerName);
            if (type) {
                controller.set('type', type);
            }
            if (code && email) {
                controller.send('setAccountInfo', resetPasswordObject);
            }
        }
    });
});