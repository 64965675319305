define('mycare-ember/register/seeker-register/index/route', ['exports', 'mycare-ember/utils/wizard-step-route', 'mycare-ember/utils/function-helpers', 'mycare-ember/mixins/scroll_top'], function (exports, _wizardStepRoute, _functionHelpers, _scroll_top) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _wizardStepRoute.default.extend(_scroll_top.default, {
        stepName: 'create-account',
        parentControllerName: 'register/seeker-register',

        beforeModel: function beforeModel() {
            if (this.get('session.authData.isAuthenticated')) {
                this.transitionTo('register.seeker-register.who');
            }
        },
        setupController: function setupController(controller) {
            this._super.apply(this, arguments);
            _functionHelpers.default.callOrIgnore(controller.scrollTop);
            _functionHelpers.default.callOrIgnoreWithContext(controller.setup, controller);
        },


        actions: {
            next: function next() {
                var parentWizardController = this.controllerFor(this.get('parentControllerName'));
                parentWizardController.backupWizardData({
                    personId: this.controller.get('wizardData.account.id')
                });
                parentWizardController.send('transitStep', true);
            }
        }
    });
});