define('mycare-ember/components/mc-worker-profile/reviews/component', ['exports', 'mycare-ember/utils/common', 'mycare-ember/utils/mc-component'], function (exports, _common, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        sortedRecommendations: Ember.computed.sort('worker.recommendations', '_sortProps'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('worker', undefined);
            this.initVar('_sortProps', ['createdOn:desc']);
            this.initVar('_editorChannelId', _common.default.createGuid());
        },


        actions: {
            response: function response(recommendation) {
                this.get('eventbus').publish('mc-worker-profile/reviews/editor:on:open:' + this.get('_editorChannelId'), recommendation);
            }
        }
    });
});