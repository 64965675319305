define("mycare-ember/components/mc-search-bar/component", ["exports", "mycare-ember/utils/mc-component"], function (exports, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        _referenceModelService: Ember.inject.service('reference-models'),
        isSearchTypeJobs: false,
        isSearchTypeCarers: false,
        doesShowSelectTypeOption: true,
        isVolunteer: false,
        isAdvanceSearch: true,
        isDescend: false,
        selectedServices: [],
        selectedLanguages: [],
        selectedRequirements: [],
        selectedEquipment: [],
        selectedEmploymentTypes: [],
        selectedFilterOptions: [],
        mobileServices: Ember.A([]),
        mobileEquipment: Ember.A([]),
        mobileRequirements: Ember.A([]),
        mobileLanguages: Ember.A([]),
        selectMin: 16,
        selectMax: 40,
        //mobile attributes
        mobileMin: 16,
        mobileMax: 40,
        min: 16,
        max: 40,
        isMobileVolunteer: false,
        searchPriceString: '16,40,false',
        sortOrders: [],
        sortOrderString: 'grade desc|lastLoginOn desc',

        didInsertElement: function didInsertElement() {
            Ember.$('.modal-fullscreen').on('show.bs.modal', function () {
                Ember.$('body').removeClass('fast-scroll');
            });

            Ember.$('.modal-fullscreen').on('hidden.bs.modal', function () {
                Ember.$('body').addClass('fast-scroll');
            });
        },
        didReceiveAttrs: function didReceiveAttrs() {
            var self = this;
            this.setSortOrders();
            this.setFilterOptions();

            Ember.RSVP.hash({
                services: self.get('_referenceModelService.getServicesAsync').perform(),
                languages: self.get('_referenceModelService.getLanguagesAsync').perform(),
                requirements: self.get('_referenceModelService.getRequirementsAsync').perform(),
                employmentTypes: self.get('_referenceModelService.getEmploymentTypesAsync').perform(),
                equipment: self.get('_referenceModelService.getEquipmentAsync').perform()
            }).then(function (_ref) {
                var services = _ref.services,
                    languages = _ref.languages,
                    requirements = _ref.requirements,
                    employmentTypes = _ref.employmentTypes,
                    equipment = _ref.equipment;

                self.set('services', services);
                self.set('languages', languages);
                self.set('requirements', requirements);
                self.set('employmentTypes', employmentTypes);
                self.set('equipment', equipment);
                self.set('isComponentReady', true);
            });
        },
        setFilterOptions: function setFilterOptions() {
            if (this.get('isSearchTypeProfile')) {
                this.set('filterOptions', Ember.A([Ember.Object.create({ id: 1, name: 'MOJ or Police' }), Ember.Object.create({ id: 2, name: 'Male' }), Ember.Object.create({ id: 3, name: 'Female' })]));
            } else {
                this.set('filterOptions', Ember.A([Ember.Object.create({ id: 1, name: 'Moj' })]));
            }
        },
        setSortOrders: function setSortOrders() {
            if (this.get('isSearchTypeProfile')) {
                this.set('sortOrders', [Ember.Object.create({ id: 0, name: 'Best match', searchString: '' }), Ember.Object.create({ id: 1, name: 'Closest workers', searchString: 'distance asc' }), Ember.Object.create({ id: 3, name: 'Highest response rate', searchString: 'respondRatio desc' })]);
                this.set('sortOrderString', 'grade desc|lastLoginOn desc');
            } else {
                this.set('sortOrders', [Ember.Object.create({ id: 0, name: 'Best match', searchString: '' }), Ember.Object.create({ id: 1, name: 'Closest jobs', searchString: 'distance asc' }), Ember.Object.create({ id: 5, name: 'Highest hourly rate', searchString: 'hourlyRate desc' }), Ember.Object.create({ id: 6, name: 'Lowest hourly rate', searchString: 'hourlyRate asc' })]);
                this.set('sortOrderString', '');
            }
        },


        isShowJobSearchLabel: function () {
            if (!this.get('doesShowSelectTypeOption') && this.get('isSearchTypeJobs')) {
                return true;
            }
        }.property('isSearchTypeJobs', 'doesShowSelectTypeOption'),

        isShowProfileSearchLabel: function () {
            if (!this.get('doesShowSelectTypeOption') && this.get('isSearchTypeCarers')) {
                return true;
            }
        }.property('isSearchTypeCarers', 'doesShowSelectTypeOption'),

        actions: {
            onOrderSelect: function onOrderSelect(option) {
                this.set('sortOrderString', option);
            },


            moreFilters: function moreFilters() {
                var self = this;
                self.set('showSummary', false);
                Ember.$('#search-filters').collapse('show');
            },

            updateFilters: function updateFilters() {
                var self = this;
                Ember.$('#search-filters').collapse('hide');
                self.set('showSummary', true);
                self.sendAction('search');
            },

            closeFilters: function closeFilters() {
                var self = this;
                Ember.$('#search-filters').collapse('hide');
                self.set('showSummary', true);
            },

            removeFilter: function removeFilter(type, item) {
                var self = this;
                self.get(type).removeObject(item);
                // what does this do?
                // self.get('remove location: ', self.get('location'));
                self.sendAction('search');
            },

            removeKeyword: function removeKeyword() {
                var self = this;
                self.set('keyword', '');
                self.sendAction('search');
            },

            clearFilter: function clearFilter() {
                var self = this;
                self.get('selectedServices').clear();
                self.get('selectedLanguages').clear();
                self.get('selectedRequirements').clear();
                self.get('selectedEquipment').clear();
                self.get('selectedEmploymentTypes').clear();
                self.get('selectedFilterOptions').clear();
            },

            setRate: function setRate(rateObj) {
                this.set('searchPriceString', rateObj.min + "," + rateObj.max + "," + rateObj.isVolunteer);
            },
            setDistance: function setDistance(distanceVal) {
                this.set('selectedDistance', parseInt(distanceVal));
            },


            /**
             * Change current search type
             * @param type jobs or carers
             */
            sendSearchType: function sendSearchType(type) {
                var self = this;
                self.sendAction('changeSearchType', type);
            },

            //mobile actions, only for mobile view
            onUpdateMin: function onUpdateMin(min) {
                if (this.get('isComponentReady') === true) {
                    this.set('mobileMin', min);
                }
            },
            onUpdateMax: function onUpdateMax(max) {
                if (this.get('isComponentReady') === true) {
                    this.set('mobileMax', max);
                }
            },
            toggleMobileVolunteer: function toggleMobileVolunteer() {
                this.toggleProperty('isMobileVolunteer');
            },


            onSelected: function onSelected(actionP, items) {
                if (actionP === 'services') {
                    this.set('mobileServices', items);
                }
                if (actionP === 'equipment') {
                    this.set('mobileEquipment', items);
                }
                if (actionP === 'requirements') {
                    this.set('mobileRequirements', items);
                }
                if (actionP === 'languages') {
                    this.set('mobileLanguages', items);
                }
            },

            setMobileChanges: function setMobileChanges() {
                var prices = (this.get('mobileMin') + "," + this.get('mobileMax') + "," + this.get('isMobileVolunteer')).split(',');
                var minPrice = prices[0];
                var maxPrice = prices[1];
                var isVolunteer = prices[2] === 'true';
                this.processSearch(this.get('mobileLanguages'), this.get('mobileRequirements'), this.get('mobileEquipment'), this.get('selectedEmploymentTypes'), this.get('mobileServices'), this.get('scoringServices'), isVolunteer, minPrice, maxPrice, this.get('selectedDistance'));
            }
            //end of mobile actions

        },

        watchSearchParameter: function () {
            var prices = (this.get('searchPriceString') || '16,40,false').split(',');
            var minPrice = prices[0];
            var maxPrice = prices[1];
            var isVolunteer = prices[2] === 'true';
            this.processSearch(this.get('selectedLanguages'), this.get('selectedRequirements'), this.get('selectedEquipment'), this.get('selectedEmploymentTypes'), this.get('selectedServices'), this.get('selectedFilterOptions'), this.get('scoringServices'), isVolunteer, minPrice, maxPrice, this.get('selectedDistance'));
        }.observes('keyword', 'selectedLanguages.[]', 'selectedRequirements.[]', 'selectedEquipment.[]', 'selectedEmploymentTypes.[]', 'selectedServices.[]', 'selectedFilterOptions.[]', 'searchPriceString', 'selectedDistance', 'sortOrderString'),

        processSearch: function processSearch(languages, requirements, equipment, employmentTypes, services, filterOptions, scoringServices, isVolunteer, lowerPrice, upperPrice, distance) {
            var self = this;
            var searchParameter = {};
            if (self.get('keyword')) {
                searchParameter.keyword = self.get('keyword');
            }

            if (self.arrToStr(languages)) {
                searchParameter.languages = self.arrToStr(languages);
            }

            if (self.arrToStr(requirements)) {
                searchParameter.requirements = self.arrToStr(requirements);
            }

            if (self.arrToStr(equipment)) {
                searchParameter.equipment = self.arrToStr(equipment);
            }

            if (filterOptions) {
                var mojAndGender = this.hasMojOrGenderFilter(filterOptions);
                searchParameter.isMoJVerified = mojAndGender.hasMoj;
                searchParameter.gender = mojAndGender.genders;
            }

            if (self.arrToStr(employmentTypes)) {
                searchParameter.employmentTypes = self.arrToStr(employmentTypes);
            }
            if (self.arrToStr(services)) {
                searchParameter.services = self.arrToStr(services);
            }

            if (self.arrToStr(scoringServices)) {
                searchParameter.scoringServices = self.arrToStr(scoringServices);
            }
            if (isVolunteer === true) {
                searchParameter.isVolunteer = true;
            } else {
                searchParameter.isVolunteer = false;
                searchParameter.lowerPrice = lowerPrice;
                searchParameter.upperPrice = upperPrice;
            }
            searchParameter.sort = this.get('sortOrderString');
            searchParameter.distance = distance;
            if (this.get('isSearchTypeProfile')) {
                searchParameter.personId = this.get('session.authData.currentPersonId') ? this.get('session.authData.currentPersonId') : undefined;
            }

            self.set('searchParameter', searchParameter);
            self.sendAction('search', searchParameter);
        },
        hasMojOrGenderFilter: function hasMojOrGenderFilter(filterOptions) {
            var hasMoj = false;
            filterOptions.forEach(function (filterOption) {
                if (filterOption.get('id') == 1) {
                    hasMoj = true;
                    return;
                }
            });
            var genders = filterOptions.filter(function (filterOption) {
                return filterOption.get('id') != 1;
            });
            var gendersFilter = this.arrToStr(genders);
            return { hasMoj: hasMoj, genders: gendersFilter };
        },


        arrToStr: function arrToStr(arr) {
            var str = '';
            var emberArray = Ember.A(arr);
            if (!Ember.isEmpty(emberArray)) {
                for (var i = 0; i < emberArray.get('length'); i++) {
                    str += emberArray.objectAt(i).get('name');
                    if (i !== emberArray.get('length') - 1) {
                        str += '|';
                    }
                }
            }
            return str;
        },

        validateForm: function validateForm() {
            var self = this;
            self.set('displaySearchLocationError', false);
            if (!self.get('isSearchTypeJobs') && !self.get('isSearchTypeCarers')) {
                self.set('displaySearchTypeError', true);
                return false;
            }

            if (self.get('location') === undefined || self.get('location') === null) {
                self.set('displaySearchLocationError', true);
                return false;
            }
            return true;
        }
    });
});