define('mycare-ember/components/mc-profile-card/placeholder/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        tagName: '',

        profileDefaultImg: function () {
            return this.get('env').getApp('PROFILE_DEFAULT_IMAGE');
        }.property()
    });
});