define("mycare-ember/controllers/partials/manage/index/timesheet-seeker-index-view", ["exports", "mycare-ember/mixins/timesheet-status-type-mixin", "mycare-ember/mixins/timesheet-seeker-manage-action-mixin"], function (exports, _timesheetStatusTypeMixin, _timesheetSeekerManageActionMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_timesheetStatusTypeMixin.default, _timesheetSeekerManageActionMixin.default, {
        auth: Ember.inject.controller('auth'),
        // EntryEditController: Ember.inject.controller('partials/manage/timesheet/modal-timesheet-edit'),
        manageIndexController: Ember.inject.controller('seekers/manage/index/index'),

        setup: function () {
            var self = this;
            self.watchEntryStatus();
            self.watchStatuses();
        }.on('init')
    });
});