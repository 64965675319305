define("mycare-ember/controllers/partials/nav-profiles-index", ["exports", "mycare-ember/mixins/nav/nav-common"], function (exports, _navCommon) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_navCommon.default, {
    auth: Ember.inject.controller('auth'),
    application: Ember.inject.controller('application'),

    watchProfileType: function () {
      var _this = this;

      this.store.findRecord('person', this.get('auth.loggedInUserId')).then(function (loggedInUser) {
        _this.set('profileType', loggedInUser.get('profileType'));
      });
    }.observes('auth.loggedInUserId'),

    setup: function () {
      var self = this;
      Ember.run.scheduleOnce('afterRender', self, function () {
        this.watchProfileType();
        this.setPerson();
      });
    }.on('init'),

    setPerson: function (personId) {
      var self = this;
      if (!personId) {
        personId = this.get('auth.loggedInUserId');
      }

      if (!Ember.isNone(personId)) {
        this.store.findRecord('person', personId).then(function (person) {
          self.set('person', person);
        });
      }
    }.observes('auth.loggedInUserId'),

    navClass: function () {
      var self = this;
      var navClass = '';
      if (self.get('auth.isCurrentlyWorker')) {
        navClass = 'carer-nav';
      } else {
        navClass = '';
      }
      return navClass;
    }.property('auth.currentProfileRole'),

    isSeeker: function () {
      var self = this;
      var env = self.get('env');
      var profileType = self.get('profileType');
      return profileType === env.getApp('ORGANISER_PROFILE_TYPE');
    }.property('profileType'),

    isCarer: function () {
      var self = this;
      return self.get('profileType') === this.get('env').getApp('WORKER_PROFILE_TYPE');
    }.property('auth.profileType'),

    isMember: function () {
      var self = this;
      return self.get('profileType') === this.get('env').getApp('MEMBER_PROFILE_TYPE');
    }.property('profileType'),

    actions: {
      redirectTo: function redirectTo(route) {
        Ember.$("button.navbar-toggle").trigger("click");
        this.transitionToRoute(route);
        this.set('isOpen', false);
        Ember.$('#body').removeClass('pushy-active');
      },

      toggleFeedbackDialog: function toggleFeedbackDialog() {
        Ember.$("button.navbar-toggle").trigger("click");
        this.set('isOpen', false);
        Ember.$('#body').removeClass('pushy-active');
        Ember.$('#modal-feedback').modal('show');
      }
    }
  });
});