define('mycare-ember/mixins/manage/account/transactions', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        pageSize: 10,
        offSet: 0,
        transactions: [],
        isShowLoadMoreButton: true,
        parkedTransaction: [],
        isAbleToGetTransaction: false,
        startTime: '',
        endTime: '',
        totalRowSize: 10,
        selectedPageSize: {},
        isTransactionLoading: false,

        pageSizes: function () {
            return [Ember.Object.create({ id: 1, size: 10 }), Ember.Object.create({ id: 2, size: 20 }), Ember.Object.create({ id: 3, size: 30 }), Ember.Object.create({ id: 4, size: 40 })];
        }.property(),

        getTransaction: function getTransaction(pageSize, offSet) {
            var self = this;
            self.set('isTransactionLoading', true);
            self.get('transactions').clear();
            self.store.query('transaction', {
                accountId: self.get('model.id'),
                pageSize: pageSize,
                offSet: offSet,
                endsOn: self.get('endTime') ? moment(self.get('endTime'), 'DD/MM/YYYY').toISOString() : '',
                startsOn: self.get('startTime') ? moment(self.get('startTime'), 'DD/MM/YYYY').toISOString() : '',
                workerId: self.get('selectedWorker.id')
            }, { reload: true }).then(function (transactions) {
                self.set('totalRowSize', transactions.get('meta').total);
                self.set('transactions', transactions.toArray());
                self.set('isTransactionLoading', false);
            });
        },

        setPages: function () {
            this.send('processSelectPage', this.get('pageSizes').objectAt(0));
        }.observes('selectedPageSize', 'totalRowSize'),

        watchSelectAccount: function () {
            var self = this;
            self.get('model').reload();
            // filter start time and end time for only correct format
            if (self.get('startTime') && self.get('endTime') && !moment.isMoment(self.get('startTime')) && !moment.isMoment(self.get('endTime')) && self.get('startTime').match("/") && self.get('endTime').match("/")) {

                var thisStartTime = self._getCorrectStartTimeFormat(self.get('startTime'));
                var thisEndTime = self._getCorrectEndTimeFormat(self.get('endTime'));

                this.set('startTimeLabel', moment(thisStartTime).tz('Pacific/Auckland').format('DD/M/YYYY'));
                this.set('endTimeLabel', moment(thisEndTime).tz('Pacific/Auckland').format('DD/M/YYYY'));
                if (!Ember.isEmpty(self.get('model')) && !Ember.isEmpty(self.get('startTime')) && !Ember.isEmpty(self.get('endTime')) && moment(thisStartTime).isBefore(moment(thisEndTime))) {
                    self.set('isTransactionLoading', true);
                    self.set('offSet', 0);
                    self.set('isLoading', true);
                    self.get('transactions').clear();
                    self.store.query('transaction', {
                        accountId: self.get('model.id'),
                        pageSize: self.get('pageSize'),
                        offSet: self.get('offSet'),
                        startsOn: thisStartTime,
                        endsOn: thisEndTime,
                        workerId: self.get('selectedWorker.id')
                    }, { reload: true }).then(function (transactions) {
                        self.set('totalRowSize', transactions.get('meta').total);
                        self.set('isLoading', false);
                        self.set('transactions', transactions.toArray());
                        self.set('isTransactionLoading', false);
                    });
                }
            }
        }.observes('startTime', 'endTime', 'model.id', 'selectedWorker'),

        actions: {
            processSelectPage: function processSelectPage(page) {
                var currentPageSize = page.get('id') * parseInt(this.get('selectedPageSize.size'));
                this.set('pageSize', this.get('selectedPageSize.size'));
                this.set('currentPageSize', currentPageSize > this.get('totalRowSize') ? this.get('totalRowSize') : currentPageSize);
                this.getTransaction(this.get('selectedPageSize.size'), (page.get('id') - 1) * parseInt(this.get('selectedPageSize.size')));
            }
        },

        _setStartTime: function _setStartTime(firstTransaction, lastTransaction) {
            var self = this;
            self.set('startTime', moment(lastTransaction).format('DD/MM/YYYY'));
            var previousMonthTime = moment(firstTransaction).add('-1', 'months');
            if (moment(lastTransaction).isAfter(previousMonthTime)) {
                self.set('startTime', previousMonthTime.format('DD/MM/YYYY'));
            }
        },

        _getCorrectStartTimeFormat: function _getCorrectStartTimeFormat(time) {
            return moment(time, 'DD/MM/YYYY').startOf('day').toISOString();
        },

        _getCorrectEndTimeFormat: function _getCorrectEndTimeFormat(time) {
            return moment(time, 'DD/MM/YYYY').endOf('day').toISOString();
        }
    });
});