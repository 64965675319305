define("mycare-ember/components/mc-schedule-picker/weekly/component", ["exports", "mycare-ember/utils/mc-component", "mycare-ember/mixins/get-date-of-week", "mycare-ember/utils/common", "mycare-ember/utils/function-helpers"], function (exports, _mcComponent, _getDateOfWeek, _common, _functionHelpers) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend(_getDateOfWeek.default, {
        tagName: '',

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('stringOfWeek', 'This week');
            this.initVar('intOfCurrentWeek', 0);
            this.initVar('date', '');
            this.initVar('onSelected', undefined);
        },
        willSetupOnce: function willSetupOnce() {
            var self = this;
            self.set('componentId', _common.default.createGuid() + 'DatePicker');
            Ember.run.scheduleOnce('afterRender', this, function () {
                if (Ember.isEmpty(self.get('selectedDate'))) {
                    self.setDate();
                } else {
                    self.setStartAndEndOfWeek(moment(self.get('selectedDate')).utc());
                    self.set('selectedDate', self.get('selectedDate'));
                }
            });
        },


        setStartAndEndOfWeek: function setStartAndEndOfWeek(date) {
            var dateOfToday = new Date();
            if (date) {
                dateOfToday = new Date(date);
            }
            var dateOfMonday = this.getMonday(dateOfToday);
            var dateOfSunday = this.getSunday(dateOfToday);
            this.set('selectedMonday', dateOfMonday);
            this.set('selectedSunday', dateOfSunday);
            this.setDateDifference(dateOfMonday);
        },

        setDateDifference: function setDateDifference(date) {
            var now = moment.utc(this.getMonday(new Date())); //todays date
            var duration = moment.duration(now.diff(date));
            var days = duration.asDays();
            var dayOfDiff = Math.round(days / 7);
            if (dayOfDiff === 0) {
                this.set('intOfCurrentWeek', dayOfDiff);
            } else {
                this.set('intOfCurrentWeek', -dayOfDiff);
            }
            this.watchIntOfCurrentWeek();
        },

        watchDate: function () {
            var selectedDate = this.get('date');
            this.setDate(selectedDate);
        }.observes('date'),

        isAbleToAddTimeSheet: function () {
            var dateOfToday = new Date();
            var dateOfMonday = this.getMonday(dateOfToday);
            return !!(this.get('isScheduleView') === true && this.get('isSeeker') === true && moment.utc(this.get('selectedSunday')).isAfter(dateOfMonday));
        }.property('selectedSunday', 'isScheduleView'),

        watchIntOfCurrentWeek: function watchIntOfCurrentWeek() {
            if (this.get('intOfCurrentWeek') === 0) {
                this.set('stringOfWeek', 'Current week');
            } else if (this.get('intOfCurrentWeek') === 1) {
                this.set('stringOfWeek', 'Next week');
            } else if (this.get('intOfCurrentWeek') > 1) {
                this.set('stringOfWeek', 'In ' + this.get('intOfCurrentWeek') + ' weeks');
            } else if (this.get('intOfCurrentWeek') === -1) {
                this.set('stringOfWeek', 'Last week');
            } else if (this.get('intOfCurrentWeek') < -1) {
                this.set('stringOfWeek', Math.abs(this.get('intOfCurrentWeek')) + ' weeks ago');
            }
        },

        _dateLabel: function () {
            var monday = this.get('selectedMonday');
            var sunday = this.get('selectedSunday');
            if (monday && sunday) {
                if (moment(monday).month() === moment(sunday).month()) {
                    return moment(monday).format('D') + " - " + moment(sunday).format('D MMMM YYYY');
                } else {
                    return moment(monday).format('D MMMM') + " - " + moment(sunday).format('D MMMM YYYY');
                }
            }
        }.property('selectedMonday', 'selectedSunday'),

        _dateLabelMobile: function () {
            var monday = this.get('selectedMonday');
            var sunday = this.get('selectedSunday');
            if (monday && sunday) {
                if (moment(monday).month() === moment(sunday).month()) {
                    return moment(monday).format('D') + " - " + moment(sunday).format('D MMM YYYY');
                } else {
                    return moment(monday).format('D MMM') + " - " + moment(sunday).format('D MMM YYYY');
                }
            }
        }.property('selectedMonday', 'selectedSunday'),

        setDate: function setDate(date) {
            var self = this;
            var selectedDate = moment(date, 'DD/MM/YYYY').startOf('day');
            self.setStartAndEndOfWeek(selectedDate);
            var formattedDate = selectedDate.toISOString();
            self.set('selectedDate', formattedDate);
            _functionHelpers.default.callOrIgnore(this.get("onSelected"), formattedDate);
        },

        actions: {
            today: function today() {
                this.setDate();
            },


            getPreviousWeek: function getPreviousWeek() {
                var selectedDate = new Date(this.get('selectedMonday'));
                var diff = selectedDate.getDate() - 7;
                var dd = new Date(selectedDate.setDate(diff));
                var selectedDateString = moment(dd).format('DD/MM/YYYY').toString();
                Ember.Application.selectedDate = selectedDateString;
                this.setDate(selectedDateString);
            },

            getNextWeek: function getNextWeek() {
                var selectedDate = new Date(this.get('selectedMonday'));
                var diff = selectedDate.getDate() + 7;
                var dd = new Date(selectedDate.setDate(diff));
                var selectedDateString = moment(dd).format('DD/MM/YYYY').toString();
                Ember.Application.selectedDate = selectedDateString;
                this.setDate(selectedDateString);
            }
        }

    });
});