define('mycare-ember/components/mc-thread/messages/component', ['exports', 'mycare-ember/utils/mc-component', 'npm:responsive-toolkit'], function (exports, _mcComponent, _npmResponsiveToolkit) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('messages', undefined);
            this.initVar('_internalMessages', undefined);
            this.initVar('isSeeker', undefined);
            this.initVar('direction', _npmResponsiveToolkit.default.is("xs") ? 'up' : 'down');
            this.mergeMessages();
        },


        mergeMessages: function () {
            var _this = this;

            this.set('_internalMessages', this.get('messages'));
            var toRemove = Ember.A([]);
            (this.get('_internalMessages') || []).forEach(function (msg, index) {
                if (msg.get('isJobListing') && msg.get('isNew') && !msg.get('isProcessed')) {
                    msg.set('isProcessed', true);
                    var targetIndex = _this.get('direction') === 'up' ? index + 1 : index - 1;
                    var messageItem = _this.get('_internalMessages').objectAt(targetIndex);
                    if (messageItem) {
                        messageItem.setProperties({
                            isJobListing: true,
                            jobListing: msg.get('jobListing')
                        });
                        toRemove.pushObject(index);
                    }
                }
            });
            toRemove.forEach(function (index) {
                if (index < _this.get('_internalMessages')) {
                    _this.get('_internalMessages').removeAt(index);
                }
            });
        }.observes('messages.[]', 'direction')

    });
});