define("mycare-ember/components/mc-popover-input/component", ["exports", "mycare-ember/utils/mc-component", "mycare-ember/utils/common"], function (exports, _mcComponent, _common) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        tagName: '',

        didReceiveAttrs: function didReceiveAttrs() {
            this.set('parentId', _common.default.createGuid());
            this.set('buttonId', _common.default.createGuid());
        },
        didRender: function didRender() {
            Ember.$('#' + this.get('parentId')).on('hide.bs.dropdown', function () {
                return false;
            });
        },


        actions: {
            open: function open() {
                this.set('_shouldRender', true);
                Ember.$('.dropdown.open').removeClass('open');
                Ember.$('#' + this.get('parentId')).addClass('open');
            },
            close: function close() {
                Ember.$('.dropdown.open').removeClass('open');
            },
            reset: function reset() {
                this.set('inputValue', null);
                this.set('value', this.get('inputValue'));
                this.send('close');
            },
            apply: function apply() {
                this.set('value', this.get('inputValue'));
                Ember.$('.dropdown.open').removeClass('open');
            }
        }
    });
});