define("mycare-ember/application/route", ["exports", "mycare-ember/mixins/switch-profile", "mycare-ember/utils/function-helpers"], function (exports, _switchProfile, _functionHelpers) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_switchProfile.default, {
        _routingService: Ember.inject.service('utils/routing'),
        _workerService: Ember.inject.service('worker'),
        _livechatService: Ember.inject.service('utils/livechat'),
        _messageService: Ember.inject.service('message'),
        _cordovaBadgeService: Ember.inject.service('utils/cordova-badge'),
        _facebookService: Ember.inject.service('fb'),
        _notification: Ember.inject.service('notification'),
        session: Ember.inject.service('session'),

        beforeModel: function beforeModel(transition) {
            this._super.apply(this, arguments);
            this.get('session').reconnectSessionOrLogout();
            Ember.$('.main-page-loader').remove();
            this.get('_livechatService').deactivateLiveChat();
            this.setPushNotification(transition);
            this.setRaygunUser();
            this.get('_routingService').init();
        },

        setRaygunUser: function setRaygunUser() {
            Raygun.setUser(this.get('session.authData.loggedInUserId'), false, false, false, false);
        },


        _watchNewNotification: function () {
            if (!this.get('session.authData.isAuthenticated')) return null;
            var self = this;
            self.get('_notification.getNotificationsAsync').perform({
                loggedInUserId: self.get('session.authData.loggedInUserId'),
                reload: true
            }).then(function (_ref) {
                var notifications = _ref.notifications,
                    numberOfViews = _ref.numberOfViews;

                self.get('eventbus').publish('notification:event:receivedNew', {
                    notifications: notifications,
                    numberOfViews: numberOfViews
                });
            });
        }.observes('session.authData.fireUser.hasNewNotification'),

        _watchNewMessage: function () {
            if (!this.get('session.authData.isAuthenticated')) return null;
            var self = this;
            self.get('_messageService.getUnReadThreadsAsync').perform({ reload: true }).then(function (_ref2) {
                var threads = _ref2.threads;

                self.get('eventbus').publish('threads:event:receivedNew', { threads: threads });
            });
            // reload workers so that worker info are refreshed on message page
            self.get('_workerService.getAllWorkersAsync').perform({
                profileId: this.get('session.authData.currentPersonId'),
                reload: true
            });
        }.observes('session.authData.fireUser.hasNewMessage'),

        setPushNotification: function setPushNotification() {
            var self = this;
            var mobileStatusController = self.controllerFor('mobile-status');
            var authController = self.controllerFor('auth');
            Ember.run.later(function () {
                mobileStatusController.set('isActivated', false);
            }, 10000);
            document.addEventListener("deviceready", onDeviceReady, false);
            document.addEventListener("pause", pause, false);
            document.addEventListener("resume", onResume, false);

            function pause() {
                mobileStatusController.set('isActivated', true);
            }

            function onResume() {
                self.get('_cordovaBadgeService').clear();
            }

            function onDeviceReady() {
                mobileStatusController.set('isActivated', false);
                var push = PushNotification.init({
                    "android": {
                        senderID: self.get('env').getApp('SENDER_ID'),
                        "icon": "icon",
                        "iconColor": "#81368b",
                        "sound": true,
                        "vibrate": true,
                        "forceShow": true
                    },
                    "ios": {
                        senderID: self.get('env').getApp('SENDER_ID'),
                        alert: "true",
                        badge: "true",
                        sound: true,
                        vibrate: true,
                        icon: "icon",
                        iconColor: "#81368b"
                    }
                });
                push.on('registration', function (data) {
                    console.log('PNSId', data.registrationId);
                    authController = self.controllerFor('auth');
                    authController.set('PNSId', data.registrationId);
                });

                push.on('notification', function (data) {
                    var response = data.additionalData;
                    if (mobileStatusController.get('isActivated') === true) {
                        mobileStatusController.set('route', response.controller);
                        mobileStatusController.set('responseId', response.id);
                        if (response.controller.toLowerCase() === 'message') {
                            self.transitionTo('threads.detail', mobileStatusController.get('responseId'));
                        } else if (response.controller.toLowerCase() === 'timesheet') {
                            var timesheetId = mobileStatusController.get('responseId');
                            if (Ember.isEmpty(timesheetId)) {
                                if (this.get('session.authData.loggedInUser.profileType').toLowerCase() === 'worker') {
                                    self.transitionTo('carers.manage.index.index');
                                } else {
                                    self.transitionTo('seekers.manage.index.index');
                                }
                            } else {
                                self.store.find('timesheet', timesheetId).then(function () {
                                    self.store.find('person', authController.get('loggedInUserId')).then(function (loggedInUser) {
                                        if (loggedInUser.get('profileType').toLowerCase() === 'worker') {
                                            self.transitionTo('carers.manage.my-schedule.schedule-detail.index', timesheetId);
                                        } else {
                                            self.transitionTo('seekers.manage.schedule-workers.index.schedule-detail', timesheetId);
                                        }
                                    });
                                });
                            }
                        }
                    }
                    mobileStatusController.set('isActivated', false);
                });

                push.on('error', function (e) {
                    console.error(e);
                    this.get('logger').log(e);
                });
            }
        },
        setAppVersion: function setAppVersion(controller) {
            var env = this.get('env');
            controller.set('appVersion', env.getApp('ENV_NAME') + " " + env.getApp('VERSION'));
            controller.set('numberOfUnReadMessages', 0);
            if (env.getApp('ENV_NAME') !== 'production') {
                console.info('INFO: ************************************************');
                console.info('INFO: MyCare Environment: ', "" + env.getApp('ENV_NAME'));
                console.info('INFO: MyCare Version    : ', "" + env.getApp('VERSION'));
                console.info('INFO: ************************************************');
            }
            Raygun.setVersion(env.getApp('ENV_NAME') + "-" + env.getApp('VERSION'));
        },


        setupController: function setupController(controller) {
            this._super.apply(this, arguments);
            this.setAppVersion(controller);
            this.get('_facebookService').FBInit().catch(function (e) {
                console.log(e);
            });
            _functionHelpers.default.callOrIgnoreWithContext(controller.setup, controller);
        },

        actions: {
            switchProfile: function switchProfile(type, personId, personName, redirectKey) {
                this.set('session.authData.currentProfileRole', type.toLowerCase());
                this.setSwitchProfile(type, personId, personName);
                this.transitionToSelectRoute(type, redirectKey);
            },

            switchProfileNoTransition: function switchProfileNoTransition(type, personId, personName) {
                this.set('session.authData.currentProfileRole', type.toLowerCase());
                this.setSwitchProfile(type, personId, personName);
            },


            resetUnReadMessagesCount: function resetUnReadMessagesCount() {
                this.controller.set('numberOfUnReadMessages', 0);
            },

            cleanJobListingWizard: function cleanJobListingWizard() {
                localStorage.removeItem('wizardDataBackup-createJobListing');
            },
            error: function error(_error) {
                if (_error && _error.errors && _error.errors[0]) {
                    this.get('logger').log("Error status: " + _error.errors[0].status);
                }
                var self = this;
                if (_error && _error.errors && _error.errors[0].status === '404') {
                    self.transitionTo('/not-found');
                }
                throw _error;
            }
        }
    });
});