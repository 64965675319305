define('mycare-ember/carers/findwork/clients/index/favourites/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            onJobListingUnFavourited: function onJobListingUnFavourited(jobListingId) {
                this.set('model.favourites', this.get('model.favourites').filter(function (f) {
                    return f.get('jobListingId') !== jobListingId;
                }));
            }
        }
    });
});