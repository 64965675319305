define('mycare-ember/models/account-report', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        endsOn: _emberData.default.attr('string'),
        startsOn: _emberData.default.attr('string'),
        totalHours: _emberData.default.attr('number'),
        totalCostClient: _emberData.default.attr('number'),
        totalCostWorker: _emberData.default.attr('number'),
        totalBeforeTaxWorker: _emberData.default.attr('number'),
        totalTaxWorker: _emberData.default.attr('number'),

        totalHoursInMinutes: function () {
            return this.get('totalHours') * 60;
        }.property('totalHours')
    });
});