define('mycare-ember/components/mc-booking/visit-breakdown-week/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({

        _wizardService: Ember.inject.service('utils/booking-wizard'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('milageSummaryTotal', undefined);
            this.initVar('expensesSummaryTotal', undefined);
            this.initVar('totalPayment', undefined);
        }
    });
});