define('mycare-ember/controllers/partials/manage/timesheet-activity-view', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    auth: Ember.inject.controller('auth'),
    timesheetController: Ember.inject.controller('seekers.manage.schedule-workers.index.schedule-detail'),
    isPayment: false,
    setup: function () {
      var self = this;
      self.set('isFirstObject', false);
      self.set('isLastObject', false);
      var activitiesLength = self.get('timesheetController.model.activities.length');
      if (activitiesLength === 1) {
        self.set('isFirstObject', true);
        self.set('isLastObject', true);
      } else if (self.get('model.sortId') === 0) {
        self.set('isFirstObject', true);
      } else if (self.get('model.sortId') === activitiesLength - 1) {
        self.set('isLastObject', true);
      }
    }.on('init').observes('model.sortId'),

    actions: {
      save: function save() {
        var self = this;
        self.get('model').save();
      },

      increasePosition: function increasePosition() {
        var self = this;
        self.get('timesheetController').increasePosition(self.get('model'));
      },

      decreasePosition: function decreasePosition() {
        var self = this;
        self.get('timesheetController').decreasePosition(self.get('model'));
      },

      remove: function remove() {
        var self = this;
        self.get('model').deleteRecord();
        self.get('model').save();
      }
    }
  });
});