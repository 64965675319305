define("mycare-ember/components/mc-submit-dss-form/component", ["exports", "mycare-ember/utils/mc-modal-component", "mycare-ember/mixins/validator", "mycare-ember/utils/Validators", "mycare-ember/utils/function-helpers"], function (exports, _mcModalComponent, _validator, _Validators, _functionHelpers) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend(_validator.default, {

        _referenceModelsService: Ember.inject.service('reference-models'),
        _profileService: Ember.inject.service('profile'),

        init: function init() {
            //_componentId needs to be declared before super
            this.initVar('_componentId', 'mc-submit-dss-form');

            this._super.apply(this, arguments);

            this.initVar('channelId', undefined);
            this.initVar('onSubmitted', undefined);
            this.initVar('_isLoading', false);
            this.initVar('_formData', {
                nhiNumber: [],
                ethnicities: [],
                disabilities: [],
                fundingTypes: [],
                organisation: [],
                fundingHost: undefined
            });
        },
        willSetupOnce: function willSetupOnce() {
            this._super.apply(this, arguments);
            this._setupFormData();
        },
        _setupFormData: function _setupFormData() {
            var _this = this;

            this.get('_referenceModelsService.getEthnicitiesAsync').perform().then(function (items) {
                _this.set('_ethnicities', items);
            });
            this.get('_referenceModelsService.getDisabilitiesAsync').perform().then(function (items) {
                _this.set('_disabilities', items);
            });
            this.get('_referenceModelsService.getOrganisationsAsync').perform().then(function (items) {
                _this.set('_organisations', items);
            });
            this.get('_referenceModelsService.getFundingTypesAsync').perform().then(function (items) {
                _this.set('_fundingTypes', items);
            });
            this.get('_referenceModelsService.getFundingHostsAsync').perform().then(function (items) {
                _this.set('fundingHosts', items);
            });

            this._resetValidation();
            this.addValidation('_formData.nhiNumber', 'nhiNumber', "NHI Number", [_Validators.default.isRequired]);
            this.addValidation('_formData.ethnicities.[]', 'ethnicities', "Ethnicity", [_Validators.default.isRequired]);
            this.addValidation('_formData.disabilities.[]', 'disabilities', "Disabilities", [_Validators.default.isRequired]);
            this.addValidation('_formData.fundingTypes.[]', 'fundingTypes', "Funding types", [_Validators.default.isRequired]);
            this.addValidation('_formData.organisation', 'organisation', "Organisation", [_Validators.default.isRequired]);
            this.addValidation('_formData.fundingHost', 'fundingHost', "Funding host", [_Validators.default.isRequired]);
            this.addValidation('_formData.consent', 'consent', "Consent", [this._validateConsent]);
        },
        _validateConsent: function _validateConsent(name, value) {
            return {
                result: value === true,
                message: name + " is required"
            };
        },


        actions: {
            close: function close() {
                this._close();
            },
            save: function save() {
                var _this2 = this;

                if (!this._validate()) {
                    return;
                }
                this.set('_isLoading', true);
                this.get('_profileService.submitDssFormAsync').perform({
                    personId: this.get('client.id'),
                    dssFormData: {
                        organisation: this.get('_formData.organisation'),
                        fundingTypes: this.get('_formData.fundingTypes'),
                        ethnicities: this.get('_formData.ethnicities'),
                        disabilities: this.get('_formData.disabilities'),
                        nhiNumber: this.get('_formData.nhiNumber'),
                        fundingHost: this.get('_formData.fundingHost')
                    }
                }).then(function (person) {
                    _functionHelpers.default.callOrIgnore(_this2.get('onSubmitted'), person);
                    _this2.set('_isLoading', false);
                    Ember.$('.modal').modal('hide');
                });
            }
        }

    });
});