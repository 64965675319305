define('mycare-ember/components/mc-booking/timesheets-preview/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('timesheetSummary', undefined);
            this.initVar('contract', undefined);
        },
        didInsertElement: function didInsertElement() {
            this.$('.visits-preview').css('overflowX', 'hidden');
            this.$('.visits-preview').css('overflowY', 'scroll');
            this.$('.visits-preview').css('overflowY', 'scroll');
            this.$('.visits-preview').css('maxHeight', $(document).height() - 260);
        }
    });
});