define('mycare-ember/components/mc-nav/authenticated/messages/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        tagName: 'li',
        classNames: ['dropdown'],
        _messageService: Ember.inject.service('message'),
        _cordovaBadgeService: Ember.inject.service('utils/cordova-badge'),
        messageSortingDesc: ['hasUnReadMessages:desc', 'message.sentOn:desc'],
        sortedThread: Ember.computed.sort('_unreadThreads', 'messageSortingDesc'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('_unreadThreads', []);
            this.initVar('_numberOfUnReadMessages', 0);
            this.initVar('loggedInUserId', undefined);

            this.getThreads();
        },


        paginatedThread: function () {
            return this.get('sortedThread').slice(0, 5);
        }.property('sortedThread'),

        getThreads: function getThreads() {
            var _this = this;

            var forceReload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

            this.get('_messageService').peekThreads().then(function (threads) {
                if (_this.isDestroyed) {
                    return;
                }
                if (Ember.isEmpty(threads) || forceReload) {
                    return _this.get('_messageService.getThreadsAsync').perform({
                        loggedInUserId: _this.get('loggedInUserId'),
                        reload: true
                    });
                }
                return threads;
            }).then(function (threads) {
                if (_this.isDestroyed) {
                    return;
                }
                _this.set('_unreadThreads', threads);
            }).finally(function () {
                _this.set('_isLoading', false);
            });
        },


        hasNewMessages: function () {
            this.getThreads(true);
        }.observes('session.authData.fireUser.hasNewMessage'),

        _updateCordorvaBadge: function () {
            this.get('_cordovaBadgeService').setBadgeNumber(this.get('session.authData.fireUser.unreadMessageCount'));
        }.observes('session.authData.fireUser.unreadMessageCount'),

        actions: {
            reloadMessages: function reloadMessages() {
                this.getThreads(true);
            }
        }
    });
});