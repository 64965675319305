define("mycare-ember/carers/manage/my-schedule/schedule-detail/note/route", ["exports", "mycare-ember/utils/mc-worker-submit-route"], function (exports, _mcWorkerSubmitRoute) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcWorkerSubmitRoute.default.extend({
        setupControllerProcess: function setupControllerProcess(controller) {
            controller.setupController();
        }
    });
});