define('mycare-ember/components/mc-timesheet/form-deleted/component', ['exports', 'mycare-ember/utils/mc-modal-component', 'mycare-ember/mixins/get-date-of-week', 'mycare-ember/mixins/timesheet-is-single-overnight', 'mycare-ember/mixins/account-pending-balance', 'mycare-ember/utils/common'], function (exports, _mcModalComponent, _getDateOfWeek, _timesheetIsSingleOvernight, _accountPendingBalance, _common) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend(_getDateOfWeek.default, _timesheetIsSingleOvernight.default, _accountPendingBalance.default, {
        timesheetService: Ember.inject.service('timesheet'),

        init: function init() {
            this.initVar('_componentId', 'mc-form-timesheet-delete');
            this._super.apply(this, arguments);
            this.initVar('_modalChannelId', _common.default.createGuid());
            this.initVar('onDeleted', undefined);
            this.initVar('channelId', 'global');
            this.set('_isLoading', undefined);
        },
        _onBeforeOpen: function _onBeforeOpen(timesheet) {
            this.set('model', timesheet);
        },


        actions: {
            deleteTimesheet: function deleteTimesheet() {
                var _this = this;

                this.set('_isLoading', true);
                this.get('timesheetService.removeVisitAsync').perform({
                    loggedInUserId: this.get('session.authData.loggedInUserId'),
                    timesheetId: this.get('model.id')
                }).then(function () {
                    _this.set('_isLoading', false);
                    _this.get('eventbus').publish('mc-form-timesheet-delete:event:deleted:' + _this.get('channelId'));
                    _this._close();
                });
            }
        }
    });
});