define('mycare-ember/components/mc-popover/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/common'], function (exports, _mcComponent, _common) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('placement', this.get('position') ? this.get('position') : 'right');
            this.initVar('buttonIcon', 'fa-question-circle');
            this.initVar('iconId', _common.default.createGuid());
            this.initVar('contentDivId', _common.default.createGuid());
        },
        _setupPopOver: function _setupPopOver() {
            var _this = this;

            var self = this;
            var popover = this.$('[data-toggle="popover"]');
            if (popover) {
                popover.popover({
                    placement: this.get('placement'),
                    trigger: 'manual',
                    content: function content() {
                        return self.$('.popover-content').html();
                    }

                });
                if (this.get('customClass')) {
                    popover.data('bs.popover').tip().addClass('visits-summary-popover');
                }

                var icon = this.$('#' + this.get('iconId'));

                Ember.$('body').on('click', function (e) {

                    var thisElement = _this.$(e.target);
                    if (thisElement && thisElement.hasClass('coverDiv')) {
                        return;
                    }

                    if (e.target === icon[0]) {
                        var isVisible = popover.data('bs.popover').tip().hasClass('in');
                        if (isVisible) {
                            popover.popover('hide');
                            return;
                        } else {
                            popover.popover('show');
                            return;
                        }
                    } else {
                        popover.popover('hide');
                    }
                });
            }
        },
        didInsertElement: function didInsertElement() {
            this._setupPopOver();
        },
        willSetupOnce: function willSetupOnce() {
            var _this2 = this;

            this.get('eventbus').subscribe('mc-popover:on:refresh:' + this.get('channelId'), function () {
                _this2._setupPopOver();
            });
        }
    });
});