define('mycare-ember/models/trust', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        description: _emberData.default.attr('string'),
        person: _emberData.default.belongsTo('person', { async: true }),
        trustType: _emberData.default.attr('number'),
        statusType: _emberData.default.attr('number'),
        createdOn: _emberData.default.attr('string'),

        isPoliceTrust: function () {
            return this.get('trustType') === 1;
        }.property('trustType'),

        isMojTrust: function () {
            return this.get('trustType') === 2;
        }.property('trustType'),

        isCheck: function () {
            return this.get('statusType') === 1;
        }.property('statusType'),

        isCompleted: function () {
            return this.get('statusType') === 2;
        }.property('statusType'),

        isPending: function () {
            return this.get('statusType') === 4;
        }.property('statusType'),

        isConviction: function () {
            return this.get('statusType') === 5;
        }.property('statusType')
    });
});