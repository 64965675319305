define('mycare-ember/controllers/partials/manage/timesheet-notes-manage-board', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        auth: Ember.inject.controller('auth'),
        manageNoteController: Ember.inject.controller('partials/manage/timesheet/modal-timesheet-entry-notes'),
        actions: {
            editNote: function editNote(note) {
                var self = this;
                self.get('manageNoteController').setup({}, note, 'Edit note');
                Ember.$('#modal-timesheet-entry-notes').modal('show');
            },

            deleteNote: function deleteNote(note) {
                note.deleteRecord();
                note.save();
            }
        }
    });
});