define("mycare-ember/seekers/findhelp/profile/route", ["exports", "mycare-ember/mixins/seeker_only", "mycare-ember/utils/mc-protected-route", "mycare-ember/mixins/nps-mixin_route"], function (exports, _seeker_only, _mcProtectedRoute, _npsMixin_route) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcProtectedRoute.default.extend(_seeker_only.default, _npsMixin_route.default, {

        actions: {
            didTransition: function didTransition() {
                Ember.$("body").addClass("page-profile");
            }
        }
    });
});