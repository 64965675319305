define("mycare-ember/me/account/address/route", ["exports", "mycare-ember/utils/mc-protected-route"], function (exports, _mcProtectedRoute) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcProtectedRoute.default.extend({
        _profileService: Ember.inject.service('profile'),

        model: function model() {
            var self = this;
            return self.store.findRecord('person', this.get('session.authData.loggedInUserId'));
        },

        actions: {
            willTransition: function willTransition() {
                this.controller.get('model.firstAddress').rollbackAttributes();
            }
        }
    });
});