define('mycare-ember/controllers/partials/listing-process-steps', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        steps: [],
        selectedSteps: [],

        setSteps: function () {
            var self = this;
            self.get('steps').clear();
            var isBeforeCurrentStep = true;
            var selectedSteps = self.get('selectedSteps');
            var colSpan = 'col-xs-4';
            if (selectedSteps.length > 3) {
                colSpan = 'col-xs-3';
            }

            for (var i = 0; i < selectedSteps.length; i++) {
                var thisCssClass = 'ember-view bs-wizard-step complete ' + colSpan;
                if (isBeforeCurrentStep === false) {
                    thisCssClass = 'ember-view bs-wizard-step ' + colSpan;
                }
                if (selectedSteps[i].isCurrentStep === true) {
                    thisCssClass = 'ember-view bs-wizard-step active ' + colSpan;
                    isBeforeCurrentStep = false;
                }
                var step = Ember.Object.create({
                    cssClass: thisCssClass,
                    content: selectedSteps[i].text
                });

                self.get('steps').pushObject(step);
            }
        }.observes('selectedSteps')
    });
});