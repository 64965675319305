define('mycare-ember/components/mc-timesheet/entry-rate-worker/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('timesheet', undefined);
            this.initVar('isShowPriceSummary', true);
            this.initVar('isApprovedOrPaid', undefined);
        },
        didInsertElement: function didInsertElement() {
            this.$('[data-toggle="popover"]').data('content', this.$('#popover-content').html());
            this.$('[data-toggle="popover"]').popover();
        }
    });
});