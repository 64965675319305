define('mycare-ember/services/utils/logger', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _env: Ember.inject.service('utils/env'),

        log: function log() {
            if (this.get('_env').getApp('ENABLE_LOGGING') === true) {
                var _console;

                (_console = console).log.apply(_console, arguments);
            }
        },
        error: function error() {
            if (this.get('_env').getApp('ENABLE_LOGGING') === true) {
                var _console2;

                (_console2 = console).error.apply(_console2, arguments);
            }
        },
        info: function info() {
            if (this.get('_env').getApp('ENABLE_LOGGING') === true) {
                var _console3;

                (_console3 = console).info.apply(_console3, arguments);
            }
        },
        warn: function warn() {
            if (this.get('_env').getApp('ENABLE_LOGGING') === true) {
                var _console4;

                (_console4 = console).warn.apply(_console4, arguments);
            }
        },
        deprecation: function deprecation(message) {
            var messageData = 'DEPRECATION: ' + message;
            Raygun.send(new Error(messageData), null, ['deprecation']);
            if (this.get('_env').getApp('ENABLE_LOGGING') === true) {
                console.warn(messageData);
            }
        },
        deprecateMethod: function deprecateMethod(_ref) {
            var oldMethod = _ref.oldMethod,
                newMethod = _ref.newMethod;

            this.deprecation(oldMethod + ' is deprecated. Please use ' + newMethod + '.');
        }
    });
});