define("mycare-ember/components/mc-joblisting-card/detail/component", ["exports", "mycare-ember/utils/mc-component"], function (exports, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        _jobListingService: Ember.inject.service('job-listing'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('jobListing', undefined);
            this.initVar('onClick', undefined);
            this.initVar('favouritePersonId', undefined);

            this.initVar('_isFavourite', false);
        },
        didReceiveAttrs: function didReceiveAttrs() {
            var _this = this;

            this.get('_jobListingService.isFavouriteAsync').perform({
                personId: this.get('favouritePersonId'),
                jobListingId: this.get('jobListing.id')
            }).then(function (result) {
                if (_this.isDestroyed) {
                    return;
                }
                _this.set('_isFavourite', result);
            });
        },


        _noFixedHours: function () {
            return this.get('_jobListingService').hasFixedHours(this.get('jobListing'));
        }.property('jobListing.hoursPerWeek'),

        actions: {
            viewJobListing: function viewJobListing(job) {
                var self = this;
                var auth = self.get('auth');
                localStorage.setItem('triggerNextJobSearch', false);
                localStorage.setItem('browseJobScrollId', job.get('id'));
                if (!self.get('auth.isAuthenticated')) {
                    Ember.$('#modal-user-not-login').modal('show');
                    return;
                } else if ((auth.get('isSeeker') || auth.get('isWorkerOrganiser')) && auth.get('currentProfileRole') === self.get('env').get('ORGANISER_PROFILE_TYPE')) {
                    //will change, when person id add the jobsearchlisting object
                    Ember.$('#modal-user-is-seeker').modal('show');
                    return;
                }
                self.transitionToRoute('browse.jobs.index.detail', job.get('id'));
            },

            toggleFavourite: function toggleFavourite() {
                var _this2 = this;

                // Eargely highlight the icon
                this.toggleProperty('_isFavourite');
                return this.get('_jobListingService').addOrRemoveFavouritesAsync(this.get('favouritePersonId'), this.get('jobListing.id')).then(function (result) {
                    if (_this2.isDestroyed) {
                        return;
                    }
                    _this2.set('_isFavourite', result);
                });
            }
        }

    });
});