define('mycare-ember/carers/manage/my-schedule/expense-breakdown/index/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        actions: {
            onNavBack: function onNavBack() {
                this.transitionToRoute('carers.manage.my-schedule.schedule-detail', this.get('timesheet.id'));
            }
        }
    });
});