define('mycare-ember/seekers/manage/schedule-workers/index/new/add-payment/controller', ['exports', 'mycare-ember/mixins/creditcard-add-process'], function (exports, _creditcardAddProcess) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_creditcardAddProcess.default, {
        newVisitsController: Ember.inject.controller('seekers/manage/schedule-workers/index/new-timesheet'),
        duplicateVisitError: [],

        setup: function setup() {
            this.set('duplicateVisitError', []);
            if (Ember.isEmpty(this.get('newVisitsController.timesheets'))) {
                this.transitionToRoute('seekers.manage.schedule-workers.index');
            } else {
                this.send('addCreditCard');
            }
        },
        creditCardProcess: function creditCardProcess() {
            var self = this;
            self.get('model.accounts').then(function (accounts) {
                return Ember.RSVP.all(accounts.map(function (acc) {
                    return Ember.RSVP.resolve(acc.get('accountType'));
                })).then(function () {
                    return accounts.filter(function (acc) {
                        return acc.get('accountType.id') !== '4';
                    }).objectAt(0);
                });
            }).then(function (selectedAccount) {
                var afterFunction = function afterFunction(creditCard) {
                    self.set('creditCardError', '');
                    selectedAccount.set('preferredCreditCard', creditCard.get('id'));
                    selectedAccount.save().then(function () {
                        self.get('newVisitsController').createTimeSheetsOrContract();
                    });
                };
                var errorHandle = function errorHandle(errorMessage) {
                    self.set('creditCardError', errorMessage);
                };
                self.addCreditCardProcess(selectedAccount, afterFunction, errorHandle);
            });
        },


        watchIsSaving: function () {
            if (Ember.isEmpty(this.get('newVisitsController.selectedContract')) === false) {
                if (this.get('newVisitsController.isSaving') === true) {
                    Ember.$('#adding-timesheets').modal('show');
                } else {
                    Ember.$('#adding-timesheets').modal('hide');
                }
            }
        }.observes('newVisitsController.isSaving'),

        actions: {
            addCreditCard: function addCreditCard() {
                var self = this;
                self.set('isAllowGetCreditCard', false);
                self.set('customTitle', '');
                self.set('creditCardError', '');
                self.creditCardProcess();
            }
        }
    });
});