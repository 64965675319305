define('mycare-ember/components/mc-auth/facebook/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/function-helpers'], function (exports, _mcComponent, _functionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.initVar('text', 'Continue with Facebook');
            this.initVar('size', undefined);
            this.initVar('onLoggingIn', undefined);

            this.set('_isLoading', false);

            this.get('session').on(this.get('session').FACEBOOK_LOGGING_IN, function () {
                _this.set('_isLoading', true);
                Ember.run.later(function () {
                    _this.set('_isLoading', false);
                }, 12000);
            });

            this.get('session').on(this.get('session').FACEBOOK_LOGGEDIN, function () {
                _this.set('_isLoading', false);
            });
        },


        actions: {
            loginWithFacebook: function loginWithFacebook() {
                _functionHelpers.default.callOrIgnore(this.get('onLoggingIn'));
                this.get('session').loginToFacebook();
            }
        }
    });
});