define("mycare-ember/components/mc-file-uploader/component", ["exports", "mycare-ember/utils/mc-modal-component", "mycare-ember/utils/function-helpers"], function (exports, _mcModalComponent, _functionHelpers) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend({

        _modalHelper: Ember.inject.service('utils/modal-helper'),
        _profileService: Ember.inject.service('profile'),
        _fileService: Ember.inject.service('file'),

        fileUploadUrl: function () {
            return this.get('env').getApp('API_HOST') + '/api/FileUpload';
        }.property(),

        _errors: [],

        init: function init() {
            //_componentId needs to be declared before super
            var self = this;
            self.initVar('_componentId', 'mc-file-uploader');
            self._super.apply(self, arguments);
            self.initVar('files', '');
            self.initVar('selectedFiles', '');
            self.initVar('onUploaded', undefined);

            Ember.$('#' + self.get('channelId')).on('hidden.bs.modal', function () {
                self.get("_errors").clear();
            });
        },


        sortedFiles: function () {
            var _this = this;

            if (this.get('selectedFiles')) {
                return this.get('person.files').filter(function (f) {
                    return !_this.get('selectedFiles').find(function (s) {
                        return s.get('url') === f.get('url');
                    });
                });
            }
            return [];
        }.property('person.files.[]', 'selectedFiles.[]'),

        actions: {
            chooseFile: function chooseFile(file) {
                var self = this;
                self.get('selectedFiles').pushObject(file);
                this.get('eventbus').publish("mc-file-uploader:on:close:" + this.get('channelId'));
                _functionHelpers.default.callOrIgnore(self.get('onUploaded'), file);
            },

            selectLocalFile: function selectLocalFile(file) {
                var self = this;
                self.set('isLoading', true);
                this.get('_fileService.createFileAsync').perform({
                    fileUrl: file.get('url'),
                    fileName: file.get('fileName'),
                    personId: self.get('person.id')
                }).then(function (tempFile) {
                    self.set('isLoading', false);
                    self.get('person.files').pushObject(tempFile);
                    self.get('selectedFiles').pushObject(tempFile);
                    _functionHelpers.default.callOrIgnore(self.get('onUploaded'), tempFile);
                    self.get('eventbus').publish("mc-file-uploader:on:close:" + self.get('channelId'));
                });
            }
        }
    });
});