define('mycare-ember/models/event', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        title: _emberData.default.attr('string'),
        description: _emberData.default.attr('string'),
        emailAddresses: _emberData.default.attr('string'),
        startsOn: _emberData.default.attr('string'),
        endsOn: _emberData.default.attr('string'),
        person: _emberData.default.belongsTo('person', { async: true })
    });
});