define('mycare-ember/components/mc-pagination/item/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        tagName: '',
        actions: {
            click: function click() {
                this.sendAction('selectPage', this.get('model'));
            }
        }
    });
});