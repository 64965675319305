define('mycare-ember/components/mc-modal-timesheet-copy/worker-item/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/common'], function (exports, _mcComponent, _common) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({

        isSelected: false,
        isDisabled: false,
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('_popoverChannelId', _common.default.createGuid());
        },
        didReceiveAttrs: function didReceiveAttrs() {
            this.set('isSelected', this.get('worker.isValid'));
            this.set('isDisabled', !this.get('worker.isValid'));
        },


        actions: {
            selectWorker: function selectWorker() {
                this.sendAction('onSelected', this.get('worker'));
            }
        }
    });
});