define('mycare-ember/instance-initializers/startup-instance', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;
    function initialize(appInstance) {
        appInstance.lookup('service:reference-models').get('fetchPublic').perform('test');
        window.AppInstance = appInstance;
    }

    exports.default = {
        name: 'startup-instance',
        initialize: initialize
    };
});