define("mycare-ember/seekers/findhelp/applicants/contract/controller", ["exports", "mycare-ember/mixins/math-number-format"], function (exports, _mathNumberFormat) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_mathNumberFormat.default, {
        auth: Ember.inject.controller('auth'),
        queryParams: ['reload'],
        reload: false,
        isNewHourlyRate: true,
        errors: [],
        newRateErrors: [],
        editContractController: Ember.inject.controller('partials/manage/contract/modal-contract-end-contract'),
        termsAndConditionController: Ember.inject.controller('partials/manage/contract/modal-terms-and-conditions'),
        hourlyRate: '',
        nightlyRate: '',
        hasNewRates: false,
        clientNameString: '',
        isShowMainContractView: false,

        setup: function setup() {
            var self = this;
            self.errors.clear();
            self.set('hourlyRate', self.get('model.contract.hourlyRate'));
            self.set('nightlyRate', self.get('model.contract.nightlyRate'));
            self.set('isShowMainContractView', false);
            self.setClientString();
            self.watchRateFees();
            self.watchOverNightRate();
        },


        hasHourlyRate: function () {
            return this.get('model.contract.hourlyRate') > 0 && !Ember.isEmpty(this.get('model.contract.hourlyRate'));
        }.property('model.contract.hourlyRate'),

        hasFixedRate: function () {
            return this.get('model.contract.nightlyRate') > 0 && !Ember.isEmpty(this.get('model.contract.nightlyRate'));
        }.property('model.contract.nightlyRate'),

        watchOverNightRate: function () {
            var self = this;
            self.set('hasOverNightRate', Ember.isEmpty(self.get('model.contract.nightRate')) === false);
        }.observes('model.contract.nightRate'),

        setClientString: function setClientString() {
            var self = this;
            var nameString = '';

            if (self.get('model.contract.createdBy.id') === self.get('model.contract.clientPerson.id')) {
                nameString = self.get('model.contract.clientName') + ' ("Client")';
                self.set('clientNameString', nameString.htmlSafe());
            } else {
                nameString = self.get('model.contract.organiserName') + ' on behalf of ' + self.get('model.contract.clientName') + ' ("Client")';
                self.set('clientNameString', nameString.htmlSafe());
            }
        },

        setStatusMessage: function () {
            var self = this;
            if (moment().utc().isAfter(self.get('model.contract.contractEndsOn'))) {
                self.set('statusMessage', 'Contract ended on ' + moment(new Date(self.get('model.contract.contractEndsOn'))).format('DD MMM YYYY HH:mm'));
            } else if (Ember.isEmpty(self.get('model.contract.clientSignedOn'))) {
                self.set('statusMessage', 'Please sign this contract.');
            } else if (Ember.isEmpty(self.get('model.contract.contractorSignedOn'))) {
                self.set('statusMessage', 'Please wait for contractor to sign this contract.');
            } else {
                self.set('statusMessage', 'Contract starts on ' + moment(new Date(self.get('model.contract.contractorSignedOn'))).format('DD MMM YYYY HH:mm'));
            }
        }.observes('model.contract.clientSignedOn', 'model.contract.isNewContact', 'model.contract.contractEndsOn', 'model.contract.contractorSignedOn'),

        isValidContract: function () {
            if (Ember.isNone(this.get('model.contract.contractEndsOn'))) {
                return true;
            }
            return moment().utc().isBefore(this.get('model.contract.contractEndsOn'));
        }.property('model.contract.contractEndsOn'),

        watchRateFees: function watchRateFees() {
            var self = this;
            if (self.get('model.contract.hourlyRate') > 0 && Ember.isEmpty(self.get('model.contract.hourlyRate')) === false) {
                self.set('hourlyFee', self.getRound(self.get('model.contract.hourlyRate') * self.get('model.contract.getServiceFee'), 2));
                self.set('hourlyFeeTotal', parseFloat(self.get('model.contract.hourlyRate')) + parseFloat(self.get('hourlyFee')));
            }
            if (self.get('model.contract.nightlyRate') > 0 && Ember.isEmpty(self.get('model.contract.nightlyRate')) === false) {
                self.set('nightlyFee', self.getRound(self.get('model.contract.nightlyRate') * self.get('model.contract.getServiceFee'), 2));
                self.set('nightlyFeeTotal', parseFloat(self.get('model.contract.nightlyRate')) + parseFloat(self.get('nightlyFee')));
            }
        },

        actions: {
            onNavBack: function onNavBack() {
                if (Ember.isNone(this.get('backTo'))) {
                    this.transitionToRoute('seekers.findhelp.applicants.index');
                } else {
                    if (this.get('backToOpts') && this.get('backToOpts') !== 'undefined') {
                        this.get('eventbus').publish('global:on:changeRoute', this.get('backTo'), this.get('backToOpts'));
                    } else {
                        this.get('eventbus').publish('global:on:changeRoute', this.get('backTo'));
                    }
                }
            },


            updateContract: function updateContract() {
                var self = this;
                self.get('editContractController').set('model', self.get('model.contract'));
                self.get('editContractController').setup();
                Ember.$('#modal-contract-end-contract').modal();
            },

            showTermsAndConditions: function showTermsAndConditions() {
                var self = this;
                self.get('termsAndConditionController').set('contract', self.get('model.contract'));
                self.get('termsAndConditionController').set('model.contract', self.get('model.contract.contractDetail'));
                Ember.$('#modal-terms-and-conditions').modal();
            },


            scheduleWorker: function scheduleWorker() {
                var self = this;
                self.transitionToRoute('seekers.manage.schedule-workers.index.visits.all');
            },

            toggleMainContractView: function toggleMainContractView() {
                this.toggleProperty('isShowMainContractView');
            }
        }

    });
});