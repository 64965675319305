define("mycare-ember/mixins/form-checker", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        isEmptyField: function isEmptyField(field) {
            return !field;
        },

        isEmail: function isEmail(email) {
            var sub = !Ember.isNone(email) && !Ember.isNone(email.trim) ? email.trim() : email;
            var emailRegx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !Ember.isNone(sub) && !Ember.isNone(sub.match(emailRegx));
        },

        isNameCorrect: function isNameCorrect(name) {
            var re = /^[A-z -.]+$/;
            return re.test(name);
        },

        isNumberAndLetterOnly: function isNumberAndLetterOnly(str) {
            var re = /^([a-zA-Z0-9 _-]+)$/;
            return re.test(str);
        },
        hasSpecialCharacters: function hasSpecialCharacters(str) {
            return !/[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/g.test(str);
        },
        addressHasSpecialCharacters: function addressHasSpecialCharacters(str) {
            return !/[-!$%^&*()_+|~=`{}\[\]:";<>?,.\/]/g.test(str);
        }
    });
});