define('mycare-ember/register/seeker-register/payment-type/route', ['exports', 'mycare-ember/utils/wizard-step-route', 'mycare-ember/utils/function-helpers', 'mycare-ember/mixins/register/isEnableRegisterProcess', 'mycare-ember/mixins/protected_route', 'mycare-ember/mixins/scroll_top'], function (exports, _wizardStepRoute, _functionHelpers, _isEnableRegisterProcess, _protected_route, _scroll_top) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _wizardStepRoute.default.extend(_isEnableRegisterProcess.default, _protected_route.default, _scroll_top.default, {
        stepName: 'payment-type',
        parentControllerName: 'register/seeker-register',

        beforeModel: function beforeModel() {
            this.isAuthenticated();
            if (this.controllerFor('auth').get('isAuthenticated')) {
                this.isEnableRegisterProcess();
            } else {
                this.transitionTo('index');
            }
        },
        setupController: function setupController(controller) {
            this._super.apply(this, arguments);
            _functionHelpers.default.callOrIgnore(controller.scrollTop);
            _functionHelpers.default.callOrIgnoreWithContext(controller.setup, controller);
        }
    });
});