define("mycare-ember/me/profiles/site-setup/route", ["exports", "mycare-ember/mixins/seeker_only", "mycare-ember/mixins/protected_route", "mycare-ember/utils/wizard-parent-route"], function (exports, _seeker_only, _protected_route, _wizardParentRoute) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _wizardParentRoute.default.extend(_protected_route.default, {

        _referenceModelService: Ember.inject.service('reference-models'),
        wizardId: 'createSite',

        model: function model() {
            return this.get('_referenceModelService.getSupportTypesAsync').perform().then(function (supportTypes) {
                return { supportTypes: supportTypes };
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model, Ember.Object.create({
                person: this.store.createRecord('person', {}),
                emails: [],
                supportTypes: model.supportTypes
            }));
        },


        stepInfoList: function stepInfoList(context, controller) {
            var self = context;
            return [{
                name: 'index',
                controller: self.controllerFor('me/profiles/site-setup/index'),
                parent: controller,
                routeName: 'me.profiles.site-setup.index',
                order: 1
            }, {
                name: 'done',
                routeName: 'me.profiles.site-setup.done',
                controller: self.controllerFor('me/profiles/site-setup/done'),
                parent: controller,
                isShowFooter: false,
                isShowHeader: true,
                order: 2
            }];
        }
    });
});