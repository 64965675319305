define('mycare-ember/utils/wizard-parent-route', ['exports', 'mycare-ember/utils/function-helpers'], function (exports, _functionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        stepInfoList: undefined,

        setupController: function setupController(controller, model, data) {
            var self = this;
            controller.set('wizardId', self.get('wizardId'));
            controller.set('model', model);
            if (data) {
                data.set('state', (JSON.parse(localStorage.getItem('wizardDataBackup-' + this.get('wizardId'))) || {}).state);
                controller.set('wizardData', data);
            }
            var stepInfoList = self.get('stepInfoList');
            if (!stepInfoList) {
                throw new Error('stepInfoList function must be defined in the WizardParentRoute');
            }
            controller.registerWizardSteps(stepInfoList(self, controller, model), data);
            _functionHelpers.default.callOrIgnoreWithContext(controller.setup, controller);
        },

        getWizardDataBackupKey: function getWizardDataBackupKey() {
            return 'wizardDataBackup-' + this.get('wizardId');
        }
    });
});