define('mycare-ember/components/multi-select/component', ['exports', 'mycare-ember/utils/common'], function (exports, _common) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        componentList: [],

        didInsertElement: function didInsertElement() {
            var self = this;

            if (Ember.isEmpty(self.get('modalId')) === true) {
                self.set('modalId', _common.default.createGuid());
            }

            if (!Ember.isEmpty(self.get('items'))) {
                self.set('componentList', self.get('items'));
                self.get('componentList').forEach(function (item) {
                    item.set('isChecked', false);
                });

                if (self.get('selectedValues.length') > 0) {
                    self.get('selectedValues').forEach(function (selectedItem) {
                        self.get('componentList').forEach(function (item) {
                            if (item.get('id') === selectedItem.get('id')) {
                                item.set('isChecked', true);
                            }
                        });
                    });
                }
            }
            Ember.run.schedule('afterRender', this, function () {
                Ember.$('#' + self.get('modalId')).on('hidden.bs.modal', function () {
                    Ember.$('body').addClass('fast-scroll');
                });
            });
        },


        reloadMultiSelect: function () {
            var self = this;
            if (!Ember.isEmpty(self.get('items'))) {
                self.set('componentList', self.get('items'));
                if (self.get('selectedValues.length') > 0) {
                    self.get('selectedValues').forEach(function (selectedItem) {
                        self.get('componentList').forEach(function (item) {
                            if (item.get('id') === selectedItem.get('id')) {
                                item.set('isChecked', true);
                            } else {
                                item.set('isChecked', false);
                            }
                        });
                    });
                } else {
                    self.get('componentList').forEach(function (item) {
                        item.set('isChecked', false);
                    });
                }
            }
        }.observes('items.[]'),

        reloadSelectedValues: function reloadSelectedValues() {
            var self = this;
            self.set('isEnableUpdate', false);
            self.get('componentList').forEach(function (item) {
                item.set('isChecked', false);
            });
            self.get('selectedValues').forEach(function (selectedItem) {
                self.get('componentList').forEach(function (item) {
                    if (!Ember.isNone(selectedItem) && item.get('id') === selectedItem.get('id')) {
                        item.set('isChecked', true);
                    }
                });
            });
            self.set('isEnableUpdate', true);
        },

        update: function () {
            var self = this;
            if (self.get('isEnableUpdate') === true) {
                self.get('selectedValues').clear();
                var list = self.get('componentList').filterBy('isChecked', true);
                self.get('selectedValues').pushObjects(list);
            }
        }.observes('componentList.@each.isChecked'),

        actions: {
            closeModal: function closeModal() {
                var self = this;
                Ember.$('#' + self.get('modalId')).modal('hide');
            },

            toggleSelectionModal: function toggleSelectionModal() {
                var self = this;
                Ember.$('#' + self.get('modalId')).modal('show');
                //Disable fast scrolling css, otherwise this css will block the modal for this modal
                Ember.$('body').removeClass('fast-scroll');
                self.reloadSelectedValues();
            },

            removeItem: function removeItem(id) {
                var self = this;
                self.get('componentList').forEach(function (item) {
                    if (item.get('id') === id) {
                        item.set('isChecked', !item.get('isChecked'));
                        self.get('selectedValues').removeObject(item);
                    }
                });
            }
        }
    });
});