define("mycare-ember/seekers/manage/circles/detail/route", ["exports", "mycare-ember/mixins/protected_route"], function (exports, _protected_route) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_protected_route.default, {
        beforeModel: function beforeModel(transition) {
            this.isAuthenticated(transition);
        },


        model: function model(params) {
            return this.store.findRecord('circle', params.circle_id);
        },

        setupController: function setupController(thisController, model) {
            var self = this;
            var controller = self.controllerFor('circles/detail');
            controller.set('model', model);
            controller.bindScrolling();
            var authCtrl = self.controllerFor('auth');
            if (model) {
                model.get('personCircles').then(function (personCircles) {
                    controller.set('isValidCircleOrganiser', false);
                    var managingCircles = personCircles.filter(function (personCircle) {
                        return personCircle.get('person.id') === authCtrl.get('loggedInUserId') && personCircle.get('role.name') === 'Organiser';
                    });
                    controller.set('isValidCircleOrganiser', !Ember.isEmpty(managingCircles));
                });
            }
        },


        renderTemplate: function renderTemplate() {
            var self = this;
            var controller = self.controllerFor('circles/detail');
            self.render('circles/detail', {
                into: 'seekers.manage.circles',
                controller: controller
            });
        },
        actions: {
            willTransition: function willTransition() {
                var controller = this.controllerFor('circles/detail');
                controller.unbindScrolling();
            }
        }
    });
});