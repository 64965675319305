define("mycare-ember/seekers/findhelp/route", ["exports", "mycare-ember/mixins/seeker_only", "mycare-ember/utils/mc-protected-route", "ember-concurrency", "mycare-ember/mixins/nps-mixin_route"], function (exports, _seeker_only, _mcProtectedRoute, _emberConcurrency, _npsMixin_route) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcProtectedRoute.default.extend(_seeker_only.default, _npsMixin_route.default, {
        livechatService: Ember.inject.service('utils/livechat'),

        setupController: function setupController() {
            this._super.apply(this, arguments);
            this.get('livechatService').deactivateLiveChat();
        }
    });
});