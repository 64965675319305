define("mycare-ember/controllers/partials/manage/index/timesheet-activity-and-note", ["exports", "mycare-ember/mixins/timesheet-status-type-mixin"], function (exports, _timesheetStatusTypeMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_timesheetStatusTypeMixin.default, {
        auth: Ember.inject.controller('auth'),
        sortProps: ['startsOn'],
        sortedEvents: Ember.computed.sort('events', 'sortProps'),
        hasPermission: false,
        isWorker: false,
        isBeyondScheduled: false,

        //new view attributes
        isNewView: true,
        hasActivities: false,
        manageNoteController: Ember.inject.controller('partials/manage/timesheet/modal-timesheet-entry-notes'),
        visitsController: Ember.inject.controller('seekers/manage/schedule-workers/index/index'),

        setup: function () {
            var self = this;
            self.set('hasActivityAndNote', false);
            if (Ember.isEmpty(self.get('model.activities')) === false || Ember.isEmpty(self.get('model.notes')) === false) {
                self.set('hasActivityAndNote', true);
                self.watchEntryStatus();
                self.setIsBeyondScheduled();
            }
        }.on('init'),

        actions: {
            // new view methods
            manageNotes: function manageNotes() {
                var self = this;
                var note = self.store.createRecord('timesheet-note', {});
                self.get('manageNoteController').setup(self.get('model'), note, 'Add note');
                Ember.$('#modal-timesheet-entry-notes').modal('show');
            },

            toAddVisits: function toAddVisits() {
                var self = this;
                self.get('visitsController').set('selectedDate', '');
                self.transitionToRoute('seekers.manage.schedule-workers.index.visits.all');
                Ember.run.later(function () {
                    self.get('visitsController').showNewTimesheet();
                }, 200);
            }
        }

    });
});