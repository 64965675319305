define("mycare-ember/mixins/mixpanel", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        mixpanelSignUp: function mixpanelSignUp(person) {
            console.log("Mixpanel Signup sent");
            mixpanel.register({
                "$name": person.get('firstName') + " " + person.get('lastName'),
                "$email": person.get('email'),
                "$created": new Date().toISOString(),
                "Person Id": person.get('id')
            });
            mixpanel.people.set({
                "$name": person.get('firstName') + " " + person.get('lastName'),
                "$email": person.get('email'),
                "$created": new Date().toISOString(),
                "Person Id": person.get('id')
            });
            mixpanel.track('Sign Up');
            mixpanel.alias(person.get('id'));
        },

        mixpanelLogin: function mixpanelLogin(person) {
            console.log("Mixpanel Login sent");
            var self = this;
            mixpanel.people.increment("# of Logins");
            mixpanel.init(this.get('env').get('mixpanel').token, {
                'loaded': function loaded() {
                    self.incrementSuperProperty("# of Logins");
                }
            });
            mixpanel.people.set({ "Last Login": new Date().toISOString() });
            mixpanel.register({ "Last Login": new Date().toISOString() });

            mixpanel.track('Login');

            if (!Ember.isNone(person)) {
                mixpanel.identify(person.get('id'));
            }
        },

        mixpanelCreateSupporterProfile: function mixpanelCreateSupporterProfile() {
            mixpanel.register({ "Is Supporter": true });
            mixpanel.people.set({ "Is Supporter": true });
            mixpanel.track('Create Supporter Profile');
        },

        mixpanelCreateWorkerProfile: function mixpanelCreateWorkerProfile(person, uploadedPicture) {
            var self = this;
            console.log("Mixpanel Create Worker Profile sent");

            mixpanel.register({
                "Location": person.get('location'),
                "How much work?": self.getArray(person.get('employmentTypes'), 'name'),
                "Hourly rates": self.getArray(person.get('hourlyRates'), 'title'),
                "Services offered": self.getArray(person.get('services'), 'name'),
                "Equipment experience": self.getArray(person.get('equipment'), 'name'),
                "Other information": self.getArray(person.get('requirements'), 'name'),
                "Languages": self.getArray(person.get('languages'), 'name'),
                "User Has Profile Picture": uploadedPicture,
                "Is Worker": true
            });
            mixpanel.people.set({
                "Location": person.get('location'),
                "How much work?": self.getArray(person.get('employmentTypes'), 'name'),
                "Hourly rates": self.getArray(person.get('hourlyRates'), 'title'),
                "Services offered": self.getArray(person.get('services'), 'name'),
                "Equipment experience": self.getArray(person.get('equipment'), 'name'),
                "Other information": self.getArray(person.get('requirements'), 'name'),
                "Languages": self.getArray(person.get('languages'), 'name'),
                "User Has Profile Picture": uploadedPicture,
                "Description": person.get('description'),
                "Interests": person.get('interests'),
                "Availability": person.get('availability'),
                "Is Worker": true
            });

            mixpanel.track('Create Worker Profile');
        },

        mixpanelCreateOrganiserAccount: function mixpanelCreateOrganiserAccount(person, uploadedPicture) {
            console.log("Mixpanel Create Organiser Account sent");

            mixpanel.register({
                "$name": person.get('firstName') + " " + person.get('lastName'),
                "Location": person.get('location'),
                "User Has Profile Picture": uploadedPicture
            });

            mixpanel.people.set({
                "$name": person.get('firstName') + " " + person.get('lastName'),
                "Location": person.get('location'),
                "User Has Profile Picture": uploadedPicture
            });

            mixpanel.track('Create Organiser Account');
        },

        mixpanelCreateClientProfile: function mixpanelCreateClientProfile(person, uploadedPicture, isSeekingSelf, isSeekingSomeone, isDss) {
            var self = this;
            console.log("Mixpanel Create Client Profile sent");

            var createdBy = '';

            if (isSeekingSelf === true) {
                mixpanel.register({ "Is Seeking For Self": true });
                mixpanel.people.set({ "Is Seeking For Self": true });
                createdBy = 'Self';
            }

            if (isSeekingSomeone === true) {
                mixpanel.register({ "Is Seeking For Someone Else": true });
                mixpanel.people.set({ "Is Seeking For Someone Else": true });
                createdBy = 'Someone Else';
            }

            mixpanel.register({
                "Is Organiser": true,
                "Is DSS": isDss,
                "Last Client Profile Created On": new Date().toISOString()
            });

            mixpanel.people.set({
                "Is Organiser": true,
                "Is DSS": isDss,
                "Last Client Profile Created On": new Date().toISOString()
            });

            mixpanel.track('Create Client Profile', {
                "Person Id": person.get('id'),
                "Client Name": person.get('firstName') + " " + person.get('lastName'),
                "Location": person.get('location'),
                "Gender": person.get('gender'),
                "Date of Birth": person.get('dateOfBirth'),
                "User Has Profile Picture": uploadedPicture,
                "Created By": createdBy,
                "Is DSS": isDss
            });

            mixpanel.people.increment("# of Client Profiles");
            mixpanel.init(this.get('env').get('mixpanel').token, {
                'loaded': function loaded() {
                    self.incrementSuperProperty("# of Client Profiles");
                }
            });
        },

        mixpanelTrustAndSafety: function mixpanelTrustAndSafety(person, address) {
            var self = this;
            console.log("Mixpanel Trust and Safety sent");

            self.mixpanelUpdateAddressAndPhone(person, address);

            mixpanel.register({ "Agreed MoJ Check": true });
            mixpanel.people.set({ "Agreed MoJ Check": true });
            mixpanel.track('Trust and Safety');
        },

        mixpanelAddExperience: function mixpanelAddExperience(experience) {
            console.log("Mixpanel Add Experience sent");

            mixpanel.people.increment("# of Experiences");
            mixpanel.people.set({ "Last Experience Created On": new Date().toISOString() });

            mixpanel.track('Add Experience', {
                "Experience Title": experience.get('title'),
                "Experience Organisation": experience.get('organisation'),
                "Experience Description": experience.get('description'),
                "Experience Started On": experience.get('startedOn'),
                "Experience Ended On": experience.get('endedOn')
            });
        },

        mixpanelFacebookShare: function mixpanelFacebookShare(isSignUp) {
            console.log("Mixpanel Share On Facebook sent");
            mixpanel.track('Share On Facebook', {
                "At End Of Sign Up": isSignUp
            });
            mixpanel.people.increment("# of Facebook Shares");
        },

        mixpanelCreateAgreementButtonClicked: function mixpanelCreateAgreementButtonClicked(fromHire) {
            console.log("Mixpanel Create agreement clicked");
            mixpanel.track('Create Agreement Button Clicked', {
                "From Hire Screen": fromHire
            });
            mixpanel.people.increment("# of Create Agreement Button Clicks");
        },

        mixpanelCreateAgreementNoThanks: function mixpanelCreateAgreementNoThanks() {
            console.log("Mixpanel Create agreement no thanks");
            mixpanel.track('Create Agreement No Thanks', {});
            mixpanel.people.increment("# of Create Agreement No Thanks");
        },

        mixpanelAgreementCreated: function mixpanelAgreementCreated() {
            console.log("Mixpanel Agreement created");
            mixpanel.track('Agreement Created', {});
            mixpanel.people.increment("# of Agreements");
        },

        mixpanelCreateAgreement: function mixpanelCreateAgreement(isSignUp) {
            console.log("Mixpanel Create agreement clicked");
            mixpanel.track('Share On Facebook', {
                "At End Of Sign Up": isSignUp
            });
            mixpanel.people.increment("# of Facebook Shares");
        },

        mixpanelHireWorker: function mixpanelHireWorker(application) {
            console.log("Mixpanel Hire Worker sent");
            mixpanel.track('Hire Worker', {
                "Worker Id": application.get('person.id'),
                "Worker Name": application.get('person.firstName') + ' ' + application.get('person.lastName'),
                "Job Application Id": application.get('id'),
                "Job Listing Id": application.get('jobListing.id')
            });
            mixpanel.people.increment("# of Worker Hires");
        },

        mixpanelAddQualification: function mixpanelAddQualification(qualification) {
            console.log("Mixpanel Add Qualification sent");

            mixpanel.people.increment("# of Qualifications");
            mixpanel.people.set({ "Last Qualification Created On": new Date().toISOString() });

            mixpanel.track('Add Qualification', {
                "Qualification Title": qualification.get('title'),
                "Qualification Organisation": qualification.get('organisation'),
                "Qualification Description": qualification.get('description'),
                "Qualification Started On": qualification.get('startedOn'),
                "Qualification Ended On": qualification.get('endedOn')
            });
        },

        mixpanelUpdateAddressAndPhone: function mixpanelUpdateAddressAndPhone(person, address) {
            console.log("Mixpanel Update Address and Phone sent");

            mixpanel.people.set({
                "Phone Number": person.get('phoneNumber'),
                "Street Address": address.get('number') + " " + address.get('street'),
                "Suburb": address.get('suburb'),
                "$city": address.get('city'),
                "Postcode": address.get('postcode')
            });
        },

        mixpanelWorkerProfileComplete: function mixpanelWorkerProfileComplete() {
            var self = this;
            console.log("Mixpanel Worker Profile Complete sent");

            self.mixpanelChangePublishStatus(true);
            mixpanel.track('Worker Profile Complete');
        },

        mixpanelChangePublishStatus: function mixpanelChangePublishStatus(publishStatus) {
            console.log("Mixpanel Change Publish Status sent");

            mixpanel.register({ "Is Published": publishStatus });
            mixpanel.people.set({ "Is Published": publishStatus });
            mixpanel.people.increment("# of Profile Publishes");
        },

        mixpanelPayment: function mixpanelPayment(person, amount, subscription) {
            var self = this;
            console.log("Mixpanel Payment sent");

            mixpanel.people.set({
                'Last Subscription Type': subscription
            });

            mixpanel.people.increment("Lifetime Revenue", amount);
            mixpanel.people.increment("# of Payments Made");
            mixpanel.init(this.get('env').get('mixpanel').token, {
                'loaded': function loaded() {
                    self.incrementSuperProperty("# of Payments Made");
                }
            });
            mixpanel.people.track_charge(amount, {
                '$time': new Date().toISOString()
            });

            mixpanel.track('Payment', {
                "Payment for": person.get('firstName') + " " + person.get('lastName'),
                "Subscription": subscription,
                "Subscription Amount": amount,
                "Payment On": new Date().toISOString()
            });
        },

        mixpanelRegistration: function mixpanelRegistration(registration) {
            var self = this;
            console.log("Mixpanel Pre Paid Subscriber sent");

            mixpanel.track('DSS Pre-Paid Subscriber Added', {
                "Pre-paid Subscriber Name": registration.get('firstName') + " " + registration.get('lastName'),
                "Date Of Birth": registration.get('dateOfBirth'),
                "Gender": registration.get('gender'),
                "Address": registration.get('address'),
                "Pre-paid Subscriber Email": registration.get('email'),
                "Phone Number": registration.get('phoneNumber'),
                "NHI Number": registration.get('uniqueId'),
                "Relationship": registration.get('relationship'),
                "Referred By": registration.get('referrer'),
                //						"NASC Organisation": registration.get('organisationType'),
                "Ethnicity": self.getArray(registration.get('ethnicities'), 'name'),
                "Disabilities": self.getArray(registration.get('disabilities'), 'name')
            });
        },

        mixpanelListAJob: function mixpanelListAJob(jobListing) {
            var self = this;
            console.log("Mixpanel List a Job sent");

            mixpanel.init(this.get('env').get('mixpanel').token, {
                'loaded': function loaded() {
                    self.incrementSuperProperty("# of Job Listings");
                }
            });
            mixpanel.people.increment("# of Job Listings");
            mixpanel.people.set({ "Last Job Listed On": new Date().toISOString() });

            mixpanel.track('List a Job', {
                "Job Listing": jobListing.get('id'),
                "Job Listing for": jobListing.get('person.id'),
                "Job Listing Title": jobListing.get('title'),
                "Job Listing Description": jobListing.get('description'),
                "Job Listing Interests": jobListing.get('interests'),
                "Job Listing Location": jobListing.get('location'),
                "Job Listing Hourly rate": jobListing.get('hourlyRate.title'),
                "Job Listing Services": self.getArray(jobListing.get('services'), 'name'),
                "Job Listing Equipment experience": self.getArray(jobListing.get('equipment'), 'name'),
                "Job Listing Other information": self.getArray(jobListing.get('requirements'), 'name'),
                "Job Listing Languages": self.getArray(jobListing.get('languages'), 'name'),
                "Applications Close On": jobListing.get('closesOn'),
                "Job Starts On": jobListing.get('startsOn'),
                "Job Ends On": jobListing.get('endsOn'),
                "Job Listing Publish Status": jobListing.get('isPublished'),
                "Job Listing Created On": new Date().toISOString()
            });
        },

        mixpanelContactAWorker: function mixpanelContactAWorker(jobListing, recipient, sender, body) {
            var self = this;
            console.log("Mixpanel Contact A Worker sent");

            mixpanel.init(this.get('env').get('mixpanel').token, {
                'loaded': function loaded() {
                    self.incrementSuperProperty("# of Matches");
                }
            });
            mixpanel.people.increment("# of Worker Contacts");
            mixpanel.init(this.get('env').get('mixpanel').token, {
                'loaded': function loaded() {
                    self.incrementSuperProperty("# of Worker Contacts");
                }
            });
            mixpanel.people.set({ "Last Contacted a Worker On": new Date().toISOString() });

            mixpanel.track('Contact a Worker', {
                "Job Listing": jobListing.get('id'),
                "Contacted Worker": recipient.get('id'),
                "Person Sender": sender.get('id'),
                "Message": body,
                "Job Listing Location": jobListing.get('location'),
                "Job Listing Hourly rate": jobListing.get('hourlyRate.title'),
                "Job Listing Services": self.getArray(jobListing.get('services'), 'name'),
                "Job Listing Equipment experience": self.getArray(jobListing.get('equipment'), 'name'),
                "Job Listing Other information": self.getArray(jobListing.get('requirements'), 'name'),
                "Job Listing Languages": self.getArray(jobListing.get('languages'), 'name'),
                "Worker's Location": recipient.get('location'),
                "Worker's Hourly rate": self.getArray(recipient.get('hourlyRates'), 'title'),
                "Worker's Services": self.getArray(recipient.get('services'), 'name'),
                "Worker's Equipment experience": self.getArray(recipient.get('equipment'), 'name'),
                "Worker's Other information": self.getArray(recipient.get('requirements'), 'name'),
                "Worker's Languages": self.getArray(recipient.get('languages'), 'name'),
                "Worker Contacted On": new Date().toISOString()
            });
        },

        mixpanelApplyForAJob: function mixpanelApplyForAJob(jobListing, applicant, body, hasAttachments) {
            var self = this;
            console.log("Mixpanel Apply For A Job sent");

            mixpanel.init(this.get('env').get('mixpanel').token, {
                'loaded': function loaded() {
                    self.incrementSuperProperty("# of Matches");
                }
            });
            mixpanel.people.increment("# of Job Applications");
            mixpanel.init(this.get('env').get('mixpanel').token, {
                'loaded': function loaded() {
                    self.incrementSuperProperty("# of Job Applications");
                }
            });
            mixpanel.people.set({ "Last Applied for a Job On": new Date().toISOString() });

            mixpanel.track('Apply for a Job', {
                "Job Listing": jobListing.get('id'),
                "Applicant": applicant.get('id'),
                "Message": body,
                "Application Has Attachments": hasAttachments,
                "Job Listing Location": jobListing.get('location'),
                "Job Listing Hourly rate": jobListing.get('hourlyRate.title'),
                "Job Listing Services": self.getArray(jobListing.get('services'), 'name'),
                "Job Listing Equipment experience": self.getArray(jobListing.get('equipment'), 'name'),
                "Job Listing Other information": self.getArray(jobListing.get('requirements'), 'name'),
                "Job Listing Languages": self.getArray(jobListing.get('languages'), 'name'),
                "Job Listing Applied for On": new Date().toISOString()
            });
        },

        // Support Circles

        mixpanelCreateSupportCircle: function mixpanelCreateSupportCircle(circle) {
            var self = this;
            console.log("Mixpanel Create Support Circle sent");

            mixpanel.init(this.get('env').get('mixpanel').token, {
                'loaded': function loaded() {
                    self.incrementSuperProperty("# of Support Circles");
                }
            });
            mixpanel.people.increment("# of Support Circles");
            mixpanel.people.set({ "Last Support Circle Created On": new Date().toISOString() });

            mixpanel.track('Create Support Circle', {
                "Support Circle Id": circle.get('id'),
                "Support Circle Title": circle.get('title'),
                "Support Circle Description": circle.get('description'),
                "Support Circle Owner Id": circle.get('person.id'),
                "Support Circle Created On": new Date().toISOString()
            });
        },

        mixpanelCreateSupportCirclePost: function mixpanelCreateSupportCirclePost(circlePost, hasImage) {
            var self = this;
            console.log("Mixpanel Create Support Circle Post sent");

            mixpanel.init(this.get('env').get('mixpanel').token, {
                'loaded': function loaded() {
                    self.incrementSuperProperty("# of Support Circle Posts");
                }
            });
            mixpanel.people.increment("# of Support Circle Posts");
            mixpanel.people.set({ "Last Support Circle Post Created On": new Date().toISOString() });

            circlePost.get('circleMember').then(function (circleMember) {
                mixpanel.track('Create Support Circle Post', {
                    "Support Circle Id": circleMember.get('circle.id'),
                    "Support Circle Post Title": circlePost.get('title'),
                    "Support Circle Post Body": circlePost.get('postBody'),
                    "Support Circle Post Has Image": hasImage,
                    "Support Circle Post Created On": new Date().toISOString()
                });
            });
        },

        mixpanelCreateSupportCircleComment: function mixpanelCreateSupportCircleComment(circleComment) {
            var self = this;
            console.log("Mixpanel Create Support Circle Comment sent");

            mixpanel.init(this.get('env').get('mixpanel').token, {
                'loaded': function loaded() {
                    self.incrementSuperProperty("# of Support Circle Comments");
                }
            });
            mixpanel.people.increment("# of Support Circle Comments");
            mixpanel.people.set({ "Last Support Circle Comment Created On": new Date().toISOString() });

            circleComment.get('circleMember').then(function (circleMember) {
                mixpanel.track('Create Support Circle Comment', {
                    "Support Circle Id": circleMember.get('circle.id'),
                    "Support Circle Post Id": circleComment.get('post.id'),
                    "Support Circle Post Title": circleComment.get('post.title'),
                    "Support Circle Comment": circleComment.get('commentBody'),
                    "Support Circle Comment Created On": new Date().toISOString()
                });
            });
        },

        mixpanelCreateSupportCircleNote: function mixpanelCreateSupportCircleNote(circleNote) {
            var self = this;
            console.log("Mixpanel Create Support Circle Note sent");

            mixpanel.init(this.get('env').get('mixpanel').token, {
                'loaded': function loaded() {
                    self.incrementSuperProperty("# of Support Circle Notes");
                }
            });
            mixpanel.people.increment("# of Support Circle Notes");
            mixpanel.people.set({ "Last Support Circle Note Created On": new Date().toISOString() });

            mixpanel.track('Create Support Circle Note', {
                "Support Circle Id": circleNote.get('circle.id'),
                "Support Circle Note Title": circleNote.get('title'),
                "Support Circle Note Description": circleNote.get('description'),
                "Support Circle Note Created On": new Date().toISOString()
            });
        },

        mixpanelAddPersonToSupportCircle: function mixpanelAddPersonToSupportCircle(id, circleId, circleTitle) {
            var self = this;
            console.log("Mixpanel Add Person To Support Circle sent");

            mixpanel.init(this.get('env').get('mixpanel').token, {
                'loaded': function loaded() {
                    self.incrementSuperProperty("# of People added to Support Circles");
                }
            });
            mixpanel.people.increment("# of People added to Support Circles");
            mixpanel.people.set({ "Last Person Added to Support Circle On": new Date().toISOString() });

            mixpanel.track('Person Added To Support Circle', {
                "Added Person Id": id,
                "Added to Support Circle Id": circleId,
                "Added to Support Circle Title": circleTitle,
                "Added To Support Circle On": new Date().toISOString()
            });
        },

        mixpanelHomepageSearch: function mixpanelHomepageSearch(location) {
            mixpanel.track('Search From App Homepage', {
                "Search Location": location
            });
        },

        getArray: function getArray(input, value) {
            var output = [];
            input.forEach(function (item) {
                output.pushObject(item.get(value));
            });
            return output;
        },

        incrementSuperProperty: function incrementSuperProperty(property) {
            var value = mixpanel.get_property(property);
            var update = {};
            if (value && typeof value === 'number') {
                update[property] = value + 1;
            } else {
                update[property] = 1;
            }
            mixpanel.register(update);
        },

        mixpanelCreateJobListingFunnel: function mixpanelCreateJobListingFunnel(personId, step) {
            console.log("Mixpanel create joblisting funnel updated: " + step);
            mixpanel.track('Create job listing', {
                "Added Person Id": personId,
                "Step": step
            });
        }
    });
});