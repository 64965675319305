define("mycare-ember/models/review", ["exports", "ember-data"], function (exports, _emberData) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        compassionate: _emberData.default.attr('number'),
        total: _emberData.default.attr('number'),
        professional: _emberData.default.attr('number'),
        trusted: _emberData.default.attr('number'),
        supportive: _emberData.default.attr('number'),
        friendly: _emberData.default.attr('number'),
        person: _emberData.default.belongsTo('person', { async: true }),
        reviewedBy: _emberData.default.belongsTo('person', { async: true }),
        reviewedOn: _emberData.default.attr('string'),

        getTotal: function () {
            return Ember.isEmpty(this.get('total')) === false ? this.get('total') : 0;
        }.property('total'),

        getProfessional: function () {
            return Ember.isEmpty(this.get('professional')) === false ? this.get('professional') : 0;
        }.property('professional'),

        getTrusted: function () {
            return Ember.isEmpty(this.get('trusted')) === false ? this.get('trusted') : 0;
        }.property('trusted'),

        getSupportive: function () {
            return Ember.isEmpty(this.get('supportive')) === false ? this.get('supportive') : 0;
        }.property('supportive'),

        getFriendly: function () {
            return Ember.isEmpty(this.get('friendly')) === false ? this.get('friendly') : 0;
        }.property('friendly')

    });
});