define("mycare-ember/carers/my-account/route", ["exports", "mycare-ember/utils/mc-protected-route"], function (exports, _mcProtectedRoute) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcProtectedRoute.default.extend({
        actions: {
            didTransition: function didTransition() {
                Ember.$("body").addClass("page-worker-account");
            }
        }
    });
});