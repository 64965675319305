define('mycare-ember/mixins/browser-detector', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        isOpera: window.opera || navigator.userAgent.indexOf(' OPR/') >= 0,
        // Opera 8.0+ (UA detection to detect Blink/v8-powered Opera)
        isFirefox: typeof InstallTrigger !== 'undefined', // Firefox 1.0+
        isSafari: Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0,
        // At least Safari 3+: "[object HTMLElementConstructor]"
        //    isChrome : !!window.chrome && !isOpera,              // Chrome 1+
        isIE: /*@cc_on!@*/!!document.documentMode, // At least IE6
        isChrome: function isChrome() {
            return !!window.chrome && !this.get('isOpera');
        }
    });
});