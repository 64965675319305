define('mycare-ember/carers/manage/my-schedule/visits/removed-declined/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        watchHasItems: function () {
            this.redirectIfEmpty();
        }.observes('model.hasRemovedOrDeclinedTimesheets'),

        redirectIfEmpty: function redirectIfEmpty() {
            //we need to check the url path so that this code is not executed if user is not on this page.
            //controllers are singletone meaing `watchHasItems` will be trigger all the time. so this workaround is required
            //eg. declined the last visit on the timesheet detail page could trigger this method.
            if (!this.get('model.hasRemovedOrDeclinedTimesheets') && location.pathname.match(/removed-declined/)) {
                this.transitionToRoute('carers.manage.my-schedule.visits');
            }
        }
    });
});