define("mycare-ember/mixins/modal-job-apply-mixin", ["exports", "mycare-ember/mixins/file-uploader-board", "mycare-ember/mixins/mixpanel"], function (exports, _fileUploaderBoard, _mixpanel) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_fileUploaderBoard.default, _mixpanel.default, {

        /**
         * File upload url for file
         */
        fileUploadUrl: function () {
            return this.get('env').getApp('API_HOST') + '/api/FileUpload';
        }.property(),

        /**
         * Url return from server after uploaded to <code>fileUploadUrl</code>
         */
        uploadedFileUrls: [],

        fileTypeAllowList: ['pdf', 'doc', 'docx', 'png', 'jpg', 'jpeg', 'gif'],

        /**
         * Maximum number of file allowance
         */
        maximumFileUpload: 3,

        /**
         * Currently selected file item
         */
        currentFileItem: null,

        /**
         * Check if AddFileButton should be disable in according to maximimFileUpload
         */
        disableAddFileBtn: function () {
            var numOfFiles = this.get('uploadedFileUrls.length') + this.get('parentObj.files.length');
            return numOfFiles >= this.get('maximumFileUpload');
        }.property('uploadedFileUrls.[]', 'maximumFileUpload', 'parentObj.files.[]'),

        hasMessage: true,

        // setBody: function () {
        //     var self = this;
        //     if(!self.get('session.authData.isAuthenticated')) return;
        //     if (!self.get('body')) {
        //         var currentUser = this.get('model.person');
        //         var body = 'Hi, my name is ' + currentUser.get('firstName') + ',' + ' I would like to apply for the job you listed ' +
        //             self.get('model.jobListing.title') + '.' + ' For more information please view my profile. Regards, ' + currentUser.get('firstName');
        //         self.set('body', body);
        //     }
        // }.observes('model.jobListing.id', 'model.jobListing.person'),


        actions: {
            /**
             * Use this method for link instead of link-to helper.
             * link-to helper does not hide boostrap modal and the black overlay will keep showing.
             */
            afterApplyContinueSearch: function afterApplyContinueSearch() {
                Ember.$('.modal').modal('hide');
                this.transitionToRoute('browse.jobs');
            },

            /**
             * Use this method for link instead of link-to helper.
             * link-to helper does not hide boostrap modal and the black overlay will keep showing.
             */
            afterApplyCheckMessage: function afterApplyCheckMessage() {
                Ember.$('.modal').modal('hide');
                this.transitionToRoute('threads');
            },

            /**
             * Add file to person
             */
            addFile: function addFile() {
                var self = this;
                if (!self.get('disableAddFileBtn')) {
                    self.get('uploadedFileUrls').pushObject(Ember.Object.create({
                        id: self.get('uploadedFileUrls.length') + 1,
                        url: '',
                        fileName: '',
                        uploadedByPerson: null
                    }));
                }
            },

            /**
             * Delete file from person
             * @param file to delete
             */
            deleteFile: function deleteFile(file) {
                if (file instanceof this.store.modelFor('file')) {
                    this.get('parentObj.files').removeObject(file);
                } else {
                    this.get('uploadedFileUrls').removeObject(file);
                }
            },

            /**
             * Open file picker
             * @param file file that is curretly editing
             */
            openFilePicker: function openFilePicker(file) {
                if (!this.get('disableAddFileBtn')) {
                    this.set('currentFileItem', file);
                    Ember.$('#modal-file-picker').modal('hide');
                    Ember.$('#modal-file-picker').modal('show');
                }
            },

            /**
             * Choose file
             * @param file file to be chosen
             */
            chooseFile: function chooseFile(file) {
                this.get('parentObj.files').removeObject(file);
                this.get('parentObj.files').pushObject(file);
                Ember.$('#modal-file-picker').modal('hide');
            },

            /**
             * Apply job.
             * @param isDraft true if the job application should be saved as Draft. Otherwise false.
             */
            makeApplication: function makeApplication(isDraft) {
                var _this = this;

                var self = this;
                self.set('isSubmitted', false);
                if (Ember.isEmpty(this.get('body'))) {
                    this.set('hasMessage', false);
                    return;
                }

                if (!self.get('session.authData.isAuthenticated')) {
                    self.transitionToRoute('login');
                    return;
                }

                if (Ember.isEmpty(self.get('application')) === false) {
                    self.saveApplication(isDraft);
                    return;
                }

                if (isDraft === true) {
                    self.set('isSavingApplicationDraft', true);
                } else {
                    self.set('isSavingApplication', true);
                }
                var currentPersonId = self.get('session.authData.currentPersonId');
                var app = self.store.createRecord('job-application');
                var applyingPerson = null;

                self.saveFiles(app).then(function () {
                    return self.store.findRecord('person', currentPersonId);
                }).then(function (person) {
                    applyingPerson = person;
                    return self.store.findRecord('job-application-status-type', isDraft ? 1 : 2);
                }).then(function (applicationStatus) {
                    self.get('parentObj.files').forEach(function (item) {
                        app.get('files').pushObject(item);
                    });

                    app.set('jobApplicationStatus', applicationStatus);
                    app.set('appliedOn', moment.utc(new Date()).toISOString());
                    app.set('modifiedOn', moment.utc(new Date()).toISOString());
                    app.set('person', applyingPerson);
                    app.set('jobListing', self.get('model.jobListing'));
                    app.set('body', self.get('body'));
                    self.mixpanelApplyForAJob(self.get('model.jobListing'), applyingPerson, self.get('body'), self.get('parentObj.files.length') > 0);
                    app.save().then(function (app) {
                        self.set('model.jobApplication', app);
                        applyingPerson.get('jobApplications').pushObject(app);
                        return applyingPerson.save().then(function () {
                            return app.get('thread');
                        });
                    });
                }).then(function () {
                    self.set('isLoading', false);
                    self.set('body', '');
                    self.set('isSubmitted', true);
                    if (isDraft) {
                        self.set('isSavingApplicationDraft', false);
                    } else {
                        self.set('isSavingApplication', false);
                    }
                    self.set('model.canApply', false);
                    self.set('model.isAlreadyApplied', true);
                }).catch(function () {
                    _this.set('isSavingApplicationDraft', false);
                    _this.set('isSavingApplication', false);
                    _this.set('isLoading', false);
                });
            }
        },

        saveApplication: function saveApplication(isDraft) {
            var self = this;
            if (isDraft) {
                self.set('isSavingApplicationDraft', true);
            } else {
                self.set('isSavingApplication', true);
            }
            var app = self.get('application');
            var appType = self.store.findRecord('job-application-status-type', isDraft ? 1 : 2);
            appType.then(function (appType) {
                app.set('body', self.get('body'));
                app.set('jobApplicationStatus', appType);
                app.save().then(function () {
                    Ember.$('.modal').modal('hide');
                    self.set('model.jobApplication', app);
                    self.set('model.canApply', false);
                    if (isDraft) {
                        self.set('isSavingApplicationDraft', false);
                    } else {
                        self.set('isSavingApplication', false);
                    }
                });
            });
        },

        /**
         * save files
         * @param app job application model.jobListing
         * @returns {*}
         */
        saveFiles: function saveFiles(app) {
            var self = this;
            var defer = Ember.RSVP.defer();
            var uploadedFileUrls = self.get('uploadedFileUrls');
            if (uploadedFileUrls.length > 0) {
                _.each(uploadedFileUrls, function (fileItem, index) {
                    self.store.createRecord('file', {
                        fileName: fileItem.get('fileName'),
                        url: fileItem.get('url'),
                        uploadedOn: moment.utc(new Date()).toISOString(),
                        uploadedByPerson: app.get('person')
                    }).save().then(function (file) {
                        app.get('files').pushObject(file);
                        if (uploadedFileUrls.length - 1 === index) {
                            self.get('uploadedFileUrls').clear();
                            defer.resolve(app);
                        }
                        return file;
                    }).catch(function (error) {
                        defer.reject(error);
                    });
                });
            } else {
                defer.resolve(app);
            }
            return defer.promise;
        }
    });
});