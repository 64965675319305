define('mycare-ember/components/mc-job-listing/modal-warning-leaving/component', ['exports', 'mycare-ember/utils/mc-modal-component', 'mycare-ember/utils/function-helpers'], function (exports, _mcModalComponent, _functionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend({

        _wizardService: Ember.inject.service('utils/booking-wizard'),
        _eventbus: Ember.inject.service('utils/eventbus'),

        init: function init() {
            this.set('_componentId', 'mc-job-listing/modal-warning-leaving');
            this._super.apply(this, arguments);
            this.initVar('channelId', 'global');
            this.initVar('onLeave', undefined);
            this.initVar('_route', undefined);
        },
        _onBeforeOpen: function _onBeforeOpen(_ref) {
            var route = _ref.route;

            this.set('_route', route);
        },


        actions: {
            leave: function leave() {
                _functionHelpers.default.callOrIgnore(this.get('onLeave'));
                this._close();
            }
        }
    });
});