define('mycare-ember/components/mc-message-composer/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/function-helpers'], function (exports, _mcComponent, _functionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('_isCollpased', false);
            this.initVar('_message', undefined);
            this.initVar('onSend', undefined);
            this.initVar('onFileOpen', undefined);
            this.initVar('onBook', undefined);
            this.initVar('onInfoView', undefined);
            this.initVar('channelId', undefined);

            this._setupEvents();
        },


        _isEnableSend: function () {
            return !Ember.isEmpty(this.get('_message')) && !Ember.isEmpty(this.get('_message').replace(/\s/g, ''));
        }.property('_message'),

        setCollpased: function () {
            if (this.isDestroyed) {
                return;
            }
            if (!Ember.isEmpty(this.get('_message')) && this.get('message.length') !== 0) {
                this.set('_isCollpased', true);
            } else {
                this.set('_isCollpased', false);
            }
        }.observes('_message'),

        didInsertElement: function didInsertElement() {
            var self = this;
            autosize(this.$('.thread-textarea'));
            this.$('.thread-textarea').on('focus click', function () {
                self.set('_isCollpased', true);
            });
            this.$('.thread-textarea').css('padding-right', 0);
            this.setTextInputWidth();
        },
        _setupEvents: function _setupEvents() {
            var _this = this;

            this.get('eventbus').subscribe('mc-message-composer:on:reset:' + this.get('channelId'), function () {
                if (_this.isDestroyed) {
                    return;
                }
                _this.set('_message', undefined);
            });
        },


        _setTextInputWidth: function () {
            var buttonCount = 4;
            if (this.get('_isCollpased')) {
                buttonCount -= 2;
            } else if (this.get('canBook') === false) {
                buttonCount -= 1;
            }
            var defaultWidth = buttonCount * 33;
            this.set('textInputWidth', this.$('.new-message-area').width() - defaultWidth - 10);
            this.$('.thread-textarea').css('width', this.get('textInputWidth'));
        }.observes('_isCollpased', 'canBook'),

        actions: {
            collpase: function collpase() {
                this.toggleProperty('_isCollpased', true);
            },
            send: function send() {
                _functionHelpers.default.callOrIgnore(this.get('onSend'), this.get('_message'));
            },
            onFileOpen: function onFileOpen() {
                _functionHelpers.default.callOrIgnore(this.get('onFileOpen'));
            },
            onInfoView: function onInfoView() {
                _functionHelpers.default.callOrIgnore(this.get('onInfoView'));
            },
            onBook: function onBook() {
                _functionHelpers.default.callOrIgnore(this.get('onBook'));
            }
        }
    });
});