define("mycare-ember/models/credit-card", ["exports", "ember-data"], function (exports, _emberData) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        account: _emberData.default.belongsTo('account', { async: true }),
        cardNumber: _emberData.default.attr('string'),
        cardName: _emberData.default.attr('string'),
        cardExpiresOn: _emberData.default.attr('string'),
        cardHolderName: _emberData.default.attr('string'),
        url: _emberData.default.attr('string'),

        isPreferredCreditCard: function () {
            return this.get('account.preferredCreditCard') === this.get('id');
        }.property('account.preferredCreditCard'),

        isVisa: function () {
            return this.get('cardName').toLowerCase() === 'visa';
        }.property('cardName'),

        isMastercard: function () {
            return this.get('cardName').toLowerCase() === 'mastercard';
        }.property('cardName'),

        isSwathe: function () {
            return Ember.isEmpty(this.get('cardName'));
        }.property('cardName')
    });
});