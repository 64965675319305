define("mycare-ember/components/mc-joblisting-editor/availabilities/component", ["exports", "mycare-ember/mixins/validator", "mycare-ember/utils/Validators", "mycare-ember/utils/mc-component"], function (exports, _validator, _Validators, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend(_validator.default, {
        _jobListingService: Ember.inject.service('job-listing'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('jobListing', null);
            this.initVar('formData', Ember.Object.create({
                when: null,
                availabilities: []
            }));
            this.initVar('personId', null);
            this.initVar('isFormValid', undefined);
            this.initVar('_isInitialised', false);
            this.initVar('_activityGroups', []);

            this.setup();
        },


        // _watchFormValid: function(){
        //     return this.set('isFormValid', this._checkIsValidationPassed());
        // }.observes('isValidationsPassed', 'isFormDirty', 'validations'),

        // isDisabledSaveButton: function(){
        //     if(this.get('isFormDirty') === false) {
        //         return true;
        //     }else if(this.get('isSaving') === true){
        //         return true;
        //     }
        //     return false;
        // }.property('isSaving', 'isFormDirty'),

        setup: function setup() {
            var _this = this;

            this.set('formData.availabilities', this.get('jobListing.availabilities'));
            this.set('formData.when', this.get('jobListing.when'));
            Ember.run.later(function () {
                if (_this.isDestroyed) {
                    return;
                }
                _this._setupValidation();
                _this._validate({ keepDirty: true });
                _this.set('_isInitialised', true);
                _this.set('isFormDirty', false);
            }, 1000);
        },
        _setupValidation: function _setupValidation() {
            this._resetValidation();
            this.addValidation('formData.availabilities.[]', 'availabilities', 'Availability', [this._validateAvailability]);
            this.addValidation('formData.when', 'availabilities', 'Availability', [this._validateAvailability]);
        },
        _validateAvailability: function _validateAvailability(name) {
            var isValid = _Validators.default.isRequired(name, this.get('formData.availabilities')).result;
            return {
                result: isValid || Ember.isEmpty(this.get('formData.when')) === false,
                message: 'Availabilities is required'
            };
        },


        // watchFormDirty: function(){
        //     var checkExistingAvailabilities = this.get('jobListing.availabilities').any(item => {
        //         return Ember.isNone(this.get('formData.availabilities').find(jItem => jItem.get('id') === item.get('id')));
        //     });
        //     var isDirty =
        //         this.get('formData.when') !== this.get('jobListing.when') ||
        //         this.get('formData.availabilities.length') !== this.get('jobListing.availabilities.length') ||
        //         checkExistingAvailabilities;
        //     this.set('isFormDirty', isDirty);
        // }.observes('formData.availabilities', 'formData.when'),

        actions: {
            save: function save() {
                var _this2 = this;

                this._validateAsync({ keepDirty: false }).then(function (isValid) {
                    _this2.set('isFormValid', isValid);
                    if (_this2.isDestroyed) {
                        return;
                    }
                    if (isValid) {
                        _this2.set('isSaving', true);
                        _this2.get('_jobListingService.updateJobListingAsync').perform({ id: _this2.get('jobListing.id'), updates: {
                                availabilities: _this2.get('formData.availabilities'),
                                when: _this2.get('formData.when')
                            } }).then(function (jobListing) {
                            if (_this2.isDestroyed) {
                                return;
                            }
                            _this2.set('jobListing', jobListing);
                            _this2.set('isSaving', false);
                            _this2.sendAction('onSaved', jobListing);
                            _this2.set('isFormDirty', false);
                        });
                    }
                });
            }
        }
    });
});