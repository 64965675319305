define('mycare-ember/models/timesheet-status', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        timesheet: _emberData.default.belongsTo('timesheet', { async: true }),
        person: _emberData.default.belongsTo('person', { async: true }),
        timesheetStatusType: _emberData.default.belongsTo('timesheet-status-type', { async: true }),
        createdOn: _emberData.default.attr('string'),
        comment: _emberData.default.attr('string')
    });
});