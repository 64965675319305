define('mycare-ember/models/ethnicity', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        ethnicityName: _emberData.default.attr('string'),
        name: function () {
            return this.get('ethnicityName');
        }.property('ethnicityName')
    });
});