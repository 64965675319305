define("mycare-ember/helpers/utc-date-format", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.utcTimeFormat = undefined;


  function utcTimeFormat(data) {
    var date = data[0],
        optionalFormat = data[1];

    if (Ember.isBlank(date)) {
      return '';
    }

    var format = 'LL';

    if (Ember.typeOf(optionalFormat) === 'string') {
      format = optionalFormat;
    }
    var dateObj = new Date(date);

    if (date === 'today') {
      dateObj = new Date();
    }

    return _moment.default.utc(dateObj).format(format);
  }

  exports.utcTimeFormat = utcTimeFormat;
  exports.default = Ember.Helper.helper(utcTimeFormat);
});