define('mycare-ember/components/mc-password-strength/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/function-helpers'], function (exports, _mcComponent, _functionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({

        _accountService: Ember.inject.service('account'),
        _profileService: Ember.inject.service('profile'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('password', undefined);
            this.initVar('onChange', undefined);
            this.initVar('_passwordRules', []);
            this.initVar('_isPasswordRulesPassed', false);

            this._watchPassword();
        },


        _watchIsValid: function () {
            _functionHelpers.default.callOrIgnore(this.get('onChange'), this.get('_isPasswordRulesPassed'));
        }.observes('_isPasswordRulesPassed'),

        _watchPassword: function () {
            var _this = this;

            this.get('_profileService.calculatePasswordStrengthAsync').perform(this.get('password')).then(function (result) {
                _this.set('_passwordRules', result);
                if (!Ember.isEmpty(result)) {
                    _this.set('_isPasswordRulesPassed', !result.any(function (rule) {
                        return rule.get('isValid') === false;
                    }));
                } else {
                    _this.set('_isPasswordRulesPassed', false);
                }
            });
        }.observes('password')
    });
});