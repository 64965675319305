define('mycare-ember/services/utils/polling', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        _store: Ember.inject.service('store'),
        _logger: Ember.inject.service('utils/logger'),
        _eventbus: Ember.inject.service('utils/eventbus'),
        _notification: Ember.inject.service('notification'),
        _messageService: Ember.inject.service('message'),
        _env: Ember.inject.service('utils/env'),
        _messageTaskId: null,
        _notificationTaskId: null,
        _isInitialised: false,
        _loggedInUserId: undefined,
        _session: Ember.inject.service('session'),

        init: function init() {}
    });
});