define("mycare-ember/components/mc-nav/authenticated/seeker-subnav/component", ["exports", "mycare-ember/utils/mc-component"], function (exports, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        livechat: Ember.inject.service('utils/livechat'),

        didInsertElement: function didInsertElement() {
            Ember.$('body').css('height', window.innerHeight);
            Ember.$('body').css('minHeight', window.innerHeight);
        }
    });
});