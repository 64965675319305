define('mycare-ember/components/mc-viewport/component', ['exports', 'npm:responsive-toolkit', 'mycare-ember/utils/mc-component'], function (exports, _npmResponsiveToolkit, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('is', undefined);
            this.set('_shouldRender', false);
            this.set('_supportedSizes', []);
        },
        didRender: function didRender() {
            var _this = this;

            Ember.$(window).resize(_npmResponsiveToolkit.default.changed(function () {
                _this.setShouldRender();
            }));
        },


        currentScreenSize: function () {
            return _npmResponsiveToolkit.default.current() != 'unrecognized' ? _npmResponsiveToolkit.default.current() : 'lg';
        }.property(),

        setShouldRender: function setShouldRender() {
            this.set('_shouldRender', this.get('_supportedSizes').includes(this.get('currentScreenSize')));
        },
        didReceiveAttrs: function didReceiveAttrs() {
            this.setupViewSizes();
            this.setShouldRender();
        },
        setupViewSizes: function setupViewSizes() {
            if (Ember.isNone(this.get('is'))) {
                console.error('Attribute, is, is required. Valid values are xs,sm,md and lg. User command to separate multiple values.');
                return;
            }
            this.set('_supportedSizes', this.get('is').split(','));
        },


        watchIs: function () {
            this.setupViewSizes();
        }.observes('is')
    });
});