define("mycare-ember/browse/jobs/index/controller", ["exports", "mycare-ember/mixins/nav-mode"], function (exports, _navMode) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_navMode.default, {
        _jobListingService: Ember.inject.service('job-listing'),
        _searchService: Ember.inject.service('search'),
        _markerService: Ember.inject.service('utils/marker'),

        queryParams: ['web', 'location'],
        location: undefined,

        zoom: 13,

        isLoading: false,
        searchRetryCount: 0,

        setup: function setup() {
            var _this = this;

            Ember.$(window).on('resize', function () {
                _this._resize();
            });
            this.set('searchRetryCount', 0);
        },


        isRedirect: function () {
            var self = this;
            if (self.get('web') === 'true') {
                self.mixpanelHomepageSearch(self.get('model.searchLocation'));
                return true;
            }
            return false;
        }.property(),

        markers: function () {
            var self = this;
            return (self.get('model.searchResult') || []).map(function (item) {
                return self.get('_markerService').createJobMarker(item, true);
            });
        }.property('model.searchResult.[]'),

        _resize: function _resize() {
            var resultContainer = Ember.$(".job-card-container");
            if (window.innerHeight === window.outerHeight) {
                resultContainer.css({
                    height: '100%',
                    maxHeight: '100%'
                });
            } else {
                var bodyHeight = window.innerHeight - 108;
                resultContainer.css({
                    height: bodyHeight,
                    maxHeight: bodyHeight
                });
            }
        },


        actions: {
            search: function search(searchParameters) {
                this.send('searchRoute', searchParameters);
            },
            changeSearchType: function changeSearchType(type) {
                if (type === "profile") {
                    this.transitionToRoute('browse.profiles', { queryParams: { location: this.get('model.searchLocation') } });
                } else if (type === "job") {
                    this.transitionToRoute('browse.jobs', { queryParams: { location: this.get('model.searchLocation') } });
                }
            },
            onCoordinateChangedEvent: function onCoordinateChangedEvent(latLng, bounds) {
                this.send('onCoordinateChangedRouteEvent', latLng, bounds);
            },
            onMapResizedEvent: function onMapResizedEvent() {
                var self = this;
                Ember.$('.panel-card-job').hover(function () {
                    var _this2 = this;

                    var aM = self.get('markers').find(function (m) {
                        return String(m.get('id')) === String($(_this2).data('id'));
                    });
                    self.set('activeMarker', aM);
                });
                this._resize();
            },
            redirectToJobListing: function redirectToJobListing() {
                var self = this;
                Ember.$('.modal').modal('hide');
                self.transitionToRoute('seekers.findhelp.job-listings.create');
            },
            toPublishJob: function toPublishJob() {
                Ember.$('#modal-profile').modal('hide');
                this.transitionTo('seekers.findhelp.job-listings.list');
            },
            toCreateJob: function toCreateJob() {
                Ember.$('#modal-profile').modal('hide');
                this.transitionTo('seekers.findhelp.job-listings.create');
            }
        }
    });
});