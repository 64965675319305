define('mycare-ember/components/mc-auth/signup-type-modal/component', ['exports', 'mycare-ember/utils/mc-modal-component'], function (exports, _mcModalComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend({
        init: function init() {
            this.set('_componentId', 'mc-auth/signup-type-modal');
            this._super.apply(this, arguments);
        },


        actions: {
            toSeekerSignup: function toSeekerSignup() {
                // temp work around
                this.get('eventbus').publish('global:on:changeRoute', 'register.seeker-register.index');
                this.get('eventbus').publish('global:on:changeRoute', 'register.seeker-register.index');
                this._close();
            },
            toWorkerSignup: function toWorkerSignup() {
                // temp work around
                this.get('eventbus').publish('global:on:changeRoute', 'register.worker-register.index');
                this.get('eventbus').publish('global:on:changeRoute', 'register.worker-register.index');
                this._close();
            }
        }
    });
});