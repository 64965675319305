define('mycare-ember/models/thread', ['exports', 'ember-data', 'mycare-ember/config/environment', 'mycare-ember/utils/common'], function (exports, _emberData, _environment, _common) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        title: _emberData.default.attr('string'),
        isMatch: _emberData.default.attr('boolean'),
        people: _emberData.default.hasMany('person', { async: true }),
        messages: _emberData.default.hasMany('message', { async: true, inverse: 'thread' }),
        messageBody: _emberData.default.attr('string'),
        messageDate: _emberData.default.attr('string'),
        message: _emberData.default.belongsTo('message', { async: true, inverse: null }),
        unReadMessages: _emberData.default.hasMany('message', { async: true, inverse: null }),
        jobApplications: _emberData.default.hasMany('job-application', { async: true }),
        contracts: _emberData.default.hasMany('contract', { async: true }),
        isDeleteEnabled: _emberData.default.attr('boolean'),

        getNames: function () {
            var names = "Me, ";
            var i = 1;
            var people = this.get('people');
            if (people.get('length') === 1) {
                return 'Me';
            }
            people.filter(function (p) {
                return p.get('id') !== localStorage.getItem(_environment.default.APP.AUTH_USER_KEY);
            }).forEach(function (person) {
                var firstName = person.get('firstName');
                if (firstName) {
                    names += person.get('firstName');
                    if (i < people.get('length') - 1) {
                        names += ", ";
                        i++;
                    }
                }
            });
            return names;
        }.property('people.@each.firstName'),

        recipient: function () {
            return this.get('people').filter(function (p) {
                return p.get('id') !== localStorage.getItem(_environment.default.APP.AUTH_USER_KEY);
            }).objectAt(0);
        }.property('people.[]'),

        isDeleted: function () {
            return this.get('people.length') <= 1;
        }.property('people.[]'),

        hasUnReadMessages: function () {
            return this.get('unReadMessages.length') > 0;
        }.property('unReadMessages.[]'),

        sentOnFormattedDate: function () {
            return _common.default.formDateForMessageOrNotification(this.get('message.sentOn'));
        }.property('message.sentOn')

    });
});