define('mycare-ember/mixins/file-checker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    fileTypeAllowList: ['pdf', 'doc', 'docx', 'png', 'jpg', 'jpeg', 'gif'],
    imageTypeAllowList: ['png', 'jpg', 'jpeg', 'gif'],

    fileCheck: function fileCheck(file) {
      if (!Ember.isEmpty(file)) {
        var fileExtention = file.name.split('.').pop().toLowerCase();
        var isFileTypeValid = false;
        for (var i = 0; i < this.fileTypeAllowList.length; i++) {
          if (this.fileTypeAllowList[i].indexOf(fileExtention) === 0) {
            isFileTypeValid = true;
          }
        }
        if (!isFileTypeValid) {
          return "Only the following file types can be uploaded .doc .docx .pdf .png .jpg .jpeg .gif";
        } else {
          return true;
        }
      } else {
        return "Your file is empty.";
      }
    },

    imageCheck: function imageCheck(file) {
      if (!Ember.isEmpty(file)) {
        var fileExtention = file.name.split('.').pop().toLowerCase();
        var isFileTypeValid = false;
        for (var i = 0; i < this.imageTypeAllowList.length; i++) {
          if (this.imageTypeAllowList[i].indexOf(fileExtention) === 0) {
            isFileTypeValid = true;
          }
        }
        if (!isFileTypeValid) {
          return "Only the following image types can be uploaded .png .jpg .jpeg .gif";
        } else {
          return true;
        }
      } else {
        return "Your file is empty.";
      }
    },

    isFileToolarge: function isFileToolarge(fileSize, limitSize) {
      if (!limitSize) {
        limitSize = 10000000;
      }

      if (fileSize < limitSize) {
        return true;
      } else {
        return "The file size can not exceed " + limitSize / 1000 / 1000 + "MB.";
      }
    }
  });
});