define('mycare-ember/pagenotfound/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        beforeModel: function beforeModel() {
            Ember.$('#body').removeClass('home');
        },

        actions: {
            willTransition: function willTransition() {
                // Ember.$('#body').addClass('home');
            }
        }
    });
});