define('mycare-ember/mixins/hire-worker-mixin', ['exports', 'mycare-ember/mixins/mixpanel'], function (exports, _mixpanel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_mixpanel.default, {
        hireWorker: function hireWorker(workerId, backTo, backToOpts) {
            var self = this;
            self.store.findRecord('person', workerId).then(function (person) {
                self.transitionToRoute('book', {
                    queryParams: {
                        backTo: backTo, backToOpts: backToOpts,
                        bookedPersonId: person.get('id')
                    }
                });
                self.mixpanelCreateAgreementButtonClicked(true);
            });
        }
    });
});