define('mycare-ember/services/utils/cache-store', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Service.extend({
        _cache: {},
        _store: Ember.inject.service('store'),

        put: function put(key, value) {
            this._cache[key] = value;
        },
        clear: function clear(key) {
            this._cache[key] = undefined;
        },
        get: function get(key) {
            return this._cache[key];
        },
        getStoreSingle: function getStoreSingle(modelName) {
            var _get;

            for (var _len = arguments.length, options = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
                options[_key - 1] = arguments[_key];
            }

            return (_get = this.get('_store')).findRecord.apply(_get, [modelName].concat(_toConsumableArray(options)));
        },
        getStoreAll: function getStoreAll(modelName) {
            var _get2;

            for (var _len2 = arguments.length, options = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
                options[_key2 - 1] = arguments[_key2];
            }

            return (_get2 = this.get('_store')).findAll.apply(_get2, [modelName].concat(_toConsumableArray(options)));
        },
        isExists: function isExists(key) {
            return Ember.isNone(this.get(key));
        }
    });
});