define("mycare-ember/mixins/dss-form", ["exports", "mycare-ember/mixins/payment-process"], function (exports, _paymentProcess) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_paymentProcess.default, {
    selectedEthnicity: [],
    selectedDisabilities: [],
    selectedOrganisation: [],
    selectedFundingTypes: [],
    dssFormErrors: [],
    isConsent: true,
    dssFormError: {},

    currentDssSubscription: null,

    dssFormSetup: function dssFormSetup() {
      var self = this;
      self.store.findAll('ethnicity', { reload: true }).then(function (items) {
        self.set('ethnicities', items);
      });
      self.store.findAll('disability', { reload: true }).then(function (items) {
        self.set('disabilities', items);
      });
      self.store.findAll('organisation', { reload: true }).then(function (items) {
        self.set('organisations', items);
      });
      self.store.findAll('funding-type', { reload: true }).then(function (items) {
        self.set('fundingTypes', items);
      });
    },


    watchIsDssUser: function () {
      var self = this;
      if (self.get('isDssUser') === true) {
        Ember.$('#dssForm').collapse('show');
      } else {
        Ember.$('#dssForm').collapse('hide');
      }
    }.observes('isDssUser'),

    submitForm: function submitForm(person, afterFunction) {
      var self = this;
      Ember.RSVP.hash({
        registrationType: self.store.findRecord('registration-type', 1)
      }).then(function (_ref) {
        var registrationType = _ref.registrationType;

        self.set('isSaving', true);
        self.set('isNotConsent', true);
        return self.store.createRecord('registration', {
          firstName: person.get('firstName'),
          lastName: person.get('lastName'),
          dateOfBirth: person.get('dateOfBirth'),
          gender: person.get('gender'),
          phoneNumber: person.get('phoneNumber'),
          uniqueId: self.get('uniqueId'),
          organisation: self.get('selectedOrganisation'),
          fundingTypes: self.get('selectedFundingTypes'),
          registrationType: registrationType,
          ethnicities: self.get('selectedEthnicity'),
          disabilities: self.get('selectedDisabilities'),
          person: person
        });
      }).then(function (registration) {
        return registration.save();
      }).then(function (registration) {
        self.set('isSubmitForm', false);
        Ember.$('#modal-pre-paid-form').modal('hide');
        self.mixpanelRegistration(registration);
        if (afterFunction) {
          afterFunction();
        }
      });
    },

    createDssSubscription: function createDssSubscription(registration, loginUser, afterFunction) {
      var self = this;
      self.set('isUpgrade', false);
      self.getSubscriptionType('dss').then(function (subtype) {

        var people = [loginUser.get('id')];
        self.store.createRecord('subscription', {
          type: subtype,
          registration: registration,
          subscribedPeople: people,
          subscriberKey: registration.get('verificationCode')
        }).save().then(function () {
          self.buildComponents(loginUser);
          if (afterFunction) {
            afterFunction();
          }
        }, function () {
          toastr.error('There was an error creating your profile, please contact Mycare');
        });
      });
    },

    createPremiumSubscription: function createPremiumSubscription(person, afterFunction) {
      var self = this;
      self.set('isUpgrade', false);
      self.getSubscriptionType().then(function (subtype) {
        var people = [person.get('id')];
        self.store.createRecord('subscription', {
          type: subtype,
          subscribedPeople: people
        }).save().then(function () {
          self.buildComponents(person);
          if (afterFunction) {
            afterFunction();
          }
        }, function () {
          self.set('isSaving', false);
          toastr.error('There was an error creating your profile, please contact Mycare');
        });
      });
    },

    watchUniqueId: function () {
      if (Ember.isEmpty(this.get('uniqueId')) === false) {
        this.set('dssFormError.uniqueId', '');
        Ember.$('#uniqueId').removeClass('has-error');
      }
    }.observes('uniqueId'),

    watchSelectedEthnicity: function () {
      if (this.get('selectedEthnicity.length') > 0) {
        this.set('dssFormError.selectedEthnicity', '');
        Ember.$('#btn-ethnicity').removeClass('has-error');
      }
    }.observes('selectedEthnicity.[]'),

    watchSelectedDisabilities: function () {
      if (this.get('selectedDisabilities.length') > 0) {
        this.set('dssFormError.selectedDisabilities', '');
        Ember.$('#btn-disability').removeClass('has-error');
      }
    }.observes('selectedDisabilities.[]'),

    watchSelectedFundingTypes: function () {
      if (this.get('selectedFundingTypes.length') > 0) {
        this.set('dssFormError.selectedFundingTypes', '');
        Ember.$('#btn-fundingTypes').removeClass('has-error');
      }
    }.observes('selectedFundingTypes.[]'),

    watchSelectedOrganisation: function () {
      if (Ember.isEmpty(this.get('selectedOrganisation')) === false) {
        this.set('dssFormError.selectedOrganisation', '');
        Ember.$('#nascOrganisation').removeClass('has-error');
      }
    }.observes('selectedOrganisation'),

    validateDssForm: function validateDssForm() {
      var self = this;
      //Clear out all previous errors
      var isValid = true;
      self.set('dssFormError', {});
      Ember.$('.has-error').removeClass('has-error');
      if (!self.get('uniqueId')) {
        Ember.$('#uniqueId').addClass('has-error');
        self.set('dssFormError.uniqueId', 'NHI number is required');
        isValid = false;
      }

      if (self.get('selectedEthnicity.length') === 0) {
        Ember.$('#btn-ethnicity').addClass('has-error');
        self.set('dssFormError.selectedEthnicity', 'Ethnicity is required');
        isValid = false;
      }

      if (self.get('selectedDisabilities.length') === 0) {
        Ember.$('#btn-disability').addClass('has-error');
        self.set('dssFormError.selectedDisabilities', 'Disability is required');
        isValid = false;
      }

      if (self.get('selectedFundingTypes.length') === 0) {
        Ember.$('#btn-fundingTypes').addClass('has-error');
        self.set('dssFormError.selectedFundingTypes', 'Funding type is required');
        isValid = false;
      }

      if (Ember.isEmpty(self.get('selectedOrganisation'))) {
        Ember.$('#nascOrganisation').addClass('has-error');
        self.set('dssFormError.selectedOrganisation', 'NASC organisation is required');
        isValid = false;
      }

      if (self.get('isConsent') === false) {
        Ember.$('#agreeInfoLabel').addClass('has-error');
        isValid = false;
      }

      return isValid;
    }
  });
});