define('mycare-ember/components/mc-worker-profile/qualifications/component', ['exports', 'mycare-ember/utils/common', 'mycare-ember/utils/mc-component'], function (exports, _common, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        sortedQualifications: Ember.computed.sort('worker.qualifications', '_sortProps'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('worker', undefined);
            this.initVar('qualificationEditorChannelId', _common.default.createGuid());
            this.initVar('_sortProps', ['startedOn:desc']);
        },


        actions: {
            addQualification: function addQualification() {
                this.get('eventbus').publish('mc-worker-profile/qualification/editor:on:open:' + this.get('qualificationEditorChannelId'), null, 'add');
            },
            onEdit: function onEdit(qualification) {
                this.get('eventbus').publish('mc-worker-profile/qualification/editor:on:open:' + this.get('qualificationEditorChannelId'), qualification, 'edit');
            }
        }
    });
});