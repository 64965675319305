define("mycare-ember/models/activity", ["exports", "ember-data"], function (exports, _emberData) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        activityType: _emberData.default.belongsTo('activity-type', { async: true }),
        timesheet: _emberData.default.belongsTo('timesheet', { async: true }),
        description: _emberData.default.attr('string'),
        completedOn: _emberData.default.attr('string'),
        completedPerson: _emberData.default.belongsTo('person', { async: true }),
        isComplete: _emberData.default.attr('boolean'),
        sortOrder: _emberData.default.attr('number'),
        isExpandable: function () {
            return Ember.isEmpty(this.get('description')) === false || Ember.isEmpty(this.get('completedPerson.content')) === false;
        }.property('completedPerson', 'description')
    });
});