define('mycare-ember/components/mc-thread/sidebar/contracts-joblistings/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/function-helpers', 'mycare-ember/utils/common'], function (exports, _mcComponent, _functionHelpers, _common) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        _profileService: Ember.inject.service('profile'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('thread', null);
            this.initVar('onRedirectToContract', null);
            this.initVar('isSeeker', false);
            this.initVar('clientId', false);
            this.initVar('workerId', false);
            this.initVar('_clientIds', false);
            this.initVar('activeContracts', []);
            this.initVar('_loggedInUserId', localStorage.getItem(this.get('env').getApp('AUTH_USER_KEY')));
            this.initVar('loggedInUser', null);
            this.initVar('_jobListingChannelId', _common.default.createGuid());
        },
        didReceiveAttrs: function didReceiveAttrs() {
            if (Ember.isEmpty(this.get('activeContracts'))) {
                this.findActiveContracts();
            }
            if (Ember.isNone(this.get('loggedInUserId'))) {
                this.findLoggedInUser();
            }
        },
        findLoggedInUser: function findLoggedInUser() {
            var _this = this;

            this.get('_profileService.getPersonByIdAsync').perform({ personId: this.get('_loggedInUserId') }).then(function () {
                _this.set('isSeeker', _this.get('session.authData.isCurrentlySeeker'));
            });
        },


        findActiveContracts: function () {
            var _this2 = this;

            var clientData = Ember.A();
            this.set('_isLoading', true);
            Ember.RSVP.resolve(this.get('thread.contracts')).then(function (contracts) {
                var activeContracts = (contracts || []).filter(function (c) {
                    return Ember.isNone(c.get('contractEndsOn')) || moment.utc(c.get('contractEndsOn')).isAfter();
                });
                activeContracts.forEach(function (c) {
                    c.set('isContractSigned', !Ember.isEmpty(c.get('contractorSignedOn')));
                    clientData.pushObject(Ember.Object.create({
                        id: c.get('clientPerson.id'),
                        modalId: _common.default.createGuid(),
                        name: c.get('clientName').split(' ')[0]
                    }));
                });
                _this2.set('activeContracts', activeContracts.sortBy('createdOn').reverse());
                _this2.set('_clientDataSet', clientData.uniqBy('id'));
                _this2.set('_isLoading', false);
            });
        }.observes('thread.contracts.[]'),

        actions: {
            onContractClick: function onContractClick(contract) {
                _functionHelpers.default.callOrIgnore(this.get('onRedirectToContract'), contract);
            },
            viewContracts: function viewContracts(clientId) {
                this.get('eventbus').publish('mc-modal-contracts:on:open:' + clientId);
            },
            viewJobListings: function viewJobListings() {
                this.get('eventbus').publish('mc-modal-joblistings:on:open:' + this.get('_jobListingChannelId'));
            }
        }
    });
});