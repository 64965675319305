define('mycare-ember/components/mc-timesheet/incident-explanation/component', ['exports', 'mycare-ember/utils/mc-modal-component', 'mycare-ember/utils/common'], function (exports, _mcModalComponent, _common) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend({
        init: function init() {
            this.set('_componentId', 'incident-modal');
            this._super.apply(this, arguments);
            this.initVar('_modalChannelId', _common.default.createGuid());
            this.initVar('channelId', undefined);
        },
        _onAfterClosed: function _onAfterClosed() {
            if (this.get('_onAfterCloseFunction')) {
                this.sendAction('_onAfterCloseFunction');
            }
        }
    });
});