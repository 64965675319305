define('mycare-ember/components/mc-worker-profile/hobbies/editor/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/mixins/validator', 'mycare-ember/utils/common'], function (exports, _mcComponent, _validator, _common) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend(_validator.default, {
        _modalHelper: Ember.inject.service('utils/modal-helper'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('mode', undefined);
            this.initVar('worker', undefined);
            this.initVar('channelId', _common.default.createGuid());
            this.initVar('_modalChannelId', _common.default.createGuid());
            this.initVar('_formData', {});
            this.initVar('_isSaving', false);
            this.initVar('_isDeleting', false);
        },
        didReceiveAttrs: function didReceiveAttrs() {
            this._setupEvents();
        },
        _setupEvents: function _setupEvents() {
            var _this = this;

            var channelId = this.get('channelId');
            var openEvent = 'mc-worker-profile/hobbies/editor:on:open:' + channelId;
            this.get('eventbus').subscribe(openEvent, function (worker, mode) {
                if (mode === 'edit' && !worker) {
                    _this.get('logger').log('\'worker\' parameter is required for event ' + openEvent);
                    return;
                }
                if (!mode) {
                    _this.get('logger').log('\'mode\' parameter is required for event ' + openEvent);
                    return;
                }
                _this.set('worker', worker);
                _this.set('mode', mode);
                _this._setupFormData(_this.get('worker'));
                _this._open();
            }, false);

            this.get('eventbus').subscribe('mc-worker-profile/hobbies/editor:on:close:' + channelId, function () {
                _this._close();
            }, false);
        },
        _setupFormData: function _setupFormData(worker) {
            this.set('_formData', Ember.Object.create({
                interests: worker.get('interests')
            }));
            // this.addValidation('_formData.interests', 'Interests', [Validators.isRequired]);
        },
        _open: function _open() {
            this.get('_modalHelper').openModal(this.get('_modalChannelId'));
        },
        _close: function _close() {
            this.get('_modalHelper').closeModal(this.get('_modalChannelId'));
        },


        actions: {
            save: function save() {
                var _this2 = this;

                if (this._validate()) {
                    this.set('_isSaving', true);
                    this.set('worker.interests', this.get('_formData.interests'));
                    this.get('worker').save().then(function () {
                        _this2.set('_isSaving', false);
                        _this2._close();
                    });
                }
            },
            delete: function _delete() {
                var _this3 = this;

                this.set('_isDeleting', true);
                this.set('worker.interests', null);
                this.get('worker').save().then(function () {
                    _this3.set('_isDeleting', false);
                    _this3._close();
                });
            }
        }
    });
});