define("mycare-ember/seekers/manage/circles/index/route", ["exports", "mycare-ember/mixins/protected_route"], function (exports, _protected_route) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_protected_route.default, {
        beforeModel: function beforeModel(transition) {
            this.isAuthenticated(transition);
        },


        model: function model() {
            var self = this;
            return self.store.query('circle', { personId: self.controllerFor('auth').get('currentPersonId') });
        },

        afterModel: function afterModel(model) {
            if (model.get('length') === 1) {
                this.transitionTo('seekers.manage.circles.detail', model.objectAt(0));
            }
        },


        setupController: function setupController(_, model) {
            var controller = this.controllerFor('circles/index');
            controller.set('model', model);
        },

        renderTemplate: function renderTemplate() {
            var self = this;
            var controller = self.controllerFor('circles/index');
            self.render('circles/index', {
                into: 'seekers.manage',
                controller: controller
            });
        }
    });
});