define("mycare-ember/me/account/reset-password/controller", ["exports", "mycare-ember/mixins/form-checker", "mycare-ember/mixins/password-checker", "mycare-ember/mixins/defer", "mycare-ember/utils/Validators", "mycare-ember/mixins/validator"], function (exports, _formChecker, _passwordChecker, _defer, _Validators, _validator) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_validator.default, _formChecker.default, _defer.default, _passwordChecker.default, {
        passwordUpdated: false,
        isUpdatingPassword: false,
        passwordErrors: [],
        _showPassword: false,
        _isPasswordValid: false,
        _showCurrentPassword: false,

        hasPasswordErrors: function () {
            return this.get('passwordErrors.length') !== 0;
        }.property('passwordErrors.[]'),

        _setHasExternalAccount: function _setHasExternalAccount() {
            this.set('hasExternalAccount', this.get('session').hasExternalAccount());
        },
        setup: function setup() {
            this.set('passwordErrors', []);
            this.setupValidations();
            this._setHasExternalAccount();
            this.set('passwordUpdated', false);
            Ember.run.scheduleOnce('afterRender', this, function () {
                var _this = this;

                Ember.$('#changePasswordModal').on('hidden.bs.modal', function () {
                    _this.set('passwordUpdated', false);
                });
            });
        },
        _validateNewPassword: function _validateNewPassword() {
            return {
                result: this.get('_isPasswordValid') === true && Ember.isEmpty(this.get('newPassword')) === false,
                message: 'New password is required'
            };
        },
        setupValidations: function setupValidations() {
            this._resetValidation();
            this.addValidation('currentPassword', 'currentPassword', 'Current password', [_Validators.default.isRequired]);
        },


        actions: {
            onClose: function onClose() {
                this.set('currentPassword', '');
                this.set('newPassword', '');
                this.set('passwordErrors', []);
            },
            togglePassword: function togglePassword() {
                this.toggleProperty('_showPassword');
            },
            toggleCurrentPassword: function toggleCurrentPassword() {
                this.toggleProperty('_showCurrentPassword');
            },
            onPasswordChange: function onPasswordChange(isValid) {
                this.set('_isPasswordValid', isValid);
            },
            onFacebookRemoved: function onFacebookRemoved() {
                this._setHasExternalAccount();
            },
            onFacebookConnected: function onFacebookConnected() {
                this._setHasExternalAccount();
            },


            setNewPassword: function setNewPassword() {
                if (this._validate() && this.get('_isPasswordValid') === true) {
                    Ember.$('.has-error').removeClass('has-error');
                    var self = this;
                    self.set('passwordUpdated', false);
                    self.set('isUpdatingPassword', true);
                    var changePassword = {
                        "changePassword": {
                            "currentPassword": self.get('currentPassword'),
                            "newPassword": self.get('newPassword')
                        }
                    };
                    changePassword = JSON.stringify(changePassword);

                    var defer = this.getDeferWithToken();
                    Ember.$.post(this.get('env').getApp('API_HOST') + '/api/logins/ChangePassword', changePassword).then(function (loginResponse) {
                        defer.resolve(loginResponse);
                    }, function (error) {
                        defer.reject(error);
                    });
                    defer.promise.then(function () {
                        self.set('passwordUpdated', true);
                        self.set('newPassword', '');
                        self.set('confirmPassword', '');
                        self.set('currentPassword', '');
                        self.set('passwordErrors', []);
                        self.set('isUpdatingPassword', false);
                        self._resetValidation();
                    }).catch(function (error) {
                        if (!Ember.$.isEmptyObject(error)) {
                            self.get('passwordErrors').clear();
                            self.get('passwordErrors').pushObject("Your current password is not correct.");
                        }
                        self.set('isUpdatingPassword', false);
                    }).finally(function () {
                        self.set('isUpdatingPassword', false);
                    });
                }
            },
            close: function close() {
                this.set("isUpdatingPassword", false);
                this.set('passwordErrors', []);
                this.resetForm();
            }
        },

        resetForm: function resetForm() {
            this.set("currentPassword", "");
            this.set("newPassword", "");
            this.set("confirmPassword", "");
        },

        validateForm: function validateForm() {
            this.get('passwordErrors').removeObjects(this.get('passwordErrors'));
            Ember.$('.has-error').removeClass('has-error');
            var isValid = true;
            if (!this.get('currentPassword')) {
                this.get('passwordErrors').pushObject('Current Password is required.');
                Ember.$('#password').addClass('has-error');
                isValid = false;
            }
            if (!this.get('newPassword')) {
                this.get('passwordErrors').pushObject('New Password is required.');
                Ember.$('#newPassword').addClass('has-error');
                isValid = false;
            }
            return isValid;
        }
    });
});