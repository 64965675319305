define('mycare-ember/components/mc-booking/existing-contract-modal/component', ['exports', 'mycare-ember/utils/mc-modal-component', 'mycare-ember/utils/function-helpers'], function (exports, _mcModalComponent, _functionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend({
        init: function init() {
            this.set('_componentId', 'mc-existing-contract-modal');
            this._super.apply(this, arguments);
            this.initVar('channelId', 'global');
            this.initVar('onSelected', undefined);
            this.initVar('onCancelled', undefined);
            this.initVar('_mode', 'hourly');
            this.set('_contract', undefined);
        },
        _onBeforeOpen: function _onBeforeOpen(_ref) {
            var contract = _ref.contract,
                mode = _ref.mode;

            this.set('_contract', contract);
            this.set('_mode', mode);
        },
        _onBeforeClose: function _onBeforeClose() {
            this.onCancelled();
        },


        actions: {
            onSelected: function onSelected() {
                _functionHelpers.default.callOrIgnore(this.get('onSelected'), {
                    contract: this.get('_contract'),
                    mode: this.get('_mode')
                });
                this._close();
            },
            onCancelled: function onCancelled() {
                _functionHelpers.default.callOrIgnore(this.get('onCancelled'), {
                    contract: this.get('_contract'),
                    mode: this.get('_mode')
                });
                this._close();
            }
        }
    });
});