define('mycare-ember/instance-initializers/ember-cli-raygun', ['exports', 'mycare-ember/config/environment', 'ember-cli-raygun/initializers/ember-cli-raygun'], function (exports, _environment, _emberCliRaygun) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initializeWithConfig = initializeWithConfig;
  function initializeWithConfig(passedConfig) {
    return (0, _emberCliRaygun.default)(passedConfig);
  }

  exports.default = {
    name: 'ember-cli-raygun',
    initialize: function initialize() {
      return (0, _emberCliRaygun.default)(_environment.default);
    }
  };
});