define('mycare-ember/account/new-member/route', ['exports', 'mycare-ember/mixins/code-email-reciever', 'mycare-ember/utils/function-helpers'], function (exports, _codeEmailReciever, _functionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_codeEmailReciever.default, {
        beforeModel: function beforeModel(transition) {
            this.processRecieveData(transition, 'account/new-member');
        },
        setupController: function setupController(controller) {
            this._super.apply(this, arguments);
            _functionHelpers.default.callOrIgnoreWithContext(controller.setup, controller);
        }
    });
});