define('mycare-ember/components/mc-thread/sidebar/worker/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/function-helpers', 'mycare-ember/utils/common'], function (exports, _mcComponent, _functionHelpers, _common) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        _workerService: Ember.inject.service('worker'),
        _store: Ember.inject.service('store'),
        worker: null,
        init: function init() {
            this._super.apply(this, arguments);

            this.initVar('thread', null);
            this.initVar('canBook', false);
            this.initVar('onConfirmHire', null);
            this.initVar('loggedInUserId', localStorage.getItem(this.get('env').getApp('AUTH_USER_KEY')));
            this.initVar('profileId', localStorage.getItem(this.get('env').getApp('AUTH_PROFILE_ID')));
            this.initVar('isShowWorker', false);
            this.initVar('isMojCompleted', undefined);
            this.set('_bookingWarningModalChannelId', _common.default.createGuid());
        },


        getAlt: function () {
            return (this.get('worker.firstName') + ' ' + this.get('worker.lastName') + '. Click here to view ' + this.get('worker.firstName') + ' ' + this.get('worker.lastName') + 's profile.').htmlSafe();
        }.property('worker'),

        actions: {
            confirmHire: function confirmHire() {
                _functionHelpers.default.callOrIgnore(this.get('onConfirmHire'));
            }
        }
    });
});