define("mycare-ember/components/mc-job-listing/menu/component", ["exports", "mycare-ember/utils/mc-component"], function (exports, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        tagName: '',
        _wizardService: Ember.inject.service('utils/seeker-listing-wizard'),
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('isMobile', false);
        },


        actions: {
            routeToStep: function routeToStep(step) {
                if (step.isEnable) {
                    this.get('_wizardService.goStep').perform(step.id - 1);
                }
            },
            onShowError: function onShowError(error) {
                toastr.error('Please add a credit card, before publishing your listing');
                console.log("error", error);
            }
        }
    });
});