define('mycare-ember/controllers/partials/modal-user-not-login-hint', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        isForSeeker: function () {
            return window.location.href.match(/browse\/profiles/) !== null;
        }.property().volatile(),

        actions: {
            toLoginPage: function toLoginPage() {
                var self = this;
                Ember.$('#modal-user-not-login').modal('hide');
                self.transitionToRoute('index');
            },

            toSeekerSignup: function toSeekerSignup() {
                this.transitionToRoute('register.seeker-register');
            },
            toWorkerSignup: function toWorkerSignup() {
                this.transitionToRoute('register.worker-register');
            },


            toRegisterPage: function toRegisterPage() {
                var self = this;
                if (window.location.href.match(/browse\/profiles/)) {
                    self.transitionToRoute('register.seeker-register');
                } else if (window.location.href.match(/browse\/jobs/)) {
                    self.transitionToRoute('register.worker-register');
                } else {
                    self.transitionToRoute('index');
                }
            }
        }
    });
});