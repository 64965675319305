define("mycare-ember/facebook/controller", ["exports", "mycare-ember/config/environment"], function (exports, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var computed = Ember.computed;
    var RSVP = Ember.RSVP;

    var _facebookSDKDeferrable = RSVP.defer();

    var fbAsyncInit = function fbAsyncInit() {
        _initFacebook(window.FB);
        _facebookSDKDeferrable.resolve(window.FB);
    };

    window.fbAsyncInit = fbAsyncInit;

    exports.default = Ember.Controller.extend({
        // Resolves when the Facebook SDK is ready.
        //
        // Usage:
        //
        //     facebook: Ember.inject.service(),
        //     foo: function() {
        //       this.get('facebook.SDK').then(function(FB) {
        //         // Facebook SDK is ready and FB is a reference to the SDK
        //       });
        //     }
        SDK: computed.alias('FB'),
        FB: computed(function () {
            _loadFacebookSDK();
            return _facebookSDKDeferrable.promise;
        }),

        showFacebookDialog: function showFacebookDialog() {
            facebookConnectPlugin.showDialog({
                method: "feed",
                quote: "I've signed up for work at Mycare. Looking for work? Create your free profile now and make a difference in your community!",
                picture: 'https://www.mycare.co.nz/img/elderly-man.jpg',
                href: 'https://app.mycare.co.nz/#/auth/register/carer',
                hashtag: '#JoinMyCare'
            }, function (response) {}, function (response) {});
        }

    });


    function _loadFacebookSDK() {
        (function (d, s, id) {
            var js;
            var fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
    }

    function _initFacebook(FB) {
        FB.init({
            appId: _environment.default.APP.FB_APP_ID,
            status: true,
            cookie: true,
            xfbml: true,
            version: _environment.default.APP.GRAPH_API_VERSION
        });
    }
});