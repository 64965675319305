define('mycare-ember/components/mc-dashboard/notification/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('_total', 0);

            this._setupEvents();
        },
        _setupEvents: function _setupEvents() {
            var _this = this;

            this.get('eventbus').subscribe('notification:event:receivedNew', function (_ref) {
                var numberofViews = _ref.numberofViews;

                if (_this.isDestroyed) {
                    return;
                }
                _this.set('_total', numberofViews);
            });
        },


        hasNew: function () {
            return this.get('_total') > 0;
        }.property('_total')
    });
});