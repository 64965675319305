define('mycare-ember/components/mc-timesheet/entry-time/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        tagName: '',

        init: function init() {
            this._super.apply(this, arguments);

            this.initVar('timesheet', undefined);
        },


        isOvernight: function () {
            return moment(this.get('timesheet.actualStartsOn')).date() !== moment(this.get('timesheet.actualEndsOn')).date();
        }.property('timesheet.{actualStartsOn,actualEndsOn}')
    });
});