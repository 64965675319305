define('mycare-ember/carers/my-account/account-detail/payout/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        pageSize: 10,
        offSet: 0,

        _accountService: Ember.inject.service('account'),

        setup: function setup() {
            var dateTo = this.get('model.summary').get('dateTo');
            dateTo = moment(dateTo).startOf('day');
            this.set('dateTo', dateTo);
        }
    });
});