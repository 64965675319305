define('mycare-ember/components/mc-timerange-picker/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/function-helpers', 'mycare-ember/utils/common'], function (exports, _mcComponent, _functionHelpers, _common) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            var now = moment().set({
                minute: Math.ceil(moment().minute() / 5) * 5
            });
            this.initVar('onSelected', undefined);
            this.initVar('initialStartTime', now);
            this.initVar('initialEndTime', now.clone().add(1, 'hour'));
            this.initVar('startTimeLabel', 'From');
            this.initVar('endTimeLabel', 'To');
            this.initVar('timePickerFrom', _common.default.createGuid());
            this.initVar('timePickerTo', _common.default.createGuid());

            this.set('_selectedStartTime', this.get('initialStartTime'));
            this.set('_selectedEndTime', this.get('initialEndTime'));
        },
        updateData: function updateData(_ref) {
            var startTime = _ref.startTime,
                endTime = _ref.endTime;


            _functionHelpers.default.callOrIgnore(this.get('onSelected'), {
                startTime: startTime, endTime: endTime
            });
        },

        actions: {
            onStartTimeSelected: function onStartTimeSelected(date) {
                var startTime = moment(date);
                this.set('_selectedStartTime', startTime);

                this.updateData({
                    startTime: startTime,
                    endTime: this.get('_selectedEndTime')
                });
            },
            onEndTimeSelected: function onEndTimeSelected(date) {
                var endTime = moment(date);
                this.set('_selectedEndTime', endTime);
                var timegap = endTime.diff(this.get('_selectedStartTime'), 'minutes');
                this.set('_timegap', Math.ceil(timegap) / 5 * 5);
                this.updateData({
                    startTime: this.get('_selectedStartTime'),
                    endTime: this.get('_selectedEndTime')
                });
            }
        }
    });
});