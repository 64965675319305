define("mycare-ember/components/ui/mc-accordian/group/component", ["exports", "mycare-ember/utils/mc-component", "mycare-ember/utils/common"], function (exports, _mcComponent, _common) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        classNames: ['panel', 'panel-default'],

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('accordianId', undefined);
            this.initVar('optimizeRendering', true);
            this.initVar('title', undefined);
            this.initVar('hasError', false);
            this.initVar('isOpenedInitially', false);
            this.initVar('id', _common.default.createGuid());
            this.initVar('_shouldRendered', this.get('isOpenedInitially'));
        },
        didInsertElement: function didInsertElement() {
            var self = this;
            //[pref]: only render if this component is activated
            Ember.$("#" + this.get('accordianId')).on('show.bs.collapse', function (e) {
                if (!self.get('_shouldRendered')) {
                    self.set('_shouldRendered', e.target.id === self.get('id'));
                }
            });
        },


        actions: {
            onClick: function onClick() {
                var _this = this;

                Ember.run.later(function () {
                    var firstErrorElem = _this.$('.panel-heading')[0];
                    firstErrorElem.scrollIntoView(true);
                }, 100);
            }
        }
    });
});