define("mycare-ember/mixins/account-pending-balance", ["exports", "mycare-ember/mixins/defer"], function (exports, _defer) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_defer.default, {
        _store: Ember.inject.service('store'),
        pendingAccount: '',
        processPendingBalance: function processPendingBalance(accountId) {
            var self = this;
            var defer = this.getDeferWithToken();
            return self.get('_store').findRecord('account', accountId).then(function (account) {
                Ember.$.get(self.get('env').getApp('API_HOST') + '/api/accounts/updatePendingBalance?id=' + account.get("id")).then(function (loginResponse) {
                    defer.resolve(loginResponse);
                }, function (error) {
                    defer.reject(error);
                });

                defer.promise.then(function () {}).catch(function () {});
            });
        }
    });
});