define('mycare-ember/helpers/short-location', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function makeShortLocation(data) {
    var location = data[0];
    if (location !== undefined && location !== null) {
      return location.replace(', New Zealand', '');
    } else {
      return location;
    }
  }

  exports.makeShortLocation = makeShortLocation;
  exports.default = Ember.Helper.helper(makeShortLocation);
});