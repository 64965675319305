define("mycare-ember/book/controller", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ["backTo", "backToOpt"],
        wizardService: Ember.inject.service('utils/booking-wizard'),

        exitWizard: function exitWizard() {
            if (!Ember.isNone(this.get('backTo'))) {
                if (this.get('backToOpt')) {
                    this.transitionToRoute(this.get('backTo'), this.get('backToOpt'));
                } else {
                    this.transitionToRoute(this.get('backTo'));
                }
            } else {
                if (this.get('session.authData.isCurrentlySeeker')) {
                    this.transitionToRoute("seekers.manage.schedule-workers.index.visits.all");
                } else {
                    this.transitionToRoute("carers.manage.my-schedule.visits");
                }
            }
        },


        isBasicDone: function () {
            if (this.get('model.isDss')) {
                return this.get('wizardService.bookingPerson') && this.get('wizardService.contract') && this.get('wizardService.budgetType');
            }
            return this.get('wizardService.bookingPerson') && this.get('wizardService.contract');
        }.property('wizardService.{bookingPerson,contract,budgetType}'),

        actions: {
            onPrev: function onPrev() {
                this.get('wizardService.goPrevAsync').perform();
            },
            onNext: function onNext() {
                this.get('wizardService.goNextAsync').perform();
            },
            exitWizard: function exitWizard() {
                this.exitWizard();
                this.get('wizardService').resetWizard();
            },
            onNavBack: function onNavBack() {
                if (this.get('wizardService.hasPrev')) {
                    this.get('wizardService.goPrevAsync').perform();
                    return;
                }
                this.exitWizard();
            }
        }
    });
});