define("mycare-ember/components/mc-slider/single/component", ["exports", "mycare-ember/utils/common", "mycare-ember/utils/mc-component"], function (exports, _common, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        // customIcon is an attribute to create font awesome icon
        tagName: '',
        isRendered: false,

        init: function init() {
            this._super();
            this.set('componentId', _common.default.createGuid());
        },


        didRender: function didRender() {
            var self = this;
            var stepSlider = document.getElementById(this.get('componentId'));
            if (stepSlider && self.get('isRendered') === false) {
                noUiSlider.create(stepSlider, {
                    start: [self.get('min')],
                    step: self.get('step'),
                    connect: [true, false],
                    range: {
                        'min': [self.get('min')],
                        'max': [self.get('max')]
                    }
                });
                stepSlider.noUiSlider.on('update', function (values) {
                    self.sendAction('onSetValue', values[0]);
                });
                self.set('isRendered', true);
            }
        }
    });
});