define("mycare-ember/controllers/partials/manage/modal-schedule-event-edit", ["exports", "mycare-ember/mixins/utc-formator"], function (exports, _utcFormator) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_utcFormator.default, {
        auth: Ember.inject.controller('auth'),
        calendarController: Ember.inject.controller('seekers/manage/calendar'),
        todayController: Ember.inject.controller('seekers/manage/index/index'),
        isEnable: true,
        isWorker: false,
        newScheduledStartsOn: '',
        errors: [],

        setup: function setup() {
            var self = this;
            self.set('title', self.get('event.title'));
            self.set('description', self.get('event.description'));
            self.set('startsOn', self.get('event.startsOn'));
            self.set('endsOn', self.get('event.endsOn'));
            self.set('newStartsOn', self.get('event.startsOn'));
            self.set('newEndsOn', self.get('event.endsOn'));

            Ember.$('#modal-schedule-event-edit').on('hidden.bs.modal', function () {
                self.set('event', {});
                self.set('selectedDate', '');
                self.set('desc', '');
                self.set('isNew', true);
                self.get('errors').clear();
            });
        },

        actions: {
            updateEvent: function updateEvent() {
                var self = this;
                if (self.validateForm() === true) {
                    self.set('isSaving', true);
                    self.set('event.title', self.get('title'));
                    self.set('event.description', self.get('description'));
                    self.set('event.startsOn', self.get('newStartsOn'));
                    self.set('event.endsOn', self.get('newEndsOn'));
                    self.get('event').save().then(function () {
                        if (!Ember.isNone(self.get('timesheet'))) {
                            self.set('timesheet.title', self.get('title'));
                            self.set('timesheet.description', self.get('description'));
                            self.set('timesheet.scheduledStartsOn', self.get('newStartsOn'));
                            self.set('timesheet.scheduledEndsOn', self.get('newEndsOn'));
                            self.set('timesheet', '');
                        }
                        self.set('isSaving', false);
                        Ember.$('#modal-schedule-event-edit').modal('hide');
                        self.get('eventbus').publish('mc-schedule-calendar:on:reload:' + self.get('_channelId'));
                    });
                }
            },

            remove: function remove() {
                var self = this;
                self.get('event').deleteRecord();
                self.get('event').save().then(function () {
                    Ember.$('#modal-schedule-event-edit').modal('hide');
                    self.get('eventbus').publish('mc-schedule-calendar:on:reload:' + self.get('_channelId'));
                    if (self.get('todayController')) {
                        self.get('todayController').setup();
                    }
                });
            },

            cancelNewTimesheet: function cancelNewTimesheet() {
                Ember.$('#modal-schedule-event').modal('hide');
            }
        },

        validateForm: function validateForm() {
            var self = this;
            var bool = true;
            self.get('errors').clear();

            if (self.isStartTimeBeforeEndTime() === false) {
                bool = false;
            }

            if (Ember.isEmpty(self.get('title'))) {
                self.get('errors').pushObject('Title is required.');
                bool = false;
            }

            return bool;
        },

        isStartTimeBeforeEndTime: function isStartTimeBeforeEndTime() {
            var self = this;
            if (self.get('isOvernightScheduled')) {
                return true;
            }
            var selectedStartDay = moment(new Date(self.get('event.startsOn')));
            //set start time
            var StartsOnHour = moment(new Date(self.get('newStartsOn'))).hour();
            var StartsOnMinute = moment(new Date(self.get('newStartsOn'))).minute();
            var startTime = selectedStartDay.set({
                'hour': StartsOnHour,
                'minute': parseInt(StartsOnMinute)
            });
            //set end time
            var selectedEndDay = moment(new Date(self.get('event.endsOn')));
            var EndOnHour = moment(new Date(self.get('newEndsOn'))).hour();
            var EndOnMinute = moment(new Date(self.get('newEndsOn'))).minute();
            var endTime = selectedEndDay.set({
                'hour': EndOnHour,
                'minute': parseInt(EndOnMinute)
            });
            if (startTime.isBefore(endTime) === false) {
                self.get('errors').pushObject('Start time must be before end time.');
            } else {
                self.set('newStartsOn', startTime.toISOString());
                self.set('newEndsOn', endTime.toISOString());
            }
            return startTime.isBefore(endTime);
        }
    });
});