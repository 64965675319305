define('mycare-ember/seekers/findhelp/applicants/contract/route', ['exports', 'mycare-ember/utils/mc-protected-route'], function (exports, _mcProtectedRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcProtectedRoute.default.extend({
        _contractService: Ember.inject.service('contract'),

        model: function model(params) {
            return this.get('_contractService.findContractByIdAsync').perform({ contractId: params.contract_id, reload: params.reload }).then(function (contract) {
                return { contract: contract };
            });
        },
        setupController: function setupController(controller) {
            this._super.apply(this, arguments);
            controller.set('isNewContractProcess', false);
        }
    });
});