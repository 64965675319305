define('mycare-ember/register/worker-register/address/controller', ['exports', 'mycare-ember/mixins/validator', 'mycare-ember/utils/Validators', 'mycare-ember/utils/common'], function (exports, _validator, _Validators, _common) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_validator.default, {

        _accountService: Ember.inject.service('account'),
        _profileService: Ember.inject.service('profile'),

        formData: {
            number: undefined,
            street: undefined,
            suburb: undefined,
            city: undefined,
            postCode: undefined,
            location: undefined
        },

        rawLocationValue: null,

        onPrev: function onPrev() {
            return true;
        },

        setup: function setup() {
            this._setupInitialFormState();
            this._setupValidations();
        },
        willWizardEnter: function willWizardEnter(stepInfo, data) {
            this.set('wizardData', data);
            this.set('wizardData.addressForm', data.backupDataFn().addressForm);
            if (Ember.isNone(data) || data.get('state.personal-details.completed') !== true) {
                return 'personal-details';
            }
        },
        _setupValidations: function _setupValidations() {
            this._resetValidation();
            this.addValidation('formData.number', 'number', 'Street number', [this._validateStreetNumber]);
            this.addValidation('formData.street', 'street', 'Street', [_Validators.default.isRequired]);
            this.addValidation('formData.location', 'location', 'Location', [this._validateLocation]);
            this.addValidation('formData.postCode', 'postCode', 'Post code', [_Validators.default.isRequired]);
            this.addValidation('formData.consent', 'consent', 'Consent', [_Validators.default.consent]);
        },
        _validateLocation: function _validateLocation() {
            var isValid = true;
            if (Ember.isEmpty(this.get('formData.location')) === true) {
                isValid = false;
            }
            if (this.get('rawLocationValue') !== this.get('wizardData.account.location') && (Ember.isEmpty(this.get('formData.location')) === true || this.get('formData.location') !== this.get('rawLocationValue'))) {
                isValid = false;
            }
            return {
                result: isValid,
                message: 'Location is required'
            };
        },
        _validateStreetNumber: function _validateStreetNumber(name, value) {
            var isValid = _Validators.default.isRequired(name, value).result && value.length <= 10 && value.length > 0 && value.replace(/\s/g, '').length > 0;
            return {
                result: isValid,
                message: 'Street number is required and must be less than 10 characters'
            };
        },
        _setupInitialFormState: function _setupInitialFormState() {
            var address = this.get('wizardData.address');
            if (Ember.isNone(address)) return;
            this.set('formData.consent', this.get('wizardData.addressForm.consent'));
            this.set('formData.number', this.get('wizardData.addressForm.number'));
            this.set('formData.street', this.get('wizardData.addressForm.street'));
            this.set('formData.postCode', this.get('wizardData.addressForm.postCode'));
            this.set('formData.location', this.get('wizardData.account.location') || this.get('wizardData.addressForm.location'));
        },


        actions: {
            submit: function submit() {
                this.send('next');
            }
        },

        _validateConsent: function _validateConsent(name, value) {
            return {
                result: value === true,
                message: name + ' is required'
            };
        },
        onNext: function onNext() {
            var _this = this;

            if (!this._validate()) {
                return false;
            }
            var account = this.get('wizardData.account');
            return _common.default.decodeLocation(this.get('formData.location')).then(function (_ref) {
                var lat = _ref.lat,
                    lng = _ref.lng;

                account.set('latitude', lat);
                account.set('longitude', lng);
                account.set('location', _this.get('formData.location'));
            }).then(function () {
                var existingAddress = _this.get('wizardData.address');
                if (Ember.isNone(existingAddress)) {
                    return _this.get('_profileService.createAddressAsync').perform({
                        personId: account.get('id'),
                        countryId: 151,
                        shouldSave: false
                    });
                } else {
                    return existingAddress;
                }
            }).then(function (address) {
                var _CommonUtils$parseAdd = _common.default.parseAddressFromMap(_this.get('formData.location').replace(', New Zealand', '')),
                    city = _CommonUtils$parseAdd.city,
                    suburb = _CommonUtils$parseAdd.suburb;

                address.set('suburb', suburb);
                address.set('city', city);
                address.set('number', _this.get('formData.number'));
                address.set('street', _this.get('formData.street'));
                address.set('postcode', _this.get('formData.postCode'));
                _this.set('wizardData.address', address);
                return address.save();
            }).then(function () {
                _this.get('stepInfo.parent').backupWizardData({
                    addressForm: _this.get('formData')
                });
            }).then(function () {
                return 'phone-verification';
            }).catch(function (e) {
                if (e.errors && e.errors[0] && e.errors[0].status === "401") {
                    _this.transitionToRoute('logout');
                    return;
                }
                throw e;
            });
        }
    });
});