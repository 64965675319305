define("mycare-ember/seekers/manage/index/route", ["exports", "mycare-ember/mixins/protected_route"], function (exports, _protected_route) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_protected_route.default, {
        beforeModel: function beforeModel(transition) {
            this._super();
            this.isAuthenticated(transition);
            if (Ember.isEmpty(transition.queryParams.date)) {
                this.transitionTo('seekers.manage.schedule-workers.index');
                return;
            }
            this.set('selectedDate', transition.queryParams.date);
        }
    });
});