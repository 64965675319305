define("mycare-ember/carers/manage/calendar/controller", ["exports", "mycare-ember/mixins/timesheet-calendar", "mycare-ember/mixins/sort-timesheet-carer-mixin"], function (exports, _timesheetCalendar, _sortTimesheetCarerMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_timesheetCalendar.default, _sortTimesheetCarerMixin.default, {
        isWorker: true,
        isScheduleView: true,
        isWeeklyShow: false,

        loginProfileId: function () {
            return this.get('auth.profileId');
        }.property(),

        setup: function setup() {
            this.watchSchedulePickerControllerTime();
        },


        watchSelectedEvent: function () {
            var self = this;
            if (!Ember.isEmpty(self.get('selectedEvent'))) {
                var selectedEvent = self.get('selectedEvent');
                self.store.findRecord('timesheet', selectedEvent.id).then(function (timesheet) {
                    self.transitionToRoute('carers.manage.my-schedule.schedule-detail', timesheet.get('id'), {
                        queryParams: {
                            backTo: 'carers.manage.calendar'
                        }
                    });
                });
            }
        }.observes('selectedEvent'),

        actions: {
            setWeeklyClick: function setWeeklyClick(bool) {
                this.set('isWeeklyShow', bool);
            }
        }
    });
});