define('mycare-ember/components/mc-schedule-picker/weekly-v3/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/function-helpers'], function (exports, _mcComponent, _functionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);

            this.initVar('initialDate', undefined);
            this.initVar('onChanged', undefined);
            this.initVar('isDisabled', false);

            this.set('_today', undefined);
            this.set('_startOfWeek', undefined);
            this.set('_endOfWeek', undefined);
            this.set('_label', undefined);
            this.set('_labelMobile', undefined);

            this._setDates(this.get('initialDate') ? moment(this.get('initialDate')) : moment());
            this._setLabels();
            this.send('onChanged');
        },
        _setDates: function _setDates(relativeDate) {
            this.set('_today', relativeDate);
            this.set('_startOfWeek', this.get('_today').clone().startOf('isoweek'));
            this.set('_endOfWeek', this.get('_today').clone().endOf('isoweek'));
        },
        _setLabels: function _setLabels() {
            var startOfWeekLabelInstance = this.get('_startOfWeek').clone().tz('Pacific/Auckland');
            var endOfWeekLabelInstance = this.get('_endOfWeek').clone().tz('Pacific/Auckland');
            if (startOfWeekLabelInstance.month() === endOfWeekLabelInstance.month()) {
                this.set('_label', startOfWeekLabelInstance.format('D') + ' - ' + endOfWeekLabelInstance.format('D MMMM YYYY'));
                this.set('_labelMobile', startOfWeekLabelInstance.format('D') + ' - ' + endOfWeekLabelInstance.format('D MMMM YYYY'));
            } else {
                this.set('_label', startOfWeekLabelInstance.format('D MMMM') + ' - ' + endOfWeekLabelInstance.format('D MMMM YYYY'));
                this.set('_labelMobile', startOfWeekLabelInstance.format('D MMM') + ' - ' + endOfWeekLabelInstance.format('D MMMM YYYY'));
            }
        },


        actions: {
            getNextWeek: function getNextWeek() {
                this._setDates(this.get('_today').clone().add(7, 'days'));
                this._setLabels();
                this.send('onChanged');
            },
            getPreviousWeek: function getPreviousWeek() {
                this._setDates(this.get('_today').clone().subtract(7, 'days'));
                this._setLabels();
                this.send('onChanged');
            },
            getToday: function getToday() {
                this._setDates(moment());
                this._setLabels();
                this.send('onChanged');
            },
            onChanged: function onChanged() {
                _functionHelpers.default.callOrIgnore(this.get('onChanged'), {
                    startDate: this.get('_startOfWeek').clone(),
                    endDate: this.get('_endOfWeek').clone(),
                    date: this.get('_today').clone()
                });
            }
        }
    });
});