define("mycare-ember/components/crop-image-upload/component", ["exports", "ember-uploader", "mycare-ember/utils/function-helpers"], function (exports, _emberUploader, _functionHelpers) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberUploader.default.FileField.extend({
        init: function init() {
            this._super.apply(this, arguments);
            if (Ember.isNone(this.get('onSelected'))) this.set('onSelected', undefined);
        },


        //Callback for when file is selected.
        filesDidChange: function () {
            var self = this;
            var files = self.get('files'); //FileList object
            if (files) {
                var onReload = function onReload(event) {
                    var picFile = event.target;
                    var allImgComponents = Ember.$('#cropper-example-2').find('img');
                    self.set('imagedata', picFile.result);
                    allImgComponents.attr('src', picFile.result);
                    _functionHelpers.default.callOrIgnore(self.get('onSelected'), picFile.result);
                };
                for (var i = 0; i < files.length; i++) {
                    var file = files[i];
                    //Only pics
                    if (file.type.match('image')) {
                        var picReader = new FileReader();
                        picReader.onload = onReload;
                        picReader.readAsDataURL(file);
                    }
                }
            }
        }.observes("files"),

        removeFiles: function () {
            var input = Ember.$('#cropImageUploader');
            input.val('');
            this.$().val('');
        }.observes('isRemoveFile')

    });
});