define("mycare-ember/components/mc-search-bar/distance/component", ["exports", "mycare-ember/utils/common", "mycare-ember/utils/mc-component", "mycare-ember/utils/function-helpers"], function (exports, _common, _mcComponent, _functionHelpers) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        tagName: '',

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('parentId', _common.default.createGuid());
            this.initVar('buttonId', _common.default.createGuid());
            this.initVar('isVolunteer', false);
            this.initVar('min', 10);
            this.initVar('step', 10);
            this.initVar('max', 40);
        },


        buttonSelectionText: function () {
            // var self = this;
            if (Ember.isEmpty(this.get('selectValue'))) {
                return '';
            } else {
                return "(" + this.get('selectValue') + " kilometers)";
            }
        }.property('selectValue'),

        didRender: function didRender() {
            Ember.$('#' + this.get('parentId')).on('hide.bs.dropdown', function () {
                return false;
            });
            this.set('isComponentReady', true);
        },


        actions: {
            open: function open() {
                Ember.$('.dropdown.open').removeClass('open');
                Ember.$('#' + this.get('parentId')).addClass('open');
            },
            updateValue: function updateValue(value) {
                if (this.get('isComponentReady') === true) {
                    this.set('selectValue', parseInt(value));
                }
            },
            applyValue: function applyValue() {
                _functionHelpers.default.callOrIgnore(this.get('onSetDistance'), this.get('selectValue'));
                this.set('isUpdated', true);
                Ember.$('.dropdown.open').removeClass('open');
            },
            cancelValue: function cancelValue() {
                this.set('selectValue', null);
                _functionHelpers.default.callOrIgnore(this.get('onSetDistance'), this.get('selectValue'));
                this.set('isUpdated', false);
                Ember.$('.dropdown.open').removeClass('open');
            }
        }
    });
});