define('mycare-ember/register/seeker-register/funding-details/controller', ['exports', 'mycare-ember/mixins/validator', 'mycare-ember/utils/Validators'], function (exports, _validator, _Validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_validator.default, {
        _referenceModelService: Ember.inject.service('reference-models'),
        _accountService: Ember.inject.service('account'),
        formData: {
            ethnicities: [],
            disabilities: [],
            fundingTypes: [],
            fundingHost: undefined
        },

        setup: function setup() {
            this._setupFormData();
            this._setupValidations();
        },
        willWizardEnter: function willWizardEnter(stepInfo, data) {
            this.set('wizardData', data);
            this.set('wizardData.fundingDetailsForm', data.backupDataFn().fundingDetailsForm);
            if (data.get('state.client-details.completed') !== true) {
                return 'client-details';
            }
        },
        _setupValidations: function _setupValidations() {
            this._resetValidation();
            this.addValidation('formData.nhiNumber', 'nhiNumber', 'NHI number', [_Validators.default.isRequired]);
            this.addValidation('formData.ethnicities.[]', 'ethnicity', 'Ethnicity', [_Validators.default.isRequired]);
            this.addValidation('formData.disabilities.[]', 'disabilities', 'Disability', [_Validators.default.isRequired]);
            this.addValidation('formData.fundingTypes.[]', 'fundingTypes', 'Funding type', [_Validators.default.isRequired]);
            this.addValidation('formData.organisation', 'organisation', 'Organisation', [_Validators.default.isRequired]);
            this.addValidation('formData.fundingHost', 'fundingHost', 'Funding host', [this._fundingHostValidation]);
            this.addValidation('formData.consent', 'consent', 'Consent', [this._validateConsent]);
        },
        _validateConsent: function _validateConsent(name, value) {
            return {
                result: value === true,
                message: name + ' is required'
            };
        },
        _fundingHostValidation: function _fundingHostValidation() {
            return this.get('isFundingHostRequired') ? Ember.isEmpty(this.get('formData.fundingHost')) ? {
                result: false,
                message: 'Funding host is required'
            } : true : true;
        },


        isFundingHostRequired: function () {
            this.set('formData.fundingHost', undefined);
            var requiredList = this.get('formData.fundingTypes').filter(function (fundType) {
                var name = fundType.get('name').toLowerCase();
                return name === 'if respite' || name === 'individualised funding' || name === 'choices in community living';
            });
            if (requiredList.length > 0) {
                return true;
            }
            return false;
        }.property('formData.fundingTypes.[]'),

        _setupFormData: function _setupFormData() {
            var _this = this;

            this.set('formData.nhiNumber', this.get('wizardData.fundingDetailsForm.nhiNumber'));
            this.set('formData.consent', this.get('wizardData.fundingDetailsForm.consent'));
            this.set('formData.ethnicities', Ember.A());
            this.set('formData.disabilities', Ember.A());
            this.set('formData.organisation', undefined);
            this.set('formData.fundingTypes', Ember.A());
            this.set('formData.fundingHost', undefined);
            this.get('_referenceModelService.getEthnicitiesAsync').perform().then(function (items) {
                _this.set('ethnicities', items);
                if (!Ember.isEmpty(_this.get('wizardData.fundingDetailsForm.ethnicities'))) {
                    _this.set('formData.ethnicities', items.filter(function (i) {
                        return _this.get('wizardData.fundingDetailsForm.ethnicities').map(function (i) {
                            return i.ethnicityName;
                        }).includes(i.get('ethnicityName'));
                    }));
                }
            });

            this.get('_referenceModelService.getDisabilitiesAsync').perform().then(function (items) {
                _this.set('disabilities', items);
                if (!Ember.isEmpty(_this.get('wizardData.fundingDetailsForm.disabilities'))) {
                    _this.set('formData.disabilities', items.filter(function (i) {
                        return _this.get('wizardData.fundingDetailsForm.disabilities').map(function (i) {
                            return i.disabilityName;
                        }).includes(i.get('disabilityName'));
                    }));
                }
            });

            this.get('_referenceModelService.getOrganisationsAsync').perform().then(function (items) {
                _this.set('organisations', items);
                if (!Ember.isEmpty(_this.get('wizardData.fundingDetailsForm.organisation.name'))) {
                    _this.set('formData.organisation', items.find(function (i) {
                        return _this.get('wizardData.fundingDetailsForm.organisation.name') === i.get('name');
                    }));
                }
            });
            this.get('_referenceModelService.getFundingTypesAsync').perform().then(function (items) {
                _this.set('fundingTypes', items);
                if (!Ember.isEmpty(_this.get('wizardData.fundingDetailsForm.fundingTypes'))) {

                    _this.set('formData.fundingTypes', items.filter(function (i) {
                        return _this.get('wizardData.fundingDetailsForm.fundingTypes').map(function (i) {
                            return i.name;
                        }).includes(i.get('name'));
                    }));
                }
            });

            this.get('_referenceModelService.getFundingHostsAsync').perform().then(function (items) {
                _this.set('fundingHosts', items);
                if (!Ember.isEmpty(_this.get('wizardData.fundingDetailsForm.fundingHost.name'))) {
                    _this.set('formData.fundingHost', items.find(function (i) {
                        return _this.get('wizardData.fundingDetailsForm.fundingHost.name') === i.get('name');
                    }));
                }
            });
        },


        onPrev: function onPrev() {
            return true;
        },

        onNext: function onNext() {
            if (!this._validate()) {
                return false;
            }

            var dssForm = Ember.Object.create({});
            dssForm.set('nhiNumber', this.get('formData.nhiNumber'));
            dssForm.set('ethnicities', this.get('formData.ethnicities'));
            dssForm.set('disabilities', this.get('formData.disabilities'));
            dssForm.set('fundingTypes', this.get('formData.fundingTypes'));
            dssForm.set('organisation', this.get('formData.organisation'));
            dssForm.set('fundingHost', this.get('formData.fundingHost'));
            dssForm.set('consent', this.get('formData.consent'));

            this.set('wizardData.dssForm', dssForm);
            this.get('stepInfo.parent').backupWizardData({
                fundingDetailsForm: dssForm
            });
        },


        actions: {
            submit: function submit() {
                this.send("next");
            }
        }
    });
});