define('mycare-ember/components/input-with-dropdown/component', ['exports', 'mycare-ember/utils/common', 'mycare-ember/utils/mc-component'], function (exports, _common, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({

        tagName: '',

        setup: function () {
            var self = this;
            self.set('selectComponentId', _common.default.createGuid());
            Ember.run.scheduleOnce('afterRender', self, function () {
                document.onclick = function (event) {
                    var classNames = event.target.className;
                    var found = false;
                    if (Ember.isEmpty(classNames) === false && classNames !== Object(classNames)) {
                        var classes = classNames.split(' ');
                        var i = 0;
                        while (i < classes.length && !found) {
                            if (classes[i] === 'dropbtn') {
                                found = true;
                            } else {
                                ++i;
                            }
                        }
                    }
                    if (!found) {
                        self.hideDropdown();
                    }
                };
            });
        }.on('init'),

        hideDropdown: function hideDropdown() {
            var dropdowns = document.getElementsByClassName("dropdown-content");
            for (var n = 0; n < dropdowns.length; n++) {
                var openDropdown = dropdowns[n];
                if (openDropdown.classList.contains('show')) {
                    openDropdown.classList.remove('show');
                }
            }
        },


        rePackItems: function rePackItems() {
            var self = this;
            self.set('rePackItems', []);
            self.get('items').forEach(function (item) {
                var itemString = item.split(' ').join('_');
                var id = self.get('selectComponentId') + "_" + itemString;
                var rePackItem = Ember.Object.create({
                    id: id,
                    name: item
                });
                self.get('rePackItems').pushObject(rePackItem);
            });
        },

        locateElement: function locateElement() {
            var self = this;
            var n;
            for (n = 0; n < self.get('items.length'); n++) {
                var item = self.get('items').objectAt(n);
                if (item === self.get('inputValue')) {
                    break;
                }
            }
            var topPos = n * 18;
            Ember.$('#' + self.get('selectComponentId')).animate({ scrollTop: topPos }, 10);
        },
        /**
         * Reset date picker with selected values when selection was maded.
         */
        actions: {
            focusIn: function focusIn() {
                var self = this;
                if (Ember.$('#' + self.get('selectComponentId')).hasClass('show') === false) {
                    self.locateElement();
                    Ember.$('#' + self.get('selectComponentId')).toggleClass("show");
                } else {
                    self.hideDropdown();
                }
            },

            pickItem: function pickItem(item) {
                this.set('inputValue', item);
            }
        }

    });
});