define('mycare-ember/me/profiles/site-setup/payment/controller', ['exports', 'mycare-ember/mixins/validator'], function (exports, _validator) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_validator.default, {
        onPrev: 'index',
        errors: {},
        initialPaymentOptions: {},
        startWatchingValidation: false,

        onWizardInit: function onWizardInit(_, data) {
            data.set('selectedSupportTypes', Ember.A([]));
            data.set('selectedPaymentOptions', Ember.A([]));
            data.set('initialPaymentOptions', Ember.A([]));
        },
        setup: function setup() {
            var _this = this;

            Ember.run.later(function () {
                _this.setupValidation();
            }, 300);
        },
        willWizardEnter: function willWizardEnter(controller, data) {
            if (data.get('state.index.completed') !== true) {
                return 'index';
            } else {
                controller.set('errors', {});
                data.set('initialPaymentOptions.isPrivatePay', data.get('person.isPrivatePay'));
                data.set('initialPaymentOptions.isGovernmentPay', data.get('person.isGovernmentPay'));
            }
        },
        saveSupportType: function saveSupportType() {
            var _this2 = this;

            this.get('wizardData.selectedSupportTypes').map(function (sp) {
                _this2.get('wizardData.person').get('supportTypes').clear();
                _this2.get('wizardData.person').get('supportTypes').pushObject(sp.get('record'));
            });
        },
        setupValidation: function setupValidation() {
            this._resetValidation();
            this.addValidation('wizardData.selectedPaymentOptions.isGovernmentPay', 'paymentOptions', 'Payment option', [this.validatePaymentOptions]);
            this.addValidation('wizardData.selectedPaymentOptions.isPrivatePay', 'paymentOptions', 'Payment option', [this.validatePaymentOptions]);
            this.addValidation('wizardData.selectedSupportTypes.[]', 'supportTypes', 'Support type', [this.validateSupportTypes]);
        },
        validateSupportTypes: function validateSupportTypes() {
            var isValid = true;
            this.set('errors.supportTypes', null);
            this.set('errors.hasOtherSupportTypeError', null);
            var supportTypesExists = this.get('wizardData.selectedSupportTypes.length');
            if (!supportTypesExists || supportTypesExists <= 0) {
                this.set('errors.supportTypes', 'Please select support types you are looking for');
                isValid = false;
            }

            var emptySupportType = this.get('wizardData.selectedSupportTypes').filter(function (supportType) {
                return Ember.isEmpty(supportType.get('value')) === true;
            });
            if (emptySupportType.get('length') > 0) {
                this.set('errors.hasOtherSupportTypeError', true);
                isValid = false;
            }
            return {
                result: isValid,
                message: 'Support type is requried'
            };
        },
        validatePaymentOptions: function validatePaymentOptions() {
            var isValid = true;
            this.set('errors.paymentOptions', null);
            if (!this.get('wizardData.selectedPaymentOptions.isGovernmentPay') && !this.get('wizardData.selectedPaymentOptions.isPrivatePay')) {
                this.set('errors.paymentOptions', 'Please select payment options for your worker');
                isValid = false;
            }
            return {
                result: isValid,
                message: 'Payment option is required'
            };
        },
        onNext: function onNext() {
            if (!this._validate()) {
                this.set('startWatchingValidation', true);
                return false;
            }
            this.saveSupportType();
            this.set('wizardData.person.isPrivatePay', this.get('wizardData.selectedPaymentOptions.isPrivatePay'));
            this.set('wizardData.person.isGovernmentPay', this.get('wizardData.selectedPaymentOptions.isGovernmentPay'));
            return 'done';
        }
    });
});