define("mycare-ember/me/profiles/site-setup/index/controller", ["exports", "mycare-ember/mixins/mixpanel", "mycare-ember/mixins/form-checker", "mycare-ember/mixins/dss-form", "mycare-ember/mixins/support-type-mixin", "mycare-ember/mixins/validator", "mycare-ember/utils/Validators"], function (exports, _mixpanel, _formChecker, _dssForm, _supportTypeMixin, _validator, _Validators) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_validator.default, _supportTypeMixin.default, _mixpanel.default, _dssForm.default, {

        _geoService: Ember.inject.service('utils/geo'),
        isLoading: false,

        fileUploadUrl: function () {
            return this.get('env').getApp('API_HOST') + '/api/FileUpload';
        }.property(),
        referenceDataService: Ember.inject.service('reference-models'),
        roles: Ember.computed.alias('referenceDataService.roles'),
        location: '',
        errors: [],
        emails: [],
        newEmail: undefined,
        currentAvatarUrl: '',
        avatarUrl: '',

        newAvatarUrl: function () {
            return this.get('env').getApp('PROFILE_DEFAULT_IMAGE');
        }.property(),

        dateOfBirth: undefined,

        hasErrors: function () {
            return this.get('errors.length') !== 0;
        }.property('errors.[]'),

        setup: function setup() {
            this.setupValidations();
        },


        updateAvatarUrl: function () {
            var self = this;
            var avatarPromise = self.get('model.person.avatar');
            if (avatarPromise) {
                avatarPromise.then(function (image) {
                    if (image === null) {
                        self.set('currentAvatarUrl', '');
                        self.set('avatarUrl', self.get('env').getApp('PROFILE_DEFAULT_IMAGE'));
                    } else {
                        self.set('currentAvatarUrl', image.get('url'));
                        self.set('avatarUrl', image.get('url'));
                    }
                });
            }
        }.observes('model.person.avatar'),

        saveSupportType: function saveSupportType(context, person) {
            var promises = this.get('selectedSupportTypes').map(function (sp) {
                if (sp.get('displayName') === 'Other' && sp.get('value')) {
                    if (sp.get('record')) {
                        return context.editSupportType(sp.get('record.id'), sp.get('value'));
                    } else {
                        return context.addSupportType(sp.get('value'), person).then(function (sp) {
                            person.get('supportTypes').pushObject(sp);
                            sp.set('value', null);
                        });
                    }
                } else {
                    return new Ember.RSVP.Promise(function (resolve) {
                        person.get('supportTypes').pushObject(sp.get('record'));
                        resolve();
                    });
                }
            });
            return Ember.RSVP.all(promises).then(function () {
                return person.save();
            });
        },

        onNext: function onNext() {
            var _this = this;

            this.set('wizardData.emails', this.get('emails'));
            var person = this.get('wizardData.person');
            if (!this._validate()) {
                return false;
            }

            var geoPromise = this.get('_geoService.decodeAsync').perform({ location: this.get('newLocation') }).then(function (latlng) {
                person.set('latitude', latlng.lat);
                person.set('longitude', latlng.lng);
                person.set('location', _this.get('newLocation'));
            });

            return Ember.RSVP.all([geoPromise, this.setFormDataAsync(), this.setAvatarAsync()]).then(function () {
                return 'done';
            });
        },

        onWizardCleanup: function onWizardCleanup() {
            this.set('newFirstName', null);
            this.set('emails', []);
            this.set('newLastName', null);
            this.set('dateOfBirth', null);
            this.set('genderValue', null);
            this.set('location', '');
        },
        setAvatarAsync: function setAvatarAsync() {
            var _this2 = this;

            var person = this.get('wizardData.person');
            return new Ember.RSVP.Promise(function (resolve) {
                if (_this2.get('newAvatarUrl') !== _this2.get('env').getApp('PROFILE_DEFAULT_IMAGE')) {
                    return _this2.store.createRecord('image', {
                        url: _this2.get('newAvatarUrl'),
                        uploadedOn: moment.utc(new Date()).toISOString()
                    }).save().then(function (avatar) {
                        person.set('avatar', avatar);
                        resolve();
                    });
                } else {
                    _this2.store.findRecord('image', 1).then(function (avatar) {
                        person.set('avatar', avatar);
                        resolve();
                    });
                }
            });
        },
        setFormDataAsync: function setFormDataAsync() {
            var _this3 = this;

            var person = this.get('wizardData.person');
            return new Ember.RSVP.Promise(function (resolve) {
                person.set('isPublished', false);
                person.set('modifiedOn', moment.utc(new Date()).toISOString());
                person.set('firstName', _this3.get('newFirstName'));
                person.set('lastName', _this3.get('newLastName'));
                person.set('dateOfBirth', _this3.get('dateOfBirth'));
                person.set('gender', _this3.get('genderValue'));
                person.set('uniqueId', _this3.get('uniqueId'));
                resolve();
            });
        },


        actions: {
            uploadAvatar: function uploadAvatar() {
                this.get('eventbus').publish("mc-image-cropper:on:open:" + this.get('_avatarUploaderChannelId'));
            },
            onDateOfBirthSelected: function onDateOfBirthSelected(momentDate) {
                this.set('dateOfBirth', momentDate ? momentDate.toISOString() : null);
            },
            onAvatarUploaded: function onAvatarUploaded(fileUrl) {
                this.set('newAvatarUrl', fileUrl);
            },
            saveAndContinue: function saveAndContinue() {
                var self = this;
                if (self._validate()) {
                    self.set('isSavingAndContinue', true);
                    self.get('_geoService.decodeAsync').perform({ location: self.get('newLocation') }).then(function (latlng) {
                        var person = self.store.createRecord('person', {
                            latitude: latlng.lat,
                            longitude: latlng.lng,
                            location: self.get('newLocation'),
                            isPublished: false,
                            modifiedOn: moment.utc(new Date()).toISOString(),
                            firstName: self.get('newFirstName'),
                            lastName: self.get('newLastName'),
                            dateOfBirth: self.get('dateOfBirth'),
                            gender: self.get('genderValue'),
                            uniqueId: self.get('uniqueId'),
                            isGovernmentPay: self.get('selectedPaymentOptions.isGovernmentPay'),
                            isPrivatePay: self.get('selectedPaymentOptions.isPrivatePay')
                        });

                        // Only create a new file if a new image has been uploaded
                        if (self.get('newAvatarUrl') !== self.get('env').getApp('PROFILE_DEFAULT_IMAGE')) {
                            return self.store.createRecord('image', {
                                url: self.get('newAvatarUrl'),
                                uploadedOn: moment.utc(new Date()).toISOString()
                            }).save().then(function (avatar) {
                                person.set('avatar', avatar);
                                person.save().then(function (person) {
                                    return self.saveSupportType(self, person);
                                }).then(function (savedPerson) {
                                    self.AfterSaveAndContinueProcess(savedPerson);
                                });
                            });
                        } else {
                            self.store.findRecord('image', 1).then(function (avatar) {
                                person.set('avatar', avatar);
                                person.save().then(function (person) {
                                    return self.saveSupportType(self, person);
                                }).then(function (savedPerson) {
                                    self.AfterSaveAndContinueProcess(savedPerson);
                                }, function () {
                                    toastr.error('There was an error creating your profile, please try again');
                                });
                            });
                        }
                    });
                }
            },


            cancel: function cancel() {
                var self = this;
                self.setup();
                self.transitionToRoute('me.profiles');
            },

            startMycare: function startMycare() {
                var self = this;
                self.setup();
                self.transitionToRoute('me.profiles');
            },

            onSetEmail: function onSetEmail(email) {
                this.emails.pushObject(email);
            },
            removeEmail: function removeEmail(email) {
                this.emails.removeObject(email);
            }
        },

        AfterSaveAndContinueProcess: function AfterSaveAndContinueProcess(savedPerson) {
            var self = this;
            self.set('isSavingAndContinue', false);
            if (self.get('isDssUser') === true) {
                self.mixpanelCreateClientProfile(savedPerson, true, false, true, true);
                self.submitForm(savedPerson, function () {
                    self.send('switchProfile', 'organiser', savedPerson.get('id'), savedPerson.get('firstName'), 'me.profiles.site-setup.payment');
                });
            } else {
                self.mixpanelCreateClientProfile(savedPerson, true, false, true, false);
                self.createPremiumSubscription(savedPerson, function () {
                    self.send('switchProfile', 'organiser', savedPerson.get('id'), savedPerson.get('firstName'), 'me.profiles.site-setup.payment');
                });
            }
        },

        setupValidations: function setupValidations() {
            this.addValidation('newFirstName', 'newFirstName', 'Site name', [_Validators.default.isRequired, _Validators.default.noSpecialCharacters]);

            this.addValidation('newLocation', 'newLocation', 'Location', [_Validators.default.isRequired]);
        }
    });
});