define('mycare-ember/browse/jobs/route', ['exports', 'mycare-ember/utils/mc-protected-route', 'mycare-ember/mixins/scroll_top'], function (exports, _mcProtectedRoute, _scroll_top) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcProtectedRoute.default.extend(_scroll_top.default, {

        actions: {
            didTransition: function didTransition() {
                Ember.$("body").addClass("page-browse");
            }
        }
    });
});