define('mycare-ember/models/country', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        name: _emberData.default.attr('string'),
        isoCode: _emberData.default.attr('string'),
        iddCode: _emberData.default.attr('string')
        //  addresses: DS.hasMany('address', {async:true})
    });
});