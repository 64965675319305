define("mycare-ember/seekers/findhelp/profile/index/edit/controller", ["exports", "mycare-ember/mixins/file-uploader-board", "mycare-ember/mixins/scroll_top", "mycare-ember/mixins/support-type-mixin", "mycare-ember/utils/common", "mycare-ember/mixins/form-checker"], function (exports, _fileUploaderBoard, _scroll_top, _supportTypeMixin, _common, _formChecker) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_formChecker.default, _supportTypeMixin.default, _fileUploaderBoard.default, _scroll_top.default, {

        auth: Ember.inject.controller('auth'),
        _avatarUploaderChannelId: _common.default.createGuid(),
        _firstLastNameChannel: _common.default.createGuid(),
        _genderChannel: _common.default.createGuid(),
        _dobChannel: _common.default.createGuid(),
        _locationChannel: _common.default.createGuid(),
        _bioChannel: _common.default.createGuid(),
        _profileService: Ember.inject.service('profile'),
        formData: {},
        fileUploadUrl: function () {
            return this.get('env').getApp('API_HOST') + '/api/FileUpload';
        }.property(),

        setup: function setup() {
            this.setFormData(this.get('model.person'));
        },
        setFormData: function setFormData(person) {
            this.set('formData', {
                firstName: person.get('firstName'),
                lastName: person.get('lastName'),
                gender: person.get('gender'),
                dob: person.get('dateOfBirth'),
                location: person.get('location'),
                description: person.get('description')
            });
        },


        textForYourselfOrOrganiser: function () {
            return this.get('auth.loggedInUserId') === this.get('model.person.id') ? 'yourself' : this.get('model.person.firstName');
        }.property('model'),

        currentAvatarUrl: '',
        avatarUrl: '',
        /**
         * Update image thumbnail after uploaded
         */
        updateAvatarUrl: function () {
            var self = this;
            var avatarPromise = self.get('model.person.avatar');
            if (avatarPromise) {
                avatarPromise.then(function (image) {
                    if (image === null) {
                        self.set('currentAvatarUrl', self.get('env').getApp('PROFILE_DEFAULT_IMAGE'));
                        self.set('avatarUrl', self.get('env').getApp('PROFILE_DEFAULT_IMAGE'));
                    } else {
                        self.set('currentAvatarUrl', image.get('url'));
                        self.set('avatarUrl', image.get('url'));
                    }
                });
            }
        }.observes('model.person.avatar'),

        actions: {
            showFirstLastNameModal: function showFirstLastNameModal() {
                this.get('eventbus').publish("profile-firstName-lastName-modal:on:open:" + this.get('_firstLastNameChannel'));
            },
            showGenderModal: function showGenderModal() {
                this.get('eventbus').publish("gender-modal:on:open:" + this.get('_genderChannel'));
            },
            showDOBModal: function showDOBModal() {
                this.get('eventbus').publish("dob-modal:on:open:" + this.get('_dobChannel'));
            },
            showLocationModal: function showLocationModal() {
                this.get('eventbus').publish("location-modal:on:open:" + this.get('_locationChannel'));
            },
            showBioModal: function showBioModal() {
                this.get('eventbus').publish("bio-modal:on:open:" + this.get('_bioChannel'));
            },
            onUpdatePerson: function onUpdatePerson(person) {
                this.setFormData(person);
            },
            onAvatarUploaded: function onAvatarUploaded(fileUrl) {
                this.get('_profileService.uploadAvatarAsync').perform({ personId: this.get('model.person.id'), avatarUrl: fileUrl });
            },
            uploadAvatar: function uploadAvatar() {
                this.get('eventbus').publish("mc-image-cropper:on:open:" + this.get('_avatarUploaderChannelId'));
            }
        }
    });
});