define('mycare-ember/components/mc-booking/time-breakdown-modal/component', ['exports', 'mycare-ember/utils/mc-modal-component'], function (exports, _mcModalComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend({
        init: function init() {
            this.set('_componentId', 'mc-booking/time-breakdown-modal');
            this._super.apply(this, arguments);
            this.set('_contract', undefined);
            this.set('_bookingType', undefined);
            this.set('_totalHours', undefined);
            this.set('_totalVisits', undefined);
            this.set('_timesheetSummary', undefined);
        },
        _onBeforeOpen: function _onBeforeOpen(_ref) {
            var contract = _ref.contract,
                bookingType = _ref.bookingType,
                totalFees = _ref.totalFees,
                totalHours = _ref.totalHours,
                totalVisits = _ref.totalVisits,
                timesheetSummary = _ref.timesheetSummary;

            this.set('_contract', contract);
            this.set('_bookingType', bookingType);
            this.set('_totalFees', totalFees);
            this.set('_totalHours', totalHours);
            this.set('_totalVisits', totalVisits);
            this.set('_timesheetSummary', timesheetSummary);
        }
    });
});