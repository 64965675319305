define('mycare-ember/components/mc-worker-vaccination-warning/component', ['exports', 'mycare-ember/utils/mc-modal-component', 'mycare-ember/mixins/validator', 'mycare-ember/utils/Validators', 'mycare-ember/utils/function-helpers'], function (exports, _mcModalComponent, _validator, _Validators, _functionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend(_validator.default, {

        _modalHelper: Ember.inject.service('utils/modal-helper'),

        init: function init() {
            this.set('_componentId', 'mc-worker-vaccination-warning');
            this._super.apply(this, arguments);

            this.set('_preventModalHideEvent', false);
        },
        _onBeforeOpen: function _onBeforeOpen() {
            this.set('_formData', {
                isAgree: false
            });
            this.set('_preventModalHideEvent', false);
        },


        actions: {}

    });
});