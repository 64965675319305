define("mycare-ember/seekers/findhelp/applicants/controller", ["exports", "mycare-ember/mixins/mixpanel"], function (exports, _mixpanel) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_mixpanel.default, {
        queryParams: ['backTo', 'backToOpts'],
        backTo: null,
        backToOpts: null,

        actions: {
            onNavBack: function onNavBack() {
                if (Ember.isNone(this.get('backTo'))) {
                    this.transitionToRoute('seekers.findhelp.applicants.index');
                } else {
                    if (this.get('backToOpts') && this.get('backToOpts') !== 'undefined') {
                        this.get('eventbus').publish('global:on:changeRoute', this.get('backTo'), this.get('backToOpts'));
                    } else {
                        this.get('eventbus').publish('global:on:changeRoute', this.get('backTo'));
                    }
                }
            }
        }
    });
});