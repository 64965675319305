define("mycare-ember/controllers/partials/uploaded-file-picker", ["exports", "mycare-ember/mixins/message-sending"], function (exports, _messageSending) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_messageSending.default, {
        auth: Ember.inject.controller('auth'),
        newjob: {},
        isDSS: false,

        currentPerson: function () {
            var self = this;
            return self.store.findRecord('person', self.get('auth.loggedInUserId'));
        }.property('auth.loggedInUserId'),

        init: function init() {
            var self = this;
            var person = self.get('currentPerson');
            person.then(function (person) {
                self.set('files', person.get('files'));
            });
        },

        actions: {
            chooseFile: function chooseFile(file) {
                var self = this;
                self.get('model').pushObject(file);
                Ember.$('#uploaded-file-picker').modal('hide');
            },

            close: function close() {
                Ember.$('#uploaded-file-picker').modal('hide');
            }
        }
    });
});