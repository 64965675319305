define('mycare-ember/components/mc-booking/mileage-breakdown-v2/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('milageSubTotal', undefined);
            this.initVar('maxMileageDistance', undefined);
            this.initVar('totalMycareMileageFee', undefined);
            this.initVar('milageTotal', undefined);
            this.initVar('milageSummaryTotal', undefined);
            this.initVar('totalNumberOfTimesheetsForAWeek', undefined);
            this.initVar('mileageCalculationArray', undefined);
            this.initVar('selectedDiv', 0);
        },

        actions: {
            toggleDiv: function toggleDiv(cnt) {
                //console.log("toggleDiv",cnt);
                this.set('selectedDiv', cnt);
                //this.toggleProperty('formData.isEnabledMilage');
                //this.set('formData.maxMileageDistance', 10);
                //this.watchMilage();
            }
        }

    });
});