define('mycare-ember/components/mc-timerange-picker-simple/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/function-helpers', 'mycare-ember/utils/common'], function (exports, _mcComponent, _functionHelpers, _common) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);

            this.initVar('onSelected', undefined);
            var timelineLabels = this.getTimelineLabels('00:00', 5, 'minutes');
            this.initVar('timelineLabels', timelineLabels);
            var now = moment().set({
                minute: Math.ceil(moment().minute() / 5) * 5
            });
            this.initVar('initialStartTime', now);

            this.initVar('initialEndTime', now.clone().add(1, 'hour'));

            this.initVar('startTimeLabel', 'From');
            this.initVar('endTimeLabel', 'To');

            var nowString = this.get('initialStartTime').format('hh:mm A');
            this.set('initialStartTimeString', nowString);
            var endString = this.get('initialEndTime').format('hh:mm A');
            this.set('initialEndTimeString', endString);

            this.set('_selectedStartTime', this.get('initialStartTime'));
            this.set('_selectedEndTime', this.get('initialEndTime'));
        },
        updateData: function updateData(_ref) {
            var startTime = _ref.startTime,
                endTime = _ref.endTime;

            _functionHelpers.default.callOrIgnore(this.get('onSelected'), {
                startTime: startTime, endTime: endTime
            });
        },
        getTimelineLabels: function getTimelineLabels(desiredStartTime, interval, period) {
            var periodsInADay = moment.duration(1, 'day').as(period);
            var timeLabels = [];
            var startTimeMoment = moment(desiredStartTime, 'hh:mm');
            for (var i = 0; i <= periodsInADay; i += interval) {
                startTimeMoment.add(i === 0 ? 0 : interval, period);
                timeLabels.push(startTimeMoment.format('hh:mm A'));
            }
            return timeLabels;
        },


        actions: {
            onStartTimeSelected: function onStartTimeSelected(date) {
                var startTime = moment(date, 'h:mm A');
                this.set('_selectedStartTime', startTime);

                if (this.get('_timegap') > 0) {
                    if (startTime.hours() === 23 && startTime.minutes() === 0) {
                        this.set('initialEndTimeString', startTime.clone().subtract(1, 'day').add(this.get('_timegap'), 'minutes').format('hh:mm A'));
                        this.set('_selectedEndTime', startTime.clone().subtract(1, 'day').add(this.get('_timegap'), 'minutes'));
                    } else {
                        this.set('initialEndTimeString', startTime.clone().add(this.get('_timegap'), 'minutes').format('hh:mm A'));
                        this.set('_selectedEndTime', startTime.clone().add(this.get('_timegap'), 'minutes'));
                    }
                }

                this.updateData({
                    startTime: startTime,
                    endTime: this.get('_selectedEndTime')
                });
            },
            onEndTimeSelected: function onEndTimeSelected(date) {
                var endTime = moment(date, 'h:mm A');
                this.set('_selectedEndTime', endTime);
                var timegap = endTime.diff(this.get('_selectedStartTime'), 'minutes');
                this.set('_timegap', Math.ceil(timegap) / 15 * 15);
                this.updateData({
                    startTime: this.get('_selectedStartTime'),
                    endTime: this.get('_selectedEndTime')
                });
            }
        }
    });
});