define("mycare-ember/controllers/partials/message-file-manager", ["exports", "mycare-ember/mixins/file-uploader-board"], function (exports, _fileUploaderBoard) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_fileUploaderBoard.default, {
    auth: Ember.inject.controller('auth'),

    setPerson: function () {
      var self = this;
      self.store.findRecord('person', self.get('auth.loggedInUserId')).then(function (sender) {
        self.set('person', sender);
      });
    }.on('init'),

    actions: {
      openFilePicker: function openFilePicker() {
        Ember.$('#uploaded-file-picker').modal('hide');
        Ember.$('#uploaded-file-picker').modal('show');
      },

      deleteFile: function deleteFile(file) {
        this.get('parentObj.files').removeObject(file);
      }
    }
  });
});