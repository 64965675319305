define('mycare-ember/helpers/get-index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function getindex(index) {

    return parseInt(index) + 1;
  }

  exports.getindex = getindex;
  exports.default = Ember.Helper.helper(getindex);
});