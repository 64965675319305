define('mycare-ember/models/applicant', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        availability: _emberData.default.attr('string'),
        isPublished: _emberData.default.attr('boolean'),
        title: _emberData.default.attr('string'),
        firstName: _emberData.default.attr('string'),
        manageRedirect: _emberData.default.attr('boolean'),
        lastName: _emberData.default.attr('string'),
        numberOfVerifications: _emberData.default.attr('number'),
        avatar: _emberData.default.belongsTo('image', { async: true }),
        hasAvatar: _emberData.default.attr('boolean'),
        isBookable: _emberData.default.attr('boolean'),
        email: _emberData.default.attr('string'),
        organisationName: _emberData.default.attr('string'),
        uniqueId: _emberData.default.attr('string'),
        interests: _emberData.default.attr('string'),
        profileType: _emberData.default.attr('string'),
        description: _emberData.default.attr('string'),
        phoneNumber: _emberData.default.attr('string'),
        createdOn: _emberData.default.attr('string'),
        modifiedOn: _emberData.default.attr('string'),
        location: _emberData.default.attr('string'),
        latitude: _emberData.default.attr('string'),
        longitude: _emberData.default.attr('string'),
        respondRatio: _emberData.default.attr('number'),
        numberOfHires: _emberData.default.attr('number'),
        hourlyRates: _emberData.default.hasMany('hourly-rate', { async: true }),
        login: _emberData.default.attr('number'),
        videos: _emberData.default.hasMany('video', { async: true }),
        images: _emberData.default.hasMany('image', { async: true }),
        thread: _emberData.default.belongsTo('thread', { async: true }),
        experiences: _emberData.default.hasMany('experience', { async: true }),
        qualifications: _emberData.default.hasMany('qualification', { async: true }),
        employmentTypes: _emberData.default.hasMany('employment-type', { async: true }),
        languages: _emberData.default.hasMany('language', { async: true }),
        requirements: _emberData.default.hasMany('requirement', { async: true }),
        services: _emberData.default.hasMany('service', { async: true }),
        favouritePeople: _emberData.default.hasMany('profile', { async: true }),
        favouriteJobListings: _emberData.default.hasMany('job-listing', { async: true }),
        relatedWith: _emberData.default.hasMany('relationship', { async: true, inverse: 'person' }),
        files: _emberData.default.hasMany('file', { async: true }),
        subscriptionType: _emberData.default.attr('string'),
        isValidSubscriber: _emberData.default.attr('boolean'),
        addresses: _emberData.default.hasMany('address', { async: true }),
        accounts: _emberData.default.hasMany('account', { async: true }),
        creditCardList: _emberData.default.hasMany('credit-card', { async: true }),
        firstAddress: Ember.computed.alias('addresses.firstObject'),
        isPhoneNumberVerified: _emberData.default.attr('boolean'),
        isEmailVerified: _emberData.default.attr('boolean'),
        isAddressVerified: _emberData.default.attr('boolean'),
        trusts: _emberData.default.hasMany('trust', { async: true }),
        dateOfBirth: _emberData.default.attr('string'),
        gender: _emberData.default.attr('string'),
        untilExpire: _emberData.default.attr('string'),
        myShareCode: _emberData.default.attr('string'),
        referrersCode: _emberData.default.attr('string'),
        isGovernmentPay: _emberData.default.attr('boolean'),
        isPrivatePay: _emberData.default.attr('boolean'),
        isCovidVaccinationVerified: _emberData.default.attr('boolean'),
        name: function () {
            if (Ember.isEmpty(this.get('lastName'))) {
                return this.get('firstName');
            }
            return this.get('firstName') + ' ' + this.get('lastName');
        }.property('firstName', 'lastName')
    });
});