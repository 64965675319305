define("mycare-ember/helpers/date-ago-in-words", ["exports", "moment"], function (exports, _moment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.timeAgoInWords = undefined;


    function timeAgoInWords(data) {
        var date = data[0];
        if (Ember.isBlank(date)) {
            return '';
        }

        var timeString = _moment.default.utc(date).fromNow();

        return timeString.substr(0, 1).toUpperCase() + timeString.substr(1);
    }

    exports.timeAgoInWords = timeAgoInWords;
    exports.default = Ember.Helper.helper(timeAgoInWords);
});