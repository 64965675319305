define('mycare-ember/components/mc-organiser-email/component', ['exports', 'mycare-ember/utils/mc-component', 'ember-concurrency', 'mycare-ember/utils/common', 'mycare-ember/mixins/validator', 'mycare-ember/utils/Validators'], function (exports, _mcComponent, _emberConcurrency, _common, _validator, _Validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend(_validator.default, {
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('newEmail', undefined);
            this.addValidation('newEmail', 'email', 'Email', [_Validators.default.isRequired, _Validators.default.isEmail]);
        },


        actions: {
            addOrganiser: function addOrganiser() {
                if (this._validate()) {
                    this.sendAction('onSetEmail', this.get('newEmail'));
                    this.set('newEmail', undefined);
                    this._resetValidation();
                }
            }
        }
    });
});