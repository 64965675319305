define("mycare-ember/components/mc-charts/bar-chart/component", ["exports", "mycare-ember/mixins/lineCharts/baseLineChart", "mycare-ember/utils/mc-component"], function (exports, _baseLineChart, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend(_baseLineChart.default, {
        init: function init() {
            this._super.apply(this, arguments);
            this.set('isLoading', false);
        },
        didRender: function didRender() {
            var self = this;
            self.set('chartParentId', self.get('chartId') + '_parent');
            var parentComponent = Ember.$('#' + self.get('chartParentId'));
            if (parentComponent.length > 0) {
                self.set('chartParentId', self.get('chartId') + '_parent');
                self.set('pickerStartingTime', self.get('startingTime'));
                self.set('localTime', self.get('startingTime'));
                self._drawDataChart();
            }
        },


        actions: {
            onUpdate: function onUpdate(date) {
                this.set('localTime', date);
                this._drawDataChart();
            }
        },

        _drawDataChart: function _drawDataChart() {
            var self = this;
            var labels = ["Africa", "Asia", "Europe", "Latin America", "North America"];
            var dataset = [{
                label: "Population (millions)",
                backgroundColor: ["#3e95cd", "#8e5ea2", "#3cba9f", "#e8c3b9", "#c45850"],
                data: [2478, 5267, 734, 784, 433]
            }];
            self.buildChart(dataset, labels);
            self.set('isLoading', false);
        }

    });
});