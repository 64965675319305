define('mycare-ember/seekers/manage/schedule-workers/index/new/activity/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        _timesheetService: Ember.inject.service('timesheet'),
        activities: [],
        selectedMyActivityGroup: [],
        myGroupCheckedList: [],
        newVisitsController: Ember.inject.controller('seekers/manage/schedule-workers/index/new-timesheet'),
        errors: {},

        actions: {
            onSelectedActivities: function onSelectedActivities(activitites) {
                this.set('newVisitsController.activities', activitites);
            },
            next: function next() {
                this.transitionToRoute('seekers.manage.schedule-workers.index.new.note');
            },
            back: function back() {
                this.transitionToRoute('seekers.manage.schedule-workers.index.new.index');
            }
        }
    });
});