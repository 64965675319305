define('mycare-ember/components/mc-timesheet/mc-mobile-timesheet-nav/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/function-helpers'], function (exports, _mcComponent, _functionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({

        wizardService: Ember.inject.service('utils/booking-wizard'),

        init: function init() {
            this._super.apply(this, arguments);
        },


        actions: {
            backToVisit: function backToVisit() {
                if (Ember.isNone(this.get('onBack'))) {
                    this.get('eventbus').publish('global:on:changeRoute', 'seekers.manage.schedule-workers.index');
                } else {
                    _functionHelpers.default.callOrIgnore(this.get('onBack'));
                }
            }
        }
    });
});