define('mycare-ember/helpers/url', ['exports', 'mycare-ember/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.url = undefined;


    function url(data) {
        var inputUrl = data[0];
        if (!inputUrl) return null;
        if (inputUrl.match(/^(http|https|data:image).*/) != null) return inputUrl;

        if (_environment.default.APP.ENV_NAME === 'android' || _environment.default.APP.ENV_NAME === 'ios') {
            if (inputUrl.charAt(0) === '/') return inputUrl.substring(1);
            return inputUrl;
        }
        if (inputUrl.charAt(0) === '/') return inputUrl;
        return '/' + inputUrl;
    }

    exports.url = url;
    exports.default = Ember.Helper.helper(url);
});