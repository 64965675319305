define('mycare-ember/components/mc-times/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('times', 0);
        },


        _numOfTimes: Ember.computed('times', function () {
            var times = parseInt(this.get('times'));
            return new Array(times).fill(1);
        })
    });
});