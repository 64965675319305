define('mycare-ember/components/mc-timesheet/form-accept/component', ['exports', 'mycare-ember/utils/mc-modal-component'], function (exports, _mcModalComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend({

        _timesheetService: Ember.inject.service('timesheet'),
        _profileService: Ember.inject.service('profile'),

        init: function init() {
            this.set('_componentId', 'mc-accept-visit-form');
            this._super.apply(this, arguments);
            this.initVar('channelId', 'global');
        },
        _onBeforeOpen: function _onBeforeOpen(_ref) {
            var timesheet = _ref.timesheet;

            this.set('timesheet', timesheet);
        },
        _onAfterOpened: function _onAfterOpened() {
            this.acceptVisit();
        },
        _onAfterClosed: function _onAfterClosed() {
            this.get('eventbus').publish('mc-accept-visit-form:event:accepted:' + this.get('channelId'));
        },


        acceptVisit: function acceptVisit() {
            return this.get('_timesheetService.acceptVisitAsync').unlinked().perform({
                loggedInUserId: this.get('session.authData.loggedInUserId'),
                timesheetId: this.get('timesheet.id')
            });
        }
    });
});