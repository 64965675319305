define('mycare-ember/components/mc-modal-super-worker/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        _env: Ember.inject.service('utils/env'),
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar("worker", {});
            this.initVar("_templateId", 'modal-super-worker');
            this.initVar("viewMode", "");
            this.setupEvents();
        },
        setupEvents: function setupEvents() {
            var _this = this;

            this.get('eventbus').subscribe('modal-super-worker:on:open:global', function (payload) {
                return _this.onShowModal(payload);
            });
        },
        onShowModal: function onShowModal(payload) {
            this.set('worker', payload.worker);
            this.set('viewMode', payload.viewMode);
            Ember.$('#' + this.get("_templateId")).modal();
        }
    });
});