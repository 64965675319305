define("mycare-ember/components/mc-submit-visit-contract-form/component", ["exports", "mycare-ember/utils/mc-modal-component", "mycare-ember/utils/common"], function (exports, _mcModalComponent, _common) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend({

        _contractService: Ember.inject.service('contract'),

        init: function init() {
            this.initVar('_componentId', 'mc-submit-visit-contract-form');
            this._super.apply(this, arguments);
            this.initVar('_modalChannelId', _common.default.createGuid());
            this.initVar('isLoading', false);
            this.initVar('_isOpened', false);
            this.initVar('errorIsAgreed', null);
            this.initVar('channelId', 'global');
            this.initVar('isAgreed', false);
        },
        validateForm: function validateForm() {
            var isValid = true;
            if (this.get('isAgreed') === false) {
                this.set('errorIsAgreed', true);
                isValid = false;
            }
            return isValid;
        },
        _onBeforeOpen: function _onBeforeOpen(contract) {
            this.set('contract', contract);
        },


        actions: {
            signContract: function signContract() {
                var _this = this;

                if (!this.validateForm()) {
                    return;
                }
                var momentNowString = moment().utc().toISOString();
                this.set('isLoading', true);
                this.get('_contractService.findContractByIdAsync').perform({
                    contractId: this.get('contract.id')
                }).then(function (contract) {
                    contract.set('contractorSignedOn', momentNowString);
                    contract.set('messageBody', _this.get('messageBody'));
                    contract.save().then(function () {
                        _this.get('eventbus').publish("mc-submit-visit-contract-form:event:signed:" + _this.get('channelId'), {
                            contract: contract
                        });
                        _this.set('isLoading', false);
                        _this._close();
                    });
                });
            }
        }
    });
});