define('mycare-ember/components/mc-thread/item/body/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/common'], function (exports, _mcComponent, _common) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('message', null);
            this.set('_fileViewerChannelId', _common.default.createGuid());
            this.setJobListingLink();
        },


        sentOn: function () {
            var sentOn = moment.utc(this.get('message.sentOn'));
            if (moment.utc().diff(sentOn, 'days') > 7) {
                return sentOn.format('DD MMM YYYY');
            } else {
                return sentOn.fromNow();
            }
        }.property('message.sentOn'),

        setJobListingLink: function () {
            var _this = this;

            Ember.RSVP.resolve(this.get('message.thread.jobApplications')).then(function (jobApplications) {
                if (Ember.isEmpty(jobApplications)) {
                    return null;
                }
                return jobApplications.objectAt(0).get('person');
            }).then(function (person) {
                if (person === null || person.get('id') === _this.get('session.authData.loggedInUserId')) {
                    _this.set('jobListingLink', 'browse.jobs.index.detail');
                } else {
                    if (_this.get('session.authData.isCurrentlySeeker')) {
                        _this.set('jobListingLink', 'seekers.findhelp.job-listings.detail.preview');
                    } else {
                        _this.set('jobListingLink', 'browse.jobs.index.detail');
                    }
                }
            });
        }.observes('message.thread.jobApplications.[]'),

        hasJobListing: function hasJobListing() {},

        actions: {
            viewImage: function viewImage() {
                this.get('eventbus').publish('mc-file-viewer:on:open:' + this.get('_fileViewerChannelId'));
            }
        }
    });
});