define('mycare-ember/utils/Validators', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        isRequired: function isRequired(name, value) {
            var sub = !Ember.isNone(value) && !Ember.isNone(value.trim) ? value.trim() : value;
            var isValid = !Ember.isEmpty(sub) && !Ember.isNone(sub);
            return {
                result: isValid,
                message: isValid ? null : name + ' is required'
            };
        },
        isRequireWithMessage: function isRequireWithMessage(message) {
            return function (name, value) {
                var sub = !Ember.isNone(value) && !Ember.isNone(value.trim) ? value.trim() : value;
                var isValid = !Ember.isEmpty(sub) && !Ember.isNone(sub);
                return {
                    result: isValid,
                    message: isValid ? null : message
                };
            };
        },
        noSpecialCharacters: function noSpecialCharacters(name, value) {
            return {
                result: !/[-!#@$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/g.test(value),
                message: name + ' should contain only letters.'
            };
        },
        noSpecialCharactersAndNumber: function noSpecialCharactersAndNumber(name, value) {
            return {
                result: !/[\d-!#@$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/g.test(value),
                message: name + ' should contain only letters.'
            };
        },
        isPhoneNumber: function isPhoneNumber(name, value) {
            return {
                result: !Ember.isEmpty(value) && !Ember.isEmpty(value.split('-')[1]) && value.split('-')[1].length >= 4 && value.split('-')[1].length <= 13 && /^(?!0+$)[a-zA-Z0-9]+$/.test(value.split('-')[1]),
                message: 'Please enter a valid phone number.'
                //message: `${name} is required and must be between 4 and 13 digits.`
            };
        },
        consent: function consent(name, value) {
            return {
                result: value === true,
                message: name + ' is required'
            };
        },
        isEmail: function isEmail(name, value) {
            var sub = !Ember.isNone(value) && !Ember.isNone(value.trim) ? value.trim() : value;
            var emailRegx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            var isValid = !Ember.isNone(sub) && !Ember.isNone(sub.match(emailRegx));
            return {
                result: isValid,
                message: isValid ? null : name + ' must be valid email address'
            };
        },
        isEqual: function isEqual(_ref) {
            var name1 = _ref.name1,
                value1 = _ref.value1,
                name2 = _ref.name2,
                value2 = _ref.value2;

            var sub1 = !Ember.isNone(value1) && !Ember.isNone(value1.trim) ? value1.trim() : value1;
            var sub2 = !Ember.isNone(value2) && !Ember.isNone(value2.trim) ? value2.trim() : value2;
            var isValid = Ember.isEqual(sub1, sub2);
            return {
                result: isValid,
                message: isValid ? null : name1 + ' must be the same as ' + name2
            };
        }
    };
});