define('mycare-ember/components/mc-visit-activites/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        activities: null,
        _activities: null,

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('activities', null);
            this.initVar('_activities', null);
        },


        _resolveActivities: function () {
            var _this = this;

            return Ember.RSVP.resolve(this.get('activities')).then(function (activites) {
                return _this.set('_activities', activites);
            });
        }.observes('activities.[]'),

        actions: {
            saveActivities: function saveActivities(activity) {
                activity.set('isComplete', !activity.get('isComplete'));
                activity.save();
            }
        }
    });
});