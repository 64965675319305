define("mycare-ember/seekers/findhelp/job-listings/controllers/final", ["exports", "mycare-ember/mixins/mixpanel", "mycare-ember/mixins/scroll_top", "mycare-ember/utils/common"], function (exports, _mixpanel, _scroll_top, _common) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_scroll_top.default, _mixpanel.default, {
        auth: Ember.inject.controller('auth'),
        geoService: Ember.inject.service('utils/geo'),
        _wizardService: Ember.inject.service('utils/seeker-listing-wizard'),
        _searchService: Ember.inject.service('search'),
        jobListing: {},
        matchResult: [],
        isLoadingMatches: false,
        _shareModalChannelId: _common.default.createGuid(),

        setup: function setup() {
            var self = this;
            this.get('_wizardService').setCurrentStep(8);
            self.set('matchResult', []);
            self.get('geoService.decodeAsync').perform({ location: self.get('jobListing.location') || 'Auckland' }).then(function (latlng) {
                self.set('isLoadingMatches', true);
                self.set('selectedServices', self.get('jobListing.services'));
                self.store.findRecord('person', self.get('auth.currentPersonId')).then(function (person) {
                    Ember.RSVP.resolve(person.get('clientServices')).then(function (services) {
                        self.set('scoringServices', services);
                        self.get('_searchService.searchProfilesAsync').perform(null, latlng, person.get('location'), services, {}, true).then(function (result) {
                            self.set('matchResult', result.slice(0, 3));
                            self.set('isLoadingMatches', false);
                            self.mixpanelCreateJobListingFunnel(self.get('session.authData.currentPersonId'), 'Step6:Done');
                            localStorage.removeItem(self.get('env').getApp('NEW_LISTING'));
                        });
                    });
                });
            });
        },


        actions: {
            goToJobListing: function goToJobListing() {

                this.transitionToRoute('seekers.findhelp.job-listings.detail.preview', this.get('jobListing.id'));
            },
            viewProfile: function viewProfile(id) {
                if (this.get('env').isMobile()) {
                    this.transitionToRoute('browse.profiles.index.detail', id);
                } else {
                    window.open(window.location.protocol + "//" + window.location.host + "/#/browse/profiles/" + id + "?backTo=true");
                }
            },
            share: function share() {
                this.get('eventbus').publish("mc-share:on:open:" + this.get('_shareModalChannelId'));
            }
        },

        validateForm: function validateForm() {
            var isValid = true;
            Ember.$('.has-error').removeClass('has-error');
            return isValid;
        }
    });
});