define("mycare-ember/register/worker-register/index/controller", ["exports", "mycare-ember/mixins/mixpanel"], function (exports, _mixpanel) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_mixpanel.default, {
        auth: Ember.inject.controller('auth'),
        _accountService: Ember.inject.service('account'),

        queryParams: ['code', 'email'],

        onNext: function onNext() {
            return 'personal-details';
        },


        actions: {
            onAccountCreatedEvent: function onAccountCreatedEvent(person) {
                //jhint ignore:line
                localStorage.setItem(this.get('env').getApp('WORKER_SIGN_UP_STAGE'), true);
                Ember.$('.home').removeClass('home');
                this.mixpanelSignUp(person);
                this.set('wizardData.account', person);
                this.set('wizardData.referrersCode', this.get('code'));
                if (!Ember.isEmpty(person.get('addresses'))) {
                    this.set('wizardData.address', person.get('addresses').objectAt(0));
                }
                this.send('next');
                return person;
            }
        }
    });
});