define('mycare-ember/models/search-profile', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        avatar: _emberData.default.attr('string'),
        hasAvatar: _emberData.default.attr('boolean'),
        numberOfVerifications: _emberData.default.attr('number'),
        numberOfHires: _emberData.default.attr('number'),
        numberOfApplications: _emberData.default.attr('number'),
        numberOfOpportunities: _emberData.default.attr('number'),
        numberOfReviews: _emberData.default.attr('number'),
        firstName: _emberData.default.attr('string'),
        description: _emberData.default.attr('string'),
        hourlyRates: _emberData.default.attr(),
        joinedOn: _emberData.default.attr('string'),
        loginOn: _emberData.default.attr('string'),
        respondRatio: _emberData.default.attr('number'),
        modifiedOn: _emberData.default.attr('string'),
        profilePicture: _emberData.default.attr('string'),
        location: _emberData.default.attr('string'),
        latitude: _emberData.default.attr('string'),
        longitude: _emberData.default.attr('string'),
        gender: _emberData.default.attr('string'),
        services: _emberData.default.attr(),
        employmentTypes: _emberData.default.attr()
    });
});