define('mycare-ember/components/mc-worker-profile/qualifications/item/component', ['exports', 'mycare-ember/utils/function-helpers', 'mycare-ember/utils/mc-component'], function (exports, _functionHelpers, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        qualification: null,
        onEdit: null,

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('qualification', null);
            this.initVar('onEdit', null);
        },


        actions: {
            onEdit: function onEdit() {
                _functionHelpers.default.callOrIgnore(this.get('onEdit'), this.get('qualification'));
            }
        }
    });
});