define('mycare-ember/components/mc-booking/modal-warning-leaving/component', ['exports', 'mycare-ember/utils/mc-modal-component', 'mycare-ember/utils/function-helpers'], function (exports, _mcModalComponent, _functionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend({

        _wizardService: Ember.inject.service('utils/booking-wizard'),

        init: function init() {
            this.set('_componentId', 'mc-booking/modal-warning-leaving');
            this._super.apply(this, arguments);
            this.initVar('channelId', 'global');
            this.initVar('onLeave', undefined);
        },


        // _onBeforeOpen() {
        //     if (!this.get('wizardService.isTimeVisited')) {
        //         this._close();
        //     }
        // },
        //
        // _onAfterClosed() {
        //     if (!this.get('wizardService.isTimeVisited')) {
        //         Ember.$('.modal-backdrop').remove();
        //         this.send('leave');
        //     }
        // },

        actions: {
            leave: function leave() {
                _functionHelpers.default.callOrIgnore(this.get('onLeave'));
                this._close();
            }
        }
    });
});