define('mycare-ember/controllers/partials/job-card-seeker', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        jobFor: function () {
            var self = this;
            var promise = self.get('model.personJobs').then(function (people) {
                return people.filter(function (item) {
                    return item.get('role.name').toLowerCase() === 'owner';
                });
            });
            return DS.PromiseArray.create({
                promise: promise
            });
        }.property('model.personJobs.@each.role')
    });
});