define('mycare-ember/components/mc-worker-card/call-to-action/component', ['exports', 'mycare-ember/utils/common', 'mycare-ember/utils/mc-component'], function (exports, _common, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({

        _livechatService: Ember.inject.service('utils/livechat'),

        init: function init() {
            this._super.apply(this, arguments);
            this.set('_modalShareChannelId', _common.default.createGuid());
        },


        actions: {
            chat: function chat() {
                this.get('_livechatService').openLivechat();
            }
        }
    });
});