define('mycare-ember/components/mc-joblisting-previewer/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/common'], function (exports, _mcComponent, _common) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('jobListing', null);
            this.set('_shareModalChannelId', _common.default.createGuid());
        },


        actions: {
            share: function share() {
                this.get('eventbus').publish('mc-share:on:open:' + this.get('_shareModalChannelId'));
            }
        }
    });
});