define("mycare-ember/components/mc-job-application-card/component", ["exports", "mycare-ember/utils/mc-component"], function (exports, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({

        _jobListingService: Ember.inject.service('job-listing'),

        init: function init() {
            this._super.apply(this, arguments);
        }
    });
});