define('mycare-ember/components/mc-verify-address/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/common', 'mycare-ember/mixins/validator', 'mycare-ember/utils/Validators', 'mycare-ember/utils/function-helpers'], function (exports, _mcComponent, _common, _validator, _Validators, _functionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend(_validator.default, {

        _modalHelper: Ember.inject.service('utils/modal-helper'),
        _profileService: Ember.inject.service('profile'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('channelId', undefined);
            this.initVar('_modalChannelId', _common.default.createGuid());
            this.initVar('_isLoading', {});
            this.initVar('_formData', {});
            this.initVar('isDoneScreen', false);
            this.initVar('worker', undefined);
            this.initVar('onCompleted', undefined);
            this.initVar('onError', undefined);
            this.initVar('redirectRouteName', undefined);
            this.initVar('_isRequiredAddress', undefined);
        },
        willSetupOnce: function willSetupOnce() {
            this._setupEvents();
            this._setupValidations();
        },
        _setupValidations: function _setupValidations() {
            this._resetValidation();
            this.addValidation('_formData.code', 'code', 'Verification code', [_Validators.default.isRequired]);
        },
        _setupEvents: function _setupEvents() {
            var _this = this;

            var channelId = this.get('channelId');
            var openEvent = 'mc-verify-address:on:open:' + channelId;
            this.get('eventbus').subscribe(openEvent, function () {
                var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
                    _ref$isDone = _ref.isDone,
                    isDone = _ref$isDone === undefined ? false : _ref$isDone,
                    _ref$redirectRouteNam = _ref.redirectRouteName,
                    redirectRouteName = _ref$redirectRouteNam === undefined ? null : _ref$redirectRouteNam;

                if (_this.isDestroyed) {
                    return;
                }
                _this.set('isDoneScreen', isDone);
                _this.set('redirectRouteName', redirectRouteName);
                _this._setupValidations();
                _this._open();
            }, false);

            this.get('eventbus').subscribe('mc-verify-address:on:close:' + channelId, function () {
                if (_this.isDestroyed) {
                    return;
                }
                _this._close();
            }, false);
        },
        _open: function _open() {
            var _this2 = this;

            Ember.RSVP.resolve(this.get('worker').get('addresses', { reload: true })).then(function (addresses) {
                _this2.set('_isRequiredAddress', Ember.isEmpty(addresses.find(function (address) {
                    return address.get('id') !== null;
                })));
                _this2.get('_modalHelper').openModal(_this2.get('_modalChannelId'));
            });
        },
        _close: function _close() {
            this.get('_modalHelper').closeModal(this.get('_modalChannelId'));
            if (!Ember.isEmpty(this.get('redirectRouteName')) && this.get('isDoneScreen') === true) {
                this.get('eventbus').publish('global:on:changeRoute', this.get('redirectRouteName'));
            }
        },


        actions: {
            close: function close() {
                this._close();
            },
            requestNewCode: function requestNewCode() {
                var _this3 = this;

                var taskId = _common.default.createGuid();
                var message = 'Hi Mycare, I need help verifying my address. Kind regards, ' + this.get('worker.firstName');
                this.get('eventbus').subscribe('mc-feedback-form:event:sent:' + taskId, function () {
                    if (_this3.isDestroyed) {
                        return;
                    }
                    _this3.set('_isRequestingNew', false);
                    _this3.set('isDoneScreen', true);
                });
                this.get('eventbus').publish('mc-feedback-form:on:send', { id: taskId, message: message });
            },
            verifyCode: function verifyCode() {
                var _this4 = this;

                if (!this._validate()) {
                    return;
                }
                this._removeValidationError('verification');
                this.set('_isLoading.verifying', true);
                Ember.RSVP.resolve(this.get('worker.addresses')).then(function (addresses) {
                    return _this4.get('_profileService.verifyAddressAsync').perform({
                        addressId: addresses.objectAt(0).get('id'),
                        code: _this4.get('_formData.code')
                    });
                }).then(function () {
                    if (_this4.isDestroyed) {
                        return;
                    }
                    _this4.set('_isLoading.verifying', false);
                    _functionHelpers.default.callOrIgnore(_this4.get('onCompleted'));
                    _this4._close();
                }, function () {
                    if (_this4.isDestroyed) {
                        return;
                    }
                    _this4.set('_isLoading.verifying', false);
                    var errorMessage = 'Verification code is incorrect, please check your letter';
                    _functionHelpers.default.callOrIgnore(_this4.get('onError'), errorMessage);
                    _this4._addValidationError('verification', errorMessage);
                });
            }
        }
    });
});