define('mycare-ember/utils/wizard-step-route', ['exports', 'mycare-ember/utils/mc-protected-route'], function (exports, _mcProtectedRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcProtectedRoute.default.extend({
        setupController: function setupController(controller) {
            var _this = this;

            var parentWizardController = this.controllerFor(this.get('parentControllerName'));
            parentWizardController.setCurrentWizardStep(this.get('stepName'), function (nextStepInfo) {
                controller.set('stepInfo', nextStepInfo);
                controller.set('wizardData', parentWizardController.getWizardData());
                if (nextStepInfo.get('name') !== _this.get('stepName')) {
                    controller.transitionToRoute(nextStepInfo.get('routeName'));
                }
            });
            this._super.apply(this, arguments);
        },


        setupWizardRoute: function setupWizardRoute(controller, model) {
            var _this2 = this;

            var parentWizardController = this.controllerFor(this.get('parentControllerName'));
            return new Ember.RSVP.Promise(function (resolve) {
                parentWizardController.setCurrentWizardStep(_this2.get('stepName'), function (nextStepInfo) {
                    controller.set('stepInfo', nextStepInfo);
                    controller.set('wizardData', parentWizardController.getWizardData());
                    if (nextStepInfo.get('name') !== _this2.get('stepName')) {
                        controller.transitionToRoute(nextStepInfo.get('routeName'));
                    }
                    controller.set('model', model);
                    resolve();
                });
            });
        }
    });
});