define('mycare-ember/components/mc-complete-moj/component', ['exports', 'mycare-ember/utils/mc-modal-component', 'mycare-ember/utils/common', 'mycare-ember/utils/function-helpers'], function (exports, _mcModalComponent, _common, _functionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend({

        _modalHelper: Ember.inject.service('utils/modal-helper'),

        init: function init() {
            var _this = this;

            this.set('_componentId', 'mc-complete-moj');
            this._super.apply(this, arguments);
            this.initVar('channelId', undefined);
            this.initVar('firstName', undefined);
            this.initVar('onClosed', undefined);

            this.set('_isRequestingNew', undefined);
            this.set('_isDoneScreen', undefined);
            this.set('_isFeedBackFormOpened', false);
            this.set('_modalChannelId', _common.default.createGuid());

            this.get('eventbus').subscribe('mc-feedback-form:event:closed', function () {
                _functionHelpers.default.callOrIgnore(_this.get('onClosed'));
            });
        },
        _onBeforeOpen: function _onBeforeOpen() {
            this.set('_isDoneScreen', false);
        },
        _onAfterClosed: function _onAfterClosed() {
            if (!this.get('_isFeedBackFormOpened')) {
                _functionHelpers.default.callOrIgnore(this.get('onClosed'));
            }
        },


        actions: {
            contactMyCare: function contactMyCare() {
                this._close();
                this.get('eventbus').publish('mc-feedback-form:on:open');
                this.set('_isFeedBackFormOpened', true);
            },
            requestNew: function requestNew() {
                var _this2 = this;

                var message = 'Hi Mycare, I need help completing my criminal convictions history check. Kind regards, ' + this.get('firstName');
                var taskId = _common.default.createGuid();
                this.set('_isRequestingNew', true);
                this.get('eventbus').publish('mc-feedback-form:on:send', { id: taskId, message: message });
                this.get('eventbus').subscribe('mc-feedback-form:event:sent:' + taskId, function () {
                    _this2.set('_isRequestingNew', false);
                    _this2.set('_isDoneScreen', true);
                });
            }
        }
    });
});