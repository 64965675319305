define("mycare-ember/components/mc-worker-card/suggestion/component", ["exports", "mycare-ember/utils/mc-component"], function (exports, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({

        _profileService: Ember.inject.service('profile'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('worker', undefined);
        },


        watchSetIsHero: function () {
            var _this = this;

            return this.get('_profileService.isHeroAsync').perform({
                workerId: this.get('worker.id')
            }).then(function (isHero) {
                _this.set('isHero', isHero);
            });
        }.observes('worker.numberOfHires', 'worker.numberOfVerifications', 'worker.respondRatio'),

        isVerified: function () {
            var _this2 = this;

            return this.get('_profileService.isVerifiedIdAsync').perform({
                workerId: this.get('worker.id')
            }).then(function (isVerified) {
                _this2.set('isVerified', isVerified);
            });
        }.observes('worker.numberOfVerifications')
    });
});