define('mycare-ember/mixins/get-date-of-week', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        getMonday: function getMonday(d) {
            return moment(d).startOf('isoWeek').day(1);
            // if (d) {
            //     d = new Date(d);
            // } else {
            //     d = new Date();
            // }
            // var day = d.getDay(),
            //     diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is monday
            // var dd = new Date(d.setDate(diff));
            // return new Date(dd);
        },

        getSunday: function getSunday(d) {
            return moment(d).startOf('isoWeek').day(7);
            // if (d) {
            //     d = new Date(d);
            // } else {
            //     d = new Date();
            // }
            // var day = d.getDay(),
            //     diff = d.getDate() + 7 - day + (day === 0 ? -7 : 0); // adjust when day is sunday
            // var dd = new Date(d.setDate(diff));
            // return moment(new Date(dd)).endOf('day').toDate();
        }

        // getStartOfMonth: function (d) {
        //     if (d) {
        //         d = new Date(d);
        //     } else {
        //         d = new Date();
        //     }
        //     var day = d.getDay(),
        //         diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is monday
        //     var dd = new Date(d.setDate(diff));
        //     return new Date(dd);
        // },
        // getEndOfMonth: function (d) {
        //     if (d) {
        //         d = new Date(d);
        //     } else {
        //         d = new Date();
        //     }
        //     var day = d.getDay(),
        //         diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is monday
        //     var dd = new Date(d.setDate(diff));
        //     return new Date(dd);
        // }
    });
});