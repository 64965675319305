define('mycare-ember/mixins/message-sending', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    sendMessage: function sendMessage(title, body, people, sender, afterSuccess, finalFunction, errorFunction, isMatch, jobApplication, files) {
      var self = this;
      if (jobApplication) {
        var thread = jobApplication.get('thread');
        thread.then(function (thread) {
          var message = self.createMessage(thread, body, sender, files);
          message.save().then(function () {
            afterSuccess();
          }, function () {
            errorFunction();
          }).finally(function () {
            finalFunction();
          });
        });
      } else {
        self.store.createRecord('thread', {
          title: title,
          people: people,
          messageDate: moment.utc(new Date()).toISOString()
        }).save().then(function (thread) {

          var message = self.createMessage(thread, body, sender, files);
          message.save().then(function (message) {
            thread.set('message', message);
            thread.save().then(function (thread) {
              afterSuccess(thread);
            });
          }, function () {
            errorFunction();
          }).finally(function () {
            finalFunction();
          });
        });
      }
    },

    createMessage: function createMessage(thread, body, sender, files) {
      var message = this.store.createRecord('message', {
        thread: thread,
        text: body,
        sentOn: moment.utc(new Date()).toISOString(),
        sender: sender
      });
      if (!Ember.isEmpty(files)) {
        message.get('files').pushObjects(files);
      }
      return message;
    },

    sendHireMessage: function sendHireMessage(sender, text, application) {
      var self = this;
      var thread = application.get('thread');
      var message = self.store.createRecord('message', {
        thread: thread,
        text: text,
        sentOn: moment.utc(new Date()).toISOString(),
        sender: sender
      });
      return message.save();
    }
  });
});