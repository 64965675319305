define('mycare-ember/components/mc-worker-profile/experience/item/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/function-helpers'], function (exports, _mcComponent, _functionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('experience', undefined);
            this.initVar('onEdit', undefined);
        },


        actions: {
            onEdit: function onEdit() {
                _functionHelpers.default.callOrIgnore(this.get('onEdit'), this.get('experience'));
            }
        }
    });
});