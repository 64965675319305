define("mycare-ember/components/mc-nav/authenticated/right-menu-set/component", ["exports", "mycare-ember/utils/mc-component"], function (exports, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        livechat: Ember.inject.service('utils/livechat'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('isSeeker', false);
            this.initVar('isWorker', false);
            this.initVar('currentPerson', undefined);
            this.initVar('loggedInUser', undefined);
            this.initVar('avatarProfile', this.get('session.authData.currentPerson'));
            this.initVar('isShowLoggedInUserAvatar', false);
            this.initVar('currentRouteName', undefined);
            this.initVar('numberOfUnReadMessages', undefined);
            this.initVar('isShowSearch', true);
        },


        _showProfileMenu: function () {
            return this.get('currentRouteName') !== 'me.profiles.index';
        }.property('currentRouteName'),

        _showEditOption: function () {
            return this.get('currentRouteName') !== 'me.account.index';
        }.property('currentRouteName'),

        actions: {
            goToSearch: function goToSearch() {
                this.get('eventbus').publish('global:on:changeRoute', this.get('isSeeker') ? 'browse.profiles.index' : 'browse.jobs.index', null, {
                    queryParams: {
                        location: this.get('session.authData.currentPerson').get('location'),
                        reset: true
                    }
                });
            },
            toggleFeedbackDialog: function toggleFeedbackDialog() {
                // this.get('eventbus').publish('mc-feedback-form:on:open');
                this.get('livechat').openLivechat();
            }
        }
    });
});