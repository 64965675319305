define("mycare-ember/auth/reset-password/route", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        beforeModel: function beforeModel(transition) {
            this.transitionTo('account.reset-password', { queryParams: transition.queryParams });
        }
    });
});