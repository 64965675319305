define('mycare-ember/services/utils/nav', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        eventbus: Ember.inject.service('utils/eventbus'),

        checkRouteForNav: function checkRouteForNav(currentPath) {
            var _this = this;

            if (_.includes(['seekers.findhelp.dss-form', 'seekers.findhelp.dashboard', 'seekers.findhelp.job-listings.list', 'seekers.findhelp.applicants.index.index', 'seekers.findhelp.favourites', 'seekers.findhelp.profile.index.edit', 'seekers.findhelp.applicants.index.workers', 'seekers.findhelp.applicants.index.applicants', 'seekers.findhelp.applicants.index.favourites', 'seekers.manage.index.index', 'seekers.manage.schedule-workers.index.visits.all', 'seekers.manage.calendar', 'seekers.manage.circles.index', 'seekers.my-account.account-detail.index', 'seekers.my-account.account-detail.credit-cards', 'seekers.my-account.account-detail.reports', 'seekers.manage.circles.detail.index', 'carers.findwork.dashboard', 'carers.findwork.dashboard.index', 'carers.findwork.clients', 'carers.findwork.clients.index.clients', 'carers.findwork.clients.index.applicants', 'carers.findwork.clients.index.favourites', 'carers.findwork.jobapplications.index', 'carers.findwork.jobapplications.pending', 'carers.findwork.profile', 'carers.manage.calendar', 'carers.manage.index.index', 'carers.manage.my-schedule.index', 'carers.manage.worker-contracts.index.index', 'threads.index', 'me.profiles.index', 'me.account', 'carers.manage.my-schedule.visits',
            // 'me.account.index',
            // 'me.account.address',
            // 'me.account.reset-password',
            'carers.my-account.index', 'carers.my-account.account-detail.index', 'carers.my-account.account-detail.bank-accounts', 'carers.my-account.account-detail.reports', 'circles.detail', 'browse.profiles.index.index', 'browse.jobs.index.index', 'notifications.index'], currentPath) === true || Ember.isNone(currentPath.match(/loading/)) === false) {
                Ember.run.later(function () {
                    _this.get('eventbus').publish('mc-navbar:on:hideBackButton');
                }, 100);
            } else {
                Ember.run.later(function () {
                    _this.get('eventbus').publish('mc-navbar:on:showBackButton');
                }, 100);
            }
            Ember.run.later(function () {
                if (currentPath === 'carers.findwork.profile') {
                    Ember.$('.nav-findjobs').removeClass('active');
                }
                if (currentPath === 'seekers.findhelp.profile.index.edit') {
                    Ember.$('.nav-findhelps').removeClass('active');
                }
            }, 100);
        }
    });
});