define('mycare-ember/components/mc-worker-profile/trust-safety/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('worker', undefined);
            this.initVar('isNoMinistryCheck', undefined);
            this.initVar('isMinistryCheckComplete', undefined);
            this.initVar('isMinistryCheckPending', undefined);
            this.initVar('isMinistryCheckConviction', undefined);
        },
        didReceiveAttrs: function didReceiveAttrs() {
            this._checkMinistryStatus();
        },
        _checkMinistryStatus: function _checkMinistryStatus() {
            var _this = this;

            this.get('worker').get('trusts').then(function (trusts) {
                if (_this.isDestroyed) {
                    return;
                }
                trusts.forEach(function (trust) {
                    if (trust.get('trustType') === 2) {
                        _this.set('isNoMinistryCheck', trust.get('statusType') === 1);
                        _this.set('isMinistryCheckComplete', trust.get('statusType') === 2);
                        _this.set('isMinistryCheckPending', trust.get('statusType') === 4);
                        _this.set('isMinistryCheckConviction', trust.get('statusType') === 5);
                    }
                });
            });
        },


        _watchCheckMinistry: function () {
            this._checkMinistryStatus();
        }.observes('worker'),

        actions: {
            toVerifyAddress: function toVerifyAddress() {
                this.get('eventbus').publish('global:on:changeRoute', 'me.account.address');
                Ember.run.later(function () {
                    Ember.$('#modal-address-validation').modal('show');
                }, 100);
            },


            showPhoneValidationModal: function showPhoneValidationModal() {
                this.transitionToRoute('me.account.index');
                this.get('eventbus').publish('global:on:changeRoute', 'me.account.index');
                Ember.run.later(function () {
                    Ember.$('#modal-phone-validation').modal('show');
                }, 500);
            }
        }

    });
});