define("mycare-ember/mixins/password-checker", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    isNewPasswordsMatch: function isNewPasswordsMatch(newPassword, confirmPassword) {
      return newPassword === confirmPassword;
    },

    passwordStrengthTest: function passwordStrengthTest(password) {
      var re = /^(?=.*\d)(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{6,}$/;
      return re.test(password);
    },

    isPasswordTooShort: function isPasswordTooShort(password) {
      return password.length < 6;
    },

    isPasswordTooLong: function isPasswordTooLong(password) {
      return password.length > 20;
    },

    hasLowerCase: function hasLowerCase(password) {
      return (/[a-z]/.test(password)
      );
    },
    isPasswordCorrect: function isPasswordCorrect(newPassword) {
      if (this.isPasswordTooShort(newPassword) || this.isPasswordTooLong(newPassword)) {
        return 'Password must be between 6 and 20 characters';
      } else {
        if (!this.passwordStrengthTest(newPassword)) {
          return "Password must be at least 6 characters long and contain at least 1 uppercase character and at least 1 number";
        } else if (!this.hasLowerCase(newPassword)) {
          return "Password must be contain at least 1 lowercase character";
        }
      }
      return true;
    }
  });
});