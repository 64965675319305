define('mycare-ember/components/mc-tooltip/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/function-helpers'], function (exports, _mcComponent, _functionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.initVar('title', undefined);
            this.initVar('onClick', undefined);
            this.initVar('channelId', undefined);
            this.initVar('placement', 'right');

            if (this.get('channelId')) {
                this.get('eventbus').subscribe('mc-tooltip:on:refresh:' + this.get('channelId'), function () {
                    _this._setupTooltip();
                });
            }
        },
        _setupTooltip: function _setupTooltip() {
            var tooltip = this.$('[data-toggle="tooltip"]');
            if (tooltip) {
                tooltip.tooltip();
            }
        },
        didInsertElement: function didInsertElement() {
            this._setupTooltip();
        },


        actions: {
            onClick: function onClick() {
                _functionHelpers.default.callOrIgnore(this.get('onClick'));
            }
        }
    });
});