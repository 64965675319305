define('mycare-ember/components/mc-worker-profile/hobbies/component', ['exports', 'mycare-ember/utils/common', 'mycare-ember/utils/mc-component'], function (exports, _common, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('worker', undefined);
            this.initVar('_editorChannelId', _common.default.createGuid());
        },


        actions: {
            edit: function edit() {
                this.get('eventbus').publish('mc-worker-profile/hobbies/editor:on:open:' + this.get('_editorChannelId'), this.get('worker'), 'edit');
            },
            create: function create() {
                this.get('eventbus').publish('mc-worker-profile/hobbies/editor:on:open:' + this.get('_editorChannelId'), this.get('worker'), 'add');
            }
        }
    });
});