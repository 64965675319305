define('mycare-ember/components/ui/mc-google-map/component', ['exports', 'mycare-ember/utils/common', 'mycare-ember/utils/mc-component'], function (exports, _common, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        tagName: '',

        /**
         * setup map view for job search.
         * This function is called whenever element in the list of markers is changed.
         */
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('selectComponentId', _common.default.createGuid());
        },
        didInsertElement: function didInsertElement() {
            this._buildMap();
        },


        _buildMap: function _buildMap() {
            var self = this;
            GMaps.geocode({
                address: self.get('location'),
                callback: function callback(results, status) {
                    if (status === 'OK') {
                        if (self.isDestroyed) {
                            return;
                        }
                        var latlng = results[0].geometry.location;
                        var map = new google.maps.Map(document.getElementById(self.get('selectComponentId')), {
                            center: new google.maps.LatLng(latlng.lat(), latlng.lng()),
                            zoom: 13,
                            scrollwheel: false,
                            navigationControl: false,
                            mapTypeControl: false,
                            scaleControl: false,
                            draggable: false,
                            zoomControl: false,
                            streetViewControl: false,
                            mapTypeId: google.maps.MapTypeId.ROADMAP
                        });
                        new google.maps.Marker({
                            position: { lat: latlng.lat(), lng: latlng.lng() },
                            map: map,
                            icon: {
                                path: google.maps.SymbolPath.CIRCLE,
                                fillColor: '#ccaed0',
                                fillOpacity: 0.6,
                                scale: 70,
                                strokeColor: '#81368B',
                                strokeWeight: 1.5
                            }
                        });
                        Ember.$(document).on('webkitfullscreenchange mozfullscreenchange fullscreenchange', function () {
                            google.maps.event.trigger(map, 'resize');
                            map.setCenter(latlng);
                        });
                    }
                }
            });
        }
    });
});