define("mycare-ember/components/mc-message-send/component", ["exports", "mycare-ember/utils/mc-modal-component", "mycare-ember/mixins/validator", "mycare-ember/utils/Validators", "mycare-ember/utils/function-helpers"], function (exports, _mcModalComponent, _validator, _Validators, _functionHelpers) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend(_validator.default, {
        init: function init() {
            this.initVar('_componentId', 'mc-message-send');
            this._super.apply(this, arguments);
            // this.initVar('onSend', undefined);
            this.initVar('postingTask', undefined);
            this.initVar('_formData', {});

            this._setupValidations();
        },


        actions: {
            sendMessage: function sendMessage() {
                var _this = this;

                if (this._validate()) {
                    this.set('_isLoading', true);
                    this.get('postingTask').perform(this.get('_formData.message')).then(function () {
                        _this.set('_isLoading', false);
                        _this._close();
                    });
                    // FunctionUtils.callOrIgnore(this.get('onSend'), this.get('_formData.message'));
                }
            }
        },

        _setupValidations: function _setupValidations() {
            this._resetValidation();
            this.addValidation('_formData.message', 'message', 'Message', [_Validators.default.isRequired]);
        },
        _onBeforeOpen: function _onBeforeOpen() {
            this.set('_isLoading', false);
            this.set('_formData', {});
            this._setupValidations();
        }
    });
});