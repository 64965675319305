define("mycare-ember/components/mc-worker-subnav-manage/component", ["exports", "mycare-ember/utils/mc-component"], function (exports, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        _profileService: Ember.inject.service('profile'),
        _circleService: Ember.inject.service('circle'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('isCircles', false);
            this.initVar('_currentPersonId', undefined);
        },
        didReceiveAttrs: function didReceiveAttrs() {
            var _this = this;

            this.get('eventbus').subscribe('global:event:isAuthenticated', function (person) {
                if (_this.isDestroyed) {
                    return;
                }
                _this.set('_currentPersonId', person.get('id'));
            });
            this.get('eventbus').subscribe('global:event:profileSwitched', function (_ref) {
                var person = _ref.person;

                if (_this.isDestroyed) {
                    return;
                }
                _this.set('_currentPersonId', person.get('id'));
            });
            this.checkIsCircles();
        },
        checkIsCircles: function checkIsCircles() {
            var self = this;
            if (!this.get('_currentPersonId')) {
                return;
            }
            this.get('_circleService.getAllCirclesByPersonIdAsync').perform({ personId: this.get('_currentPersonId') }).then(function (circles) {
                if (self.isDestroyed) {
                    return;
                }
                self.set('isCircles', !Ember.isEmpty(circles));
            });
        },


        _watchCircles: function () {
            var self = this;
            self.checkIsCircles();
        }.observes('_currentPersonId')
    });
});