define("mycare-ember/components/mc-popover-options/component", ["exports", "mycare-ember/utils/mc-component", "mycare-ember/utils/common"], function (exports, _mcComponent, _common) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        tagName: '',

        didReceiveAttrs: function didReceiveAttrs() {
            var _this = this;

            this.set('parentId', _common.default.createGuid());
            this.set('buttonId', _common.default.createGuid());

            if (this.get('items')) {
                this.get('items').forEach(function (item) {
                    _this.get('selectedValues').forEach(function (selectedItem) {
                        if (selectedItem.get('id') == item.get('id')) {
                            item.set('isChecked', true);
                            return;
                        }
                    });
                });
            }
        },
        didRender: function didRender() {
            Ember.$('#' + this.get('parentId')).on('hide.bs.dropdown', function () {
                return false;
            });
        },


        actions: {
            open: function open() {
                this.set('_shouldRender', true);
                Ember.$('.dropdown.open').removeClass('open');
                Ember.$('#' + this.get('parentId')).addClass('open');
            },
            checkboxChange: function checkboxChange(item) {
                item.toggleProperty('isChecked');
            },
            clearSelectedOptions: function clearSelectedOptions() {
                Ember.$('.dropdown.open').removeClass('open');
            },
            apply: function apply() {
                var selectedItems = this.get('items').filterBy('isChecked', true);
                this.set('selectedValues', selectedItems);
                Ember.$('.dropdown.open').removeClass('open');
            }
        }
    });
});