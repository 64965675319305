define("mycare-ember/components/mc-joblisting-editor/required/component", ["exports", "mycare-ember/utils/mc-component"], function (exports, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        _referenceModelsService: Ember.inject.service('reference-models'),
        _jobListingService: Ember.inject.service('job-listing'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('jobListing', null);
            this.initVar('onSaved', null);
            this.initVar('_equipmentOptions', []);
            this.initVar('_requirementOptions', []);
            this.initVar('_languageOptions', []);
            this.initVar('isSaving', false);
            this.initVar('isFormValid', undefined);
            this.initVar('_isInitialised', false);
            this.initVar('_formData', {
                idealWorker: null,
                equipment: [],
                requirements: [],
                languages: []
            });

            this.setup();
        },


        titleEquipment: function () {
            var totalItems = this.get('_formData.equipment.length');
            var count = totalItems ? "(" + totalItems + ")" : '';
            return "Equipment experience " + count;
        }.property('_formData.equipment.[]'),

        titleRequirement: function () {
            var totalItems = this.get('_formData.requirements.length');
            var count = totalItems ? "(" + totalItems + ")" : '';
            return "My worker must have " + count;
        }.property('_formData.requirements.[]'),

        titleLanguage: function () {
            var totalItems = this.get('_formData.languages.length');
            var count = totalItems ? "(" + totalItems + ")" : '';
            return "Languages " + count;
        }.property('_formData.languages.[]'),

        setup: function setup() {
            var _this = this;

            this.get('_referenceModelsService.getEquipmentAsync').perform().then(function (equipment) {
                _this.set('_equipmentOptions', equipment);
                _this.set('_formData.equipment', _this.get('jobListing.equipment').toArray());
                //always true as there is no valiation required
                _this.set('isFormValid', true);
            });

            this.get('_referenceModelsService.getRequirementsAsync').perform().then(function (requirements) {
                if (_this.isDestroyed) {
                    return;
                }
                _this.set('_requirementOptions', requirements);
                _this.set('_formData.requirements', _this.get('jobListing.requirements').toArray());
            });

            this.get('_referenceModelsService.getLanguagesAsync').perform().then(function (languages) {
                if (_this.isDestroyed) {
                    return;
                }
                _this.set('_languageOptions', languages);
                _this.set('_formData.languages', _this.get('jobListing.languages').toArray());
            });

            Ember.run.later(function () {
                if (_this.isDestroyed) {
                    return;
                }
                _this.set('_formData.idealWorker', _this.get('jobListing.idealWorker'));
                _this.set('_formData.isRequiredMoj', _this.get('jobListing.isRequiredMoj'));
                _this.set('_isInitialised', true);
                _this.set('isFormDirty', false);
            }, 1000);
        },


        // watchFormDirty: function(){
        //     if(this.get('_isInitialised') === false){
        //         return;
        //     }
        //     Ember.RSVP
        //         .hash({
        //             equipment: this.get('jobListing.equipment'),
        //             languages: this.get('jobListing.languages'),
        //             requirements: this.get('jobListing.requirements'),
        //         })
        //         .then(({equipment, languages, requirements}) => {
        //             if(this.isDestroyed){
        //                 return;
        //             }
        //             var getIds = (list) => (list || Ember.A()).map(it => it.id).sort();
        //             var isDirty =
        //                 this.get('_formData.idealWorker') !== this.get('jobListing.idealWorker') ||
        //                 this.get('_formData.isRequiredMoj') !== this.get('jobListing.isRequiredMoj') ||
        //                 !_.isEqual(getIds(equipment), getIds(this.get('_formData.equipment'))) ||
        //                 !_.isEqual(getIds(languages), getIds(this.get('_formData.languages'))) ||
        //                 !_.isEqual(getIds(requirements), getIds(this.get('_formData.requirements')));
        //             this.set('isFormDirty', isDirty);
        //         });
        // }.observes('_formData.isRequiredMoj', '_formData.equipment.[]',
        //     '_formData.languages.[]', '_formData.requirements.[]', '_formData.idealWorker'),

        actions: {
            onSelected: function onSelected(category, selectedItems) {
                this.set("_formData." + category, selectedItems);
            },
            toggleRequiredMoj: function toggleRequiredMoj(event) {
                this.set('_formData.isRequiredMoj', event.target.checked);
            },
            save: function save() {
                var _this2 = this;

                this.set('isSaving', true);
                this.get('_jobListingService.updateJobListingAsync').perform({
                    id: this.get('jobListing.id'),
                    updates: {
                        equipment: this.get('_formData.equipment').toArray(),
                        languages: this.get('_formData.languages').toArray(),
                        requirements: this.get('_formData.requirements').toArray(),
                        idealWorker: this.get('_formData.idealWorker'),
                        isRequiredMoj: this.get('_formData.isRequiredMoj')
                    }
                }).then(function (jobListing) {
                    if (_this2.isDestroyed) {
                        return;
                    }
                    _this2.set('jobListing', jobListing);
                    _this2.set('isSaving', false);
                    _this2.set('isFormDirty', false);
                    _this2.sendAction('onSaved', jobListing);
                });
            }
        }
    });
});