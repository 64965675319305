define('mycare-ember/models/client', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        firstName: _emberData.default.attr('string'),
        lastName: _emberData.default.attr('string'),
        location: _emberData.default.attr('string'),
        avatarUrl: _emberData.default.attr('string'),
        createdOn: _emberData.default.attr('string'),
        isClient: _emberData.default.attr('boolean'),
        isDSSAccount: _emberData.default.attr('boolean'),
        isAllowedToBook: _emberData.default.attr('boolean'),
        thread: _emberData.default.belongsTo('thread', { async: true }),

        isFavourite: _emberData.default.attr('boolean'),

        jobListingId: _emberData.default.attr('string'),
        title: _emberData.default.attr('string'),
        hourlyRate: _emberData.default.attr('string'),
        hoursPerWeek: _emberData.default.attr('string'),
        description: _emberData.default.attr('string'),
        closesOn: _emberData.default.attr('string'),
        isOpportunity: _emberData.default.attr('boolean')
    });
});