define("mycare-ember/models/search-job-listing", ["exports", "ember-data"], function (exports, _emberData) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        logo: _emberData.default.attr("string"),
        title: _emberData.default.attr("string"),
        jobType: _emberData.default.attr("string"),
        description: _emberData.default.attr("string"),
        hourlyRate: _emberData.default.attr("string"),
        listedOn: _emberData.default.attr("string"),
        location: _emberData.default.attr("string"),
        latitude: _emberData.default.attr("string"),
        longitude: _emberData.default.attr("string"),
        hoursPerWeek: _emberData.default.attr("string"),
        services: _emberData.default.attr(),
        employmentTypes: _emberData.default.attr(),
        closesOn: _emberData.default.attr("string"),
        isHourlyRate: _emberData.default.attr("boolean")
    });
});