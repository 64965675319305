define("mycare-ember/components/mc-selectize/component", ["exports", "mycare-ember/utils/mc-component", "mycare-ember/utils/function-helpers", "mycare-ember/utils/common"], function (exports, _mcComponent, _functionHelpers, _common) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('options', []);
            this.initVar('isTag', false);
            this.initVar('onSelected', undefined);
            this.initVar('valuePath', 'id');
            this.initVar('labelPath', 'name');
            this.initVar('multi', false);
            this.initVar('channelId', undefined);
            this.initVar('placeholder', 'Select...');
            this.initVar('channelId', undefined);
            this.initVar('onOptionAdded', undefined);
            this.initVar('onOptionRemoved', undefined);

            this.set('_selected', []);
            this.set('_modalId', _common.default.createGuid());
        },
        _setup: function _setup() {
            var _this = this;

            var select = this.$('#' + this.get('_modalId'));
            var options = {
                plugins: ['remove_button', 'restore_on_backspace'],
                sortField: 'text',
                highlight: false,
                hideSelected: true,
                placeholder: this.get('placeholder'),
                onChange: function onChange(items) {
                    _functionHelpers.default.callOrIgnore(_this.get('onSelected'), null);
                    if (items) {
                        //will not be array if it is not multi select.
                        if (!items.forEach) items = [items];
                        var selectedOptions = [];
                        items.forEach(function (item) {
                            selectedOptions.pushObject(_this.get('options').find(function (opt) {
                                return opt.get(_this.get('valuePath')) === item;
                            }));
                        });
                        _functionHelpers.default.callOrIgnore(_this.get('onSelected'), selectedOptions);
                    }
                }
            };
            if (this.get('isTag')) {
                options.delimiter = ',';
                options.create = function (input) {
                    return {
                        value: input,
                        text: input
                    };
                };
            }
            var $select = this.$(select).selectize(options);
            this.set('_instance', $select[0].selectize);
            this.get('_instance').on('item_add', function () {
                _functionHelpers.default.callOrIgnore(_this.get('onOptionAdded'), _this.get('_instance').getValue());
            });
            this.get('_instance').on('item_remove', function () {
                _functionHelpers.default.callOrIgnore(_this.get('onOptionRemoved'), _this.get('_instance').getValue());
            });
        },
        didInsertElement: function didInsertElement() {
            var _this2 = this;

            this._setup();
            if (this.get('channelId')) {
                this.get('eventbus').subscribe("mc-chosen:on:reset:" + this.get('channelId'), function () {
                    _this2._setup();
                }, false);
            }
        },


        watchOptions: function () {
            this._setup();
        }.observes('options.[]')
    });
});