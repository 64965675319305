define('mycare-ember/mixins/nav/nav-common', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    auth: Ember.inject.controller('auth'),

    actions: {
      redirectToIndex: function redirectToIndex() {
        var self = this;
        if (self.get('auth.isAuthenticated')) {
          if (self.get('person.relationships.length') > 0) {
            if (self.get('auth.isSeeker') === true) {
              self.transitionToRoute('seekers.findhelp');
              return;
            } else if (self.get('isCarer') === true) {
              self.transitionToRoute('carers.findwork');
              return;
            }
          } else {
            self.transitionToRoute('me.profiles');
            return;
          }
        }
        self.transitionToRoute('index');
      }
    }
  });
});