define("mycare-ember/components/mc-availability/picker/component", ["exports", "mycare-ember/utils/function-helpers", "mycare-ember/utils/mc-component"], function (exports, _functionHelpers, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        _referenceModelsService: Ember.inject.service('reference-models'),

        init: function init() {
            this._super.apply(this, arguments);

            this.initVar('modelWithAvailabilities', undefined);
            this.initVar('extraPaddingCoach', true);
            this.initVar('onSaved', undefined);
            this.initVar('onSave', undefined);
            this.initVar('validation', undefined);
            this.initVar('isValid', false);
            this.initVar('isDelegateSave', false);
            this.initVar('mode', undefined);
            this.initVar('allItems', []);
            this.initVar('weekdays', ['M', 'T', 'W', 'T', 'F', 'S', 'S']);

            this.set('_formData', {
                isSelectAll: false,
                when: null
            });
            this.initVar('_isSaving', false);
        },
        didReceiveAttrs: function didReceiveAttrs() {
            if (this.get('modelWithAvailabilities')) {
                this._setupFormData(this.get('modelWithAvailabilities'));
            }
        },


        _watchSelection: function _watchSelection() {
            var isAllSelected = this.get('_formData.availabilities').every(function (group) {
                return group.get('items').every(function (item) {
                    return item.get('isChecked') === true;
                });
            });
            this.set('_formData.isSelectAll', isAllSelected ? isAllSelected : undefined);
        },

        _watchSelectAll: function () {
            var _this = this;

            if (Ember.isNone(this.get('_formData.isSelectAll'))) {
                return;
            }
            this.get('_formData.availabilities').forEach(function (group) {
                group.get('items').forEach(function (item) {
                    return item.set('isChecked', _this.get('_formData.isSelectAll'));
                });
            });
            this._setModelData();
        }.observes('_formData.isSelectAll'),

        _setupFormData: function _setupFormData(modelWithAvailabilities) {
            var _this2 = this;

            //jshint ignore:line
            Ember.RSVP.resolve(this.get('_referenceModelsService.getAvailabilitiesAsync').perform()).then(function (types) {
                if (_this2.isDestroyed) return;
                var existingAvailabilities = modelWithAvailabilities.get('availabilities') || [];
                var availabilities = types.map(function (item) {
                    var isChecked = !Ember.isNone(existingAvailabilities.find(function (i) {
                        return i.get('id') === item.get('id');
                    }));
                    return Ember.Object.create({
                        record: item,
                        isChecked: isChecked
                    });
                });

                _this2._setTimeRows(availabilities);
                _this2._setDateRows(availabilities);
            });
            if (this.get('mode') === 'job-listing') {
                this.set('_formData.when', modelWithAvailabilities.get('when'));
            } else if (modelWithAvailabilities.get('availability')) {
                this.set('_formData.when', modelWithAvailabilities.get('availability'));
            }
        },
        _setTimeRows: function _setTimeRows(availabilities) {
            var groupByTime = _.groupBy(availabilities.toArray(), function (item) {
                return item.get('record.time');
            });
            var groupByTimeWithName = Ember.keys(groupByTime).map(function (key) {
                return Ember.Object.create({
                    name: key,
                    items: _.sortBy(groupByTime[key], function (item) {
                        return item.get('record.dayId');
                    })
                });
            });
            this.set('_formData.availabilities', _.sortBy(groupByTimeWithName, function (group) {
                return group.get('items').objectAt(0).get('record.timeId');
            }));
        },
        _setDateRows: function _setDateRows(availabilities) {
            var _this3 = this;

            var groupByTime = _.groupBy(availabilities.toArray(), function (item) {
                return item.get('record.dayId');
            });
            var groupByTimeWithName = Ember.keys(groupByTime).map(function (key) {
                return Ember.Object.create({
                    name: _this3.getWeekdayString(key),
                    items: _.sortBy(groupByTime[key], function (item) {
                        return item.get('record.timeId');
                    })
                });
            });
            this.set('_formData.dayOrderAvailabilities', _.sortBy(groupByTimeWithName, function (group) {
                return group.get('items').objectAt(0).get('record.dayId');
            }));
        },
        getWeekdayString: function getWeekdayString(dayIndex) {
            return this.get('weekdays')[dayIndex - 1];
        },


        _watchWhen: function () {
            this._setWhen();
        }.observes('_formData.when'),

        _setWhen: function _setWhen() {
            this.set('modelWithAvailabilities.when', this.get('_formData.when'));
            this.set('modelWithAvailabilities.availability', this.get('_formData.when'));
        },
        _setModelData: function _setModelData() {
            var _this4 = this;

            this.get('allItems').clear();
            this.get('_formData.availabilities').forEach(function (group) {
                _this4.get('allItems').pushObjects(group.get('items'));
            });
            this._setWhen();
            if (this.get('isDelegateSave')) {
                this.set('modelWithAvailabilities.availabilities', this.get('allItems').filter(function (item) {
                    return item.get('isChecked') === true;
                }).map(function (item) {
                    return item.get('record');
                }));
            }
        },


        actions: {
            onSelectedEvent: function onSelectedEvent(item, event) {
                item.set('isChecked', !item.get('isChecked'));
                this._watchSelection();
                this._setModelData();
            },
            save: function save() {
                var _this5 = this;

                this.set('_isSaving', true);
                this._setModelData();
                this.set('modelWithAvailabilities.availabilities', this.get('allItems').filter(function (item) {
                    return item.get('isChecked') === true;
                }).map(function (item) {
                    return item.get('record');
                }));

                if (!this.get('isByPassSave')) {
                    this.get('modelWithAvailabilities').save().then(function () {
                        _this5.set('_isSaving', false);
                        _this5.get('allItems').clear();
                        _functionHelpers.default.callOrIgnore(_this5.get('onSaved'));
                    });
                } else {
                    this.set('_isSaving', false);
                    this.get('allItems').clear();
                    _functionHelpers.default.callOrIgnore(this.get('onSaved'));
                }
            }
        }
    });
});