define("mycare-ember/carers/manage/worker-contracts/contract/controller", ["exports", "mycare-ember/mixins/math-number-format", "mycare-ember/mixins/scroll_top", "mycare-ember/utils/common"], function (exports, _mathNumberFormat, _scroll_top, _common) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_mathNumberFormat.default, _scroll_top.default, {
        queryParams: ['backTo', 'backToOpts'],
        backTo: null,
        backToOpts: null,
        termsAndConditionController: Ember.inject.controller('partials/manage/contract/modal-terms-and-conditions'),
        newRateErrors: [],
        errors: [],
        _bankIrdFormComponentId: _common.default.createGuid(),
        isShowMainContractView: false,
        isShowSignedContractSummary: false,

        hasHourlyRate: function () {
            return this.get('model.contract.hourlyRate') > 0 && !Ember.isEmpty(this.get('model.contract.hourlyRate'));
        }.property('model.contract.hourlyRate'),

        hasFixedRate: function () {
            return this.get('model.contract.nightlyRate') > 0 && !Ember.isEmpty(this.get('model.contract.nightlyRate'));
        }.property('model.contract.nightlyRate'),

        setup: function setup() {
            var self = this;
            self.set('isValidContract', false);
            self.set('isCareForMyself', false);
            self.set('isShowMainContractView', false);
            self.set('isShowSignedContractSummary', false);
            self.checkWorkerBankAccount();
            self.set('hourlyRate', self.get('model.contract.hourlyRate'));

            if (self.get('model.contract.clientPerson.id') === self.get('session.authData.loggedInUserId')) {
                self.set('isCareForMyself', true);
            }

            if (!Ember.isEmpty(self.get('model.contract.clientSignedOn')) && (Ember.isEmpty(self.get('model.contract.contractEndsOn')) || moment().isBefore(self.get('model.contract.contractEndsOn')))) {
                self.set('isValidContract', true);
                self.set('nightlyRate', self.get('model.contract.nightlyRate'));
                // if (Ember.isEmpty(self.get('model.contract.contractorSignedOn'))) {
                //     Ember.run.scheduleOnce('afterRender', self, function () {
                //         self.showNewContractForm();
                //     });
                // }
            }
            self.setClientString();
        },
        checkWorkerBankAccount: function checkWorkerBankAccount() {
            var self = this;
            self.set('isShowBankAccountForm', !this.get('model.bankAccountCheck.isBankAccountSetup'));
            self.set('isShowIRDForm', !this.get('model.bankAccountCheck.isIrdSetup'));
            self.set('accountDetailRequirementMessage', null);
            if (self.get('isShowIRDForm') === true && self.get('isShowBankAccountForm') === true) {
                self.set('accountDetailRequirementMessage', "We’ll also need your bank account details and IRD number so we can make sure you get paid.");
            } else if (self.get('isShowIRDForm') === false && self.get('isShowBankAccountForm') === true) {
                self.set('accountDetailRequirementMessage', "We’ll also need your bank account details so we can make sure you get paid.");
            } else if (self.get('isShowIRDForm') === true && self.get('isShowBankAccountForm') === false) {
                self.set('accountDetailRequirementMessage', "We’ll also need your IRD number so we can make sure you get paid.");
            }
        },


        setClientString: function setClientString() {
            var self = this;
            var nameString = '';
            if (self.get('model.contract.createdBy.id') === self.get('model.contract.clientPerson.id')) {
                nameString = self.get('model.contract.clientName') + ' ("Client")';
                self.set('clientNameString', nameString.htmlSafe());
            } else {
                nameString = self.get('model.contract.organiserName') + ' on behalf of ' + self.get('model.contract.clientName') + ' ("Client")';
                self.set('clientNameString', nameString.htmlSafe());
            }
        },

        setStatusMessage: function () {
            var self = this;
            if (!Ember.isEmpty(self.get('model.contract.contractEndsOn'))) {
                self.set('statusMessage', 'Agreement is closed at ' + moment(new Date(self.get('model.contract.contractEndsOn'))).format('DD MMM YYYY HH:mm'));
            } else if (Ember.isEmpty(self.get('model.contract.clientSignedOn'))) {
                self.set('statusMessage', 'Please wait for client to sign this contract.');
            } else if (Ember.isEmpty(self.get('model.contract.contractorSignedOn'))) {
                self.set('statusMessage', 'Please sign this contract.');
            } else {
                self.set('statusMessage', 'Agreement is start at ' + moment(new Date(self.get('model.contract.contractorSignedOn'))).format('DD MMM YYYY HH:mm'));
            }
        }.observes('model.contract.clientSignedOn', 'model.contract.isNewContact', 'model.contract.contractEndsOn', 'model.contract.contractorSignedOn'),

        watchOverNightRate: function () {
            var self = this;
            self.set('hasOverNightRate', Ember.isEmpty(self.get('model.contract.nightRate')) === false);
        }.observes('model.contract.nightRate'),

        actions: {
            toggleMainContractView: function toggleMainContractView() {
                this.toggleProperty('isShowMainContractView');
            },
            onNavBack: function onNavBack() {
                if (Ember.isNone(this.get('backTo'))) {
                    this.transitionToRoute('carers.manage.worker-contracts.index.index');
                } else {
                    if (this.get('backToOpts') && this.get('backToOpts') !== 'undefined') {
                        this.transitionToRoute(this.get('backTo'), this.get('backToOpts'));
                    } else {
                        this.transitionToRoute(this.get('backTo'));
                    }
                }
            },
            onBankFormClosed: function onBankFormClosed() {
                this.set('isClosing', false);
                this.set('isSaving', false);
            },
            onIrdBankAccountCompleted: function onIrdBankAccountCompleted() {
                var self = this;
                self.set('isSaving', false);
                self.set('isClosing', false);
            },


            // clickShowNewContractForm: function () {
            //     var self = this;
            //     self.showNewContractForm();
            // },

            routeToMessage: function routeToMessage() {
                var self = this;
                self.get('model.contract.thread').then(function (thread) {
                    self.transitionToRoute('threads.detail', thread);
                });
            },

            showMessageModal: function showMessageModal() {
                var self = this;
                self.store.findRecord('person', self.get('session.authData.loggedInUserId')).then(function (person) {
                    self.set('messageBody', self.get('env').getApp('WORKER_CONTRACT_DEFAULT_MESSAGE') + person.get('firstName'));
                    Ember.$('#modal-contract-message').modal('show');
                });
            },
            close: function close() {
                this.set('isClosing', false);
                this.set('isSaving', false);
                Ember.$('#modal-contract-message').modal('hide');
            },
            addMessage: function addMessage() {
                var self = this;
                self.set('model.contract.messageBody', self.get('messageBody'));
                self.signContract();
            },
            showTermsAndConditions: function showTermsAndConditions() {
                var self = this;
                self.get('termsAndConditionController').set('contract', self.get('model.contract'));
                self.get('termsAndConditionController').set('model.contract', self.get('model.contract.contractDetail'));
                Ember.$('#modal-terms-and-conditions').modal();
            },
            back: function back() {
                var self = this;
                if (Ember.isNone(self.get('model.contract.contractEndsOn'))) {
                    self.transitionToRoute('carers.manage.worker-contracts.index');
                } else {
                    self.transitionToRoute('carers.manage.worker-contracts.index.past');
                }
            }
        },

        signContract: function signContract() {
            this.set('isClosing', false);
            this.set('isSaving', true);
            this.processingSignContract();
        },

        processingSignContract: function processingSignContract() {
            var self = this;
            self.set('isShowSignedContractSummary', false);
            var momentNowString = moment().utc().toISOString();
            self.set('model.contract.contractorSignedOn', momentNowString);
            self.get('model.contract').save().then(function () {
                if (self.get('isShowIRDForm') === true || self.get('isShowBankAccountForm') === true) {
                    Ember.$('#modal-contract-message').modal('hide');
                    self.get('eventbus').publish("mc-bank-ird-form:on:open:" + self.get('_bankIrdFormComponentId'));
                    Ember.$('#modal-contract-message').modal('hide');
                } else {
                    self.set('isSaving', false);
                    self.set('isClosing', false);
                    Ember.$('#modal-contract-message').modal('hide');
                    self.set('isShowSignedContractSummary', true);
                }
            });
        },
        validForm: function validForm() {
            var self = this;
            var bool = true;
            self.get('errors').clear();
            if (self.get('isShowIRDForm') === true) {
                var irdNumber = self.get('irdNumber').replace(/-/g, '');
                if (Ember.isNone(irdNumber)) {
                    self.get('errors').pushObject('IRD number is required.');
                    bool = false;
                } else if (irdNumber.length < 8 || irdNumber.length > 9 || !_common.default.isNumber(self.get('irdNumber'))) {
                    self.get('errors').pushObject('IRD number must be 8 or 9 numbers');
                    bool = false;
                }
            }
            if (self.get('isShowBankAccountForm') === true) {
                if (!_common.default.isNumber(self.get('bank'))) {
                    self.get('errors').pushObject('Bank field must be a number');
                    bool = false;
                }
                if (!_common.default.isNumber(self.get('branch'))) {
                    self.get('errors').pushObject('Branch field must be a number');
                    bool = false;
                }

                if (!_common.default.isNumber(self.get('accountNumber'))) {
                    self.get('errors').pushObject('Account number field must be a number');
                    bool = false;
                } else {
                    if (self.get('accountNumber.length') !== 7) {
                        self.get('errors').pushObject('Account number length must be 7');
                        bool = false;
                    }
                }

                if (!_common.default.isNumber(self.get('suffix'))) {
                    self.get('errors').pushObject('Suffix must be a number');
                    bool = false;
                }
            }
            if (bool === false) {
                self.scrollTop();
            }
            return bool;
        }
    });
});