define('mycare-ember/seekers/manage/schedule-workers/index/visits/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ['date', 'reload'],
        manageDateController: Ember.inject.controller('partials/manage/date-selected'),
        timesheetService: Ember.inject.service('timesheet'),
        _isLoading: true,
        init: function init() {
            var _this = this;

            this.get('eventbus').subscribe('seeker-visits-reload', function (bool) {
                _this.setLoading(bool);
            }, false);
        },
        setLoading: function setLoading(bool) {
            this.set('_isLoading', bool);
        },

        actions: {
            onWeekChanged: function onWeekChanged(changes) {
                var _this2 = this;

                Ember.run.scheduleOnce('afterRender', this, function () {
                    _this2.set('weekStartDate', changes.startDate);
                    _this2.set('weekEndDate', changes.endDate);
                    var newDate = moment(changes.startDate).toISOString();
                    if (newDate !== _this2.get('date')) {
                        _this2.set('date', newDate);
                        _this2.set('_isLoading', true);
                        _this2.send('onRouteWeekChanged', changes);
                    }
                });
            },


            toggleCopyTimesheetModal: function toggleCopyTimesheetModal() {
                this.get('eventbus').publish('mc-timesheet-copy-week-form:on:open:global', this.get('model'));
            },

            createNewTimesheet: function createNewTimesheet() {
                this.transitionToRoute('book', {
                    queryParams: {
                        date: moment(this.get('weekStartDate')).toISOString()
                    }
                });
            },

            toggleAllOrNoRemoveTimesheets: function toggleAllOrNoRemoveTimesheets() {
                if (this.get('isShowAllTimesheet')) {
                    this.get('model').set('allTimesheetsArray', this.get('model.allTimesheets'));
                } else {
                    this.get('model').set('allTimesheetsArray', this.get('model.allWithNoRemoveOrDeclinedTimesheets'));
                }
                this.set('isShowAllTimesheet', !this.get('isShowAllTimesheet'));
            }
        }
    });
});