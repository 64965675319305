define('mycare-ember/initializers/startup', ['exports', 'mycare-ember/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;


    function initHotJar(h, o, t, j, a, r) {
        h.hj = h.hj || function () {
            (h.hj.q = h.hj.q || []).push(arguments);
        };
        h._hjSettings = { hjid: _environment.default.APP.HOTJAR_CODE, hjsv: 5 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
    }

    function initialize() {
        moment.tz.setDefault('Pacific/Auckland');
        window['moment-range'].extendMoment(moment);

        //initialise MixPanel
        mixpanel.init(_environment.default.mixpanel.token);

        //initialise hotjar
        initHotJar(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

        // initialise Google Analytics
        gtag('config', _environment.default.APP.GOOGLE_ANALYTICS_KEY);

        fbq('init', _environment.default.APP.FB_PIXEL_ID);
        fbq('track', 'PageView');
    }

    exports.default = {
        name: 'startup',
        initialize: initialize
    };
});