define("mycare-ember/models/relationship", ["exports", "ember-data", "mycare-ember/config/environment"], function (exports, _emberData, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        person: _emberData.default.belongsTo('person', { async: true }),
        relatedPerson: _emberData.default.belongsTo('person', { async: true }),
        role: _emberData.default.belongsTo('role', { async: true }),

        isWorker: function () {
            return this.get('role.name') === 'Worker';
        }.property('role'),

        isOrganiser: function () {
            return this.get('role.name') === 'Organiser';
        }.property('role'),

        isSelected: function () {
            var self = this;
            if (self.get('person.id') === localStorage.getItem(_environment.default.APP.AUTH_PROFILE_ID)) {
                if (self.get('isOrganiser') && localStorage.getItem(_environment.default.APP.CURRENT_PROFILE_ROLE) === 'organiser') {
                    return true;
                } else {
                    return self.get('isWorker') && localStorage.getItem('currentProfileRole') === 'worker';
                }
            }
        }.property('person', 'role')
    });
});