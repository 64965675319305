define('mycare-ember/components/mc-booking/mc-mobile-booking-nav/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({

        wizardService: Ember.inject.service('utils/booking-wizard'),

        init: function init() {
            this._super.apply(this, arguments);
        },


        actions: {
            openNav: function openNav() {
                if (location.href.match(/mobile-nav/)) {
                    var visitRoute = this.get('session.authData.isCurrentlySeeker') ? 'seekers.manage.schedule-workers.index.visits.all' : 'carers.manage.my-schedule.visits';
                    this.get('eventbus').publish('global:on:changeRoute', visitRoute);
                } else {
                    this.get('eventbus').publish('global:on:changeRoute', 'book.mobile-nav');
                }
            }
        }

    });
});