define('mycare-ember/components/ui/mc-coach/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('hasError', false);
            this.initVar('coach', { coach: true });
            this.initVar('content', { content: true });
        },
        didInsertElement: function didInsertElement() {
            var $form = this.$('.form-group');
            var $listing = this.$(".listing-help");
            $form.mouseover(function () {
                $listing.show();
            });
            $form.mouseleave(function () {
                $listing.hide();
            });
        }
    });
});