define("mycare-ember/circles/index/route", ["exports", "mycare-ember/mixins/protected_route"], function (exports, _protected_route) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_protected_route.default, {
        beforeModel: function beforeModel(transition) {
            this.isAuthenticated(transition);
        },


        model: function model() {
            var self = this;
            return self.store.query('circle', { personId: self.controllerFor('auth').get('currentPersonId') });
        },

        setupController: function setupController(controller, model) {
            if (model.get('length') === 1) {
                this.transitionTo('circles.detail', model.objectAt(0));
            }
        }
    });
});