define("mycare-ember/components/mc-timesheet/event-editor/component", ["exports", "mycare-ember/utils/mc-component"], function (exports, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('errors', {});
            this.initVar('onUpdated', undefined);
        },
        didInsertElement: function didInsertElement() {
            this._setupEvents();
        },
        _setupEvents: function _setupEvents() {
            var _this = this;

            this.get('eventbus').subscribe("mc-form-event-edit:on:open", function (event) {
                if (_this.isDestroyed || _this.get('_isOpened') === true) {
                    return;
                }
                _this.set('event', event);
                _this.setupData();
                _this._open();
            }, false);
            this.get('eventbus').subscribe("mc-form-event-edit:on:close", function () {
                _this.set('timesheet', null);
                _this._close();
            }, false);
        },
        _open: function _open() {
            var _this2 = this;

            this.set('_isOpened', true);
            var $modal = this.$("#mc-timesheet-event-editor");

            if (!$modal) {
                return;
            }
            $modal.on('shown.bs.modal', function () {
                if (_this2.isDestroyed) {
                    return;
                }
                _this2.set('_isOpened', true);
            });
            $modal.on('hidden.bs.modal', function () {
                if (_this2.isDestroyed) {
                    return;
                }
                _this2.set('_isOpened', false);
            });
            $modal.modal('show');
        },
        _close: function _close() {
            var $modal = this.$("#mc-timesheet-event-editor");
            if (this.get('_isOpened') === false || !$modal) {
                return;
            }
            this.set('_isOpened', false);
            $modal.modal('hide');
        },


        setupData: function setupData() {
            this.set('title', this.get('event.title'));
            this.set('description', this.get('event.description'));
            this.set('startsOn', this.get('event.startsOn'));
            this.set('endsOn', this.get('event.endsOn'));
            this.set('newStartsOn', this.get('event.startsOn'));
            this.set('newEndsOn', this.get('event.endsOn'));
        },

        actions: {
            updateEvent: function updateEvent() {
                var _this3 = this;

                var self = this;
                if (self.validateForm() === true) {
                    self.set('isSaving', true);
                    self.set('event.title', self.get('title'));
                    self.set('event.description', self.get('description'));
                    self.set('event.startsOn', self.get('newStartsOn'));
                    self.set('event.endsOn', self.get('newEndsOn'));
                    self.get('event').save().then(function () {
                        if (_this3.isDestroyed) {
                            return;
                        }
                        self.set('isSaving', false);
                        var onUpdated = self.get('onUpdated');
                        if (onUpdated) {
                            self.sendAction('onUpdated');
                            onUpdated();
                        }
                        self._close();
                    });
                }
            },

            remove: function remove() {
                var self = this;
                self.get('event').deleteRecord();
                self.get('event').save().then(function () {
                    if (self.isDestroyed) {
                        return;
                    }
                    var onUpdated = self.get('onUpdated');
                    if (onUpdated) {
                        onUpdated();
                    }
                    self._close();
                });
            }
        },

        validateForm: function validateForm() {
            var self = this;
            var bool = true;
            self.set('errors', {});

            if (self.isStartTimeBeforeEndTime() === false) {
                bool = false;
            }

            if (Ember.isEmpty(self.get('title'))) {
                self.set('errors.title', 'Title is required');
                bool = false;
            }

            return bool;
        },

        isStartTimeBeforeEndTime: function isStartTimeBeforeEndTime() {
            var self = this;
            if (self.get('timesheet.isOvernightScheduled')) {
                return true;
            }
            var selectedStartDay = moment(new Date(self.get('event.startsOn')));
            //set start time
            var StartsOnHour = moment(new Date(self.get('newStartsOn'))).hour();
            var StartsOnMinute = moment(new Date(self.get('newStartsOn'))).minute();
            var startTime = selectedStartDay.set({
                'hour': StartsOnHour,
                'minute': parseInt(StartsOnMinute)
            });
            //set end time
            var selectedEndDay = moment(new Date(self.get('event.endsOn')));
            var EndOnHour = moment(new Date(self.get('newEndsOn'))).hour();
            var EndOnMinute = moment(new Date(self.get('newEndsOn'))).minute();
            var endTime = selectedEndDay.set({
                'hour': EndOnHour,
                'minute': parseInt(EndOnMinute)
            });
            if (startTime.isBefore(endTime) === false) {
                self.set('errors.startTime', 'Start time must be before end time.');
            } else {
                self.set('newStartsOn', startTime.toISOString());
                self.set('newEndsOn', endTime.toISOString());
            }
            return startTime.isBefore(endTime);
        }
    });
});