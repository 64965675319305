define('mycare-ember/initializers/global-injector', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;
    function initialize(App) {
        App.inject('mixin', 'router', 'router:main');
        App.inject('controller', 'router', 'router:main');
        App.inject('component', 'router', 'router:main');
        App.inject('service', 'router', 'router:main');

        App.inject('route', 'env', 'service:utils/env');
        App.inject('controller', 'env', 'service:utils/env');
        App.inject('model', 'env', 'service:utils/env');
        App.inject('component', 'env', 'service:utils/env');
        App.inject('mixin', 'env', 'service:utils/env');

        App.inject('route', 'profileService', 'service:utils/eventbus');
        App.inject('controller', 'eventbus', 'service:utils/eventbus');
        App.inject('model', 'eventbus', 'service:utils/eventbus');
        App.inject('component', 'eventbus', 'service:utils/eventbus');
        App.inject('mixin', 'eventbus', 'service:utils/eventbus');

        App.inject('route', 'logger', 'service:utils/logger');
        App.inject('controller', 'logger', 'service:utils/logger');
        App.inject('model', 'logger', 'service:utils/logger');
        App.inject('component', 'logger', 'service:utils/logger');
        App.inject('mixin', 'logger', 'service:utils/logger');

        App.inject('route', 'session', 'service:session');
        App.inject('controller', 'session', 'service:session');
        App.inject('model', 'session', 'service:session');
        App.inject('component', 'session', 'service:session');
        App.inject('mixin', 'session', 'service:session');
    }

    exports.default = {
        name: 'global-injector',
        initialize: initialize
    };
});