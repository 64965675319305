define('mycare-ember/components/mc-timesheet/feedback/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('timesheet', undefined);
            this.initVar('isWorker', false);
            this.initVar('_isSendingLike', undefined);
            this.initVar('_isSendingDisLike', undefined);
            this.initVar('_likePropertyKey', this.get('isWorker') ? 'isVisitOkayWorker' : 'isVisitOkayClient');
        },


        isLikeDisabled: function () {
            var likeValue = this.get('timesheet.' + this.get('_likePropertyKey'));
            return likeValue !== undefined && likeValue !== false;
        }.property('timesheet.isVisitOkayWorker', 'timesheet.isVisitOkayClient'),

        isDisLikeDisabled: function () {
            var likeValue = this.get('timesheet.' + this.get('_likePropertyKey'));
            return likeValue !== undefined && likeValue !== true;
        }.property('timesheet.isVisitOkayWorker', 'timesheet.isVisitOkayClient'),

        actions: {
            addLikeDisLikeVisit: function addLikeDisLikeVisit(isLike) {
                var _this = this;

                if (isLike) {
                    this.set('_isSendingLike', true);
                } else {
                    this.set('_isSendingDisLike', true);
                }
                this.get('timesheet').set(this.get('_likePropertyKey'), isLike);
                this.get('timesheet').save().then(function () {
                    if (isLike) {
                        _this.set('_isSendingLike', false);
                    } else {
                        _this.set('_isSendingDisLike', false);
                    }
                });
            }
        }
    });
});