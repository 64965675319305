define("mycare-ember/helpers/capitalize", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function capitalize(data) {
    var string = data[0];
    if (string) {
      return string.capitalize();
    }
    return '';
  }

  exports.capitalize = capitalize;
  exports.default = Ember.Helper.helper(capitalize);
});