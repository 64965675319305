define("mycare-ember/circles/index/controller", ["exports", "mycare-ember/mixins/payment-process", "mycare-ember/mixins/nav-mode"], function (exports, _paymentProcess, _navMode) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_navMode.default, _paymentProcess.default, {
        fileUploadUrl: function () {
            return this.get('env').getApp('API_HOST') + '/api/FileUpload';
        }.property(),
        auth: Ember.inject.controller('auth'),
        circleSorting: ['createdOn:desc'],
        sortedCircles: Ember.computed.sort('model', 'circleSorting'),

        circleDefaultImg: function () {
            return this.get('env').getApp('CIRCLE_DEFAULT_IMAGE');
        }.property(),

        // isCreateButtonEnabled: false,

        // setIsCreateButtonEnabled: function () {
        //     var self = this;
        //     return self.store.findRecord('person', self.get('auth.loggedInUserId')).then(function (person) {
        //         if (self.get('auth.isWorker')) {
        //             self.set('isCreateButtonEnabled', false);
        //         }
        //         else if (person.get('relationships.length') === 0) {
        //             self.set('isCreateButtonEnabled', false);
        //         }
        //         else if (self.get('sortedCircles.length') === 0) {
        //             self.set('isCreateButtonEnabled', true);
        //         }
        //     });
        // }.observes('auth.loggedInUserId'),

        actions: {
            create: function create() {
                var self = this;
                self.set('isLoading', true);
                self.store.findRecord('person', self.get('auth.currentPersonId')).then(function (person) {
                    var afterFunction = function afterFunction() {
                        self.store.query('circle', { personId: self.get('auth.currentPersonId') }, { reload: true }).then(function (circles) {
                            var circle = circles.objectAt(0);
                            self.transitionToRoute('circles.detail', circle);
                        });
                    };
                    self.buildComponents(person, afterFunction);
                });
            }
        }
    });
});