define('mycare-ember/components/mc-timesheet/group/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('timesheetGroups', undefined);
            this.initVar('showHeader', true);
            this.initVar('backTo', undefined);
            this.initVar('isWorker', true);
        },


        _timesheetGroupKeys: function () {
            if (this.get('timesheetGroups')) {
                return Object.keys(this.get('timesheetGroups')).map(function (key) {
                    return {
                        key: key,
                        keyDisplay: moment(key, 'DD-MMM-YYYY').toISOString()
                    };
                });
            }
            return [];
        }.property('timesheetGroups.[]')
    });
});