define('mycare-ember/me/profiles/site-setup/done/controller', ['exports', 'mycare-ember/mixins/mixpanel', 'ember-concurrency'], function (exports, _mixpanel, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_mixpanel.default, {
        onPrev: 'index',
        isLoading: false,

        _referenceModelService: Ember.inject.service('reference-models'),
        _inviteService: Ember.inject.service('invite'),

        /*   willWizardEnter(_, data){
               if(data.get(`state.payment.completed`) !== true){
                   return 'payment';
               }
           },*/

        actions: {
            done: function done() {
                var _this = this;

                this.set('isLoading', true);
                return this.get('wizardData.person').save().then(function (savedPerson) {
                    _this.mixpanelCreateClientProfile(savedPerson, true, false, true, false);
                    if (_this.get('wizardData.emails.length') > 0) {
                        _this.get('_inviteService.sendInviteAsync').perform({
                            personId: savedPerson.get('id'),
                            emails: _this.get('wizardData.emails'),
                            message: "",
                            firstName: "",
                            invitePersonType: 1 // seeker
                        }).then(function () {
                            _this.createPremiumSubscription(savedPerson, function () {
                                _this.send('switchProfile', 'organiser', savedPerson.get('id'), savedPerson.get('firstName'), 'seekers.findhelp.dashboard');
                                _this.get('stepInfo.parent').cleanupWizard();
                                _this.set('isLoading', false);
                            });
                        });
                    } else {
                        _this.createPremiumSubscription(savedPerson, function () {
                            _this.send('switchProfile', 'organiser', savedPerson.get('id'), savedPerson.get('firstName'), 'seekers.findhelp.dashboard');
                            _this.get('stepInfo.parent').cleanupWizard();
                            _this.set('isLoading', false);
                        });
                    }
                });
            }
        },

        createPremiumSubscription: function createPremiumSubscription(person, afterFunction) {
            var self = this;
            self.getSubscriptionType().then(function (subtype) {
                var people = [person.get('id')];
                self.store.createRecord('subscription', {
                    type: subtype,
                    subscribedPeople: people
                }).save().then(function () {
                    if (afterFunction) {
                        self.createCircle(person);
                        afterFunction.call(this);
                    }
                }, function () {
                    toastr.error('There was an error creating your profile, please contact Mycare');
                });
            });
        },

        createCircle: function createCircle(person, afterFunction) {
            var self = this;
            self.store.query('circle', { personId: person.get('id') }).then(function (circles) {
                if (circles.get('length') === 0) {
                    var owners = [person.get('id')];
                    self.newcircle('', 'default.png', person.get('firstName') + "'s support circle", 'Keep friends and family informed in ' + person.get('firstName') + "'s support circle", owners, afterFunction);
                }
            });
        },

        newcircle: function newcircle(imageUrl, fileName, circleTitle, circleDescription, owners, finishAction, errorAction) {
            var self = this;
            self.store.createRecord('image', {
                url: imageUrl,
                fileName: fileName,
                uploadedOn: moment.utc(new Date()).toISOString()
            }).save().then(function (image) {
                self.store.createRecord('circle', {
                    title: circleTitle,
                    description: circleDescription,
                    createdOn: moment.utc(new Date()).toISOString(),
                    image: image,
                    owners: owners
                }).save().then(function (circle) {
                    self.mixpanelCreateSupportCircle(circle);
                    if (finishAction) {
                        finishAction();
                    }
                }, function () {
                    if (errorAction) {
                        errorAction();
                    }
                });
            });
        },

        getSubscriptionType: function getSubscriptionType(subscriptionType) {
            var self = this;
            return self.get('_referenceModelService.getSubscriptionTypesAsync').perform().then(function (subscriptionTypes) {
                if (Ember.isEmpty(subscriptionType)) {
                    subscriptionType = 'recruitment1';
                }
                for (var i = 0; i < subscriptionTypes.get('length'); i++) {
                    var selectedType = subscriptionTypes.objectAt(i);
                    if (selectedType.get('typeName').toLowerCase() === subscriptionType.toLowerCase()) {
                        return selectedType;
                    }
                }
            });
        }
    });
});