define('mycare-ember/components/mc-task-link/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        tagName: '',

        init: function init() {
            this._super.apply(this, arguments);

            this.initVar('task', undefined);
            this.initVar('params', undefined);
            this.initVar('text', undefined);
            this.initVar('isDisabled', false);

            Ember.Logger.assert(!Ember.isNone(this.get('task')));
        }
    });
});