define("mycare-ember/seekers/findhelp/job-listings/controllers/payment", ["exports", "mycare-ember/mixins/mixpanel", "mycare-ember/mixins/creditcard-add-process", "mycare-ember/mixins/dss-form", "mycare-ember/mixins/scroll_top", "ember-concurrency"], function (exports, _mixpanel, _creditcardAddProcess, _dssForm, _scroll_top, _emberConcurrency) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_scroll_top.default, _mixpanel.default, _creditcardAddProcess.default, _dssForm.default, {
        _wizardService: Ember.inject.service('utils/seeker-listing-wizard'),
        _accountService: Ember.inject.service('account'),
        errors: [],
        person: {},
        jobListing: {},
        setup: function setup() {
            this.get('_wizardService').setCurrentStep(7);
            this.setupForm();
        },
        setupForm: function setupForm() {
            var self = this;
            self.set('isLoadingUrl', true);
            self.set('creditCardCreateUrl', null);
            Ember.RSVP.resolve(this.get('person.accounts')).then(function (accounts) {
                var currentAccount = accounts.filter(function (acc) {
                    return acc.get('accountTypeName').toLowerCase() !== 'worker';
                }).objectAt(0);
                self.set('currentAccount', currentAccount);
                self.set('isIFClient', currentAccount.get('accountTypeName').toLowerCase() === 'if' || currentAccount.get('accountTypeName').toLowerCase() === 'if family carers');
                self.executeCreditCard(currentAccount);
            });
        },


        actions: {
            skip: function skip() {
                this.stopGetCreditCard();
                this.mixpanelCreateJobListingFunnel(this.get('session.authData.currentPersonId'), 'Step5:Payment:skipped');
                this.get('_wizardService.goNextStep').perform();
            },


            addCreditCard: function addCreditCard() {
                this.stopGetCreditCard();
                this.executeCreditCard(this.get('currentAccount'));
            }
        },

        addCreditCardAsync: (0, _emberConcurrency.task)(function () {
            this.executeCreditCard(this.get('currentAccount'));
        }),

        executeCreditCard: function executeCreditCard(account) {
            var self = this;
            self.set('creditCardError', false);
            var errorHandle = function errorHandle(errorMessage) {
                self.get('errors').clear();
                self.get('errors').pushObject(errorMessage);
            };
            var saveJoblisting = function saveJoblisting() {
                self.mixpanelCreateJobListingFunnel(self.get('session.authData.currentPersonId'), 'Step5:Payment:Completed');
                var person = self.get('session.authData.currentPerson');

                self.get('_accountService.getAllCreditCardsAsync').perform({ clientId: person.get('id') }).then(function (creditCards) {
                    self.get('_wizardService._creditCards').pushObjects(creditCards);
                    self.set('_wizardService._jobListing.hasCreditCard', true);
                    self.get('_wizardService.goNextStep').perform();
                });
            };
            self.addCreditCardProcess(account, saveJoblisting, errorHandle);
        },

        watchPostURL: function () {
            var self = this;
            if (Ember.isEmpty(self.get('creditCardCreateUrl')) === false) {
                self.set('isLoadingUrl', false);
            }
        }.observes('creditCardCreateUrl'),

        validateForm: function validateForm() {
            var isValid = true;
            Ember.$('.has-error').removeClass('has-error');
            return isValid;
        }
    });
});