define('mycare-ember/threads/route', ['exports', 'mycare-ember/utils/mc-protected-route', 'mycare-ember/mixins/scroll_top'], function (exports, _mcProtectedRoute, _scroll_top) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcProtectedRoute.default.extend(_scroll_top.default, {
        beforeModel: function beforeModel(transition) {
            this._super();
            this.isAuthenticated(transition);
            Ember.$('.home').removeClass('home');
        },

        model: function model() {
            var self = this;
            return self.store.findRecord('person', self.controllerFor('auth').get('loggedInUserId'));
        },

        actions: {
            didTransition: function didTransition() {
                Ember.$("body").addClass("page-messages");
            }
        }
    });
});