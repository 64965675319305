define("mycare-ember/components/multi-email-input/component", ["exports", "mycare-ember/mixins/form-checker", "mycare-ember/utils/mc-component"], function (exports, _formChecker, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend(_formChecker.default, {
        tagName: '',

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('emailList', []);
            this.initVar('errors', []);
        },


        setup: function () {
            this.get('emailList').clear();
        }.on('init'),

        /**
         * Setup component on startup
         */
        hasEmailList: function () {
            return this.get('emailList.length') > 0;
        }.property('emailList'),

        actions: {
            addEmail: function addEmail() {
                var self = this;
                if (self.validateEmail(self.get('newEmail'))) {
                    self.get('emailList').pushObject(self.get('newEmail'));
                    self.set('newEmail', '');
                }
            },
            remove: function remove(email) {
                this.get('emailList').removeObject(email);
            }
        },

        watchNewEmail: function () {
            var self = this;
            if (Ember.isEmpty(self.get('newEmail')) === false) {
                self.get('errors').clear();
            }
        }.observes('newEmail'),

        validateEmail: function validateEmail(email) {
            var self = this;
            var bool = false;
            self.get('errors').clear();
            if (this.isEmail(email) === false) {
                self.get('errors').pushObject('Email format is not correct.');
            } else {
                bool = true;
            }

            return bool;
        }
    });
});