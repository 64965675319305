define('mycare-ember/services/store', ['exports', 'ember-data', 'npm:lodash'], function (exports, _emberData, _npmLodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Store.extend({
        _queryCache: Ember.Object.create({}),

        query: function query(modelName, _query) {
            var _this = this;

            var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
                _ref$reload = _ref.reload,
                reload = _ref$reload === undefined ? false : _ref$reload;

            var queryString = JSON.stringify(_query);
            var cacheName = modelName + '_' + btoa(unescape(encodeURIComponent(queryString)));
            var hasCache = _npmLodash.default.has(this.get('_queryCache'), cacheName);
            if (hasCache && !reload) {
                return Ember.RSVP.resolve(this.get('_queryCache').get(cacheName));
            }
            return this._super(modelName, _query).then(function (result) {
                _this.get('_queryCache').set(cacheName, result);
                return result;
            });
        }
    });
});