define('mycare-ember/components/mc-worker-profile/profile-toggle/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('worker', undefined);
            this.initVar('_formData', {
                isPublished: this.get('worker.isPublished')
            });
        },


        save: function () {
            this.set('worker.isPublished', this.get('_formData.isPublished'));
            this.get('worker').save();
        }.observes('_formData.isPublished')

    });
});