define('mycare-ember/components/mc-worker-description/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/common', 'mycare-ember/mixins/validator', 'mycare-ember/utils/Validators'], function (exports, _mcComponent, _common, _validator, _Validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend(_validator.default, {

        _modalHelper: Ember.inject.service('utils/modal-helper'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('channelId', undefined);
            this.initVar('_modalChannelId', _common.default.createGuid());
            this.initVar('_isLoading', false);
            this.initVar('_formData', {});

            this._setupEvents();
            this._setupValidations();
        },
        _setupValidations: function _setupValidations() {
            this._resetValidation();
            this.addValidation('_formData.description', 'description', 'Description', [_Validators.default.isRequired]);
        },
        _setupEvents: function _setupEvents() {
            var _this = this;

            var channelId = this.get('channelId');
            var openEvent = 'mc-worker-description:on:open:' + channelId;
            this.get('eventbus').subscribe(openEvent, function () {
                _this._open();
            }, false);

            this.get('eventbus').subscribe('mc-worker-description:on:close:' + channelId, function () {
                _this._close();
            }, false);
        },
        _open: function _open() {
            this.get('_modalHelper').openModal(this.get('_modalChannelId'));
        },
        _close: function _close() {
            this.get('_modalHelper').closeModal(this.get('_modalChannelId'));
        },


        actions: {
            save: function save() {
                var _this2 = this;

                if (!this._validate()) {
                    return;
                }
                this.set('_isLoading', true);
                this.set('worker.description', this.get('_formData.description'));
                this.get('worker').save().then(function () {
                    _this2.set('_isLoading', false);
                    _this2._close();
                });
            }
        }
    });
});