define('mycare-ember/components/mc-verify-phone/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/common', 'mycare-ember/mixins/validator', 'mycare-ember/utils/Validators', 'mycare-ember/utils/function-helpers'], function (exports, _mcComponent, _common, _validator, _Validators, _functionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend(_validator.default, {

        _modalHelper: Ember.inject.service('utils/modal-helper'),
        _profileService: Ember.inject.service('profile'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('channelId', undefined);
            this.initVar('_modalChannelId', _common.default.createGuid());
            this.initVar('_isLoading', {});
            this.initVar('_formData', {});
            this.initVar('onCompleted', undefined);
            this.initVar('onError', undefined);
        },
        willSetupOnce: function willSetupOnce() {
            this._setupEvents();
            this._setupValidations();
        },
        _setupValidations: function _setupValidations() {
            this._resetValidation();
            this.addValidation('_formData.code', 'code', 'Verification code', [_Validators.default.isRequired]);
        },
        _setupEvents: function _setupEvents() {
            var _this = this;

            var channelId = this.get('channelId');
            var openEvent = 'mc-verify-phone:on:open:' + channelId;
            this.get('eventbus').subscribe(openEvent, function () {
                _this._open();
            }, false);

            this.get('eventbus').subscribe('mc-verify-phone:on:close:' + channelId, function () {
                _this._close();
            }, false);
        },
        _open: function _open() {
            this.get('_modalHelper').openModal(this.get('_modalChannelId'));
        },
        _close: function _close() {
            this.get('_modalHelper').closeModal(this.get('_modalChannelId'));
        },


        actions: {
            requestCode: function requestCode() {
                var _this2 = this;

                this.set('_isLoading.request', true);
                this.get('_profileService.requestPhoneVerificationAsync').perform().then(function () {
                    if (_this2.isDestroyed) {
                        return;
                    }
                    _this2.set('_isLoading.request', false);
                });
            },
            verifyCode: function verifyCode() {
                var _this3 = this;

                if (!this._validate()) {
                    return;
                }
                this._removeValidationError('verification');
                this.set('_isLoading.verifying', true);
                this.get('_profileService.verifyPhoneVerificationCodeAsync').perform({ code: this.get('_formData.code') }).then(function () {
                    _this3.set('_isLoading.verifying', false);
                    _functionHelpers.default.callOrIgnore(_this3.get('onCompleted'));
                    _this3._close();
                }, function () {
                    _this3.set('_isLoading.verifying', false);
                    var errorMessage = 'Verification code is incorrect, please check your mobile phone';
                    _functionHelpers.default.callOrIgnore(_this3.get('onError'), errorMessage);
                    _this3._addValidationError('verification', errorMessage);
                });
            }
        }
    });
});