define("mycare-ember/seekers/findhelp/job-listings/list/controller", ["exports", "ember-concurrency"], function (exports, _emberConcurrency) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        _wizardService: Ember.inject.service('utils/seeker-listing-wizard'),
        _accountService: Ember.inject.service('account'),
        _profileService: Ember.inject.service('profile'),
        _jobListingService: Ember.inject.service('job-listing'),

        actions: {
            enterCreateListingWizard: function enterCreateListingWizard() {
                localStorage.removeItem(this.get('env').getApp('NEW_LISTING'));
                this.transitionToRoute('seekers.findhelp.job-listings.create');
            }
        }
    });
});