define('mycare-ember/controllers/partials/manage/contract/modal-terms-and-conditions', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        setup: function () {
            var self = this;
            self.store.queryRecord('contract-base-detail', { current: true }).then(function (contractDetail) {
                self.set('baseDetail', contractDetail);
                self.set('model', contractDetail);
                Ember.$('#modal-terms-and-conditions').on('hidden.bs.modal', function () {
                    self.set('model', contractDetail);
                    self.set('contract', null);
                });
            });
        }.on('init')

    });
});