define('mycare-ember/components/mc-thread/sidebar/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('thread', null);
            this.initVar('canBook', false);
            this.initVar('onRedirectToContract', null);
            this.initVar('onConfirmHire', null);
            this.initVar('isContractSigned', false);
            this.initVar('isSeeker', undefined);
            this.initVar('loggedInUserId', undefined);
            this.initVar('_workerPerson', undefined);
            this.initVar('worker', undefined);
        },


        firstJob: function () {
            return this.get('thread.jobApplications').objectAt(0);
        }.property('thread.jobApplications.[]')

    });
});