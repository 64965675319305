define('mycare-ember/components/mc-message-nav/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/function-helpers', 'mycare-ember/utils/common'], function (exports, _mcComponent, _functionHelpers, _common) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.set('_newMessageFormChannelId', _common.default.createGuid());

            this.initVar('thread', undefined);
            this.initVar('onSend', undefined);
            this.initVar('onBook', undefined);
            this.initVar('onFileOpen', undefined);
            this.initVar('onInfoView', undefined);
            this.initVar('canBook', false);
        },


        actions: {
            onSend: function onSend() {
                _functionHelpers.default.callOrIgnore(this.get('onSend'));
            },
            onFileOpen: function onFileOpen() {
                _functionHelpers.default.callOrIgnore(this.get('onFileOpen'));
            },
            onInfoView: function onInfoView() {
                _functionHelpers.default.callOrIgnore(this.get('onInfoView'));
            },
            onBook: function onBook() {
                _functionHelpers.default.callOrIgnore(this.get('onBook'));
            }
        }
    });
});