define('mycare-ember/models/circle', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        title: _emberData.default.attr('string'),
        description: _emberData.default.attr('string'),
        image: _emberData.default.belongsTo('image', { async: true }),
        personCircles: _emberData.default.hasMany('person-circle', { async: true }),
        circleNotes: _emberData.default.hasMany('circle-note', { async: true }),
        createdOn: _emberData.default.attr('string'),
        hasValidSubscription: _emberData.default.attr('string'),
        owners: _emberData.default.attr(),
        person: _emberData.default.belongsTo('person')
    });
});