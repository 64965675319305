define("mycare-ember/mixins/mobile-phone-verification", ["exports", "mycare-ember/mixins/defer"], function (exports, _defer) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_defer.default, {
    modalErrors: {},
    actions: {
      requestCode: function requestCode(shouldRequest) {
        var self = this;
        self.showPhoneVerify(shouldRequest);
      },

      validatePhoneCode: function validatePhoneCode() {
        var self = this;
        self.set('isPhoneVerifying', true);
        self.verifyPhoneCode();
      }
    },

    showPhoneVerify: function showPhoneVerify(shouldRequest) {
      var self = this;
      self.set('isRequestPhoneVerification', shouldRequest);
      self.get('model').save().then(function () {
        Ember.$('#modal-phone-validation').modal('show');
        if (shouldRequest) {
          self.requestPhoneVerification();
        }
      });
    },

    requestPhoneVerification: function requestPhoneVerification() {
      var self = this;
      var defer = self.getDeferWithToken();

      Ember.$.post(self.get('env').getApp('API_HOST') + '/api/Logins/GeneratePhoneNumberVerification').then(function () {
        self.set('isRequestPhoneVerification', false);
      }, function (error) {
        defer.reject(error);
        self.get('errors').pushObject('Could not generate verification code, please try again');
        self.set('isRequestPhoneVerification', false);
      });
    },

    verifyPhoneCode: function verifyPhoneCode(callback) {
      var self = this;
      self.set('modalErrors', {});
      Ember.$('.has-error').removeClass('has-error');
      var defer = self.getDeferWithToken();
      if (self.get('verificationCode')) {
        var validationObject = {
          phoneVerify: {
            verificationCode: self.get('verificationCode')
          }
        };

        validationObject = JSON.stringify(validationObject);
        Ember.$.post(self.get('env').getApp('API_HOST') + '/api/Logins/VerifyPhoneNumber', validationObject).then(function () {
          self.set('model.isPhoneNumberVerified', true);
          Ember.$('#modal-phone-validation').modal('hide');
          self.set('isPhoneVerifying', false);
          self.set('isVerificationSuccessful', true);
          callback.call(self);
        }, function (error) {
          defer.reject(error);
          self.set('modalErrors.code', 'Verification code is incorrect, please check your mobile phone');
          Ember.$('#verificationCode').addClass('has-error');
          self.set('isPhoneVerifying', false);
        });
      } else {
        self.set('modalErrors.code', 'Verification code is required');
        Ember.$('#verificationCode').addClass('has-error');
        self.set('isPhoneVerifying', false);
      }
    }
  });
});