define('mycare-ember/components/mc-worker-profile/info/component', ['exports', 'mycare-ember/utils/common', 'mycare-ember/utils/mc-component'], function (exports, _common, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('worker', undefined);
            this.initVar('_editorChannelId', _common.default.createGuid());
            this.initVar('_availabilityEditorChannelId', _common.default.createGuid());
        },
        didReceiveAttrs: function didReceiveAttrs() {
            this.set('_availabilityEditorChannelId', new Date().getTime());
            this.set('_editorChannelId', new Date().getTime());
        },


        actions: {
            edit: function edit() {
                this.get('eventbus').publish('mc-worker-profile/info/editor:on:open:' + this.get('_editorChannelId'), this.get('worker'));
            },
            editAvailability: function editAvailability() {
                this.get('eventbus').publish('mc-worker-profile/availability:on:open:' + this.get('_availabilityEditorChannelId'), this.get('worker'));
            }
        }
    });
});