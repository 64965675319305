define("mycare-ember/seekers/findhelp/profile/index/credit-card/route", ["exports", "mycare-ember/mixins/scroll_top", "mycare-ember/mixins/seeker_only", "mycare-ember/mixins/protected_route"], function (exports, _scroll_top, _seeker_only, _protected_route) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_protected_route.default, _scroll_top.default, _seeker_only.default, {

        beforeModel: function beforeModel(transition) {
            this._super();
            this.isAuthenticated(transition);
        },

        model: function model() {
            var currentPersonId = this.controllerFor('auth').get('currentPersonId');

            return Ember.RSVP.hash({
                account: this.store.findRecord('person', currentPersonId).then(function (person) {
                    return Ember.RSVP.resolve(person.get('accounts')).then(function (accounts) {
                        return accounts.filter(function (acc) {
                            return acc.get('accountType.id') !== '4';
                        }).objectAt(0);
                    });
                }),
                accountTypes: this.store.findAll('account-type')
            });
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model.account);
            controller.set('errors', []);
        },

        actions: {
            willTransition: function willTransition() {
                // this.controller.get('model.person').rollbackAttributes();
            }
        }

    });
});