define('mycare-ember/components/mc-validation/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _mcComponent.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.initVar('validation', undefined);
      this.initVar('id', undefined);
      this.initVar('noFormGroupClass', false);
    }
  });
});