define("mycare-ember/account/reset-password/controller", ["exports", "mycare-ember/mixins/password-checker", "mycare-ember/mixins/browser-detection", "mycare-ember/mixins/defer", "mycare-ember/mixins/nav-mode"], function (exports, _passwordChecker, _browserDetection, _defer, _navMode) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_passwordChecker.default, _defer.default, _browserDetection.default, _navMode.default, {
        errors: {},
        _showPassword: false,
        _isPasswordValid: false,
        isTokenInvalid: false,
        isLoading: false,

        actions: {
            togglePassword: function togglePassword() {
                this.toggleProperty('_showPassword');
            },
            onPasswordChange: function onPasswordChange(isValid) {
                this.set('_isPasswordValid', isValid);
            },


            submit: function submit() {
                var self = this;
                self.set("isLoading", true);
                self.set("isSuccess", false);
                this.set('errors', {});
                if (!Ember.isEmpty(self.get("newPassword"))) {
                    if (self.isPasswordStrong()) {
                        self.getDeferWithToken();
                        var resetObject = {
                            resetPassword: {
                                email: self.get("resetAccountInfo.email"),
                                code: self.get("resetAccountInfo.code"),
                                password: self.get("newPassword")
                            }
                        };
                        resetObject = JSON.stringify(resetObject);
                        self.set('isTokenInvalid', false);
                        Ember.$.post(this.get('env').getApp('API_HOST') + '/api/logins/resetPassword', resetObject).then(function () {
                            self.set("isSuccess", true);
                            self.get('session.loginAsync').perform({
                                email: self.get("resetAccountInfo.email"),
                                password: self.get("newPassword")
                            }).then(function () {
                                self.resetForm();
                                self.transitionToRoute('/');
                                self.set("isLoading", false);
                            });
                        }, function () {
                            self.set('isTokenInvalid', true);
                            self.set("isLoading", false);
                            self.set('errors.invalid', 'Could not reset password.');
                        }).finally(function () {
                            self.set("isLoading", false);
                        });
                    }
                } else {
                    self.set('errors.confirmPassword', 'Passwords does not match, please confirm your password.');
                }
            },

            setAccountInfo: function setAccountInfo(resetAccountInfo) {
                var self = this;
                self.set("resetAccountInfo", resetAccountInfo);
            }
        },

        resetForm: function resetForm() {
            var self = this;
            self.set("newPassword", "");
        },

        isPasswordStrong: function isPasswordStrong() {
            var self = this;
            if (self.passwordStrengthTest(self.get("newPassword"))) {
                return true;
            } else {
                self.set('errors.newPassword', "Password must contain at least 1 uppercase character, contain at least 1 number and at least 6 characters.");
                return false;
            }
        }
    });
});