define('mycare-ember/register/seeker-register/who/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    actions: {
      onWhoSelected: function onWhoSelected(_ref) {
        var chosen = _ref.chosen;
        //jshint ignore:line
        this.set('chosen', chosen);

        //trigger next step manually, next is handled by this route and will call onNext() on this controller
        this.send('next');
      }
    },

    onNext: function onNext() {
      this.set('wizardData.signingFor', this.get('chosen'));
    }
  });
});