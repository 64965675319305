define("mycare-ember/components/mc-multiselect-checkboxes/component", ["exports", "mycare-ember/utils/function-helpers", "mycare-ember/utils/mc-component"], function (exports, _functionHelpers, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('options', null);
            this.initVar('intialValues', []);
            this.initVar('onSelected', null);
            this.initVar('actionParam', null);

            if (!Ember.isEmpty(this.get('options'))) {
                this._setupFormData();
            }
        },


        _reload: function () {
            this._setupFormData();
        }.observes('options.[]', 'intialValues.[]'),

        _setupFormData: function () {
            var initialValues = this.get('initialValues') || Ember.A();
            var hasInitialValue = function hasInitialValue(item) {
                return !Ember.isNone(initialValues.find(function (initItem) {
                    return initItem.get('id') === item.get('id');
                }));
            };
            this.get('options').forEach(function (item) {
                item.set('isChecked', hasInitialValue(item));
            });
        }.observes('initialValues.[]'),

        actions: {
            onSelected: function onSelected(item) {
                item.toggleProperty('isChecked');
                var selectedItems = this.get('options').filter(function (item) {
                    return item.get('isChecked') === true;
                });
                _functionHelpers.default.callOrIgnore(this.get('onSelected'), this.get('actionParam'), selectedItems);
            }
        }
    });
});