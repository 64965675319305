define("mycare-ember/components/job-picker/component", ["exports", "mycare-ember/utils/mc-component"], function (exports, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({

        /**
         * Setup component at startup.
         */
        didInsertElement: function didInsertElement() {
            Ember.run.scheduleOnce('afterRender', this, this.setup);
        },

        setup: function setup() {
            var self = this;
            var input = Ember.$('.cp-jobPicker-selector');
            input.change(function () {
                self.set('selectedValueId', this.value);
            });
        }
    });
});