define('mycare-ember/wizard/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        wizardService: Ember.inject.service('utils/wizard-sample'),

        actions: {
            onPrev: function onPrev() {
                this.get('wizardService.goPrevAsync').perform();
            },
            onNext: function onNext() {
                this.get('wizardService.goNextAsync').perform();
            }
        }
    });
});