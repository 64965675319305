define('mycare-ember/notifications/index/route', ['exports', 'mycare-ember/utils/mc-protected-route'], function (exports, _mcProtectedRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcProtectedRoute.default.extend({
        model: function model() {
            return this.store.findRecord('person', this.get('session.authData.loggedInUserId'));
        }
    });
});