define('mycare-ember/components/mc-thread/sidebar/people/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        _profileService: Ember.inject.service('profile'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('people', null);
            this.initVar('thread', null);
        },


        sortedPeople: function () {
            var _this = this;

            var me = this.get('people').find(function (p) {
                return p.get('id') === _this.get('session.authData.loggedInUserId');
            });
            var others = this.get('people').filter(function (p) {
                return p.get('id') !== _this.get('session.authData.loggedInUserId');
            });
            return [me].concat(others);
        }.property('people.[]')
    });
});