define('mycare-ember/models/notification', ['exports', 'ember-data', 'mycare-ember/utils/common'], function (exports, _emberData, _common) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        person: _emberData.default.belongsTo('person', { async: true }),
        notificationUrl: _emberData.default.attr('string'),
        details: _emberData.default.attr('string'),
        isRead: _emberData.default.attr('boolean'),
        isViewed: _emberData.default.attr('boolean'),
        createdOn: _emberData.default.attr('string'),
        readOn: _emberData.default.attr('string'),
        notificationCategory: _emberData.default.attr('number'),

        icon: function () {
            switch (this.get('notificationCategory')) {
                case 1:
                    return 'fa-bullhorn';
                case 2:
                    return 'fa-calendar';
                case 3:
                    return 'fa-star';
                case 4:
                    return 'fa-credit-card';
                case 5:
                    return 'fa-file-text-o';
                default:
                    return 'fa-bullhorn';
            }
        }.property('notificationCategory'),

        createdOnFormattedDate: function () {
            return _common.default.formDateForMessageOrNotification(this.get('createdOn'));
        }.property('message.sentOn'),

        url: function () {
            var url = this.get('notificationUrl');
            url = url.replace('#', '');
            url = url.replace('http://mc-app-build.mycare.net.nz', '');
            url = url.replace('https://mc-app-build.mycare.net.nz', '');
            url = url.replace('http://app.mycare.co.nz', '');
            url = url.replace('https://app.mycare.co.nz', '');
            url = url.replace('http://mc-app-test.mycare.net.nz', '');
            url = url.replace('https://mc-app-test.mycare.net.nz', '');
            url = url.replace('http://localhost:4200', '');
            url = url.replace('//', '/');
            url = url.replace('findhelp/manage/agreements', 'findhelp/applicants/contract');
            return url;
        }.property('notificationUrl')
    });
});