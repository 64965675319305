define('mycare-ember/carers/manage/my-schedule/date/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ['backTo', 'backToOpts', 'date'],
        date: null,
        backTo: null,
        backToOpts: null,

        actions: {
            onNavback: function onNavback() {
                if (!Ember.isNone(this.get('backTo'))) {
                    this.transitionToRoute(this.get('backTo'));
                } else {
                    this.transitionToRoute("carers.manage.calendar");
                }
            }
        }

    });
});