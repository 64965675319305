define("mycare-ember/components/mc-timesheet/incident/component", ["exports", "mycare-ember/utils/mc-component", "mycare-ember/utils/function-helpers"], function (exports, _mcComponent, _functionHelpers) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var NOTE = 1;
    var INCIDENT = 2;

    exports.default = _mcComponent.default.extend({

        timesheetService: Ember.inject.service('timesheet'),
        _referenceModelService: Ember.inject.service('reference-models'),
        _shouldDisplay: function () {
            return !(Ember.isEmpty(this.get('_notes')) && this.get('noteType') === INCIDENT);
        }.property('noteType', '_notes.[]'),

        _title: function () {
            return this.get('noteType') === NOTE ? 'Notes' : 'Incidents';
        }.property('noteType'),

        setNotes: function () {
            var _this = this;

            Ember.RSVP.hash({
                categories: this.get('_referenceModelService.getTimesheetNoteCategoryAsync').perform(),
                timesheetNotes: this.get('timesheet.notes')
            }).then(function (_ref) {
                var timesheetNotes = _ref.timesheetNotes;

                var notes = timesheetNotes.filter(function (n) {
                    return n.get('timesheetNoteCategory.isIncident') === true;
                });

                if (_this.isDestroyed) {
                    return;
                }
                _this.set('_notes', notes.sortBy('createdOn').reverse());
            });
        }.observes('noteType', 'timesheet.notes.[]'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('timesheet', undefined);
            this.initVar('isSeeker', undefined);
            this.initVar('loggedInUserId', undefined);
            this.initVar('onEditNote', undefined);
            this.initVar('onAddNote', undefined);
            this.initVar('noteType', undefined);
            this.initVar('_isBeyondScheduled', undefined);
            this.initVar('_isApproveOrPaid', undefined);
            this.initVar('loggedInUserId', this.get('session.authData.loggedInUserId'));
        },
        willSetupOnce: function willSetupOnce() {
            this.setIsBeyondScheduled();
            this.setNotes();
        },


        setIsBeyondScheduled: function setIsBeyondScheduled() {
            var _this2 = this;

            this.get('timesheetService.isBeyondScheduledAsync').perform({ timesheetId: this.get('timesheet.id') }).then(function (result) {
                return _this2.set('_isBeyondScheduled', result);
            });

            this.get('timesheetService.isApproveOrPaidAsync').perform({ timesheetId: this.get('timesheet.id') }).then(function (result) {
                return _this2.set('_isApproveOrPaid', result);
            });
        },

        actions: {
            addNote: function addNote() {
                _functionHelpers.default.callOrIgnore(this.get('onAddNote'), this.get('noteType'));
            }
        }
    });
});