define('mycare-ember/components/mc-timerange-input/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/function-helpers', 'mycare-ember/utils/common'], function (exports, _mcComponent, _functionHelpers, _common) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);

            this.initVar('onSelected', undefined);
            this.initVar('timePickerFrom', _common.default.createGuid());
            this.initVar('timePickerTo', _common.default.createGuid());

            var now = moment().set({
                minute: Math.ceil(moment().minute() / 5) * 5
            });
            this.initVar('initialStartTime', now);

            this.initVar('initialEndTime', now.clone().add(1, 'hour'));

            this.initVar('startTimeLabel', 'From');
            this.initVar('endTimeLabel', 'To');

            var nowString = this.get('initialStartTime').format('hh:mm A');
            this.set('initialStartTimeString', nowString);
            var endString = this.get('initialEndTime').format('hh:mm A');
            this.set('initialEndTimeString', endString);

            this.set('_selectedStartTime', this.get('initialStartTime'));
            this.set('_selectedEndTime', this.get('initialEndTime'));
            this.set('_timegap', 60);
        },
        updateData: function updateData(_ref) {
            var startTime = _ref.startTime,
                endTime = _ref.endTime;

            _functionHelpers.default.callOrIgnore(this.get('onSelected'), {
                startTime: startTime, endTime: endTime
            });
        },
        didInsertElement: function didInsertElement() {
            var self = this;
            document.getElementById(this.get('timePickerFrom')).value = this.get('_selectedStartTime').format('HH:mm');
            document.getElementById(this.get('timePickerFrom')).addEventListener('change', function (e) {
                self.onStartTimeSelected(e.target.value);
            });

            document.getElementById(this.get('timePickerTo')).value = this.get('_selectedEndTime').format('HH:mm');
            document.getElementById(this.get('timePickerTo')).addEventListener('change', function (e) {
                self.onEndTimeSelected(e.target.value);
            });
        },
        onStartTimeSelected: function onStartTimeSelected(date) {
            var startTime = moment(date, 'HH:mm');
            this.set('_selectedStartTime', startTime);
            if (this.get('_timegap') > 0) {
                if (startTime.hours() === 23 && startTime.minutes() === 0) {
                    document.getElementById(this.get('timePickerTo')).value = startTime.clone().subtract(1, 'day').add(this.get('_timegap'), 'minutes').format('HH:mm');
                    this.set('_selectedEndTime', startTime.clone().subtract(1, 'day').add(this.get('_timegap'), 'minutes'));
                } else {
                    document.getElementById(this.get('timePickerTo')).value = startTime.clone().add(this.get('_timegap'), 'minutes').format('HH:mm');
                    this.set('_selectedEndTime', startTime.clone().add(this.get('_timegap'), 'minutes'));
                }
            }
            this.updateData({
                startTime: startTime,
                endTime: this.get('_selectedEndTime')
            });
        },
        onEndTimeSelected: function onEndTimeSelected(date) {
            var endTime = moment(date, 'h:mm A');
            this.set('_selectedEndTime', endTime);
            var timegap = endTime.diff(this.get('_selectedStartTime'), 'minutes');
            this.updateData({
                startTime: this.get('_selectedStartTime'),
                endTime: this.get('_selectedEndTime')
            });
        }
    });
});