define('mycare-ember/utils/mc-route', ['exports', 'mycare-ember/utils/function-helpers', 'mycare-ember/mixins/utils-mixin', 'mycare-ember/mixins/scroll_top'], function (exports, _functionHelpers, _utilsMixin, _scroll_top) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_utilsMixin.default, _scroll_top.default, {
        _subscriptions: [],

        beforeModel: function beforeModel() {
            this._super.apply(this, arguments);
            Ember.$("body").removeClass();
            Ember.$("body").addClass("fast-scroll ember-application");
            var shouldContiue = true;
            if (this.isAuthenticated) {
                shouldContiue = this.isAuthenticated();
            }
            if (shouldContiue) {
                var asyncMethod = this.get('beforeModelAsync');
                if (asyncMethod) {
                    return asyncMethod.perform.apply(asyncMethod, arguments);
                }
            }
        },
        model: function model() {
            this._super.apply(this, arguments);
            var asyncMethod = this.get('modelAsync');
            if (asyncMethod) {
                return asyncMethod.perform.apply(asyncMethod, arguments);
            }
        },
        afterModel: function afterModel() {
            this._super.apply(this, arguments);
            var asyncMethod = this.get('afterModelAsync');
            if (asyncMethod) {
                return asyncMethod.perform.apply(asyncMethod, arguments);
            }
        },
        setupController: function setupController(controller) {
            this._super.apply(this, arguments);
            Ember.$('.light.wootric-bottom').attr('style', 'display: none !important');
            this.scrollTop();
            var asyncMethod = this.get('setupControllerAsync');
            if (asyncMethod) {
                return asyncMethod.perform.apply(asyncMethod, arguments).then(function () {
                    _functionHelpers.default.callOrIgnoreWithContext(controller.setup, controller);
                });
            } else {
                _functionHelpers.default.callOrIgnoreWithContext(controller.setup, controller);
            }
        },
        createSubscriptions: function createSubscriptions(subscriptions) {
            var _this = this;

            var self = this;
            subscriptions.forEach(function (_ref) {
                var publisher = _ref.publisher,
                    key = _ref.key,
                    callable = _ref.callable;

                publisher.off(publisher.get(key), self, callable);
                publisher.on(publisher.get(key), self, callable);
                _this.get('_subscriptions').concat(subscriptions);
            });
        },
        removeSubscriptions: function removeSubscriptions() {
            var _this2 = this;

            this.get('_subscriptions').forEach(function (_ref2) {
                var publisher = _ref2.publisher,
                    key = _ref2.key,
                    callable = _ref2.callable;

                publisher.off(key, _this2, callable);
            });
        }
    });
});