define('mycare-ember/mixins/protected_route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({

        redirectService: Ember.inject.service('utils/redirect'),
        eventbus: Ember.inject.service('utils/eventbus'),

        beforeModel: function beforeModel(transition) {
            this._super();
            this.isAuthenticated(transition);
        },

        isAuthenticated: function isAuthenticated(transition) {
            var _this = this;

            var self = this;
            var isAuthenticated = this.get('session.authData.isAuthenticated');
            var redirectService = this.get('redirectService');
            if (isAuthenticated) {
                self.store.findRecord('person', this.get('session.authData.loggedInUserId')).then(function (person) {
                    _this.get('eventbus').publish('global:event:isAuthenticated', person);
                });
            }
            if (!isAuthenticated && (transition || this.routeName)) {
                redirectService.clearAll();
                redirectService.store(this.get('env').getApp('REDIRECT_BEFORELOGIN_KEY'), transition || this.routeName);
                self.transitionTo('index');
            } else if (!this.get('session.authData.currentPersonId')) {
                if (transition && !transition.targetName.match(/me.profiles/) && !transition.targetName.match(/me.account/)) {
                    redirectService.store(this.get('env').getApp('REDIRECT_PROFILE_KEY'), transition);
                }
                self.transitionTo('me.profiles');
            } else {
                if (transition && !transition.targetName.match(/me.profiles/) && !transition.targetName.match(/me.account/)) {
                    redirectService.store(this.get('env').getApp('REDIRECT_PROFILE_KEY'), transition);
                }
            }
            return isAuthenticated;
        },

        actions: {
            willTransition: function willTransition(transition) {
                this._super.apply(this, arguments);
                var self = this;
                var authController = self.controllerFor('auth');
                if (!this.get('session.authData.isAuthenticated') && transition.routeName !== 'index') {
                    authController.set('previousTransition', transition);
                    self.transitionTo('index');
                }
            }
        }
    });
});