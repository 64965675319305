define('mycare-ember/account/confirm-email/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        beforeModel: function beforeModel(transition) {
            var controller = this.controllerFor('account/confirm-email');
            var email = transition.queryParams.email;
            var code = transition.queryParams.code;
            if (code && email) {
                return controller.get('_confirmEmailAsync').perform({ email: email, code: code });
            } else {
                controller.set('_isSuccess', false);
                controller.set('_isLoading', false);
            }
        }
    });
});