define('mycare-ember/controllers/partials/manage/payment-made-partial', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        _accountService: Ember.inject.service('account'),
        isPayment: false,
        setup: function () {

            this.checkPaymentType();
        }.on('init'),

        checkPaymentType: function checkPaymentType() {
            var _this = this;

            this.get('_accountService.getTransactionTypeByIdAsync').perform({ id: 3 }).then(function (transactionType) {
                Ember.RSVP.resolve(_this.get('model.transactionType')).then(function (t) {
                    var bool = t.get('name').toLowerCase() === transactionType.get('name').toLowerCase();
                    _this.set('isPayment', bool);
                });
            });
        }
    });
});