define("mycare-ember/services/utils/facebook", ["exports", "mycare-ember/config/environment"], function (exports, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var computed = Ember.computed;
    var RSVP = Ember.RSVP;

    var _facebookSDKDeferrable = RSVP.defer();

    var fbAsyncInit = function fbAsyncInit() {
        _initFacebook(window.FB);
        _facebookSDKDeferrable.resolve(window.FB);
    };

    window.fbAsyncInit = fbAsyncInit;

    exports.default = Ember.Service.extend({
        SDK: computed.alias('FB'),
        FB: computed(function () {
            _loadFacebookSDK();
            return _facebookSDKDeferrable.promise;
        }),

        init: function init() {
            this._super.apply(this, arguments);
            _loadFacebookSDK();
        }
    });


    function _loadFacebookSDK() {
        (function (d, s, id) {
            var js;
            var fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
    }

    function _initFacebook(FB) {
        FB.init({
            appId: _environment.default.APP.FB_APP_ID,
            status: true,
            cookie: true,
            xfbml: true,
            version: _environment.default.APP.GRAPH_API_VERSION
        });
    }
});