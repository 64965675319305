define("mycare-ember/components/mc-nav/authenticated/pushy/component", ["exports", "mycare-ember/utils/mc-component"], function (exports, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({

        livechat: Ember.inject.service('utils/livechat'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('currentPerson', null);
            this.initVar('isSeeker', false);
            this.initVar('isPushyOpened', false);
        },


        actions: {
            closePushy: function closePushy() {
                this.set('isPushyOpened', false);
                Ember.$('#body').removeClass('pushy-active');
            },
            redirectTo: function redirectTo(route) {
                this.send('closePushy');
                this.get('eventbus').publish('global:on:changeRoute', route);
            },


            toggleFeedbackDialog: function toggleFeedbackDialog() {
                this.send('closePushy');
                this.get('livechat').openLivechat();
            },

            editProfile: function editProfile() {
                this.send('closePushy');
                if (this.get('isSeeker')) {
                    this.get('eventbus').publish('global:on:changeRoute', 'seekers.findhelp.profile.index.edit', this.get('currentPersoncurrentPerson.id'));
                } else {
                    this.get('eventbus').publish('global:on:changeRoute', 'carers.findwork.profile');
                }
            }
        }
    });
});