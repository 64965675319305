define('mycare-ember/mixins/math-number-format', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    getRound: function getRound(num, len) {
      if (!len) {
        len = 0;
      }
      return num.toFixed(len);
    }
  });
});