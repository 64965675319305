define('mycare-ember/controllers/partials/manage/index/timesheet-worker-index-view', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend( /*timesheetStatusMixin, timesheetWorkerManageActionMixin,*/{
        auth: Ember.inject.controller('auth'),
        // EntryEditController: Ember.inject.controller('partials/manage/timesheet/modal-timesheet-edit'),
        timesheetSubmitController: Ember.inject.controller('partials/manage/timesheet/modal-timesheet-submit'),

        setup: function () {
            var self = this;
            self.watchEntryStatus();
            self.watchStatuses();
        }.on('init')

    });
});