define("mycare-ember/seekers/findhelp/dss-form/route", ["exports", "mycare-ember/mixins/seeker_only", "mycare-ember/mixins/protected_route", "mycare-ember/mixins/scroll_top"], function (exports, _seeker_only, _protected_route, _scroll_top) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_scroll_top.default, _protected_route.default, _seeker_only.default, {
        beforeModel: function beforeModel(transition) {
            this._super();
            this.isAuthenticated(transition);
        },
        model: function model() {
            var currentPersonId = this.controllerFor('auth').get('currentPersonId');
            return this.store.findRecord('person', currentPersonId);
        },

        setupController: function setupController(controller, model) {
            this.scrollTop();
            controller.set('model', model);
            controller.setup();
        }
    });
});