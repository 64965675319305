define('mycare-ember/models/activity-type', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        name: _emberData.default.attr('string'),
        createdPerson: _emberData.default.belongsTo('person', { async: true }),
        isArchived: _emberData.default.attr('boolean'),
        sortOrder: _emberData.default.attr('number'),
        activityGroup: _emberData.default.belongsTo('activity-group', { async: true })
    });
});