define("mycare-ember/models/account", ["exports", "ember-data", "ember-concurrency"], function (exports, _emberData, _emberConcurrency) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        person: _emberData.default.belongsTo('person', { async: true }),
        bankAccounts: _emberData.default.hasMany('bank-account', { async: true }),
        preferredBankAccount: _emberData.default.belongsTo('bank-account', { async: true, inverse: 'preferredBy' }),
        accountBalance: _emberData.default.attr('number'),
        pendingBalance: _emberData.default.attr('number'),
        lifetimeEarnings: _emberData.default.attr('number'),
        hoursPurchased: _emberData.default.attr('number'),
        accountType: _emberData.default.belongsTo('account-type', { async: true }),
        accountTypeName: _emberData.default.attr('string'),
        transactionItems: _emberData.default.attr('string'),
        creditCards: _emberData.default.hasMany('credit-card', { async: true }),
        preferredCreditCard: _emberData.default.attr('string'),
        discountPeriodEndsOn: _emberData.default.attr('string'),
        discountPeriodStartsOn: _emberData.default.attr('string'),
        isGstRegistered: _emberData.default.attr('boolean'),
        irdNumber: _emberData.default.attr('string'),
        topupAmount: _emberData.default.attr('string'),
        updateBalance: _emberData.default.attr('number'),
        isInvoicable: _emberData.default.attr('boolean'),
        remainDiscountBalance: _emberData.default.attr('number'),
        totalVisitCost: _emberData.default.attr('number'),
        paidClientFees: _emberData.default.attr('number'),
        estPaidClientFees: _emberData.default.attr('number'),
        totalApprovedClientFees: _emberData.default.attr('number'),
        allowNegativeBalance: _emberData.default.attr('boolean'),
        negativeBalanceLimit: _emberData.default.attr('number'),

        availableBalance: function () {
            return this.get('accountBalance') - this.get('pendingBalance');
        }.property('accountBalance', 'pendingBalance'),

        clientFeeRate: function () {
            return this.get('accountType.clientServiceFee');
        }.property('accountType'),

        savingsBalance: function () {
            return -(this.get('remainDiscountBalance') * this.get('clientFeeRate'));
        }.property('remainDiscountBalance'),

        paidDiscountBalance: function () {
            return (this.get('accountType.bookingThreshold') - this.get('remainDiscountBalance')) * this.get('clientFeeRate');
        }.property('remainDiscountBalance'),

        paidServiceFee: function () {
            return this.get('paidClientFees') > this.get('accountType.bookingThreshold') ? this.get('accountType.bookingThreshold') : this.get('paidClientFees');
        }.property('paidClientFees', 'accountType.bookingThreshold'),

        savedServiceFee: function () {
            return this.get('totalApprovedClientFees') - this.get('accountType.bookingThreshold') > 0 ? this.get('totalApprovedClientFees') - this.get('accountType.bookingThreshold') : 0;
        }.property('totalApprovedClientFees')
    });
});