define('mycare-ember/mixins/activity-type-create-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    auth: Ember.inject.controller('auth'),

    setupActivities: function setupActivities() {
      var self = this;
      self.store.query('activity-group', { personId: self.get('auth.profileId') }, { reload: true }).then(function (activityGroup) {
        activityGroup.forEach(function (ag) {
          ag.get('activityTypes').forEach(function (activityType) {
            activityType.set('isChecked', false);
          });
        });
        var CustomGroup = activityGroup.findBy('name', 'My activities');
        var tempActicityGroup = activityGroup.toArray();
        tempActicityGroup.removeObject(CustomGroup);
        CustomGroup.set('isCustomGroup', true);
        tempActicityGroup.insertAt(0, CustomGroup);

        var newSortedGroups = [];
        var tempGroup = [];
        tempActicityGroup.forEach(function (activityGroup) {
          tempGroup = [];
          tempGroup.pushObject(activityGroup);
          newSortedGroups.pushObject(tempGroup);
        });
        self.set('activityGroups', newSortedGroups);
      });
    },

    actions: {
      createActivityType: function createActivityType() {
        var self = this;
        var name = self.get('activityString');
        self.get('errors').clear();
        if (name === "" || Ember.isEmpty(name)) {
          self.get('errors').pushObject('Please give a name for this activity.');
          return;
        }

        self.store.findRecord('person', self.get('auth.profileId')).then(function (person) {
          var activityType = self.store.createRecord('activity-type', {
            name: name,
            createdPerson: person
          });
          activityType.save().then(function (activityType) {
            activityType.set('isChecked', true);
            activityType.set('isComplete', false);
            self.store.findRecord('activity-group', 1).then(function (activityGroup) {
              activityGroup.get('activityTypes').pushObject(activityType);
              Ember.run.later(function () {
                var targetComponent = Ember.$('#' + self.get('activityGroupDomId') + activityGroup.get('id'));
                if (targetComponent.hasClass('in') === false) {
                  targetComponent.addClass('in');
                }
              }, 100);
            });
          });
        });

        this.set('activityString', '');
      }
    }
  });
});