define("mycare-ember/components/mc-joblisting-card/favourite/component", ["exports", "mycare-ember/utils/mc-component", "mycare-ember/utils/function-helpers"], function (exports, _mcComponent, _functionHelpers) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        _profileService: Ember.inject.service('profile'),
        _jobListingService: Ember.inject.service('job-listing'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('jobListing', undefined);
            this.initVar('favouritePersonId', undefined);
            this.initVar('onRemoved', undefined);
        },


        actions: {
            toggleFavourite: function toggleFavourite() {
                var _this = this;

                // Eargely highlight the icon
                return this.get('_profileService.addOrRemoveFavouriteJobListingAsync').perform({
                    personId: this.get('favouritePersonId'),
                    jobListingId: this.get('jobListing.jobListingId')
                }).then(function (result) {
                    _this.set('jobListing.isFavourite', result);
                    _functionHelpers.default.callOrIgnore(_this.get('onRemoved'), _this.get('jobListing.jobListingId'));
                });
            },
            goToJob: function goToJob() {
                var _this2 = this;

                this.get('_jobListingService.findByIdAsync').perform({ id: this.get('jobListing.jobListingId') }).then(function () {
                    _this2.get('eventbus').publish('global:on:changeRoute', 'browse.jobs.index.detail', _this2.get('jobListing.jobListingId'), {
                        queryParams: {
                            backTo: true
                        }
                    });
                });
            }
        }
    });
});