define("mycare-ember/components/mc-timesheet/notes/incident/component", ["exports", "mycare-ember/utils/mc-component", "mycare-ember/utils/function-helpers"], function (exports, _mcComponent, _functionHelpers) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('note', undefined);
            this.initVar('noteType', 1);
            this.initVar('isSeeker', undefined);
            this.initVar('onEditNote', undefined);
            this.initVar('showActions', undefined);
        },


        actions: {
            editNote: function editNote() {
                _functionHelpers.default.callOrIgnore(this.get('onEditNote'), this.get('note'));
            },
            deleteNote: function deleteNote() {
                this.get('note').deleteRecord();
                this.get('note').save();
            }
        }
    });
});