define('mycare-ember/models/experience', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        title: _emberData.default.attr('string'),
        organisation: _emberData.default.attr('string'),
        startedOn: _emberData.default.attr('string'),
        endedOn: _emberData.default.attr('string'),
        description: _emberData.default.attr('string'),
        person: _emberData.default.belongsTo('person')
    });
});