define('mycare-ember/components/mc-remove-visit-form/component', ['exports', 'mycare-ember/utils/common', 'mycare-ember/mixins/validator', 'mycare-ember/utils/Validators', 'mycare-ember/utils/mc-modal-component'], function (exports, _common, _validator, _Validators, _mcModalComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend(_validator.default, {
        timesheetService: Ember.inject.service('timesheet'),
        _modalHelper: Ember.inject.service('utils/modal-helper'),

        init: function init() {
            this.set('_componentId', 'mc-remove-visit-form');
            this._super.apply(this, arguments);
            this.initVar('channelId', undefined);
            this.initVar('timesheet', undefined);
            this.initVar('_isLoading', false);
            this.initVar('_modalChannelId', _common.default.createGuid());
            this.initVar('errors', {});
            this.initVar('_formData', {
                reason: undefined
            });
            this.initVar('channelId', 'global');
            this._setupValidations();
        },
        _setupValidations: function _setupValidations() {
            this._resetValidation();
            this.addValidation('_formData.reason', 'reason', 'This field', [_Validators.default.isRequired]);
        },
        _onBeforeOpen: function _onBeforeOpen(_ref) {
            var timesheet = _ref.timesheet;

            this.set('_formData', {
                reason: undefined
            });
            this._setupValidations();
            this.set('timesheet', timesheet);
        },


        actions: {
            saveAndSubmit: function saveAndSubmit() {
                if (!this._validate()) {
                    return;
                }
                this.get('timesheetService.removeVisitAsync').perform({
                    loggedInUserId: this.get('session.authData.loggedInUserId'),
                    timesheetId: this.get('timesheet.id'),
                    reason: this.get('_formData.reason')
                });
                this.set('_isLoading', false);
                this.get('eventbus').publish('mc-remove-visit-form:event:removed:' + this.get('channelId'), {
                    timesheet: this.get('timesheet')
                });
                this._close();
            }
        }

    });
});