define('mycare-ember/models/profile', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    availabilities: _emberData.default.hasMany('availability', { async: true }),
    title: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    interests: _emberData.default.attr('string'),
    numberOfVerifications: _emberData.default.attr('number'),
    avatar: _emberData.default.belongsTo('image', { async: true }),
    email: _emberData.default.attr('string'),
    organisationName: _emberData.default.attr('string'),
    shortDescription: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    createdOn: _emberData.default.attr('string'),
    modifiedOn: _emberData.default.attr('string'),
    availability: _emberData.default.attr('string'),
    location: _emberData.default.attr('string'),
    gender: _emberData.default.attr('string'),
    latitude: _emberData.default.attr('string'),
    longitude: _emberData.default.attr('string'),
    respondRatio: _emberData.default.attr('number'),
    numberOfHires: _emberData.default.attr('number'),
    hourlyRates: _emberData.default.hasMany('hourly-rate', { async: true }),
    employmentTypes: _emberData.default.hasMany('employment-type', { async: true }),
    videos: _emberData.default.hasMany('video', { async: true }),
    images: _emberData.default.hasMany('image', { async: true }),
    recommendations: _emberData.default.hasMany('recommendation', { async: true }),
    experiences: _emberData.default.hasMany('experience', { async: true }),
    languages: _emberData.default.hasMany('language', { async: true }),
    qualifications: _emberData.default.hasMany('qualification', { async: true }),
    requirements: _emberData.default.hasMany('requirement', { async: true }),
    services: _emberData.default.hasMany('service', { async: true }),
    equipment: _emberData.default.hasMany('equipment', { async: true }),
    isPhoneNumberVerified: _emberData.default.attr('boolean'),
    isEmailVerified: _emberData.default.attr('boolean'),
    isAddressVerified: _emberData.default.attr('boolean'),
    trusts: _emberData.default.hasMany('trust', { async: true }),
    review: _emberData.default.belongsTo('review', { async: true }),
    isCovidVaccinationVerified: _emberData.default.attr('boolean')
  });
});