define('mycare-ember/seekers/my-account/account-detail/route', ['exports', 'mycare-ember/utils/mc-protected-route'], function (exports, _mcProtectedRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcProtectedRoute.default.extend({

        _accountService: Ember.inject.service('account'),

        model: function model(param) {
            return this.get('_accountService.getAccountByIdAsync').perform({ id: param.account_id, reload: true });
        }
    });
});