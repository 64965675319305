define('mycare-ember/wizard/step3/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        wizardService: Ember.inject.service('utils/wizard-sample'),

        init: function init() {
            this._super.apply(this, arguments);
        },
        setupController: function setupController() {
            this._super.apply(this, arguments);
            this.get('wizardService.goAsync').perform('3');
        }
    });
});