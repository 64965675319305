define("mycare-ember/helpers/single-or-plural", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function singleOrPlural(data) {
    var length = data[0];
    var single = data[1];
    var plural = data[2];
    return length === 1 ? single : plural;
  }

  exports.singleOrPlural = singleOrPlural;
  exports.default = Ember.Helper.helper(singleOrPlural);
});