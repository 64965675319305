define('mycare-ember/models/hourly-rate', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        title: _emberData.default.attr('string'),
        lowerValue: _emberData.default.attr('string'),
        upperValue: _emberData.default.attr('string'),
        name: function () {
            return this.get('title');
        }.property('title')
    });
});