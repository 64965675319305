define('mycare-ember/mixins/lineCharts/multi-line-chart-data', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        drawDataChart: function drawDataChart() {
            var self = this;
            var dataArray = [];
            var reportTypes = self.get('reportTypes');
            reportTypes.forEach(function (reportType) {
                self.getDataQuery(reportType).then(function (response) {
                    var rawData = response.objectAt(0);
                    dataArray.push({ label: rawData.get('reportType'), data: rawData.get('values').split(',') });
                    if (dataArray.length === reportTypes.length) {
                        self.setChart();
                        var myChart = self.setChart();
                        var colorScale = d3.scale.linear().domain([0, dataArray.length]).range([{ color: "#22a0c8", opacity: 0.2 }, { color: "#5c068b", opacity: 0.2 }]);
                        dataArray.forEach(function (dataObject, index) {
                            myChart.data.datasets.push({
                                label: dataObject.label,
                                data: dataObject.data,
                                border: 1,
                                backgroundColor: self.hexToRgb(colorScale(index).color, colorScale(index).opacity)
                            });
                        });
                        myChart.update();
                    }
                });
            });
        }
    });
});