define('mycare-ember/helpers/timesheet-time-display', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    function timesheetTimeDisplay(data) {
        var time = (data[0] / 60).toString();
        var timeArr = Ember.A(time.split('.'));
        if (timeArr.get('length') > 1) {
            if (parseInt(timeArr[0]) !== 0) {
                return Ember.String.htmlSafe(timeArr[0] + '<small>h</small> ' + (Number('0.' + timeArr[1]) * 60).toFixed(2).split('.')[0] + '<small>m</small>');
            }
            return Ember.String.htmlSafe((Number('0.' + timeArr[1]) * 60).toFixed(2).split('.')[0] + '<small>m</small>');
        } else {
            return Ember.String.htmlSafe(timeArr[0] + '<small>h</small>');
        }
    }

    exports.timesheetTimeDisplay = timesheetTimeDisplay;
    exports.default = Ember.Helper.helper(timesheetTimeDisplay);
});