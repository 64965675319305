define('mycare-ember/mixins/browser-detection', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    isIE: !!document.documentMode,
    documentMode: document.documentMode,

    currentUrl: function () {
      return window.location.href;
    }.property('window.location.href'),

    isInvalidBrowser: function () {
      var self = this;
      return self.get('isIE') && self.get('documentMode') < 11;
    }.property()
  });
});