define("mycare-ember/components/mc-dashboard/seeker/call-to-action/component", ["exports", "mycare-ember/utils/mc-component"], function (exports, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({

        _profileService: Ember.inject.service('profile'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('isActive', false);
            this.initVar('_searchLocation', undefined);
        },
        didReceiveAttrs: function didReceiveAttrs() {
            this._setIsActive();
            this._setSearchLocation();
        },


        _setSearchLocation: function () {
            var _this = this;

            if (Ember.isNone(this.get('session.authData.currentPersonId'))) return null;
            this.get('_profileService.getPersonByIdAsync').perform({ personId: this.get('session.authData.currentPersonId') }).then(function (person) {
                _this.set('searchLocation', person.get('location'));
            });
        }.observes('session.authData.currentPersonId'),

        _setIsActive: function _setIsActive() {
            this.set('isActive', Ember.isNone(localStorage.getItem("seeker-dashboard-calltoaction-active")));
        },

        actions: {
            never: function never() {
                localStorage.setItem("seeker-dashboard-calltoaction-active", "false");
                this._setIsActive();
            },
            later: function later() {
                this.set('isActive', false);
            }
        }
    });
});