define("mycare-ember/book/index/route", ["exports", "mycare-ember/utils/mc-route"], function (exports, _mcRoute) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcRoute.default.extend({
        beforeModel: function beforeModel() {
            this.transitionTo('book.mobile-nav');
        }
    });
});