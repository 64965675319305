define('mycare-ember/carers/my-account/account-detail/reports/route', ['exports', 'mycare-ember/mixins/protected_route', 'mycare-ember/mixins/scroll_top'], function (exports, _protected_route, _scroll_top) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_protected_route.default, _scroll_top.default, {
        beforeModel: function beforeModel(transition) {
            this.isAuthenticated(transition);
        },


        setupController: function setupController(controller, model) {
            controller.set('model', model);
            controller.setup();
        }
    });
});