define("mycare-ember/components/mc-worker-equipment-editor/component", ["exports", "mycare-ember/utils/mc-modal-component", "mycare-ember/utils/function-helpers"], function (exports, _mcModalComponent, _functionHelpers) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend({
        _referenceModelService: Ember.inject.service('reference-models'),

        init: function init() {
            var _this = this;

            //_componentId needs to be declared before super
            this.initVar('_componentId', 'mc-worker-equipment-editor');
            this._super.apply(this, arguments);
            this.initVar('channelId', undefined);
            this.initVar('worker', undefined);
            this.initVar('onSaved', undefined);
            this.initVar('_equipments', []);

            this.get('_referenceModelService.getEquipmentAsync').perform().then(function (_allEquipment) {
                var initialValues = _this.get('worker.equipment');
                var _equipments = _allEquipment.map(function (item) {
                    item.set('isChecked', (initialValues || []).includes(item));
                    return item;
                });
                _this.set('_equipments', _equipments);
            });
        },
        _getSelectedValues: function _getSelectedValues() {
            return this.get('_equipments').filter(function (item) {
                return item.get('isChecked') === true;
            });
        },


        actions: {
            onSave: function onSave() {
                var _this2 = this;

                var selectedValues = this._getSelectedValues();
                if (selectedValues.every(function (item) {
                    return _this2.get('worker.equipment').includes(item);
                })) {
                    this._close();
                    return;
                }
                _functionHelpers.default.callOrIgnore(this.get('onSaved'), selectedValues);
                this._close();
            }
        }
    });
});