define('mycare-ember/helpers/timesheet-time-display-hour', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    function timesheetTimeDisplay(data) {
        if (!data[0]) return null;
        var time = data[0].toString();
        var timeArr = Ember.A(time.split('.'));
        if (timeArr.get('length') > 1) {
            var hour = timeArr[0] + 'h';
            var minute = (Number('0.' + timeArr[1]) * 60).toFixed(2).split('.')[0] + 'm';
            if (parseInt(timeArr[0]) !== 0) {
                return hour + ' ' + minute;
            }
            return (Number('0.' + timeArr[1]) * 60).toFixed(2).split('.')[0] + 'm';
        } else {
            return timeArr[0] + 'h';
        }
    }

    exports.timesheetTimeDisplay = timesheetTimeDisplay;
    exports.default = Ember.Helper.helper(timesheetTimeDisplay);
});