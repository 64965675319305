define('mycare-ember/mixins/nps-mixin_route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({

        _profileService: Ember.inject.service('profile'),

        setupNPS: function setupNPS() {
            var self = this;
            Ember.RSVP.hash({
                loggedInUser: this.get('_profileService.getPersonByIdAsync').perform({
                    personId: this.get('session.authData.loggedInUserId')
                }),
                person: this.get('_profileService.getPersonByIdAsync').perform({
                    personId: this.get('session.authData.currentPersonId')
                })
            }).then(function (_ref) {
                var loggedInUser = _ref.loggedInUser,
                    person = _ref.person;

                var createdOn = Math.round(new Date(loggedInUser.get('createdOn')).getTime() / 1000);
                var email = loggedInUser.get('email');
                var location = loggedInUser.get('location');
                var subscription = 'Worker';

                if (!self.get('session.authData.isWorker')) {
                    subscription = person.get('subscriptionType');
                    location = person.get('location');
                }

                var locationArray = (location || "").split(', ');
                var length = locationArray.length;
                if (locationArray.length > 1) {
                    location = locationArray[length - 2] + ", " + locationArray[length - 1];
                } else {
                    location = locationArray[0];
                }

                window.wootricSettings = {
                    email: email,
                    created_at: createdOn,
                    account_token: self.get('env').getApp('NPS_TOKEN'),
                    properties: {
                        subscription: subscription,
                        location: location
                    }
                };

                /* jshint ignore:start */
                var i = new Image();
                i.src = "//d8myem934l1zi.cloudfront.net/pixel.gif?account_token=" + window.wootricSettings.account_token + "&email=" + encodeURIComponent(window.wootricSettings.email) + "&created_at=" + window.wootricSettings.created_at + "&url=" + encodeURIComponent(window.location) + "&random=" + Math.random();
                window.lightningjs || function (c) {
                    function g(b, d) {
                        d && (d += (/\?/.test(d) ? "&" : "?") + "lv=1");
                        c[b] || function () {
                            var i = window,
                                h = document,
                                j = b,
                                g = h.location.protocol,
                                l = "load",
                                k = 0;
                            (function () {
                                function b() {
                                    a.P(l);
                                    a.w = 1;
                                    c[j]("_load");
                                }

                                c[j] = function () {
                                    function m() {
                                        m.id = e;
                                        return c[j].apply(m, arguments);
                                    }

                                    var b;
                                    var e = ++k;
                                    b = this && this !== i ? this.id || 0 : 0;
                                    (a.s = a.s || []).push([e, b, arguments]);
                                    m.then = function (b, c, h) {
                                        var d = a.fh[e] = a.fh[e] || [],
                                            j = a.eh[e] = a.eh[e] || [],
                                            f = a.ph[e] = a.ph[e] || [];
                                        b && d.push(b);
                                        c && j.push(c);
                                        h && f.push(h);
                                        return m;
                                    };
                                    return m;
                                };
                                var a = c[j]._ = {};
                                a.fh = {};
                                a.eh = {};
                                a.ph = {};
                                a.l = d ? d.replace(/^\/\//, (g === "https:" ? g : "http:") + "//") : d;
                                a.p = { 0: +new Date() };
                                a.P = function (b) {
                                    a.p[b] = new Date() - a.p[0];
                                };
                                a.w && b();
                                i.addEventListener ? i.addEventListener(l, b, !1) : i.attachEvent("on" + l, b);
                                var q = function q() {
                                    function b() {
                                        return ["<head></head><", c, ' onload="var d=', n, ";d.getElementsByTagName('head')[0].", d, "(d.", g, "('script')).", i, "='", a.l, "'\"></", c, ">"].join("");
                                    }

                                    var c = "body";
                                    var e = h[c];
                                    if (!e) return setTimeout(q, 100);
                                    a.P(1);
                                    var d = "appendChild",
                                        g = "createElement",
                                        i = "src",
                                        k = h[g]("div"),
                                        l = k[d](h[g]("div")),
                                        f = h[g]("iframe"),
                                        n = "document";
                                    var p;
                                    k.style.display = "none";
                                    e.insertBefore(k, e.firstChild).id = o + "-" + j;
                                    f.frameBorder = "0";
                                    f.id = o + "-frame-" + j;
                                    /MSIE[ ]+6/.test(navigator.userAgent) && (f[i] = "javascript:false");
                                    f.allowTransparency = "true";
                                    l[d](f);
                                    try {
                                        f.contentWindow[n].open();
                                    } catch (s) {
                                        //noinspection CommaExpressionJS
                                        a.domain = h.domain, p = "javascript:var d=" + n + ".open();d.domain='" + h.domain + "';", f[i] = p + "void(0);";
                                    }
                                    try {
                                        var r = f.contentWindow[n];
                                        r.write(b());
                                        r.close();
                                    } catch (t) {
                                        f[i] = p + 'd.write("' + b().replace(/"/g, String.fromCharCode(92) + '"') + '");d.close();';
                                    }
                                    a.P(2);
                                };
                                a.l && q();
                            })();
                        }();
                        c[b].lv = "1";
                        return c[b];
                    }

                    var o = "lightningjs",
                        k = window[o] = g(o);
                    k.require = g;
                    k.modules = c;
                }({});
                window.wootric = lightningjs.require("wootric", "//d27j601g4x0gd5.cloudfront.net/beacon.js");
                /* jshint ignore:end */

                if (window.wootric) {
                    self.run();
                }
            });
        },

        run: function run() {
            window.wootric_survey_immediately = this.get('env').getApp('IS_NPS_IMMEDIATELY'); // Shows survey immediately for testing purposes.
            window.wootric_no_surveyed_cookie = this.get('env').getApp('IS_NPS_IMMEDIATELY'); // Bypass cookie based throttle for testing purposes.
            window.wootric("run");
        }
    });
});