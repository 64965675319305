define("mycare-ember/helpers/to-lower-case", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function timeAgoInWords(data) {
    var string = data[0];
    if (string) {
      return string.toLowerCase();
    }
    return '';
  }

  exports.timeAgoInWords = timeAgoInWords;
  exports.default = Ember.Helper.helper(timeAgoInWords);
});