define('mycare-ember/services/utils/marker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    _eventbus: Ember.inject.service('utils/eventbus'),

    init: function init() {
      this._super.apply(this, arguments);

      //defined global method so that marker can this to change route
      window.transitionToRoute = function () {
        var _get;

        (_get = this.get('_eventbus')).publish.apply(_get, ['global:on:changeRoute'].concat(Array.prototype.slice.call(arguments)));
        return false;
      }.bind(this);
    },
    trimText: function trimText(description) {
      if (description !== undefined && description !== null) {
        var strLength = 75;
        if (description.length > strLength) {
          description = description.substring(0, strLength - 1) + "... ";
        }
        return description;
      } else {
        return '';
      }
    },
    createProfileMarker: function createProfileMarker(item) {
      var canView = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var onClick = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var color = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '#81368B';

      var services = '';
      if (item.get('services')) {
        services = item.get('services').join(', ');
      }
      var content = null;
      if (canView) {
        content = '\n          <div class="profile-info">\n            <div class="panel-header">\n              <h3>\n                <a onclick="transitionToRoute(\'browse.profiles.index.detail\', ' + item.get('id') + ', {queryParams: { backTo: true }})">\n                    ' + item.get('firstName') + '\n                </a>\n              </h3>\n              <p>\n                <small><i class="fa fa-map-marker"></i>&nbsp;' + item.get('location') + '</small>\n              </p>\n            </div>\n            <div class="panel-body">\n              <p>\n                ' + this.trimText(item.get('description')) + '\n                  <a onclick="transitionToRoute(\'browse.profiles.index.detail\', ' + item.get('id') + ', {queryParams: { backTo: true }})">\n                    more\n                </a>\n              </p>\n              <p>\n                <strong>Services:&nbsp;</strong> ' + services + '\n              </p>\n            </div>\n          </div>\n        ';
      } else {
        content = '\n          <div class="profile-info">\n            <div class="panel-header">\n              <h3>\n                ' + item.get('firstName') + '\n              </h3>\n              <p>\n                <small><i class="fa fa-map-marker"></i>&nbsp;' + item.get('location') + '</small>\n              </p>\n            </div>\n            <div class="panel-body">\n              <p>\n                ' + this.trimText(item.get('description')) + '\n              </p>\n              <p>\n                <strong>Services:&nbsp;</strong> ' + services + '\n              </p>\n            </div>\n          </div>\n        ';
      }
      return Ember.Object.create({
        id: item.get('id'),
        lat: item.get('latitude'),
        lng: item.get('longitude'),
        title: item.get('title'),
        content: content,
        icon: {
          path: "M0-165c-27.618 0-50 21.966-50 49.054C-50-88.849 0 0 0 0s50-88.849 50-115.946C50-143.034 27.605-165 0-165z",
          fillColor: '#ccaed0',
          fillOpacity: 1,
          strokeColor: color,
          strokeWeight: 1.5,
          scale: 1 / 5
        }
      });
    },
    createJobMarker: function createJobMarker(item) {
      var canView = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var color = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '#009BDE';

      var services = '';
      if (item.get('services')) {
        services = item.get('services').join(', ');
      }

      var content = null;
      if (canView) {
        content = '\n        <div class="job-listing-wrapper-small" style="max-width:300px">\n           <div class="job-listing-wrapper pink">\n            <div class="profile-info">\n              <div class="panel-header">\n                <h3><a onclick="transitionToRoute(\'browse.jobs.index.detail\', ' + item.get('id') + ', {queryParams: { backTo: true }})">' + item.get('title') + '</a></h3>\n                <p>\n                  <small><i class="fa fa-map-marker"></i>&nbsp;' + item.get('location') + '</small>\n                </p>\n              </div>\n              <div class="panel-body">\n                <p>\n                  ' + this.trimText(item.get('description')) + '\n                  <a onclick="transitionToRoute(\'browse.jobs.index.detail\', ' + item.get('id') + ', {queryParams: { backTo: true }})">more</a>\n                </p>\n                <p>\n                  <strong>Services:&nbsp;</strong> ' + services + '\n                </p>\n              </div>\n            </div>\n           </div>\n        </div>\n        ';
      } else {
        content = '\n        <div class="job-listing-wrapper-small" style="max-width:300px">\n           <div class="job-listing-wrapper pink">\n            <div class="profile-info">\n              <div class="panel-header">\n                <h3>' + item.get('title') + '</h3>\n                <p>\n                  <small><i class="fa fa-map-marker"></i>&nbsp;' + item.get('location') + '</small>\n                </p>\n              </div>\n              <div class="panel-body">\n                <p>\n                  ' + this.trimText(item.get('description')) + '\n                </p>\n                <p>\n                  <strong>Services:&nbsp;</strong> ' + services + '\n                </p>\n              </div>\n            </div>\n           </div>\n        </div>\n        ';
      }

      return Ember.Object.create({
        id: item.get('id'),
        lat: item.get('latitude'),
        lng: item.get('longitude'),
        title: item.get('title'),
        content: content,
        icon: {
          path: "M0-165c-27.618 0-50 21.966-50 49.054C-50-88.849 0 0 0 0s50-88.849 50-115.946C50-143.034 27.605-165 0-165z",
          fillColor: '#99d7f1',
          fillOpacity: 1,
          strokeColor: color,
          strokeWeight: 1.5,
          scale: 1 / 5
        }
      });
    }
  });
});