define("mycare-ember/controllers/partials/modal-contact-screened-worker", ["exports", "mycare-ember/mixins/message-sending"], function (exports, _messageSending) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_messageSending.default, {
    auth: Ember.inject.controller('auth'),
    isDSS: false,
    isCloseJobListing: false,
    isJobListingOpen: true,

    setMessage: function () {
      var self = this;
      var application = self.get('currentApplication');
      self.store.findRecord('person', self.get('auth.loggedInUserId')).then(function () {
        var text = "Thank you for your interest, I won't be proceeding with you for this job. I wish you all the best.";
        if (self.get('isHire')) {
          text = 'Thank you for applying for the job listing: ' + application.get('jobListing.title') + '. I would like to work with you.';
        }
        self.set('message', text);
      });
    }.observes('isHire'),

    setIsJobListingOpen: function () {
      var self = this;
      self.set('isJobListingOpen', false);
      var jobListing = self.get('currentApplication.jobListing');
      if (jobListing.get('closesOn') === null || new Date(jobListing.get('closesOn')) > new Date()) {
        self.set('isJobListingOpen', true);
      }
    }.observes('currentApplication.jobListing'),

    actions: {
      sendMessage: function sendMessage(type) {
        var self = this;
        self.set('isSend', true);
        self.store.findRecord('job-application-status-type', 6).then(function (status) {
          var application = self.get('currentApplication');
          application.set('jobApplicationStatus', status);
          application.save().then(function (application) {

            self.store.findRecord('person', self.get('auth.loggedInUserId')).then(function (sender) {
              var text = self.get('message');
              self.sendHireMessage(sender, text, application, type);
              self.set('isAssign', false);
              self.set('isSend', false);
              if (self.get('isHire')) {
                self.send('shouldCloseJobListing');
              } else {
                Ember.$('.modal').modal('hide');
              }
            });
          });
        });
      },

      undo: function undo() {
        var self = this;
        var application = self.get('currentApplication');
        self.store.findRecord('job-application-status-type', 2).then(function (status) {
          application.set('jobApplicationStatus', status);
          application.save().then(function () {
            Ember.$('.modal').modal('hide');
          });
        });
      },

      shouldCloseJobListing: function shouldCloseJobListing() {
        var self = this;
        Ember.$('.modal').modal('hide');
        if (self.get('isHire') && self.get('isJobListingOpen')) {
          self.set('showCloseJobListing', true);
          Ember.run.later(function () {
            Ember.$('#modal-assign-job').modal('show');
          }, 500);
        }
      },

      closeJobListing: function closeJobListing() {
        var self = this;
        self.set('isClosing', true);
        var date = new Date();
        self.get('currentApplication.jobListing').then(function (jobListing) {
          var newCloseTime = new Date(date.getTime() - 1000 * 60 * 60);
          jobListing.set('closesOn', moment.utc(newCloseTime).toISOString());
          jobListing.save().then(function () {
            Ember.$('.modal').modal('hide');
            self.set('showCloseJobListing', false);
            self.set('isClosing', false);
          }, function () {
            toastr.error('There was an error closing your job listing');
            self.set('isClosing', false);
          });
        });
      },

      leaveJobListingOpen: function leaveJobListingOpen() {
        var self = this;
        Ember.$('.modal').modal('hide');
        self.set('showCloseJobListing', false);
      }
    }
  });
});