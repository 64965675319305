define("mycare-ember/mixins/time-to-quarter", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    getQuarterTime: function getQuarterTime(time) {
      var intOfMinute = moment(time).minute();

      if (intOfMinute >= 45) {

        intOfMinute = 45;
      } else if (intOfMinute >= 30) {

        intOfMinute = 30;
      } else if (intOfMinute >= 15) {

        intOfMinute = 15;
      } else {
        intOfMinute = 0;
      }
      return moment(time).minute(intOfMinute);
    }
  });
});