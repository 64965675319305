define('mycare-ember/mixins/utc-formator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    strEndsWith: function strEndsWith(str, suffix) {
      var lastChar = str[str.length - 1];
      return lastChar === suffix;
    },
    manualUtcFormat: function manualUtcFormat(str) {
      if (!this.strEndsWith(str, 'Z')) {
        str = str + 'Z';
      }
      return str;
    }
  });
});