define('mycare-ember/mixins/switch-profile', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({

        redirectService: Ember.inject.service('utils/redirect'),
        eventbus: Ember.inject.service('utils/eventbus'),

        setSwitchProfile: function setSwitchProfile(type, personId, personName) {
            var self = this;
            self.get('session').get('swithProfileAsync').perform({ type: type, personId: personId, personName: personName }).then(function () {
                self.store.findRecord('person', personId).then(function (person) {
                    return self.get('eventbus').publish('global:event:profileSwitched', { person: person, profileType: type });
                });
            });
            /**
             * When switching to worker mode, the url needs to be recorded so that
             * when user try to visit to seeker url without personId, the profile page can redirect back to
             * the intented seeker url after profile is selected.
             * We need to disable recording while in seeker mode to prevent from redirect after normal profile selection
             * Remove this code, when such redirect is desired.
             */
            if (type.toLowerCase() === 'worker') {
                this.get('redirectService').set('isListening', true);
            } else {
                this.get('redirectService').set('isListening', false);
            }
        },

        _fallbackRedirect: function _fallbackRedirect(isSeeker, redirectKeyOrRouteName) {
            this.transitionTo(redirectKeyOrRouteName || (isSeeker ? 'seekers.findhelp.dashboard' : 'carers.findwork.dashboard'));
        },


        transitionToSelectRoute: function transitionToSelectRoute(type, redirectKeyOrRouteName) {
            if (type == null) {
                type = window.location.href.match(/browse\/jobs/) ? "worker" : "organiser";
            }
            if (type.toLowerCase() === 'organiser') {
                var orgRouteName = this.get('redirectService').getRouteName(redirectKeyOrRouteName);
                if (orgRouteName) {
                    if (Ember.isNone(orgRouteName.match(/carers/)) && Ember.isNone(orgRouteName.match(/browse\.jobs/))) {
                        this.get('redirectService').consume(redirectKeyOrRouteName);
                    } else {
                        this._fallbackRedirect(true, null);
                    }
                } else {
                    this._fallbackRedirect(true, orgRouteName);
                }
            } else if (type.toLowerCase() === 'worker') {
                var workerRouteName = this.get('redirectService').getRouteName(redirectKeyOrRouteName);
                if (workerRouteName) {
                    if (Ember.isNone(workerRouteName.match(/seekers/)) && Ember.isNone(workerRouteName.match(/browse.profiles/))) {
                        this.get('redirectService').consume(redirectKeyOrRouteName);
                    } else {
                        this._fallbackRedirect(false, null);
                    }
                } else {
                    this._fallbackRedirect(false, workerRouteName);
                }
            }
        }
    });
});