define('mycare-ember/components/mc-timesheet/bottom-summary/worker/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({

        _timesheetService: Ember.inject.service('timesheet'),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);

            this.initVar('timesheet', undefined);
            this.initVar('backTo', undefined);
            this.initVar('isShowPriceSummary', undefined);
            this.set('_statusName', undefined);
            this.set('_disabledUpdate', false);

            this.createSubscriptions([{
                publisher: this.get('_timesheetService'),
                key: 'VISIT_REMOVING',
                callable: function callable(_ref) {
                    var timesheet = _ref.timesheet;

                    if (_this.get('timesheet.id') !== timesheet.get('id')) return;
                    _this.set('_statusName', 'removed');
                    _this.set('_disabledUpdate', true);
                }
            }, {
                publisher: this.get('_timesheetService'),
                key: 'VISIT_REMOVE_FAILED',
                callable: function callable(_ref2) {
                    var timesheet = _ref2.timesheet;

                    timesheet.rollbackAttributes();
                    _this._reloader({ timesheet: timesheet });
                    _this.watchCurrentStatus();
                }
            }, {
                publisher: this.get('_timesheetService'),
                key: 'VISIT_REMOVED',
                callable: this._reloader.bind(this)
            }]);
            this.watchCurrentStatus();
        },
        didReceiveAttrs: function didReceiveAttrs() {
            this.watchCurrentStatus();
        },


        didInsertElement: function didInsertElement() {
            Ember.$('body').css('height', window.innerHeight);
            Ember.$('body').css('minHeight', window.innerHeight);
        },

        _reloader: function _reloader(_ref3) {
            var _this2 = this;

            var timesheet = _ref3.timesheet;

            if (this.get('timesheet.id') !== timesheet.get('id')) return;
            Ember.RSVP.resolve(this.get('timesheet').reload()).catch(function (e) {
                var redirectRoute = _this2.get('backTo') || 'seekers.manage.schedule-workers.index.visits.all';
                console.error('Might be reloading deleted timesheets. Can ignore and page will be redirected to ' + redirectRoute, e);
                _this2.get('eventbus').publish('global:on:changeRoute', redirectRoute);
            });
            this.set('_disabledUpdate', false);
        },


        watchCurrentStatus: function () {
            if (this.get('timesheet.timesheetStatuses') && !this.get('_disabledUpdate')) {
                this.set('currentStatus', this.get('timesheet.timesheetStatuses').objectAt(0));
                this.set('_statusName', this.get('currentStatus.timesheetStatusType.name'));
            }
        }.observes('timesheet', 'timesheet.timesheetStatuses.[]'),

        actions: {
            onActionInit: function onActionInit(hasActions) {
                this.set('_hasActions', hasActions);
            }
        }
    });
});