define('mycare-ember/controllers/partials/manage/timesheet-activity-check', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    auth: Ember.inject.controller('auth'),

    actions: {
      save: function save() {
        var self = this;
        self.store.findRecord('person', self.get('auth.loggedInUserId')).then(function (person) {
          self.set('model.completedPerson', person);
          self.set('model.completedOn', moment.utc(new Date()).toISOString());
          self.set('model.isComplete', !self.get('model.isComplete'));
          if (self.get('model.isComplete') === false) {
            self.set('model.completedPerson', '');
            self.set('model.completedOn', '');
          }
          self.get('model').save();
        });
      }
    }
  });
});