define('mycare-ember/seekers/manage/route', ['exports', 'mycare-ember/utils/mc-protected-route'], function (exports, _mcProtectedRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcProtectedRoute.default.extend({

        _profileService: Ember.inject.service('profile'),

        model: function model() {
            return this.get('_profileService.getPersonByIdAsync').perform({ personId: this.get('session.authData.currentPersonId') });
        },


        actions: {
            didTransition: function didTransition() {
                Ember.$("body").addClass("page-manage");
            }
        }
    });
});