define("mycare-ember/seekers/manage/circles/detail/edit/route", ["exports", "mycare-ember/mixins/protected_route"], function (exports, _protected_route) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_protected_route.default, {
        beforeModel: function beforeModel(transition) {
            this.isAuthenticated(transition);
        },


        renderTemplate: function renderTemplate() {
            var self = this;
            var detailController = self.controllerFor('circles/detail');
            var editCircleController = self.controllerFor('circles/detail/edit');
            var detailmodel = detailController.get('model');
            self.store.findRecord('person', self.controllerFor('auth').get('loggedInUserId')).then(function (person) {
                detailmodel.get('personCircles').then(function (personCircles) {
                    var isAllow = false;
                    personCircles.forEach(function (personCircle) {
                        if (personCircle.get('person.id') === person.get('id') && personCircle.get('role.name') === 'Organiser') {
                            isAllow = true;
                        }
                    });
                    if (isAllow === false) {
                        self.transitionTo('circles');
                    } else {
                        editCircleController.set('newCircle', detailmodel);
                        editCircleController.set('imageUrl', detailmodel.get('image.url'));
                        self.render('circles/detail/edit', {
                            into: 'seekers.manage.circles',
                            controller: editCircleController
                        });
                    }
                });
            });
        }
    });
});