define("mycare-ember/browse/route", ["exports", "mycare-ember/mixins/scroll_top"], function (exports, _scroll_top) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_scroll_top.default, {

        actions: {
            didTransition: function didTransition() {
                Ember.$("body").addClass("page-browse");
            }
        }
    });
});