define("mycare-ember/components/mc-circle/post-item/component", ["exports", "mycare-ember/utils/mc-component", "mycare-ember/mixins/mixpanel", "mycare-ember/utils/common", "mycare-ember/utils/function-helpers"], function (exports, _mcComponent, _mixpanel, _common, _functionHelpers) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend(_mixpanel.default, {
        _divId: _common.default.createGuid(),
        _profileService: Ember.inject.service('profile'),
        _circleService: Ember.inject.service('circle'),

        commentsSortingDesc: ['modifiedOn:asc'],
        sortedComments: Ember.computed.sort('model.comments', 'commentsSortingDesc'),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.get('_profileService.getPersonByIdAsync').perform({
                personId: this.get('loggedInUserId')
            }).then(function (person) {
                _this.set('loggedInUser', person);
            });
        },


        didReceiveAttrs: function didReceiveAttrs() {
            this.setIsValidCircleOrganiser();
        },

        setIsValidCircleOrganiser: function setIsValidCircleOrganiser() {
            //orgainser has the right
            var self = this;
            self.get('circle.personCircles').then(function (personCircles) {
                self.set('isValidCircleOrganiser', false);
                personCircles.forEach(function (personCircle) {
                    if (personCircle.get('person.id') === self.get('loggedInUserId') && personCircle.get('role.name') === 'Organiser') {
                        self.set('isValidCircleOrganiser', true);
                    }
                });
            });
        },

        isEnableToDelete: function () {
            // the person who post have the right
            return this.get('model.circleMember.person.id') === this.get('loggedInUserId');
        }.property('model.circleMember.person'),

        actions: {
            editPost: function editPost() {
                var self = this;
                self.set('isEdit', true);
            },

            cancelEdit: function cancelEdit() {
                this.get('model').rollbackAttributes();
                this.set('isEdit', false);
            },
            SavePost: function SavePost() {
                var self = this;
                Ember.$('.has-error').removeClass('hass-error');
                if (Ember.isEmpty(self.get('model.postBody')) === false) {
                    self.get('model').save().then(function () {
                        self.set('isEdit', false);
                    });
                } else {
                    Ember.$('#' + self.get('_divId')).addClass('has-error');
                }
            },

            deletePost: function deletePost(post) {
                var _this2 = this;

                bootbox.confirm('<h3 class="mt-0">Are you sure to remove this post?</h3>', function (result) {
                    if (result) {
                        post.deleteRecord();
                        post.save().then(function (post) {
                            _functionHelpers.default.callOrIgnore(_this2.get('onDeleted'), post.get('id'));
                        });
                    }
                });
            },

            postComment: function postComment(post) {
                var self = this;
                self.set('isEmpty', false);
                if (post.get('currentMessageText') && post.get('currentMessageText.length') !== 0) {
                    this.get('_profileService.getPersonByIdAsync').perform({
                        personId: this.get('currentPersonId')
                    }).then(function () {
                        self.get('_circleService.createCommentAsync').perform({
                            commentBody: post.get('currentMessageText'),
                            post: post
                        }).then(function (comment) {
                            self.mixpanelCreateSupportCircleComment(comment);
                            post.set('currentMessageText', '');
                        }, function () {
                            toastr.error('There was an error creating comment, please try again');
                            post.set('currentMessageText', '');
                        });
                    });
                } else {
                    self.set('isEmpty', true);
                }
            }
        }
    });
});