define("mycare-ember/components/mc-timesheet/event-create/component", ["exports", "mycare-ember/utils/mc-modal-component", "mycare-ember/mixins/validator", "mycare-ember/utils/Validators", "mycare-ember/mixins/time-to-quarter"], function (exports, _mcModalComponent, _validator, _Validators, _timeToQuarter) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend(_validator.default, _timeToQuarter.default, {

        _profileService: Ember.inject.service('profile'),
        _timesheetService: Ember.inject.service('timesheet'),
        newScheduledStartsOnDate: moment(new Date()).format('DD/MM/YYYY'),

        init: function init() {
            this.initVar('_componentId', 'modal-schedule-event-create');
            this._super.apply(this, arguments);
            this.initVar('isNew', true);
            this.initVar('isEnable', true);
            this.initVar('isWorker', false);
            this.initVar('hireWorkers', []);
            this.initVar('channelId', undefined);
            this.initVar('newScheduledStartsOnDate', moment(new Date()).format('DD/MM/YYYY'));
            this.initVar('errors', []);
            this.initVar('onUpdated', undefined);
        },
        _onBeforeOpen: function _onBeforeOpen() {
            if (this.isDestroyed) {
                return;
            }
            this.setup();
        },
        setup: function setup() {
            var self = this;
            self.setScheduledTime();
            self.set('event', {});
            self.set('selectedDate', '');
            self.set('title', '');
            self.set('description', '');
            self.set('isNew', true);
            self.get('errors').clear();
            this.setupValidation();
        },


        setScheduledTime: function setScheduledTime() {
            var self = this;
            if (self.isDestroyed) {
                return;
            }
            var selectedWeekdayString = self.get('selectedDate');
            var selectedEndsOnWeekdayString = self.get('selectedDate');
            if (self.get('selectedDate') === undefined || !Ember.isNone(self.get('selectedDate'))) {
                selectedWeekdayString = moment().toISOString();
                selectedEndsOnWeekdayString = moment().add(1, 'hours').toISOString();
            }
            var selectedWeekday = moment(selectedWeekdayString);
            var EndsOnWeekday = moment(selectedEndsOnWeekdayString);

            self.set('scheduledStartsOn', selectedWeekday);
            self.set('newScheduledStartsOn', self.getQuarterTime(selectedWeekday));
            self.set('scheduledEndsOn', EndsOnWeekday);
            self.set('newScheduledEndsOn', self.getQuarterTime(EndsOnWeekday));
        },

        profilePerson: function profilePerson() {
            var self = this;
            return self.get('_profileService.getPersonByIdAsync').perform({ personId: self.get('loginProfileId') });
        },

        actions: {
            cancelNewTimesheet: function cancelNewTimesheet() {
                Ember.$('#modal-schedule-event').modal('hide');
            },

            saveAndPublish: function saveAndPublish() {
                var self = this;
                if (self._validate()) {
                    self.profilePerson().then(function (person) {
                        self.addEvent(person);
                    });
                }
            }
        },

        addEvent: function addEvent(person) {
            var self = this;
            self.set('isPublishing', true);
            var title = self.get('title');
            var description = self.get('description');
            var selectedStartDay = moment(self.get('newScheduledStartsOnDate'), 'DD/MM/YYYY').startOf('day');
            var startsMoment = moment(self.get('newScheduledStartsOn'));
            var StartsOnHour = startsMoment.hour();
            var StartsOnMinute = startsMoment.minute();

            var scheduledStartsOn = selectedStartDay.set({
                'hour': StartsOnHour,
                'minute': parseInt(StartsOnMinute)
            }).toISOString();

            var selectedEndDay = moment(self.get('newScheduledStartsOnDate'), 'DD/MM/YYYY').startOf('day');
            if (self.get('isOvernightScheduled')) {
                selectedEndDay.add(1, 'day');
            }

            var EndOnHour = moment(new Date(self.get('newScheduledEndsOn'))).hour();
            var EndOnMinute = moment(new Date(self.get('newScheduledEndsOn'))).minute();
            var scheduledEndOn = selectedEndDay.set({
                'hour': EndOnHour,
                'minute': parseInt(EndOnMinute)
            }).toISOString();

            self.get('_timesheetService.createEventAsync').perform({
                title: title,
                description: description,
                scheduledStartsOn: scheduledStartsOn,
                scheduledEndOn: scheduledEndOn,
                person: person
            }).then(function () {
                self.set('isPublishing', false);
                var onUpdated = self.get('onUpdated');
                if (onUpdated) {
                    onUpdated();
                }
                self._close();
            });
        },

        setupValidation: function setupValidation() {
            this._resetValidation();
            this.addValidation('title', 'title', 'Title', [_Validators.default.isRequired]);
            this.addValidation('description', 'description', 'Description', [_Validators.default.isRequired]);
            this.addValidation('scheduledStartsOn', 'startTime', 'Start time', [this.validateStartTime]);
        },


        validateStartTime: function validateStartTime() {
            var self = this;
            if (self.get('isOvernightScheduled')) {
                return true;
            }

            var selectedStartDay = moment(new Date());
            //set start time
            var StartsOnHour = moment(new Date(self.get('newScheduledStartsOn'))).hour();
            var StartsOnMinute = moment(new Date(self.get('newScheduledStartsOn'))).minute();
            var startTime = selectedStartDay.set({
                'hour': StartsOnHour,
                'minute': parseInt(StartsOnMinute)
            });
            //set end time
            var selectedEndDay = moment(new Date());
            var EndOnHour = moment(new Date(self.get('newScheduledEndsOn'))).hour();
            var EndOnMinute = moment(new Date(self.get('newScheduledEndsOn'))).minute();
            var endTime = selectedEndDay.set({
                'hour': EndOnHour,
                'minute': parseInt(EndOnMinute)
            });
            return {
                result: startTime.isBefore(endTime),
                message: 'Start time must be before end time.'
            };
        }

    });
});