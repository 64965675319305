define('mycare-ember/components/ui/mc-date-month-year-picker/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/common', 'mycare-ember/utils/function-helpers'], function (exports, _mcComponent, _common, _functionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);

            this.initVar('maxYear', 120);
            this.initVar('minYear', 0);
            this.initVar('initialValue', undefined);
            this.initVar('error', undefined);
            this.initVar('onSelected', undefined);
            this.initVar('isDisabed', undefined);

            this.initVar('_yearOptions', undefined);
            this.initVar('_monthOptions', undefined);
            this.initVar('_dateOptions', undefined);
            this.initVar('_selectedMonthOptions', undefined);
            this.initVar('_selectedYearOptions', undefined);
            this.initVar('_selectedDateOptions', undefined);

            this.initVar('_selectedDateValue', undefined);
            this.initVar('_selectedMonthValue', undefined);
            this.initVar('_selectedYearValue', undefined);

            this._setupDateOptions(31);
            this._setupMonthOptions();
            this._setupYearOptions();
            this._setupInitialValue();
        },
        _setupDateOptions: function _setupDateOptions(range) {
            var dateOptions = _common.default.createSelectOptions({
                models: _.map(_.range(1, range + 1), function (item, index) {
                    return Ember.Object.create({ id: index, value: item });
                }),
                labelKey: 'value',
                modelName: 'date',
                prompt: 'Day',
                isIncludeNew: false
            });
            this.set('_dateOptions', dateOptions);
        },
        _setupMonthOptions: function _setupMonthOptions() {
            var monthOptions = _common.default.createSelectOptions({
                models: _.map(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'], function (item, index) {
                    return Ember.Object.create({ id: index, value: item });
                }),
                labelKey: 'value',
                modelName: 'month',
                prompt: 'Month',
                isIncludeNew: false
            });
            this.initVar('_monthOptions', monthOptions);
        },
        _setupYearOptions: function _setupYearOptions() {
            var currentYear = moment().year() - this.get('minYear');
            var yearOptions = _common.default.createSelectOptions({
                models: _.map(_.range(this.get('maxYear')), function (value, index) {
                    return Ember.Object.create({ id: index, value: currentYear - value });
                }),
                labelKey: 'value',
                modelName: 'year',
                prompt: 'Year',
                isIncludeNew: false
            });
            this.initVar('_yearOptions', yearOptions);
        },


        _watchInitialValue: function () {
            this._setupInitialValue();
        }.observes('initialValue'),

        _setupInitialValue: function _setupInitialValue() {
            this.set('_isInitial', true);
            var momentInitialDate = this.get('initialValue');
            if (Ember.isEmpty(momentInitialDate) || typeof momentInitialDate !== 'string') {
                return;
            }
            if (typeof momentInitialDate === 'string') {
                momentInitialDate = moment(this.get('initialValue'));
            }
            var date = momentInitialDate.date();
            var month = momentInitialDate.month();
            var year = momentInitialDate.year();

            this.set('_selectedMonthOption', this.get('_monthOptions').find(function (opt) {
                return opt.get('record.id') === month;
            }));
            this.set('_selectedYearOption', this.get('_yearOptions').find(function (opt) {
                return opt.get('record.value') === year;
            }));
            this.set('_selectedDateOption', this.get('_dateOptions').find(function (opt) {
                return opt.get('record.value') === date;
            }));

            this.set('_selectedDayValue', this.get('_selectedDateOption.record.value'));
            this.set('_selectedMonthValue', this.get('_selectedMonthOption.record.id'));
            this.set('_selectedYearValue', this.get('_selectedYearOption.record.value'));
        },


        _watchSelectedValues: function () {
            var day = this.get('_selectedDayValue');
            var year = this.get('_selectedYearValue');
            var month = this.get('_selectedMonthValue');
            if (!Ember.isNone(day) && !Ember.isNone(year) && !Ember.isNone(month)) {
                if (day === -1 || month === -1 || year === -1) {
                    if (this.get('_isInitial')) {
                        _functionHelpers.default.callOrIgnore(this.get('onSelected'), moment(this.get('initialValue')));
                    } else {
                        _functionHelpers.default.callOrIgnore(this.get('onSelected'), null);
                    }
                } else {
                    var date = moment();
                    date.set({ date: day, year: year, month: month });
                    _functionHelpers.default.callOrIgnore(this.get('onSelected'), date);
                }
            } else {
                if (this.get('_isInitial')) {
                    _functionHelpers.default.callOrIgnore(this.get('onSelected'), moment(this.get('initialValue')));
                } else {
                    _functionHelpers.default.callOrIgnore(this.get('onSelected'), null);
                }
            }
        }.observes('_selectedDayValue', '_selectedMonthValue', '_selectedYearValue'),

        _updateDateOptionsBaseOnMonth: function _updateDateOptionsBaseOnMonth() {
            var isLeapYear = this.isLeapYear(this.get('_selectedYearValue'));
            switch (this.get('_selectedMonthValue')) {
                case 8:
                case 3:
                case 5:
                case 10:
                    this._setupDateOptions(30);
                    break;
                case 1:
                    this._setupDateOptions(isLeapYear ? 29 : 28);
                    break;
                default:
                    this._setupDateOptions(31);
                    break;
            }
        },


        actions: {
            onDaySelected: function onDaySelected(value) {
                this.set('_isInitial', false);
                if (value) {
                    this.set('_selectedDayValue', value.get('record.value'));
                }
            },
            onMonthSelected: function onMonthSelected(value) {
                this.set('_isInitial', false);
                if (value) {
                    this.set('_selectedMonthValue', value.get('record.id'));
                    this._updateDateOptionsBaseOnMonth(value.get('record.id'));
                }
            },
            onYearSelected: function onYearSelected(value) {
                this.set('_isInitial', false);
                if (value) {
                    this.set('_selectedYearValue', value.get('record.value'));
                    this._updateDateOptionsBaseOnMonth();
                }
            }
        },

        isLeapYear: function isLeapYear(year) {
            return year % 4 === 0 && year % 100 !== 0 || year % 400 === 0;
        }
    });
});