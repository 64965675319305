define('mycare-ember/components/mc-nav/loader/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        mode: function () {
            var currentProfileRole = localStorage.getItem(this.get('env').getApp('CURRENT_PROFILE_ROLE'));
            return currentProfileRole === 'worker' ? 'worker' : 'seeker';
        }.property().volatile(),

        isSeeker: function () {
            return this.get('mode') === 'seeker';
        }.property('mode'),

        isWorker: function () {
            return this.get('mode') === 'worker';
        }.property('mode')
    });
});