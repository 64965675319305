define('mycare-ember/mixins/validator', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        init: function init() {
            this.set('validations', {});
            this.set('_validationRegistry', []);
            this.set('isValidationsPassed', false);
            this._super.apply(this, arguments);
        },
        _resetValidation: function _resetValidation() {
            var _this = this;

            if (!Ember.isEmpty(this.get('_validationRegistry'))) {
                this.get('_validationRegistry').forEach(function (reg) {
                    if (_this.isDestroyed) {
                        return;
                    }
                    _this.removeObserver(reg.key, _this, function () {
                        _this._validateField(reg);
                    });
                });
            }
            this.set('validations', {});
            this.set('_validationRegistry', []);
            this.set('isValidationsPassed', false);
        },
        _resetErrors: function _resetErrors() {
            this.set('validations', {});
        },


        _checkIsValidationPassed: function _checkIsValidationPassed() {
            var _this2 = this;

            var isValid = true;
            Object.keys(this.get('validations')).forEach(function (key) {
                var showError = _this2.get('validations.' + key + '.showError');
                if (isValid && !Ember.isNone(showError) && showError === true) {
                    isValid = false;
                }
            });
            this.set('isValidationsPassed', isValid);
            return isValid;
        },

        addValidation: function addValidation(key, validationKey, name, validators) {
            var _this3 = this;

            var errorKey = 'validations.' + validationKey;
            this.set(errorKey, { name: name });
            this.removeObserver(key, this, function () {
                _this3._validateField({ key: key, validationKey: validationKey, name: name, validators: validators });
            });
            this.addObserver(key, this, function () {
                _this3._validateField({ key: key, validationKey: validationKey, name: name, validators: validators });
            });
            this.get('_validationRegistry').pushObject({ key: key, validationKey: validationKey, name: name, validators: validators });
        },
        _addValidationError: function _addValidationError(key, message) {
            this.set('validations.' + key, {
                isDirty: true,
                showError: true,
                error: message
            });
        },
        _removeValidationError: function _removeValidationError(key) {
            if (this.get('validations')) {
                this.set('validations.' + key, null);
            }
        },
        _validate: function _validate() {
            var _this4 = this;

            var field = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

            var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
                _ref$keepDirty = _ref.keepDirty,
                keepDirty = _ref$keepDirty === undefined ? false : _ref$keepDirty;

            //jshint ignore:line
            if (!keepDirty) {
                Object.keys(this.get('validations') || []).forEach(function (key) {
                    _this4.set('validations.' + key + '.isDirty', true);
                });
            }
            var result = this.get('_validationRegistry').filter(function (item) {
                return field ? item.key === field : true;
            }).map(function (item) {
                item.keepDirty = keepDirty;
                return _this4._validateField(item);
            });
            return !result.any(function (item) {
                return item === false;
            });
        },
        _validateAsync: function _validateAsync() {
            var _this5 = this;

            var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
                _ref2$keepDirty = _ref2.keepDirty,
                keepDirty = _ref2$keepDirty === undefined ? false : _ref2$keepDirty;

            //jshint ignore:line
            if (!keepDirty) {
                Object.keys(this.get('validations')).forEach(function (key) {
                    _this5.set('validations.' + key + '.isDirty', true);
                });
            }
            var result = this.get('_validationRegistry').map(function (item) {
                item.keepDirty = keepDirty;
                return _this5._validateField(item);
            });
            return Ember.RSVP.resolve(!result.any(function (item) {
                return item === false;
            }));
        },


        _validateField: function _validateField(_ref3) {
            var _this6 = this;

            var key = _ref3.key,
                validationKey = _ref3.validationKey,
                name = _ref3.name,
                validators = _ref3.validators,
                keepDirty = _ref3.keepDirty;

            var isValid = true;
            var errors = Ember.A();
            Ember.A(validators).forEach(function (validator) {
                if (isValid) {
                    var _validator$call = validator.call(_this6, name, _this6.get(key)),
                        result = _validator$call.result,
                        message = _validator$call.message;

                    isValid = result;
                    if (message) {
                        errors.pushObject(message);
                    }
                }
            });
            var errorKey = 'validations.' + validationKey;
            if (!this.get('validations')) {
                return true;
            }
            var errorObject = {
                name: name,
                error: Ember.isEmpty(errors) || isValid ? null : errors.objectAt(0),
                showError: isValid === false && !Ember.isNone(this.get(errorKey)) && this.get(errorKey + '.isDirty') === true
            };
            if (Ember.isNone(keepDirty) || keepDirty === false) {
                errorObject.isDirty = true;
            }
            this.set(errorKey, errorObject);
            this._checkIsValidationPassed();
            return isValid;
        }
    });
});