define('mycare-ember/me/account/index/route', ['exports', 'mycare-ember/mixins/protected_route', 'mycare-ember/utils/function-helpers'], function (exports, _protected_route, _functionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_protected_route.default, {
        beforeModel: function beforeModel(transition) {
            this.isAuthenticated(transition);
        },

        actions: {
            willTransition: function willTransition() {
                this.controller.get('model').rollbackAttributes();
            }
        },

        setupController: function setupController(controller, model) {
            this._super.apply(this, arguments);
            controller.set('model', model.person);
            _functionHelpers.default.callOrIgnoreWithContext(controller.setup, controller);
        }
    });
});