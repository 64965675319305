define('mycare-ember/components/multi-person-avatar/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        limitedPeople: function () {
            return this.get('people').slice(0, 3);
        }.property('people')
    });
});