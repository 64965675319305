define("mycare-ember/components/mc-profile-card/component", ["exports", "mycare-ember/utils/mc-component", "ember-concurrency"], function (exports, _mcComponent, _emberConcurrency) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        tagName: '',
        redirectService: Ember.inject.service('utils/redirect'),
        relationshipService: Ember.inject.service('relationship'),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.initVar('rel', null);
            this.initVar('isEdit', null);
            this.createSubscriptions([{
                publisher: this.get('eventbus'),
                key: 'global:event:profileSwitched',
                isEventBus: true,
                callable: function callable() {
                    if (_this.get('isEdit')) {
                        _this.get('eventbus').publish('global:on:changeRoute', 'seekers.findhelp.profile.index.edit', _this.get('rel.person.id'));
                        _this.set('isEdit', false);
                    }
                }
            }]);
        },


        redirectKey: function () {
            var redirectKey = null;
            var beforeLoginKey = this.get('env').getApp('REDIRECT_BEFORELOGIN_KEY');
            var redirectService = this.get('redirectService');
            if (redirectService.hasKey(beforeLoginKey)) {
                redirectKey = beforeLoginKey;
            } else {
                var profileKey = this.get('env').getApp('REDIRECT_PROFILE_KEY');
                if (redirectService.hasKey(profileKey)) {
                    redirectKey = profileKey;
                }
            }
            return redirectKey;
        }.property(),

        isSelected: function () {
            return this.get('rel.person.id') === this.get('session.authData.currentPersonId') && this.get('session.authData.currentProfileRole') === this.get('rel.role.name').toLowerCase();
        }.property('currentPersonId', 'rel.person.id'),

        actions: {
            handleSwitchProfile: function handleSwitchProfile() {
                this.sendAction('onSwitchProfile', this.get('rel.role.name'), this.get('rel.person.id'), this.get('rel.person.firstName'), this.get('redirectKey'));
            }
        }
    });
});