define('mycare-ember/mixins/support-type-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    addSupportType: function addSupportType(supportType, person) {
      return this.store.createRecord('support-type', {
        name: supportType,
        createdPerson: person.get('id')
      }).save();
    },

    editSupportType: function editSupportType(id, name) {
      return this.store.findRecord('support-type', id).then(function (sp) {
        sp.set('name', name);
        return sp.save();
      });
    },

    deleteSupportType: function deleteSupportType(id) {
      return this.store.findRecord('support-type', id).then(function (sp) {
        sp.set('name', name);
        return sp.delete();
      });
    }
  });
});