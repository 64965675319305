define("mycare-ember/browse/profiles/index/detail/review/route", ["exports", "mycare-ember/mixins/protected_route"], function (exports, _protected_route) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_protected_route.default, {
    beforeModel: function beforeModel(transition) {
      var self = this;
      self._super();
      this.isAuthenticated(transition);
    },

    actions: {
      willTransition: function willTransition() {
        var self = this;
        Ember.$('#modal-profile-review').modal('hide');
        self.controllerFor('browse/profiles/index/detail').setup();
      }
    },

    renderTemplate: function renderTemplate() {
      var self = this;
      var controller = self.controllerFor('browse/profiles/index/detail');
      controller.get('messageErrors').clear();
      self.render('partials/browse/profiles/modal-profile-review', {
        into: 'browse.profiles.index.detail',
        controller: controller
      });
      Ember.run.scheduleOnce('afterRender', this, function () {
        Ember.$('#modal-profile-review').modal('show');
      });
    }
  });
});