define("mycare-ember/components/mc-schedule-picker/monthly/component", ["exports", "mycare-ember/mixins/get-date-of-week", "mycare-ember/utils/common", "mycare-ember/utils/mc-component"], function (exports, _getDateOfWeek, _common, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend(_getDateOfWeek.default, {
        tagName: '',
        stringOfWeek: 'This week',
        intOfCurrentWeek: 0,
        date: '',
        setup: function () {
            var self = this;
            self.set('componentId', _common.default.createGuid() + 'DatePicker');
            Ember.run.scheduleOnce('afterRender', this, function () {
                if (Ember.isEmpty(self.get('selectedDate'))) {
                    self.setDate();
                } else {
                    self.setStartAndEndOfWeek(moment(self.get('selectedDate')).utc());
                    self.set('selectedDate', self.get('selectedDate'));
                }
            });
        }.on('init'),

        setStartAndEndOfWeek: function setStartAndEndOfWeek(date) {
            var dateOfToday = new Date();
            if (date) {
                dateOfToday = new Date(date);
            }
            var startDate = moment(dateOfToday).startOf('month');
            var endDate = moment(dateOfToday).endOf('month');
            this.set('selectedMonthStart', startDate);
            this.set('selectedMonthEnd', endDate);
        },

        setDateDifference: function setDateDifference(date) {
            var now = moment.utc(this.getMonday(new Date())); //todays date
            var duration = moment.duration(now.diff(date));
            var days = duration.asDays();
            var dayOfDiff = Math.round(days / 7);
            if (dayOfDiff === 0) {
                this.set('intOfCurrentWeek', dayOfDiff);
            } else {
                this.set('intOfCurrentWeek', -dayOfDiff);
            }
            // this.watchIntOfCurrentWeek();
        },

        watchDate: function () {
            var selectedDate = this.get('date');
            this.setDate(selectedDate);
        }.observes('date'),

        isAbleToAddTimeSheet: function () {
            var dateOfToday = new Date();
            var dateOfMonday = this.getMonday(dateOfToday);
            return !!(this.get('isScheduleView') === true && this.get('isSeeker') === true && moment.utc(this.get('selectedSunday')).isAfter(dateOfMonday));
        }.property('selectedSunday', 'isScheduleView'),

        setDate: function setDate(date) {
            var self = this;
            var selectedDate = moment(date, 'DD/MM/YYYY').startOf('day').utc();
            self.setStartAndEndOfWeek(selectedDate);
            var formattedDate = selectedDate.toISOString();
            self.set('selectedDate', formattedDate);
        },

        actions: {
            today: function today() {
                this.setDate();
            },


            getPreviousMonth: function getPreviousMonth() {
                var selectedDate = new Date(this.get('selectedDate'));
                var selectedDateString = moment(selectedDate).add(-1, 'M').format('DD/MM/YYYY').toString();
                Ember.Application.selectedDate = selectedDateString;
                this.setDate(selectedDateString);
            },

            getNextMonth: function getNextMonth() {
                var selectedDate = new Date(this.get('selectedDate'));
                var selectedDateString = moment(selectedDate).add(1, 'M').format('DD/MM/YYYY').toString();
                Ember.Application.selectedDate = selectedDateString;
                this.setDate(selectedDateString);
            }
        }

    });
});