define('mycare-ember/seekers/my-account/account-detail/index/route', ['exports', 'mycare-ember/utils/mc-protected-route'], function (exports, _mcProtectedRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcProtectedRoute.default.extend({
        model: function model() {
            return this.modelFor('seekers.my-account.account-detail');
        },


        actions: {
            willTransition: function willTransition() {
                this.controller.set('isAbleToGetTransaction', false);
            }
        }
    });
});