define("mycare-ember/mixins/timesheet-is-single-overnight", ["exports", "mycare-ember/mixins/get-date-of-week"], function (exports, _getDateOfWeek) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_getDateOfWeek.default, {
    existTimesheet: [],
    getTimesheetsByDate: function getTimesheetsByDate() {
      var self = this;
      var date = self.get('selectedDay');
      if (Ember.isEmpty(date)) {
        date = new Date();
      }
      var profileId = self.get('auth.profileId');
      var monday = moment(self.getMonday(date)).startOf("day").utc().toISOString();
      var sunday = moment(self.getSunday(date)).endOf("day").utc().toISOString();
      self.store.query('timesheet', {
        personId: profileId,
        isWorker: self.get('isWorker'),
        periodStartsOn: monday,
        periodEndsOn: sunday
      }).then(function (timesheets) {
        self.set('isLoading', false);
        self.set('existTimesheet', timesheets);
      });
    },

    isSingleOvernight: function isSingleOvernight(intOfWeekday) {
      var self = this;
      if (Ember.isEmpty(self.get('isOvernightScheduled')) || self.get('isOvernightScheduled') === false) {
        return true;
      }
      var overnights = self.get('existTimesheet').filterBy('isOvernightScheduled');
      overnights.removeObject(self.get('model'));
      for (var i = 0; i < overnights.get('length'); i++) {
        var overnight = overnights.objectAt(i);
        var date = moment.utc(overnight.get('scheduledStartsOn'));
        var dow = date.day();
        var currentDate = moment.utc(self.get('model.scheduledStartsOn'));
        var cdow = currentDate.day();
        if (isNaN(cdow)) {
          cdow = intOfWeekday;
        }
        if (dow === cdow) {
          return false;
        }
      }
      return true;
    },

    isSingleActualOvernight: function isSingleActualOvernight(intOfWeekday) {
      var self = this;
      if (Ember.isEmpty(self.get('isOvernightActual')) || self.get('isOvernightActual') === false) {
        return true;
      }
      var overnights = self.get('existTimesheet').filterBy('isOvernightActual');
      overnights.removeObject(self.get('model'));
      for (var i = 0; i < overnights.get('length'); i++) {
        var overnight = overnights.objectAt(i);
        var date = moment.utc(overnight.get('scheduledStartsOn'));
        var dow = date.day();
        var currentDate = moment.utc(self.get('model.scheduledStartsOn'));
        var cdow = currentDate.day();
        if (isNaN(cdow)) {
          cdow = intOfWeekday;
        }
        if (dow === cdow) {
          return false;
        }
      }
      return true;
    }
  });
});