define("mycare-ember/controllers/partials/modal-circle-edit-note", ["exports", "mycare-ember/mixins/form-checker", "mycare-ember/mixins/mixpanel"], function (exports, _formChecker, _mixpanel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_formChecker.default, _mixpanel.default, {
    currentNote: {},
    errors: [],

    actions: {
      setNote: function setNote(note) {
        var self = this;
        self.set('currentNote.note', note);
        self.set('currentNote.title', note.get('title'));
        self.set('currentNote.description', note.get('description'));
      },

      saveNote: function saveNote() {
        var self = this;
        self.set('isSaving', true);
        self.get('errors').removeObjects(self.get('errors'));
        if (self.formCheck()) {
          var note = self.get('currentNote.note');
          note.set('title', self.get('currentNote.title'));
          note.set('description', self.get('currentNote.description'));
          Ember.$('#modal-circle-edit-note').modal('hide');
          note.save().then(function () {
            self.set('isSaving', false);
          }, function () {
            self.set('isSaving', false);
            toastr.error('There was an error saving note, please try again');
          });
        }
      },

      deleteNote: function deleteNote() {
        var self = this;
        var note = self.get('currentNote.note');
        bootbox.confirm('<h3 class="mt-0">Do you really want to delete this note?</h3>', function (result) {
          if (result) {
            note.deleteRecord();
            note.save();
            Ember.$('#modal-circle-edit-note').modal('hide');
          }
        });
      }
    },

    formCheck: function formCheck() {
      var self = this;
      var result = false;
      if (self.isEmptyField(self.get('currentNote.title'))) {
        self.get('errors').pushObject("Title is required");
        self.set('isSaving', false);
      } else {
        result = true;
      }
      return result;
    },

    reset: function reset() {
      var self = this;
      self.get('errors').clear();
    }
  });
});