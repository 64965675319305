define('mycare-ember/components/mc-read-more/component', ['exports', 'mycare-ember/utils/mc-component', 'npm:responsive-toolkit'], function (exports, _mcComponent, _npmResponsiveToolkit) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({

        tagName: '',

        init: function init() {
            this._super.apply(this, arguments);

            this.initVar('content', undefined);
            this.initVar('maxLength', 300);
            this.initVar('minLength', 150);

            this.set('_managedContent', undefined);
            this.set('_isCollpased', true);

            this._parseContent();
        },


        _shouldCollpased: function () {
            return this.get('content.length') > this.get('minLength') && _npmResponsiveToolkit.default.is("xs");
        }.property('content', 'minLength'),

        _parseContent: function _parseContent() {
            var content = this.get('content');
            if (Ember.isNone(content)) return null;
            if (this.get('_isCollpased')) {
                content = content.substring(0, this.get('maxLength'));
            }
            this.set('_managedContent', content);
        },


        _watchContent: function () {
            this._parseContent();
        }.observes('content', '_isCollpased'),

        actions: {
            toggle: function toggle() {
                this.toggleProperty('_isCollpased');
            }
        }

    });
});