define("mycare-ember/components/mc-worker-profile/availability/component", ["exports", "mycare-ember/utils/function-helpers", "mycare-ember/utils/mc-modal-component"], function (exports, _functionHelpers, _mcModalComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend({
        _modalHelper: Ember.inject.service('utils/modal-helper'),

        init: function init() {
            //_componentId needs to be declared before super
            this.initVar('_componentId', 'mc-worker-profile/availability');

            this._super.apply(this, arguments);

            this.initVar('channelId', null);
            this.initVar('_worker', null);
            this.initVar('onSaved', null);
            this.initVar('_formData', {});
            this.initVar('_isSaving', false);
            this.initVar('hasWhenOrAvailabilities', true);
        },
        _onBeforeOpen: function _onBeforeOpen(worker) {
            this.set('_worker', worker);
            this.get('_worker').reload();

            this.setHasWhenOrAvailabilities();
        },
        _onBeforeClose: function _onBeforeClose() {
            this.get('_worker').reload();
        },
        _onAfterClosed: function _onAfterClosed() {
            this.set('_worker', undefined);
        },


        isWatchValidated: function () {
            this.setHasWhenOrAvailabilities();
        }.observes('_worker.{availabilities.[],availability}'),

        setHasWhenOrAvailabilities: function setHasWhenOrAvailabilities() {
            this.set('hasWhenOrAvailabilities', this.get('_worker.availabilities.length') > 0 || !Ember.isEmpty(this.get('_worker.availability')));
        },
        actions: {
            onSavedEvent: function onSavedEvent() {
                var _this = this;

                if (!this.hasWhenOrAvailabilities) {
                    return;
                }
                this.set('_isSaving', true);
                this.get('_worker').save().then(function () {
                    _functionHelpers.default.callOrIgnore(_this.get('onSaved'), _this.get('_worker.availabilities'));
                    _this._close();
                    _this.set('_isSaving', false);
                });
            }
        }
    });
});