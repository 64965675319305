define("mycare-ember/seekers/manage/schedule-workers/index/date/route", ["exports", "mycare-ember/mixins/protected_route", "mycare-ember/mixins/scroll_top"], function (exports, _protected_route, _scroll_top) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_protected_route.default, _scroll_top.default, {
        beforeModel: function beforeModel(transition) {
            this._super();
            this.isAuthenticated(transition);
            if (Ember.isEmpty(transition.queryParams.date) === false) {
                this.set('selectedDate', transition.queryParams.date);
                // this.transitionTo('seekers.manage.schedule-workers.index');
                // return;
            }
        },

        setupController: function setupController(controller) {
            controller.set('selectedDate', this.get('selectedDate'));
            controller.setup();
        }
    });
});