define("mycare-ember/seekers/findhelp/profile/index/credit-card/controller", ["exports", "mycare-ember/mixins/creditcard-add-process"], function (exports, _creditcardAddProcess) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_creditcardAddProcess.default, {
        auth: Ember.inject.controller('auth'),
        errors: [],
        topupAmount: 100,
        isLoadingCreditCards: false,
        customTitle: 'Add payment method',

        setup: function setup() {
            var self = this;
            self.store.findRecord('person', self.get('auth.profileId')).then(function (person) {
                self.set('profilePerson', person);
            });
            self.set('isIRDRequired', false);
        },

        setAccountDetail: function () {
            var self = this;
            self.set('isGstRegistered', self.get('model.isGstRegistered'));
            self.set('irdNumber', (self.get('model.irdNumber') || "").replace(/-/g, ''));
            self.set('topupAmount', self.get('model.topupAmount'));
            self.set('isNonIFClient', self.get('model.accountTypeName').toLowerCase() != 'if' && self.get('model.accountTypeName').toLowerCase() != 'if family carers');
        }.observes('model'),

        setFilteredCreditCards: function () {
            var self = this;
            //toggleProperty and set trick for ember single render problem
            self.get('model.creditCards').then(function (creditCards) {

                var filteredCreditCards = creditCards.filter(function (cc) {
                    return cc.get('isNew') === false;
                }).filter(function (creditCard) {
                    return self.get('model.preferredCreditCard') === creditCard.get('id');
                });
                var sortedCreditCards = filteredCreditCards.sortBy('isPreferredCreditCard').toArray().reverse();
                if (sortedCreditCards.filter(function (cc) {
                    return !Ember.isNone(cc.get('cardNumber'));
                }).get('length') > 0) {
                    self.set('customTitle', 'Update payment method');
                }
                self.set('filteredCreditCards', sortedCreditCards);
            });
        }.observes('model.creditCards.[]', 'model.creditCards.@each.isPreferredCreditCard'),

        executeCreditCard: function executeCreditCard() {
            var self = this;
            self.set('creditCardError', '');
            var afterFunction = function afterFunction(creditCard) {
                self.set('model.preferredCreditCard', creditCard.get('id'));
                self.get('model').save().then(function () {
                    self.setFilteredCreditCards();
                    Ember.$('#modal-credit-card-add').modal('hide');
                });
            };
            var errorHandle = function errorHandle() {
                self.set('errors', []);
            };
            self.addCreditCardProcess(self.get('model'), afterFunction, errorHandle);
        },

        watchTopupAmount: function () {
            if (!isNaN(parseFloat(this.get('topupAmount'))) || isFinite(this.get('topupAmount'))) {
                this.set('errors.topupAmount', null);
                Ember.$('#topupAmountDiv').removeClass('has-error');
            }
        }.observes('topupAmount'),

        canTopupAccount: function () {
            return this.get('model.accountTypeName').toLowerCase() !== 'worker';
        }.property('model.accountTypeName'),

        actions: {
            // make sure use the same name when add a credit card
            addCreditCard: function addCreditCard() {
                var self = this;
                self.executeCreditCard();
            },

            updateAccount: function updateAccount() {
                var self = this;
                if (self.validForm()) {
                    self.set('isSaving', true);
                    self.get('model').set('topupAmount', self.get('topupAmount'));
                    self.get('model').save().then(function () {
                        self.set('isSaving', false);
                    });
                }
            }
        },

        validForm: function validForm() {
            var self = this;
            var bool = true;
            self.get('errors').clear();

            if (!isNaN(parseFloat(self.get('topupAmount'))) && isFinite(self.get('topupAmount'))) {
                if (self.get('topupAmount') < 100 || self.get('topupAmount') > 1000) {
                    self.set('errors.topupAmount', 'Topup amount must between 100 and 1000.');
                    bool = false;
                }
            } else {
                self.set('errors.topupAmount', 'Topup amount must be a number.');
                bool = false;
            }

            return bool;
        }
    });
});