define('mycare-ember/components/mc-payment-form-modal/component', ['exports', 'mycare-ember/utils/mc-modal-component', 'mycare-ember/utils/function-helpers'], function (exports, _mcModalComponent, _functionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend({
        init: function init() {
            //_componentId needs to be declared before super
            this.initVar('_componentId', 'mc-payment-form-modal');

            this._super.apply(this, arguments);

            this.initVar('channelId', undefined);
            this.initVar('account', undefined);
            this.initVar('onSuccess', undefined);
            this.initVar('onError', undefined);
            this.initVar('formTitle', undefined);
            this.initVar('isActive', true);
            this.initVar('_isInBackground', true);
        },
        _onAfterOpened: function _onAfterOpened() {
            this._super.apply(this, arguments);
            this.set('_isInBackground', false);
        },
        _onAfterClosed: function _onAfterClosed() {
            this._super.apply(this, arguments);
            this.set('_isInBackground', true);
        },


        actions: {
            onError: function onError(error) {
                this.set('error', error);
                _functionHelpers.default.callOrIgnore(this.get('onError'), error);
            },
            onSuccess: function onSuccess() {
                _functionHelpers.default.callOrIgnore(this.get('onSuccess'));
                this._close();
            },
            restart: function restart() {
                var _this = this;

                this.set('isActive', false);
                Ember.run.later(function () {
                    _this.set('error', null);
                    _this.set('isActive', true);
                }, 1000);
            }
        }
    });
});