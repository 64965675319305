define("mycare-ember/components/mc-phone-picker/component", ["exports", "mycare-ember/utils/mc-component", "mycare-ember/utils/function-helpers"], function (exports, _mcComponent, _functionHelpers) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('country', undefined);
            this.initVar('areaCode', '');
            this.initVar('isNZ', true);
            this.initVar('isPhoneNumberError', true);
            this.initVar('onSelected', undefined);
            this.initVar('names', ['020', '021', '022', '027', '028', '029']);
            this.setup();
        },
        didReceiveAttrs: function didReceiveAttrs() {
            this.watchCountry();
        },
        setup: function setup() {
            var self = this;
            var phoneNumber = self.get('phoneNumber');
            if (!Ember.isBlank(phoneNumber)) {
                var phoneNumbers = phoneNumber.split("-");
                if (phoneNumbers.length === 2) {
                    self.set('areaCode', phoneNumbers[0]);
                    self.set('phoneShort', phoneNumbers[1]);
                } else if (phoneNumbers.length === 1) {
                    self.set('areaCode', '');
                    self.set('phoneShort', phoneNumbers[0]);
                } else {
                    self.set('areaCode', phoneNumbers[0] + '-' + phoneNumbers[1]);
                    self.set('phoneShort', phoneNumbers[2]);
                }
            } else {
                if (Ember.isEmpty(self.get('areaCode'))) {
                    self.set('areaCode', self.get('names').objectAt(1));
                }
            }
        },


        _onChange: function () {
            var self = this;
            self.set('isPhoneNumberError', false);
            if (Ember.isEmpty(self.get('areaCode')) || self.get('phoneShort') === 'undefined' || self.get('phoneShort.length') < 6) {
                self.set('isPhoneNumberError', true);
            } else {
                self.set('isPhoneNumberError', false);
            }
            if (self.get('areaCode') && self.get('phoneShort')) {
                // FunctionUtils.callOrIgnore(this.get('onSelected'), self.get('areaCode') + '-' + self.get('phoneShort'))
            }
            var phoneNumber = self.get('areaCode') + '-' + self.get('phoneShort');
            if (this.get('isNZ') === false) {
                phoneNumber = self.get('phoneShort');
            }
            _functionHelpers.default.callOrIgnore(this.get('onSelected'), phoneNumber);
        }.observes('areaCode', 'phoneShort'),

        watchCountry: function () {
            var self = this;
            self.set('isNZ', false);
            if (Ember.isNone(self.get('country')) || self.get('country.name') === 'New Zealand') {
                self.set('isNZ', true);
            }
        }.observes('country'),

        setPhoneNumber: function () {
            var self = this;
            var phoneNumber = self.get('phoneNumber');
            if (!Ember.isBlank(phoneNumber)) {
                var phoneNumbers = phoneNumber.split("-");
                if (phoneNumbers.length === 2) {
                    self.set('areaCode', phoneNumbers[0]);
                    self.set('phoneShort', phoneNumbers[1]);
                } else if (phoneNumbers.length === 1) {
                    self.set('areaCode', '');
                    self.set('phoneShort', phoneNumbers[0]);
                } else {
                    self.set('areaCode', phoneNumbers[0] + '-' + phoneNumbers[1]);
                    self.set('phoneShort', phoneNumbers[2]);
                }
            } else {
                // self.set('areaCode', '021');
            }
        }.observes('phoneNumber')
    });
});