define('mycare-ember/logout/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        beforeModel: function beforeModel() {
            var authController = this.controllerFor('auth');
            authController.set('isPNSRegistered', false);
            this.get('session').logout();
        }
    });
});