define('mycare-ember/models/worker', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        availabilities: _emberData.default.hasMany('availability', { async: true }),
        isFavourite: _emberData.default.attr('boolean'),
        isBookable: _emberData.default.attr('boolean'),
        isWorker: _emberData.default.attr('boolean'),
        isApplicant: _emberData.default.attr('boolean'),
        isInvite: _emberData.default.attr('boolean'),
        isDeleteEnabled: _emberData.default.attr('boolean'),
        firstName: _emberData.default.attr('string'),
        lastName: _emberData.default.attr('string'),
        location: _emberData.default.attr('string'),
        avatarUrl: _emberData.default.attr('string'),
        thread: _emberData.default.belongsTo('thread', { async: true }),
        isValid: _emberData.default.attr('boolean'),
        isVerified: _emberData.default.attr('boolean'),
        isHero: _emberData.default.attr('boolean'),
        mojStatus: _emberData.default.attr('number'),
        createdOn: _emberData.default.attr('string'),
        personId: _emberData.default.attr('string'),
        person: _emberData.default.belongsTo('person', { async: true, inverse: null }),
        isAllowedWorkerToBook: _emberData.default.attr('boolean'),
        isCovidVaccinationVerified: _emberData.default.attr('boolean'),
        isMojCompleted: function () {
            return this.get('mojStatus') === 2;
        }.property('mojStatus')

    });
});