define('mycare-ember/me/route', ['exports', 'mycare-ember/mixins/protected_route', 'mycare-ember/mixins/scroll_top'], function (exports, _protected_route, _scroll_top) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_protected_route.default, _scroll_top.default, {
        beforeModel: function beforeModel(transition) {
            Ember.$('.modal').modal('hide');
            this.isAuthenticated(transition);
            Ember.$('.home').removeClass('home');
        },

        actions: {
            didTransition: function didTransition() {
                Ember.$("body").addClass("page-profile");
            }
        }
    });
});