define("mycare-ember/controllers/partials/modal-profile-manage-qualification", ["exports", "mycare-ember/mixins/mixpanel"], function (exports, _mixpanel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_mixpanel.default, {
    currentItem: null,
    isEditMode: false,
    toggleEndDate: false,
    title: 'Add qualification',
    modal: null,
    errors: [],

    setupPresentCheckbox: function () {
      var self = this;
      if (self.get('isEditMode')) {
        if (!self.get('currentItem.endedOn')) {
          self.set('toggleEndDate', true);
        } else {
          self.set('toggleEndDate', false);
        }
      }
    }.observes('currentItem.endedOn'),

    setEndDate: function () {
      var self = this;
      if (self.get('toggleEndDate')) {
        Ember.$(Ember.$('#qualificationEndedOn')).find('select').addClass('has-disabled');
        Ember.$(Ember.$('#qualificationEndedOn')).find('select').prop('selectedIndex', 0);
        self.set('currentItem.endedOn', null);
      } else {
        Ember.$(Ember.$('#qualificationEndedOn')).find('select').removeClass('has-disabled');
      }
    }.observes('toggleEndDate'),

    actions: {
      save: function save() {
        var self = this;
        self.set('isSaving', true);
        var qualification = self.get('currentItem');
        if (self.validateForm(qualification)) {
          if (!self.get('isEditMode')) {
            qualification = self.store.createRecord('qualification', {
              title: self.get('currentItem.title'),
              organisation: self.get('currentItem.organisation'),
              description: self.get('currentItem.description'),
              startedOn: self.get('currentItem.startedOn'),
              endedOn: self.get('currentItem.endedOn'),
              person: self.get('currentItem.person')
            });
          }
          qualification.save().then(function () {
            Ember.$('.modal').modal('hide');
            self.set('isSaving', false);
          }, function () {
            toastr.error('There was an error saving your qualification, please try again');
            self.set('isSaving', false);
          });
        } else {
          self.set('isSaving', false);
        }
      }
    },

    validateForm: function validateForm(qualification) {
      var self = this;
      var isValid = true;
      self.get('errors').clear();
      Ember.$('.has-error').removeClass('has-error');

      if (!qualification.get('title')) {
        isValid = false;
        Ember.$('#title').addClass('has-error');
        self.get('errors').pushObject('Qualification is required');
      }

      if (!qualification.get('organisation')) {
        isValid = false;
        Ember.$('#organisation').addClass('has-error');
        self.get('errors').pushObject('Organisation is required');
      }

      if (!qualification.get('startedOn')) {
        isValid = false;
        Ember.$('#qualificationStartedOn').find('select').addClass('has-error');
        Ember.$('#qualificationEndedOn').find('select').addClass('has-error');
        self.get('errors').pushObject('Time period is required');
      }

      if (!self.get('toggleEndDate') && !qualification.get('endedOn')) {
        isValid = false;
        Ember.$('#qualificationEndedOn').find('select').addClass('has-error');
        self.get('errors').pushObject('End date is required');
      }

      if (!self.get('toggleEndDate') && qualification.get('startedOn') > qualification.get('endedOn')) {
        isValid = false;
        Ember.$('#qualificationEndedOn').find('select').addClass('has-error');
        self.get('errors').pushObject('End date should be greater than start date');
      }

      return isValid;
    }
  });
});