define("mycare-ember/seekers/findhelp/job-listings/edit/index/route", ["exports", "mycare-ember/utils/mc-route", "mycare-ember/mixins/seeker_only", "npm:responsive-toolkit"], function (exports, _mcRoute, _seeker_only, _npmResponsiveToolkit) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcRoute.default.extend(_seeker_only.default, {
        _wizardService: Ember.inject.service('utils/seeker-listing-wizard'),
        beforeModel: function beforeModel() {
            if (_npmResponsiveToolkit.default.is('xs') && !Ember.isEmpty(this.get('_wizardService.jobListing.id'))) {
                this.set('_wizardService.mode', 'edit');
                this.transitionTo('seekers.findhelp.job-listings.mobile-edit-menu', this.get('_wizardService.jobListing.id'));
            } else {
                this.transitionTo('seekers.findhelp.job-listings.edit.basic-info');
            }
        }
    });
});