define('mycare-ember/helpers/status-rename-worker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function statusRenameWorker(data) {
    var string = data[0];
    var timeString = data[1];
    var isAfter = null;
    if (!Ember.isNone(timeString)) {
      isAfter = moment().utc().isAfter(moment(timeString).utc().toISOString());
    }
    if (string && string.toLowerCase() === 'pending') {
      return 'Pending approval';
    } else if (string && string.toLowerCase() === 'review') {
      return 'Submitted';
    } else if (isAfter === true && string && string.toLowerCase() === 'scheduled') {
      return 'Please submit';
    } else if (string && string.toLowerCase() === 'queried') {
      return 'Please check';
    }

    return string;
  }

  exports.statusRenameWorker = statusRenameWorker;
  exports.default = Ember.Helper.helper(statusRenameWorker);
});