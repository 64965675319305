define("mycare-ember/me/profiles/site-setup/done/route", ["exports", "mycare-ember/mixins/protected_route", "mycare-ember/utils/wizard-step-route", "mycare-ember/mixins/scroll_top"], function (exports, _protected_route, _wizardStepRoute, _scroll_top) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _wizardStepRoute.default.extend(_protected_route.default, _scroll_top.default, {
        stepName: 'done',
        parentControllerName: 'me/profiles/site-setup',

        setupController: function setupController() {
            this._super.apply(this, arguments);
            this.scrollTop();
        }
    });
});