define('mycare-ember/helpers/to-lower-case-worker-style', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function timeAgoInWords(data) {
    var string = data[0];
    if (string) {
      string = string.toLowerCase();
      if (string === 'review') {
        return 'paid';
      }
      return string;
    }
    return '';
  }

  exports.timeAgoInWords = timeAgoInWords;
  exports.default = Ember.Helper.helper(timeAgoInWords);
});