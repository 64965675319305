define('mycare-ember/components/mc-image-cropper/step-one/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/function-helpers'], function (exports, _mcComponent, _functionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('onEnter', undefined);
            this.initVar('onCompleted', undefined);
            this.initVar('description', 'For better results, select a picture of yourself');

            _functionHelpers.default.callOrIgnore(this.get('onEnter'));
        }
    });
});