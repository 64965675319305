define('mycare-ember/wizard/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        wizardService: Ember.inject.service('utils/wizard-sample'),

        setupController: function setupController() {
            this.get('wizardService').configure({
                wizardId: 'wizard-sample',
                steps: [{ id: '1', name: 'Step 1', order: 1, route: 'wizard.step1' }, { id: '2', name: 'Step 2', order: 2, route: 'wizard.step2' }, { id: '3', name: 'Step 3', order: 3, route: 'wizard.step3' }],
                data: {}
            });
        }
    });
});