define('mycare-ember/services/utils/routing', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        eventbus: Ember.inject.service('utils/eventbus'),
        router: Ember.inject.service('-routing'),

        init: function init() {
            this.get('eventbus').subscribe('global:on:changeRoute', this._onChangeRoute);
        },

        _onChangeRoute: function _onChangeRoute(routeName) {
            var object = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
            var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

            if (object) {
                if (options) {
                    this.get('router').transitionTo(routeName, object, options);
                } else {
                    this.get('router').transitionTo(routeName, object);
                }
            } else {
                if (options) {
                    this.get('router').transitionTo(routeName, options);
                } else {
                    this.get('router').transitionTo(routeName);
                }
            }
        }
    });
});