define('mycare-ember/helpers/approveBy', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.approveBy = approveBy;
    function approveBy(params /*, hash*/) {
        if (params[0] && params[0].toLowerCase() === "system") {
            return 'Mycare';
        }
        return params[0];
    }

    exports.default = Ember.Helper.helper(approveBy);
});