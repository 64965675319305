define('mycare-ember/seekers/findhelp/profile/index/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ['backTo', 'backToOpt'],
        backTo: null,
        backToOpt: null,
        needs: ['partials/modal-feedback'],
        isLoading: false,
        feedback: Ember.computed.alias('controllers.partials/modal-feedback'),

        subscriptionLabel: function () {
            var self = this;
            if (self.get('model.subscriptionType').toLowerCase() === 'dss') {
                return 'Ministry of Health Funded';
            } else if (self.get('model.subscriptionType').toLowerCase() === "trial" || self.get('model.subscriptionType').toLowerCase() === "free") {
                return 'Mycare Free';
            } else {
                return 'Mycare Premium';
            }
        }.property('model.subscriptionType'),

        actions: {
            onNavBack: function onNavBack() {
                if (Ember.isNone(this.get('backTo'))) {
                    this.transitionToRoute('seekers.findhelp.dashboard');
                } else {
                    if (this.get('backToOpt')) {
                        this.get('eventbus').publish('global:on:changeRoute', this.get('backTo'), this.get('backToOpt'));
                    } else {
                        this.get('eventbus').publish('global:on:changeRoute', this.get('backTo'));
                    }
                }
            },

            cancelSubscription: function cancelSubscription() {
                var self = this;
                self.set('feedback.feedbackString', 'Hi Mycare, I would like to cancel my subscription');
                Ember.$('#modal-feedback').modal('show');
            }
        }
    });
});