define('mycare-ember/controllers/partials/job-application-card', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        isDraft: false,
        isSubmitted: false,

        setup: function () {
            var self = this;
            if (Ember.isEmpty(self.get('model')) === false) {
                if (self.get('model.jobApplicationStatus.name').toLowerCase() === 'draft') {
                    self.set('isDraft', true);
                } else if (self.get('model.jobApplicationStatus.name').toLowerCase() !== 'draft') {
                    self.set('isSubmitted', true);
                }
                if (self.get('model.jobApplicationStatus.name').toLowerCase() === 'hire') {
                    self.set('isHired', true);
                } else if (self.get('model.jobApplicationStatus.name').toLowerCase() === 'not suitable') {
                    self.set('isNotSuitable', true);
                }
            }
        }.on('init'),

        noFixedHours: function () {
            var self = this;
            return self.get('model.jobListing.getHoursPerWeek') === 0 || Ember.isEmpty(self.get('model.jobListing.getHoursPerWeek'));
        }.property('model.jobListing.getHoursPerWeek'),

        actions: {
            viewApplication: function viewApplication() {
                var self = this;
                var app = self.get('model');
                var listing = app.get('jobListing');

                if (self.get('isDraft')) {
                    self.transitionToRoute('browse.jobs.index.detail.draft', listing);
                } else if (self.get('isSubmitted')) {
                    self.transitionToRoute('browse.jobs.index.detail.submitted', listing);
                }
            }
        }
    });
});