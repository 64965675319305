define('mycare-ember/components/mc-timepicker/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/function-helpers', 'mycare-ember/utils/common'], function (exports, _mcComponent, _functionHelpers, _common) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('initialValue', undefined);
            this.initVar('minTime', undefined);
            this.initVar('maxTime', undefined);
            this.initVar('onSelected', undefined);
            this.initVar('inputId', _common.default.createGuid());
            this.initVar('step', 5);
            this.set('_timepickerEle', undefined);
        },
        didInsertElement: function didInsertElement() {
            var _this = this;

            this.setup();
            $("#" + this.get('inputId')).on('keyup', function (e) {

                if (e.keyCode >= 37 && e.keyCode <= 40) {
                    return;
                }
                if (e.keyCode === 8) {
                    $('#' + _this.get('inputId')).val('');
                    return;
                }

                var hhmm = $('#' + _this.get('inputId')).val();
                if (e.keyCode === 13 && hhmm.length < 4) {
                    e.preventDefault();
                    while (hhmm.length < 4) {
                        hhmm = "0" + hhmm;
                    }
                    _this.setup(moment(hhmm, 'hh:mm a'));
                    return;
                } else if (e.keyCode === 13) {
                    return;
                } else {
                    if (hhmm.length >= 4) {
                        _this.setup(moment(hhmm, 'hh:mm a'));
                        var thisTime = $('#' + _this.get('inputId')).val();
                        _functionHelpers.default.callOrIgnore(_this.get('onSelected'), moment(thisTime, 'hh:mm a'));
                        return;
                    }
                }
            });
        },


        watchMinMaxTime: function () {
            this.setup();
        }.observes('minTime', 'maxTime'),

        watchIntialValue: function () {
            this.setup();
        }.observes('initialValue'),

        setup: function setup(momentTime) {
            var self = this;
            var $ele = this.$('.mc-timepicker');
            var options = {
                forceRoundTime: true,
                step: this.get('step'),
                timeFormat: 'h:i A'
            };
            if (this.get('minTime')) {
                options.minTime = moment(this.get('minTime')).tz('Pacific/Auckland').format('hh:mm a');
            }
            if (this.get('maxTime')) {
                options.maxTime = this.get('maxTime');
            }
            var timepicker = $ele.timepicker(options);
            if (!Ember.isNone(this.get('_timepickerEle'))) {
                this.get('_timepickerEle').off('changeTime');
            }
            this.set('_timepickerEle', timepicker);

            timepicker.on('changeTime', function () {
                var time = $(this).val();
                _functionHelpers.default.callOrIgnore(self.get('onSelected'), moment(time, 'hh:mm a'));
            });
            if (momentTime) {
                $ele.timepicker('setTime', momentTime.toDate());
            } else {
                $ele.timepicker('setTime', moment(this.get('initialValue')).toDate());
            }
        },
        willDestroy: function willDestroy() {
            if (!Ember.isNone(this.get('_timepickerEle'))) {
                this.get('_timepickerEle').off('changeTime');
            }
        }
    });
});