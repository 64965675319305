define("mycare-ember/book/mobile-nav/route", ["exports", "mycare-ember/utils/mc-route", "npm:responsive-toolkit"], function (exports, _mcRoute, _npmResponsiveToolkit) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcRoute.default.extend({
        wizardService: Ember.inject.service('utils/booking-wizard'),

        beforeModel: function beforeModel() {
            if (!_npmResponsiveToolkit.default.is('xs')) {
                this.transitionTo('book.basic-info');
            }
        }
    });
});