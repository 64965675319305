define('mycare-ember/components/mc-thread/sidebar/joblistings/component', ['exports', 'mycare-ember/utils/mc-modal-component'], function (exports, _mcModalComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend({
        init: function init() {
            this.set('_componentId', 'mc-modal-joblistings');
            this._super.apply(this, arguments);

            this.initVar('thread', null);
            this.initVar('backdrop', "true");
        }
    });
});