define('mycare-ember/controllers/partials/modal-feedback', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    feedbackErrors: [],

    actions: {
      sendFeedback: function sendFeedback() {
        var self = this;
        if (self.validateForm()) {
          self.set('isSending', true);
          var feedbackObj = self.store.createRecord('feedback', { feedback: self.get('feedbackString') });
          feedbackObj.save().then(function () {
            self.set('isSending', false);
            Ember.$('#modal-feedback').modal('hide');
            self.set('feedbackString', '');
          });
        }
      }
    },

    validateForm: function validateForm() {
      this.get('feedbackErrors').clear();
      var isValid = true;
      if (!this.get('feedbackString')) {
        isValid = false;
        this.get('feedbackErrors').pushObject('Please enter your feedback.');
      }
      return isValid;
    }
  });
});