define("mycare-ember/seekers/findhelp/job-listings/create/availability/controller", ["exports", "mycare-ember/seekers/findhelp/job-listings/controllers/availability"], function (exports, _availability) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _availability.default.extend({
        setup: function setup() {
            this.set('formData.availabilities', []);
            this.parentSetup();
        }
    });
});