define("mycare-ember/models/message", ["exports", "ember-data", "mycare-ember/config/environment"], function (exports, _emberData, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        avatar: _emberData.default.belongsTo('file', { async: true }),
        thread: _emberData.default.belongsTo('thread', { async: true }),
        text: _emberData.default.attr('string'),
        sentOn: _emberData.default.attr('string'),
        isIncludeProfile: _emberData.default.attr('boolean'),
        sender: _emberData.default.belongsTo('person', { async: true }),
        people: _emberData.default.attr(),
        isContract: _emberData.default.attr('boolean'),
        files: _emberData.default.hasMany('file', { async: true }),
        isSystemMessage: _emberData.default.attr('boolean'),
        isSentByMe: false,
        setIsSentByMe: function () {
            var self = this;
            Ember.RSVP.resolve(this.get('sender')).then(function (sender) {
                self.set('isSentByMe', self.get('id') !== null && sender !== null && localStorage.getItem(_environment.default.APP.AUTH_USER_KEY) === sender.get('id'));
            });
        }.observes('sender'),

        init: function init() {
            this.setIsSentByMe();
        }
    });
});