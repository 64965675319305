define('mycare-ember/mixins/utils-mixin', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        envFor: function envFor(key) {
            return this.get('env').getApp(key);
        }
    });
});