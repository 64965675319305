define('mycare-ember/seekers/findhelp/job-listings/detail/preview/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ['backTo', 'backToOpt'],
        backTo: null,
        backToOpt: null,

        actions: {
            onBack: function onBack() {
                if (!Ember.isNone(this.get('backTo'))) {
                    if (this.get('backToOpt')) {
                        this.transitionToRoute(this.get('backTo'), this.get('backToOpt'));
                    } else {
                        this.transitionToRoute(this.get('backTo'));
                    }
                } else {
                    this.transitionToRoute('seekers.findhelp.job-listings.edit', this.get('model.id'));
                }
            }
        }
    });
});