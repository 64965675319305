define("mycare-ember/utils/mc-component", ["exports", "mycare-ember/mixins/validator", "mycare-ember/utils/function-helpers", "ember-changeset", "ember-changeset-validations"], function (exports, _validator, _functionHelpers, _emberChangeset, _emberChangesetValidations) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_validator.default, {

        _subscriptions: [],

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('_isInitialised', false);
            this.initVar('_isInitialising', false);
        },
        initVar: function initVar(name, defaultValue) {
            if (Ember.isNone(this.get(name))) this.set(name, defaultValue);
        },
        ensureVar: function ensureVar(name) {
            Ember.Logger.assert(this.get(name) !== undefined, "Attribute '" + name + "' is none and it is required for " + Ember.inspect(this));
        },
        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            if (this.get('_isInitialised') === false && this.get('_isInitialising') === false) {
                this.set('_isInitialising', true);
                _functionHelpers.default.callOrIgnoreWithContext.apply(_functionHelpers.default, [this.willSetupOnce, this].concat(Array.prototype.slice.call(arguments)));
                this.toggleProperty('_isInitialised');
            }
        },
        isAttrChanged: function isAttrChanged(attrs, key) {
            if (!attrs.oldAttrs) return false;
            return attrs.newAttrs[key].value !== attrs.oldAttrs[key].value;
        },
        set: function set(key, val) {
            if (!this.isDestroyed) {
                this._super(key, val);
            }
        },
        willSetupOnce: function willSetupOnce() {},


        /**
         * Create event subscription to given publisher. Publisher are the object that conformed to Ember.Evented protocol.
         * Life cycle of subscriptions created by this method will be automatically managed and clean up afterward.
         * @param subscriptions
         */
        createSubscriptions: function createSubscriptions(subscriptions) {
            var _this = this;

            var self = this;
            subscriptions.forEach(function (_ref) {
                var publisher = _ref.publisher,
                    key = _ref.key,
                    callable = _ref.callable,
                    _ref$isEventBus = _ref.isEventBus,
                    isEventBus = _ref$isEventBus === undefined ? false : _ref$isEventBus;

                if (isEventBus) {
                    publisher.unsubscribe(key, callable);
                    publisher.subscribe(key, callable, false);
                } else {
                    publisher.off(publisher.get(key), self, callable);
                    publisher.on(publisher.get(key), self, callable);
                }
                _this.get('_subscriptions').concat(subscriptions);
            });
        },
        willDestroyElement: function willDestroyElement() {
            var _this2 = this;

            this.get('_subscriptions').forEach(function (_ref2) {
                var publisher = _ref2.publisher,
                    key = _ref2.key,
                    callable = _ref2.callable;

                publisher.off(key, _this2, callable);
                if (publisher.unsubscribe) {
                    publisher.unsubscribe(key, callable);
                }
            });
        },
        createChangeset: function createChangeset(model, validations) {
            return new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(validations), validations);
        }
    });
});