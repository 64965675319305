define('mycare-ember/components/mc-joblisting-card/browse/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('jobListing', undefined);
            this.initVar('onClick', undefined);
            this.initVar('favouritePersonId', undefined);

            this.initVar('_isFavourite', false);
        }
    });
});