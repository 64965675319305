define("mycare-ember/helpers/status-rename", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function statusRename(data) {
    var string = data[0];
    var timeString = data[1];
    var isAfter = null;
    if (!Ember.isNone(timeString)) {
      if (timeString.match('Z' + "$") !== 'Z') {
        timeString = timeString + 'Z';
      }
      isAfter = moment().utc().isAfter(moment(timeString).utc().toISOString());
    }
    if (string && (string.toLowerCase() === 'pending' || string.toLowerCase() === 'review')) {
      return 'Pending approval';
    } else if (isAfter === true && string && string.toLowerCase() === 'scheduled') {
      return 'Please submit';
    }
    return string;
  }

  exports.statusRename = statusRename;
  exports.default = Ember.Helper.helper(statusRename);
});