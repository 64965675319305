define("mycare-ember/seekers/manage/schedule-workers/index/new/add-payment/route", ["exports", "mycare-ember/mixins/protected_route", "mycare-ember/mixins/scroll_top"], function (exports, _protected_route, _scroll_top) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_protected_route.default, _scroll_top.default, {
        beforeModel: function beforeModel() {},

        model: function model() {
            var self = this;
            return self.store.findRecord('person', self.controllerFor('auth').get('profileId'));
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);
            controller.setup();
        },
        actions: {
            willTransition: function willTransition() {
                this.controller.set('creditCardError', false);
                this.controller.stopGetCreditCard();
                Ember.$('.modal-backdrop').remove();
            }
        }
    });
});