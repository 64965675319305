define('mycare-ember/services/utils/env', ['exports', 'mycare-ember/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        get: function get(name) {
            return _environment.default[name];
        },
        getApp: function getApp(name) {
            return _environment.default.APP[name];
        },
        isProd: function isProd() {
            return _environment.default.APP.ENV_NAME === 'production';
        },
        isAndroid: function isAndroid() {
            return _environment.default.APP.ENV_NAME === 'android';
        },
        isIOS: function isIOS() {
            return _environment.default.APP.ENV_NAME === 'ios';
        },
        isMobile: function isMobile() {
            return this.isAndroid() || this.isIOS();
        }
    });
});