define("mycare-ember/mixins/verifyemail", ["exports", "mycare-ember/mixins/defer"], function (exports, _defer) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_defer.default, {

    verifyemail: function verifyemail(uerid, spinnerid) {
      var self = this;
      if (spinnerid) {
        self.set(spinnerid, true);
      }
      var defer = this.getDeferWithToken();
      Ember.$.get(self.get('env').getApp('API_HOST') + '/api/logins/ResendEmailVerification?id=' + uerid).then(function (response) {
        defer.resolve(response);
      }, function (error) {
        defer.reject(error);
      });

      defer.promise.then(function () {
        if (spinnerid) {
          self.set(spinnerid, false);
        }
      }).catch(function () {
        if (spinnerid) {
          self.set(spinnerid, false);
        }
      }).finally(function () {
        if (spinnerid) {
          self.set(spinnerid, false);
        }
      });
    }
  });
});