define("mycare-ember/seekers/findhelp/job-listings/create/controller", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        _wizardService: Ember.inject.service('utils/seeker-listing-wizard'),

        actions: {
            onLeave: function onLeave() {
                this.send("onRouteLeave");
            }
        }
    });
});