define('mycare-ember/components/mc-timesheet/history/component', ['exports', 'mycare-ember/utils/mc-modal-component', 'ember-concurrency'], function (exports, _mcModalComponent, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend({
        init: function init() {
            this.initVar('_componentId', 'mc-timesheet-history');
            this._super.apply(this, arguments);
            this.initVar('channelId', 'global');
            this.initVar('isWorker', false);
        },
        _onBeforeOpen: function _onBeforeOpen(_ref) {
            var timesheet = _ref.timesheet,
                _ref$isWorker = _ref.isWorker,
                isWorker = _ref$isWorker === undefined ? false : _ref$isWorker;

            this.set('timesheet', timesheet);
            this.set('isWorker', isWorker);
        }
    });
});