define("mycare-ember/auth/controller", ["exports", "mycare-ember/mixins/defer", "mycare-ember/mixins/mixpanel"], function (exports, _defer, _mixpanel) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_defer.default, _mixpanel.default, {
        isRedirecting: false,
        previousTransition: null,
        isPNSRegistered: false,

        loggedInUserId: Ember.computed.alias('session.authData.loggedInUserId'),
        loggedInUser: Ember.computed.alias('session.authData.loggedInUser'),
        currentPerson: Ember.computed.alias('session.authData.currentPerson'),
        currentPersonId: Ember.computed.alias('session.authData.currentPersonId'),
        profileId: Ember.computed.alias('session.authData.currentPersonId'),
        currentProfileRole: Ember.computed.alias('session.authData.currentProfileRole'),
        profileType: Ember.computed.alias('session.authData.loggedInUser.profileType'),
        isSelfManaged: Ember.computed.alias('session.authData.isSelfManaged'),
        isAuthenticated: Ember.computed.alias('session.authData.isAuthenticated'),
        isSeeker: Ember.computed.alias('session.authData.isSeeker'),
        isWorkerOrganiser: Ember.computed.alias('session.authData.isWorkerOrganiser'),
        isCarer: Ember.computed.alias('session.authData.isWorker'),
        isWorker: Ember.computed.alias('session.authData.isWorker'),
        isMember: Ember.computed.alias('session.authData.isMember'),
        isCurrentlySeeker: Ember.computed.alias('session.authData.isCurrentlySeeker'),
        isCurrentlyWorker: Ember.computed.alias('session.authData.isCurrentlyWorker'),

        setup: function () {
            this.get('session').reconnectSessionOrLogout();
            this._watchIsAuthenticated();
        }.on('init'),

        numOfSupportingPeople: function () {
            var loggedInUser = this.get('loggedInUser');
            return loggedInUser.get('relationships').get('length') - 1;
        }.property('loggedInUser'),
        //refactor registerPNSId method into session server, make sure not call this multiple times
        _watchIsAuthenticated: function () {
            if (this.get('isAuthenticated') && !Ember.isEmpty(this.get('PNSId')) && this.get('isPNSRegistered') === false) {
                if (this.get('env').isMobile()) {
                    this.registerPNSId();
                }
            }
        }.observes('isAuthenticated', 'PNSId'),

        registerPNSId: function registerPNSId() {
            var _this = this;

            var self = this;
            self.getDeferWithToken();
            var registerPNSId = self.get('PNSId');
            var device = self.get('env').isAndroid() ? 'gcm' : 'apns';
            Ember.RSVP.resolve(Ember.$.get(this.get('env').getApp('API_HOST') + "/api/logins/PushNotificationRegister?pnsIdentifier=" + registerPNSId + "&device=" + device)).then(function () {
                _this.set('isPNSRegistered', true);
            });
        }
    });
});