define('mycare-ember/components/mc-timesheet/visit/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({

        timesheetSorting: ['sortingDate:asc'],
        _sortedGroupedTimesheets: Ember.computed.sort('_groupedTimesheets', 'timesheetSorting'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('timesheets', []);
            this.initVar('_groupedTimesheets', []);
        },
        didReceiveAttrs: function didReceiveAttrs() {
            this.groupTimesheetsByDate();
        },
        groupTimesheetsByDate: function groupTimesheetsByDate() {
            var groupedTimesheets = this.get('_groupedTimesheets');
            (this.get('timesheets') || []).forEach(function (timesheetWithDate) {
                var group = groupedTimesheets.find(function (group) {
                    return group.get('date') === timesheetWithDate.get('date');
                });
                if (Ember.isNone(group)) {
                    timesheetWithDate.set('sortingDate', moment(timesheetWithDate.get('date'), 'dddd D MMM').toISOString());
                    groupedTimesheets.pushObject(timesheetWithDate);
                } else {
                    timesheetWithDate.get('items').forEach(function (item) {
                        if (!group.get('items').includes(item)) {
                            group.get('items').pushObject(item);
                        }
                    });
                }
            });
        }
    });
});