define('mycare-ember/models/subscription', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        url: _emberData.default.attr('string'),
        type: _emberData.default.belongsTo('subscription-type', { async: true }),
        registration: _emberData.default.belongsTo('registration', { async: true }),
        subscribedPeople: _emberData.default.attr(),
        count: _emberData.default.attr('number'),
        price: _emberData.default.attr('number'),
        subscriberKey: _emberData.default.attr('string'),
        isInvoice: _emberData.default.attr('string')
    });
});