define('mycare-ember/carers/manage/my-schedule/visits/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ['date', 'reload'],
        isShowAllTimesheet: false,

        init: function init() {
            var _this = this;

            this.get('eventbus').subscribe('worker-visits-reload', function (bool) {
                _this.setLoading(bool);
            }, false);
        },
        setLoading: function setLoading(bool) {
            this.set('_isLoading', bool);
        },

        actions: {
            onWeekChanged: function onWeekChanged(changes) {
                var _this2 = this;

                Ember.run.scheduleOnce('afterRender', this, function () {
                    var newDate = moment(changes.startDate).toISOString();
                    if (newDate !== _this2.get('date')) {
                        _this2.set('date', newDate);
                        _this2.set('_isLoading', true);
                        _this2.send('onRouteWeekChanged', changes);
                    }
                });
            },
            onTimesheetStatusFilter: function onTimesheetStatusFilter(val) {
                if (val) {
                    this.set('_isLoading', true);
                    this.transitionToRoute('carers.manage.my-schedule.visits.' + val);
                    this.set('_isLoading', false);
                }
            },
            toggleAllOrNoRemoveTimesheets: function toggleAllOrNoRemoveTimesheets() {
                if (this.get('isShowAllTimesheet')) {
                    this.get('model').set('allTimesheetsArray', this.get('model.allTimesheets'));
                } else {
                    this.get('model').set('allTimesheetsArray', this.get('model.allWithNoRemoveOrDeclinedTimesheets'));
                }
                this.set('isShowAllTimesheet', !this.get('isShowAllTimesheet'));
            }
        }
    });
});