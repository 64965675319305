define('mycare-ember/mixins/file-uploader-board', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    fileUploadUrl: function () {
      return this.get('env').getApp('API_HOST') + '/api/FileUpload';
    }.property(),

    filesUploadErrors: [],
    // only use for two way binding with other controller
    parentObj: {},

    hasUploadErrors: function () {
      return this.get('filesUploadErrors.length') > 0;
    }.property('filesUploadErrors.[]'),

    uploadcomplete: function () {
      var percent = this.get('percent') + '%';
      return Ember.String.htmlSafe("width: " + percent);
    }.property('percent'),

    isUploading: function () {
      Ember.$('#uploaded-file-picker').modal('hide');
      Ember.$('#modal-file-picker').modal('hide');
      return this.get('percent') > 0;
    }.property('percent'),

    getUploadPercent: function () {
      return parseInt(this.get('percent')) + '%';
    }.property('percent'),

    actions: {
      upload: function upload(obj) {
        var self = this;
        self.send('setSuccessMessage', '');
        var file = self.store.createRecord('file', {
          url: obj.url,
          fileName: obj.fileName,
          uploadedOn: moment.utc(new Date()).toISOString(),
          uploadedByPerson: self.get('person.id')
        });
        file.save().then(function (file) {
          self.send('toggleIsAdd');
          self.get('parentObj.files').pushObject(file);
          self.get('person.files').pushObject(file);
          Ember.$('#uploaded-file-picker').modal('hide');
          Ember.$('#modal-file-picker').modal('hide');
        }, function () {
          toastr.error('There was a problem adding your file, please try again');
        });
      },

      showUploadModal: function showUploadModal() {
        Ember.$('.uploader').click();
      },

      checkFile: function checkFile(text) {
        this.get('filesUploadErrors').pushObject(text);
      },

      emptyErrors: function emptyErrors() {
        this.set('filesUploadErrors', []);
      },

      toggleIsAdd: function toggleIsAdd() {
        this.set('percent', 0);
        this.set('filesUploadErrors', []);
        this.set('isAdd', !this.get('isAdd'));
      },

      setSuccessMessage: function setSuccessMessage(message) {
        this.set('filesUploadErrors', []);
        this.set("successMessage", message);
      },

      showFilePicker: function showFilePicker() {
        Ember.$('.uploader').trigger('click');
      }
    }
  });
});