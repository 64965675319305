define("mycare-ember/carers/manage/my-schedule/schedule-detail/note/controller", ["exports", "mycare-ember/utils/common", "ember-concurrency", "mycare-ember/utils/Validators", "mycare-ember/mixins/validator"], function (exports, _common, _emberConcurrency, _Validators, _validator) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_validator.default, _Validators.default, {
        submitService: Ember.inject.service('utils/worker-timesheet-submit-wizard'),
        formData: {},
        init: function init() {
            this._setupValidations();
        },
        setupController: function setupController() {
            this.set('formData.hasNote', false);
            this.set('formData.note', undefined);
            var note = this.get('submitService.note');
            if (!Ember.isEmpty(note)) {
                this.set('formData.hasNote', true);
                this.set('formData.note', note.get('description'));
                Ember.run.schedule("afterRender", this, function () {
                    Ember.$('#collapseNotes').collapse('toggle');
                });
            }
            this._setupValidations();
        },
        _setupValidations: function _setupValidations() {
            this._resetValidation();
            this.addValidation('formData.note', 'note', 'Note', [_Validators.default.isRequired]);
        },


        actions: {
            ShowNote: function ShowNote() {
                this.set('formData.hasNote', true);
            },
            noAction: function noAction() {
                this.set('formData.hasNote', false);
                this.nextStep();
            },
            onNavBack: function onNavBack() {
                this.get('submitService').goStep(0);
            },
            onNext: function onNext() {
                if (!this._validate()) {
                    return;
                }
                this.get('submitService.addNoteAsync').perform({
                    noteCategoryId: 1,
                    description: this.get('formData.note')
                });
                this.nextStep();
            }
        },

        nextStep: function nextStep() {
            this.get('submitService').goStep(2);
        }
    });
});