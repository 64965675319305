define("mycare-ember/threads/index/controller", ["exports", "mycare-ember/mixins/nav-mode", "mycare-ember/utils/common"], function (exports, _navMode, _common) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_navMode.default, {
        auth: Ember.inject.controller('auth'),
        threads: [],
        messageSortingDesc: ['hasUnReadMessages:desc', 'message.sentOn:desc'],
        sortedThread: Ember.computed.sort('threads', 'messageSortingDesc'),
        relatedWith: [],
        pageSize: 10,
        offset: 0,
        isThreadLoading: true,
        isMore: true,
        announcementModalId: _common.default.createGuid(),

        actions: {
            onRecipientSelected: function onRecipientSelected(recipients) {
                this.set('selectedValues', recipients);
            }
        }
    });
});