define('mycare-ember/models/post', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        title: _emberData.default.attr('string'),
        image: _emberData.default.belongsTo('image', { async: true }),
        postBody: _emberData.default.attr('string'),
        comments: _emberData.default.hasMany('comment', { async: true }),
        circleMember: _emberData.default.belongsTo('person-circle', { async: true }),
        circle: _emberData.default.belongsTo('circle', { async: true }),
        createdOn: _emberData.default.attr('string'),
        modifiedOn: _emberData.default.attr('string'),
        circleMemberFirstName: _emberData.default.attr('string'),
        circleMemberAvatar: _emberData.default.belongsTo('image', { async: true })
    });
});