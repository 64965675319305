define("mycare-ember/seekers/findhelp/applicants/index/workers/route", ["exports", "mycare-ember/mixins/seeker_only", "mycare-ember/utils/mc-protected-route"], function (exports, _seeker_only, _mcProtectedRoute) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcProtectedRoute.default.extend(_seeker_only.default, {
        _workerService: Ember.inject.service('worker'),

        model: function model() {
            return this.modelFor('seekers.findhelp.applicants.index');
        }
    });
});