define("mycare-ember/models/transaction", ["exports", "ember-data"], function (exports, _emberData) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        timesheet: _emberData.default.attr('string'),
        createdOn: _emberData.default.attr('string'),
        account: _emberData.default.belongsTo('account', { async: true }),
        transactionType: _emberData.default.belongsTo('transaction-type', { async: true }),
        periodEndsOn: _emberData.default.attr('string'),
        periodStartsOn: _emberData.default.attr('string'),
        amount: _emberData.default.attr('number'),
        balance: _emberData.default.attr('number'),
        description: _emberData.default.attr('string'),

        init: function init() {
            this._super.apply(this, arguments);
            this._loadProperties();
        },


        isCredit: function () {
            return this.get('amount') >= 0;
        }.property('amount'),

        _loadProperties: function () {
            var _this = this;

            Ember.RSVP.resolve(this.get('transactionType')).then(function (transactionType) {
                _this.set('isVisit', transactionType.get('name').toLowerCase() === 'visit');
                _this.set('isPayout', transactionType.get('name').toLowerCase() === 'payment');
            });
        }.observes('transactionType'),

        //    isMyCareFee: function(){
        //        return this.get('transactionType.name').toLowerCase()==='mycare fee';
        //    }.property('transactionType'),

        payout: function () {
            return Math.abs(this.get('amount'));
        }.property('amount')

    });
});