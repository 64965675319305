define('mycare-ember/helpers/time-during', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    function timeDuring(data) {
        var time = data[0] / 60;
        if (Ember.isBlank(time)) {
            return '';
        }
        var hourAndMinute = time.toString().split('.');
        var hour = hourAndMinute[0];
        var minute = 0;
        if (hourAndMinute.length > 0) {
            minute = hourAndMinute[1];
        }
        var hourString = hour + 'h';
        var minuteString = '';
        if (minute !== undefined && minute !== 0) {
            minuteString = data[0] % 60 + 'm';
            return hourString + ' ' + minuteString;
        } else {
            return hourString;
        }
    }

    exports.timeDuring = timeDuring;
    exports.default = Ember.Helper.helper(timeDuring);
});