define('mycare-ember/components/ui/mc-infinite-scroll/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/function-helpers'], function (exports, _mcComponent, _functionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.initVar('onLoad', undefined);
            this.initVar('visibleHeight', undefined);
            this.initVar('channelId', undefined);
            this.initVar('enableScrollOptimization', false);
            this.initVar('isScrollOnPage', false);
            this.initVar('_lastScrollTop', 0);
            this.initVar('_fired', false);
            this.initVar('_scrollMarkMarginOnPageScroll', 50);
            this.initVar('_scrollMarkMarginTop', 40);
            this.initVar('_isDisabledScrolling', true);

            this.get('eventbus').subscribe('mc-infinite-scroll:on:load:' + this.get('channelId'), function () {
                Ember.run.scheduleOnce('afterRender', function () {
                    _this.set('_isDisabledScrolling', true);
                    if (_this.$('.scroller')) {
                        _this.$('.scroller').css('paddingTop', 10000);
                    }
                });
            }, false);
            this.get('eventbus').subscribe('mc-infinite-scroll:on:ready:' + this.get('channelId'), function () {
                Ember.run.scheduleOnce('afterRender', function () {
                    if (_this.$('.scroller')) {
                        _this.$('.scroller').css('paddingTop', 0);
                        _this.set('_isDisabledScrolling', false);
                        var $container = Ember.$(window);
                        $container.scroll(_this._handleScroll.bind(_this));
                    }
                });
            }, false);
        },
        didInsertElement: function didInsertElement() {
            this._setupContainer();
        },
        _setupContainer: function _setupContainer() {
            if (Ember.isNone(this.get('visibleHeight'))) {
                this.set('visibleHeight', window.innerHeight + window.innerHeight * 0.2);
            }
            var $container = null;
            if (this.get('isScrollOnPage') === true) {
                $container = Ember.$(window);
                if (this.get('enableScrollOptimization')) {
                    this.$('.scroller').css('paddingTop', 10000);
                    this.$('.scroller').css('overflow', 'hidden');
                    this.set('_isDisabledScrolling', true);
                    $container.scroll(this._handleScroll.bind(this));
                } else {
                    this.set('_isDisabledScrolling', false);
                    $container.scroll(this._handleScroll.bind(this));
                }
            } else {
                $container = this.$('.scroll-container');
                $container.css('height', this.get('visibleHeight'));
                $container.css('maxHeight', this.get('visibleHeight'));
                $container.css('minHeight', this.get('visibleHeight'));
                $container.scroll(this._handleScroll.bind(this));
            }
        },
        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);
            this.$().off('scroll');
            Ember.$(window).unbind('scroll', this._handleScroll);
        },
        _handleScroll: function _handleScroll() {
            if (this.get('_isDisabledScrolling') && $('.scroller').css('paddingTop') !== '0px') {
                $(window).scrollTop(0);
                return null;
            }
            var $container = this.get('isScrollOnPage') === true ? Ember.$(window) : this.$('.scroll-container');
            var $content = this.get('isScrollOnPage') === true ? Ember.$(document) : this.$('.scroll-content');

            var scrollTop = $container.scrollTop();
            var containerHeight = $container.height();
            var contentHeight = $content.height();
            var isScrollingUp = scrollTop < this.get('_lastScrollTop');

            var scrollMarkMargin = this.get('isScrollOnPage') === true ? this.get('_scrollMarkMarginOnPageScroll') : 0;

            if (isScrollingUp) {
                if (scrollTop < this.get('_scrollMarkMarginTop')) {
                    if (!this.get('_fired')) {
                        _functionHelpers.default.callOrIgnore(this.get('onScrollUp'));
                        _functionHelpers.default.callOrIgnore(this.get('onScroll'));
                        this.set('_fired', true);
                    }
                } else {
                    this.set('_fired', false);
                }
            } else {
                if (scrollTop + containerHeight >= contentHeight - scrollMarkMargin) {
                    if (!this.get('_fired')) {
                        _functionHelpers.default.callOrIgnore(this.get('onScrollDown'));
                        _functionHelpers.default.callOrIgnore(this.get('onScroll'));
                        this.set('_fired', true);
                    }
                } else {
                    this.set('_fired', false);
                }
            }
            this.set('_lastScrollTop', scrollTop);
        }
    });
});