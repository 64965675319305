define('mycare-ember/utils/common', ['exports', 'mycare-ember/utils/function-helpers'], function (exports, _functionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        createGuid: function createGuid() {
            function s4() {
                return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
            }

            return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
        },

        isNumber: function isNumber(n) {
            return !isNaN(parseFloat(n)) && isFinite(n);
        },

        parseAddressFromMap: function parseAddressFromMap(location) {
            if (Ember.isNone(location)) {
                return null;
            }
            var locations = location.split(', ');
            return {
                suburb: locations[0],
                city: locations[locations.length === 1 ? 0 : 1]
            };
        },
        closeBrowserTab: function closeBrowserTab(env) {
            if (env.isMobile()) {
                window.history.back();
            } else {
                window.close();
            }
        },
        scrollToFirstErrorInput: function scrollToFirstErrorInput() {
            var selector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '.has-error';
            var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 40;
            //jshint ignore:line
            Ember.run.later(function () {
                var firstErrorElem = Ember.$(selector)[0];
                firstErrorElem.scrollIntoView(true);
                var scrolledY = window.scrollY;
                if (scrolledY) {
                    window.scroll(0, scrolledY - offset);
                }
            }, 100);
        },
        decodeLocation: function decodeLocation(location) {
            var defer = Ember.RSVP.defer();
            GMaps.geocode({
                address: location,
                callback: function callback(results, status) {
                    if (status === 'OK') {
                        var latlng = results[0].geometry.location;
                        defer.resolve({
                            lat: latlng.lat(),
                            lng: latlng.lng()
                        });
                    } else {
                        defer.reject(status);
                    }
                }
            });
            return defer.promise;
        },
        roundToTwo: function roundToTwo(num) {
            return +(Math.round(num + "e+2") + "e-2");
        },


        /**
         * Generate options list for mc-select component
         * @param models list of orginal data
         * @param modelName name of the type of model to use in prompt message
         * @param labelKey property path for label in each model
         * @param valueKey property path for value in each model
         * @param prompt custom prompt message that override the default one with modelName
         * @param isIncludeNew include option for creating new mode
         * @returns list of options that can be passed to mc-select component
         */
        createSelectOptions: function createSelectOptions(_ref) {
            var models = _ref.models,
                modelName = _ref.modelName,
                labelKey = _ref.labelKey,
                _ref$valueKey = _ref.valueKey,
                valueKey = _ref$valueKey === undefined ? 'id' : _ref$valueKey,
                prompt = _ref.prompt,
                _ref$isIncludeNew = _ref.isIncludeNew,
                isIncludeNew = _ref$isIncludeNew === undefined ? true : _ref$isIncludeNew;
            //jshint ignore:line
            Ember.Logger.assert(!Ember.isNone(models), 'models is required');
            Ember.Logger.assert(!Ember.isNone(modelName), 'modelName is required');
            Ember.Logger.assert(!Ember.isNone(labelKey), 'labelKey is required');
            Ember.Logger.assert(!Ember.isNone(valueKey), 'valueKey is required');
            var selectOptions = Ember.A([Ember.Object.create({
                value: -1,
                label: prompt ? prompt : 'Select an ' + modelName,
                record: null
            })]);
            if (isIncludeNew === true) {
                selectOptions.pushObject(Ember.Object.create({
                    value: 0,
                    label: 'Create a new ' + modelName,
                    record: null
                }));
            }
            models.forEach(function (model) {
                return selectOptions.pushObject(Ember.Object.create({
                    value: model.get(valueKey),
                    label: model.get(labelKey),
                    record: model
                }));
            });
            return selectOptions;
        },
        formDateForMessageOrNotification: function formDateForMessageOrNotification(date) {
            var sentOn = moment(date);
            var dayDiff = moment().diff(sentOn, 'days');

            //today
            if (sentOn.date() === moment().date()) {
                return sentOn.format('h:mm a');
            }

            //last 7 days
            if (dayDiff < 7) {
                return sentOn.format('ddd [at] h:mm a');
            }

            //this year
            if (sentOn.year() === moment().year()) {
                return sentOn.format('MMM D [at] h:mm a');
            }

            return sentOn.format('D/MM/YY');
        },
        broadcastMessageToTab: function broadcastMessageToTab(command, data) {
            localStorage.setItem('message', JSON.stringify({
                command: command, data: data
            }));
            localStorage.removeItem('message');
        },
        subscribeToTabEvent: function subscribeToTabEvent(command, fn) {
            console.log('Subscribe tab event: ' + command);
            Ember.$(window).on('storage', function (ev) {
                if (ev.originalEvent.key !== 'message') return; // ignore other keys
                var message = JSON.parse(ev.originalEvent.newValue);
                if (!message) return; // ignore empty msg or msg reset
                // act on messages
                if (message.command === command) {
                    _functionHelpers.default.callOrIgnore(fn, message);
                }
            });
        },
        arrayBufferToBase64: function arrayBufferToBase64(buffer) {
            var binary = '';
            var bytes = new Uint8Array(buffer);
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
        },


        respondToVisibility: function respondToVisibility(element, callback) {
            var self = this;
            var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
            var observer = new MutationObserver(function () {
                callback.call(self, Ember.$(element).is(':visible'));
            });
            observer.observe(element, { attributes: true, childList: true, characterData: true, subtree: true });
        },

        playNotification: function playNotification() {
            var filename = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "light";

            document.getElementById('notification-tone').innerHTML = '\n            <audio autoplay="autoplay"><source src="sounds/' + filename + '.mp3" type="audio/mpeg" />\n            <source src="sounds/' + filename + '.ogg" type="audio/ogg" />\n            <embed hidden="true" autostart="true" loop="false" src="sounds/' + filename + '.mp3" /></audio>\n        ';
        }
    };
});