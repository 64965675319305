define('mycare-ember/carers/findwork/clients/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        _profileService: Ember.inject.service('profile'),

        setup: function setup() {
            this.setSearchLocation();
        },


        setSearchLocation: function () {
            var _this = this;

            if (Ember.isNone(this.get('session.authData.currentPersonId'))) return null;
            this.get('_profileService.getPersonByIdAsync').perform({ personId: this.get('session.authData.currentPersonId') }).then(function (person) {
                _this.set('searchLocation', person.get('location'));
            });
        }.observes('session.authData.currentPersonId'),

        actions: {
            removeFromFavourites: function removeFromFavourites(favouriteJob) {
                var self = this;
                self.store.findRecord('job-listing', favouriteJob.get('id')).then(function (job) {
                    self.get('worker').get('favouriteJobListings').removeObject(job);
                    self.get('worker').save().catch(function () {
                        toastr.error('There was an error removing favourite, please try again');
                    });
                });
            }

        }
    });
});