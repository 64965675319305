define("mycare-ember/components/mc-worker-suggestion/item/component", ["exports", "mycare-ember/utils/mc-component", "mycare-ember/utils/function-helpers"], function (exports, _mcComponent, _functionHelpers) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        tagName: '',

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('worker', undefined);
            this.initVar('onRemove', undefined);
            this.initVar('onBook', undefined);
            this.initVar('_isRemoving', undefined);
        },


        actions: {
            book: function book() {
                _functionHelpers.default.callOrIgnore(this.get('onBook'), this.get('worker'));
            },
            message: function message() {
                this.get('eventbus').publish('global:on:changeRoute', 'threads.detail', this.get('worker.thread.id'));
            }
        }
    });
});