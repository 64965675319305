define('mycare-ember/components/mc-nav/authenticated/left-menu-set/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('isSeeker', undefined);
            this.initVar('isWorker', undefined);
            this.initVar('currentPerson', undefined);
            this.initVar('numberOfUnReadMessages', 0);
        }
    });
});