define("mycare-ember/components/mc-worker-profile/hourly-rates/editor/component", ["exports", "mycare-ember/mixins/validator", "mycare-ember/utils/common", "mycare-ember/utils/mc-component"], function (exports, _validator, _common, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend(_validator.default, {
        _referenceModelService: Ember.inject.service('reference-models'),
        _modalHelper: Ember.inject.service('utils/modal-helper'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('worker', undefined);
            this.initVar('channelId', _common.default.createGuid());
            this.initVar('_modalChannelId', _common.default.createGuid());
            this.initVar('_cropperChannelId', _common.default.createGuid());
            this.initVar('_employmentTypes', []);
            this.initVar('_hourlyRates', []);
            this.initVar('_formData', {});
            this.initVar('_isSaving', false);
            this.initVar('_isLoadImageCropper', true);
            this._setupEvents();
        },
        _setupEvents: function _setupEvents() {
            var _this = this;

            var channelId = this.get('channelId');
            var openEvent = "mc-worker-profile/hourly-rates/editor:on:open:" + channelId;
            this.get('eventbus').subscribe(openEvent, function (worker) {
                if (worker) {
                    _this.set('worker', worker);
                }
                _this._setupFormData(_this.get('worker'));
                _this._open();
            }, false);

            this.get('eventbus').subscribe("mc-worker-profile/hourly-rates/editor:on:close:" + channelId, function () {
                _this._close();
            }, false);
        },
        _setupFormData: function _setupFormData(worker) {
            var _this2 = this;

            this.set('_formData', Ember.Object.create({
                hourlyRates: []
            }));
            this.get('_referenceModelService.getHourlyRatesAsync').perform().then(function (rates) {
                var existingHourlyRates = worker.get('hourlyRates');
                var hourlyRates = rates.map(function (item) {
                    var isChecked = !Ember.isNone(existingHourlyRates.find(function (i) {
                        return i.get('id') === item.get('id');
                    }));
                    return Ember.Object.create({
                        record: item,
                        isChecked: isChecked
                    });
                });
                _this2.set('_formData.hourlyRates', hourlyRates);
            });

            this._setupValidation();
        },
        _setupValidation: function _setupValidation() {
            this.addValidation('_formData.hourlyRates.@each.isChecked', 'hourlyRates', 'Hourly rate', [this._validateHourlyRate]);
        },
        _validateHourlyRate: function _validateHourlyRate(name) {
            var checkedItems = this.get('_formData.hourlyRates').filter(function (rate) {
                return rate.get('isChecked') === true;
            });
            var isValid = !Ember.isEmpty(checkedItems);
            return { result: isValid, message: name + " is required" };
        },
        _open: function _open() {
            this.get('_modalHelper').openModal(this.get('_modalChannelId'));
        },
        _close: function _close() {
            this.get('_modalHelper').closeModal(this.get('_modalChannelId'));
        },


        actions: {
            save: function save() {
                var _this3 = this;

                if (this._validate()) {
                    var promises = [];
                    Ember.RSVP.all(promises).then(function () {
                        if (_this3.isDestroyed) {
                            return;
                        }
                        _this3.set('_isSaving', true);
                        _this3.set('worker.hourlyRates', _this3.get('_formData.hourlyRates').filter(function (emp) {
                            return emp.get('isChecked') === true;
                        }).map(function (emp) {
                            return emp.get('record');
                        }));
                        _this3.get('worker').save().then(function () {
                            _this3.set('_isSaving', false);
                            _this3._close();
                        });
                    });
                }
            }
        }
    });
});