define("mycare-ember/seekers/manage/schedule-workers/index/new/contract/controller", ["exports", "mycare-ember/mixins/math-number-format"], function (exports, _mathNumberFormat) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_mathNumberFormat.default, {
        isAllowToRedirect: false,
        newVisitsController: Ember.inject.controller('seekers/manage/schedule-workers/index/new-timesheet'),
        isShowMainContractView: false,
        isNewVisit: true,
        error: {},
        duplicateVisitError: [],

        setup: function setup() {
            var self = this;
            if (Ember.isEmpty(self.get('model.contract')) === true) {
                self.transitionToRoute('seekers.manage.schedule-workers.index.new');
                return;
            }
            $("#modal-agree-and-create-visits").on("hidden.bs.modal", function () {
                self.set('error', {});
            });
            self.set('hourlyRate', self.get('model.contract.hourlyRate'));
            self.set('nightlyRate', self.get('model.contract.nightlyRate'));
            self.set('selectedAccount', self.get('model.contract.account'));
            self.set('isAllowToRedirect', false);
            self.set('duplicateVisitError', []);
            self.setClientString();
        },


        setClientString: function setClientString() {
            var self = this;
            var nameString = '';

            if (self.get('model.contract.createdBy.id') === self.get('model.contract.clientPerson.id')) {
                nameString = self.get('model.contract.clientName') + ' ("Client")';
                self.set('clientNameString', nameString.htmlSafe());
            } else {
                nameString = self.get('model.contract.createdBy.name') + ' on behalf of ' + self.get('model.contract.clientPerson.name') + ' ("Client")';
                self.set('clientNameString', nameString.htmlSafe());
            }
        },

        watchRateFees: function () {
            var self = this;
            if (self.get('hourlyRate') >= 0 && Ember.isEmpty(self.get('hourlyRate')) === false && self.get('selectedAccount.accountType.hasServiceFee')) {
                self.set('hourlyFee', self.getRound(self.get('hourlyRate') * self.get('selectedAccount.accountType.getServiceFee'), 2));
                self.set('hourlyFeeTotal', parseFloat(self.get('hourlyRate')) + parseFloat(self.get('hourlyFee')));
            }
            if (self.get('nightlyRate') >= 0 && Ember.isEmpty(self.get('nightlyRate')) === false && self.get('selectedAccount.accountType.hasServiceFee')) {
                self.set('nightlyFee', self.getRound(self.get('nightlyRate') * self.get('selectedAccount.accountType.getServiceFee'), 2));
                self.set('nightlyFeeTotal', parseFloat(self.get('nightlyRate')) + parseFloat(self.get('nightlyFee')));
            }
        }.observes('hourlyRate', 'nightlyRate', 'selectedAccount'),

        hasHourlyRate: function () {
            return this.get('model.contract.hourlyRate') > 0 && !Ember.isEmpty(this.get('model.contract.hourlyRate'));
        }.property('model.contract.hourlyRate'),

        hasFixedRate: function () {
            return this.get('model.contract.nightlyRate') > 0 && !Ember.isEmpty(this.get('model.contract.nightlyRate'));
        }.property('model.contract.nightlyRate'),

        actions: {
            signContract: function signContract() {
                var self = this;
                Ember.$('.modal').modal('hide');
                Ember.$('#modal-agree-and-create-visits').modal('show');
                var contract = self.get('model.contract');
                contract.get('account').then(function (account) {
                    self.set('newVisitsController.isSaving', true);
                    account.save().then(function () {
                        contract.save().then(function (thisContract) {
                            self.set('isAllowToRedirect', true);
                            self.set('newVisitsController.selectedContract', thisContract);
                            self.set('newVisitsController.isSaving', false);
                            self.get('newVisitsController').createTimeSheetsOrContract();
                        });
                    }, function () {
                        self.set('error.accountError', "Please add a card, before creating contract.");
                        self.set('newVisitsController.isSaving', false);
                    });
                });
            },
            leaveAnyway: function leaveAnyway() {
                this.set('isAllowToRedirect', true);
                this.get('leavingTransition').retry();
                Ember.$('#modal-stop-route-change').modal('hide');
                Ember.$('#modal-agree-and-create-visits').modal('hide');
            },
            toggleMainContractView: function toggleMainContractView() {
                this.toggleProperty('isShowMainContractView');
                var element = document.getElementById("scrollTop");
                element.scrollIntoView(true);
                window.scrollBy(0, -55);
            }
        }
    });
});