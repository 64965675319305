define('mycare-ember/controllers/partials/manage/timesheet-activity-edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    auth: Ember.inject.controller('auth'),
    isPayment: false,

    actions: {
      save: function save() {
        var self = this;
        self.get('model').save();
      },
      remove: function remove() {
        var self = this;
        self.get('model').deleteRecord();
        self.get('model').save();
      }
    }
  });
});