define('mycare-ember/components/mc-thread/announcement/component', ['exports', 'mycare-ember/utils/mc-modal-component'], function (exports, _mcModalComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend({
        init: function init() {
            //_componentId needs to be declared before super
            this.initVar('_componentId', 'mc-thread-announcement');
            this._super.apply(this, arguments);
            this.initVar('channelId', undefined);
        },
        _onAfterClosed: function _onAfterClosed() {
            localStorage.setItem('thread-announcement-ack', true);
        }
    });
});