define('mycare-ember/controllers/partials/manage/timesheet/transaction-row', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        auth: Ember.inject.controller('auth'),
        application: Ember.inject.controller('application'),
        invoiceController: Ember.inject.controller('partials/manage/timesheet/invoice'),

        isMyCareFee: function () {
            return this.get('model.transactionType.name').toLowerCase() === 'mycare fee';
        }.property(),

        actions: {
            viewInvoiceDetail: function viewInvoiceDetail() {
                var self = this;
                if (self.get('auth.isCurrentlySeeker')) {
                    self.transitionToRoute('seekers.manage.schedule-workers.index.schedule-detail.invoice');
                } else if (self.get('auth.isCurrentlyWorker')) {
                    self.transitionToRoute('carers.manage.my-schedule.schedule-detail.invoice');
                }
            }
        }
    });
});