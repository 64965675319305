define('mycare-ember/components/mc-mobile-timepicker-modal/component', ['exports', 'mycare-ember/utils/mc-modal-component', 'mycare-ember/utils/function-helpers', 'mycare-ember/utils/common'], function (exports, _mcModalComponent, _functionHelpers, _common) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend({
        init: function init() {
            this.set('_componentId', 'mc-mobile-timepicker-modal');
            this._super.apply(this, arguments);

            this.initVar('onSet', undefined);
            this.initVar('hasError', false);
            this.initVar('initialValue', undefined);
            this.initVar('isDisabled', false);
            this.initVar('selectedDate', undefined);

            this.set('_selectedDate', undefined);
            this.set('channelId', _common.default.createGuid());

            this._initCurrentTime();
        },
        _initCurrentTime: function _initCurrentTime() {
            if (Ember.isNone(this.get('initialValue'))) return;
            this.set('_selectedDate', this.get('initialValue'));
        },


        _watchInitialValue: function () {
            this._initCurrentTime();
        }.observes('initialValue'),

        _onAfterOpened: function _onAfterOpened() {
            var _this = this;

            Ember.run.later(function () {
                _this.set('_renderTimePicker', true);
                _functionHelpers.default.callOrIgnore(_this.get('onSet'), _this.get('_selectedDate'));
            }, 200);
        },


        actions: {
            set: function set() {
                _functionHelpers.default.callOrIgnore(this.get('onSet'), this.get('_selectedDate'));
                this._close();
            },
            openModal: function openModal() {
                this.get('eventbus').publish('mc-mobile-timepicker-modal:on:open:' + this.get('channelId'));
            },
            onPickerChanged: function onPickerChanged(date) {
                var selectedDate = moment(this.get('selectedDate') || moment());
                var momentDate = moment(date);
                momentDate = momentDate.set({
                    'year': selectedDate.year(),
                    'month': selectedDate.month(),
                    'date': selectedDate.date()
                });
                var oldValue = this.get('_selectedDate');
                this.set('_selectedDate', momentDate.toISOString());
                if (Ember.isNone(oldValue)) {
                    _functionHelpers.default.callOrIgnore(this.get('onSet'), this.get('_selectedDate'));
                }
            }
        }
    });
});