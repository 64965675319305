define('mycare-ember/mixins/sort-timesheet-carer-mixin', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        validTimesheetTotal: 0,
        validTimesheetTotalHours: 0,
        validTimesheetTotalOvernight: 0,
        validTimesheetTotalPrice: 0,

        setSummary: function () {
            var self = this;
            var validTimesheets = self.get('sortedContent');

            self.setTotalValidHoursAndPrices(validTimesheets);
        }.observes('sortedContent.@each.timesheetStatuses.[]', 'sortedContent.@each.actualDuration'),

        setTotalValidHoursAndPrices: function setTotalValidHoursAndPrices(validTimesheets) {
            var self = this;
            self.set('validTimesheetTotalHours', 0);
            self.set('validTimesheetTotalOvernight', 0);
            self.set('validTimesheetTotalPrice', 0);
            validTimesheets = validTimesheets.filter(function (timesheet) {
                return Ember.isEmpty(timesheet.get('timesheetStatuses').filter(function (s) {
                    return s.get('timesheetStatusType.id') === '6';
                }));
            });
            validTimesheets.forEach(function (timesheet) {
                if (timesheet.get('isOvernightActual') === true) {
                    self.set('validTimesheetTotalOvernight', self.get('validTimesheetTotalOvernight') + 1);
                    self.set('validTimesheetTotalPrice', parseFloat(self.get('validTimesheetTotalPrice')) + parseFloat(timesheet.get('totalCostWorker')));
                } else {
                    self.set('validTimesheetTotalHours', self.get('validTimesheetTotalHours') + timesheet.get('actualDuration'));
                    self.set('validTimesheetTotalPrice', parseFloat(self.get('validTimesheetTotalPrice')) + parseFloat(timesheet.get('totalCostWorker')));
                }
            });
            self.set('validTimesheetTotal', validTimesheets.get('length'));
        }
    });
});