define('mycare-ember/models/registration', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        person: _emberData.default.belongsTo('person'),
        dateOfBirth: _emberData.default.attr('string'),
        benefitAmount: _emberData.default.attr('number'),
        gender: _emberData.default.attr('string'),
        address: _emberData.default.attr('string'),
        phoneNumber: _emberData.default.attr('string'),
        uniqueId: _emberData.default.attr('string'),
        relationship: _emberData.default.attr('string'),
        referrer: _emberData.default.attr('string'),
        hasPartner: _emberData.default.attr('boolean', { defaultValue: false }),
        hasChildren: _emberData.default.attr('boolean', { defaultValue: false }),
        atHome: _emberData.default.attr('boolean', { defaultValue: false }),
        organisation: _emberData.default.belongsTo('organisation', { async: true }),
        fundingHost: _emberData.default.belongsTo('funding-host', { async: true }),
        isUsed: _emberData.default.attr('boolean', { defaultValue: true }),
        isAccountExist: _emberData.default.attr('boolean'),
        ethnicities: _emberData.default.hasMany('ethnicity', { async: true }),
        disabilities: _emberData.default.hasMany('disability', { async: true }),
        fundingTypes: _emberData.default.hasMany('funding-type', { async: true }),
        benefitTypes: _emberData.default.hasMany('benefit-type', { async: true }),
        registrationType: _emberData.default.belongsTo('registration-type', { async: true }),
        verificationCode: _emberData.default.attr('string')
    });
});