define('mycare-ember/seekers/findhelp/job-listings/edit/price/controller', ['exports', 'mycare-ember/seekers/findhelp/job-listings/controllers/price'], function (exports, _price) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _price.default.extend({
        setup: function setup() {
            this.set('formData.hourlyRateAmount', this.get('jobListing.hourlyRateAmount') ? this.get('jobListing.hourlyRateAmount') : 0);
            if (this.get('jobListing.hourlyRateAmount') == 0 || Ember.isEmpty(this.get('jobListing.hourlyRateAmount'))) {
                this.set('formData.isVolunteer', true);
            }
            this.parentSetup();
        }
    });
});