define('mycare-ember/models/invite', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        personId: _emberData.default.attr('number'),
        firstName: _emberData.default.attr('string'),
        email: _emberData.default.attr('string'),
        isAccepted: _emberData.default.attr('boolean'),
        isCovidVaccinationVerified: _emberData.default.attr('boolean')

    });
});