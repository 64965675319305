define("mycare-ember/carers/my-account/account-detail/reports/controller", ["exports", "mycare-ember/mixins/manage/account/reports"], function (exports, _reports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_reports.default, {
        auth: Ember.inject.controller('auth'),
        isWorker: true,
        profileId: function () {
            return this.get('auth.profileId');
        }.property()

    });
});