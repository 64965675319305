define("mycare-ember/components/mc-location-picker/component", ["exports", "mycare-ember/utils/mc-component"], function (exports, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('international', false);
            this.initVar('rawValue', undefined);
        },
        didInsertElement: function didInsertElement() {
            this._setup();
        },
        _setup: function _setup() {
            var self = this;
            var locationPicker = Ember.$('.cp-locationPicker-location');
            locationPicker.attr('data-valid', 'true');

            // hack to fix fastClick iOS issue
            // https://github.com/jshimko/meteor-geocomplete/issues/11
            // noinspection EqualityComparisonWithCoercionJS
            if (typeof window.google == 'undefined') {
                // jshint ignore:line
                Ember.run.later(function () {
                    self._setup();
                }, 1000);
            } else {
                Ember.$(document).on({
                    'DOMNodeInserted': function DOMNodeInserted() {
                        Ember.$('.pac-item, .pac-item span', this).addClass('needsclick');
                    }
                }, '.pac-container');

                // end hack
                if (self.get('international') === true) {
                    locationPicker.geocomplete({
                        types: ['(regions)']
                    }).bind("geocode:result", function () {
                        locationPicker.attr('data-valid', 'true');
                        self.set('location', locationPicker.val());
                    });
                } else if (self.get('international') === false) {
                    locationPicker.geocomplete({
                        types: ['(regions)'],
                        componentRestrictions: { country: "nz" }
                    }).bind("geocode:result", function () {
                        locationPicker.attr('data-valid', 'true');
                        self.set('location', locationPicker.val());
                    });
                }
                self.set('value', self.get('location'));
            }
        },


        _watchTempString: function () {
            this.set('rawValue', this.get('value'));
        }.observes('value'),

        _setLocation: function () {
            var self = this;
            var locationPicker = Ember.$('.cp-locationPicker-location');
            locationPicker.attr('data-valid', 'true');
            this.set('isLocationValid', true);
            self.set('value', self.get('location'));
        }.observes('location')
    });
});