define("mycare-ember/circles/detail/route", ["exports", "mycare-ember/mixins/protected_route"], function (exports, _protected_route) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_protected_route.default, {
        beforeModel: function beforeModel(transition) {
            this.isAuthenticated(transition);
        },


        model: function model(params) {
            return this.store.findRecord('circle', params.circle_id);
        },

        afterModel: function afterModel(model) {
            var self = this;
            var authCtrl = self.controllerFor('auth');
            if (model && authCtrl.get('isSeeker')) {
                self.transitionTo('seekers.manage.circles.detail', model);
            } else if (model && authCtrl.get('isWorker')) {
                self.transitionTo('carers.manage.circles.detail', model);
            }
        },
        setupController: function setupController(controller, model) {
            controller.set('model', model);
            controller.bindScrolling();
        },


        actions: {
            willTransition: function willTransition() {
                this.controller.unbindScrolling();
            }
        }
    });
});