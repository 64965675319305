define("mycare-ember/components/mc-nav/authenticated/back-button/component", ["exports", "mycare-ember/utils/function-helpers", "mycare-ember/utils/mc-component"], function (exports, _functionHelpers, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('backTo', undefined);
            this.initVar('backToOpts', undefined);
        },


        actions: {
            goBack: function goBack() {
                if (Ember.isNone(this.get('onBack'))) {
                    this.get('eventbus').publish('global:on:changeRoute', this.get('backTo'), this.get('backToOpts'));
                } else {
                    _functionHelpers.default.callOrIgnore(this.get('onBack'));
                }
            }
        }
    });
});