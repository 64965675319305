define("mycare-ember/me/account/controller", ["exports", "mycare-ember/mixins/nav-mode"], function (exports, _navMode) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_navMode.default, {
        auth: Ember.inject.controller('auth'),

        navPills: function () {
            var self = this;
            var css = '';
            if (self.get('auth.currentProfileRole') === self.get('env').getApp('WORKER_PROFILE_TYPE')) {
                css = 'nav-pills-worker';
            }
            return css;
        }.property('auth.currentProfileRole'),

        navClass: function () {
            var self = this;
            var navClass = '';
            if (self.get('auth.currentProfileRole') === self.get('env').getApp('WORKER_PROFILE_TYPE')) {
                navClass = 'carer-nav';
            } else {
                navClass = '';
            }
            return navClass;
        }.property('auth.currentProfileRole'),

        showFeedbackDialog: function showFeedbackDialog() {
            Ember.$("button.navbar-toggle").trigger("click");
            Ember.$('#modal-feedback').modal('show');
        },


        appVersion: function () {
            var env = this.get('env');
            return "Version: " + env.getApp('VERSION');
        }.property().volatile(),

        actions: {
            toPreviousRoute: function toPreviousRoute() {
                history.back();
            },
            toggleFeedbackDialog: function toggleFeedbackDialog() {
                this.showFeedbackDialog();
            }
        }
    });
});