define('mycare-ember/controllers/partials/job-events-board', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        workers: function () {
            var self = this;
            var promise = self.get('model.personJobs').then(function (eventMembers) {
                return eventMembers.filter(function (item) {
                    return item.get('role.name').toLowerCase() === 'worker';
                });
            });
            return DS.PromiseArray.create({
                promise: promise
            });
        }.property('model.personJobs.@each.role'),

        organisers: function () {
            var self = this;
            var promise = self.get('model.personJobs').then(function (eventMembers) {
                return eventMembers.filter(function (item) {
                    return item.get('role.name').toLowerCase() === 'organiser';
                });
            });
            return DS.PromiseArray.create({
                promise: promise
            });
        }.property('model.personJobs.@each.role')
    });
});