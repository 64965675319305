define("mycare-ember/components/mc-timesheet/event/component", ["exports", "mycare-ember/utils/mc-component"], function (exports, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({

        tagName: '',
        _timesheetService: Ember.inject.service('timesheet'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('isEditorActive', false);
            this.initVar('onUpdated', undefined);
        },


        actions: {
            showEventModal: function showEventModal() {
                var self = this;
                self.get('_timesheetService.getEventByIdAsync').perform({ eventId: self.get('model.eventId') }).then(function (event) {
                    self.get('eventbus').publish("mc-form-event-edit:on:open", event);
                });
            }
        }
    });
});