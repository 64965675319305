define("mycare-ember/helpers/time-difference", ["exports", "moment"], function (exports, _moment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.timeDifference = undefined;


    function timeDifference(data) {
        var endDate = data[0],
            startDate = data[1];

        if (Ember.isBlank(endDate) || Ember.isBlank(startDate)) {
            return '';
        }

        var duration = _moment.default.duration((0, _moment.default)(endDate).diff((0, _moment.default)(startDate)));
        var minutes = duration.asMinutes();
        return minutes / 60;
    }

    exports.timeDifference = timeDifference;
    exports.default = Ember.Helper.helper(timeDifference);
});