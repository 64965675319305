define("mycare-ember/seekers/manage/calendar/controller", ["exports", "mycare-ember/mixins/timesheet-calendar", "mycare-ember/mixins/sort-timesheet-seeker-mixin", "mycare-ember/utils/common"], function (exports, _timesheetCalendar, _sortTimesheetSeekerMixin, _common) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_timesheetCalendar.default, _sortTimesheetSeekerMixin.default, {
        isWorker: false,
        isScheduleView: true,
        isShowAll: false,
        isWeeklyShow: false,
        eventCreateChannelId: 'eventCreateChannelId',

        auth: Ember.inject.controller('auth'),
        newTimesheetController: Ember.inject.controller('seekers/manage/schedule-workers/index/new/index'),
        manageDateController: Ember.inject.controller('partials/manage/date-selected'),
        _channelId: _common.default.createGuid(),

        loginProfileId: function () {
            return this.get('auth.profileId');
        }.property(),

        setup: function setup() {
            this.watchSchedulePickerControllerTime();
            this.reloadModel();
        },


        watchSelectedEvent: function () {
            var self = this;
            if (!Ember.isEmpty(self.get('selectedEvent'))) {
                var selectedEvent = self.get('selectedEvent');
                if (selectedEvent.isEvent) {
                    self.store.findRecord('event', selectedEvent.id).then(function (event) {
                        self.get('eventbus').publish("mc-form-event-edit:on:open", event);
                    });
                } else {
                    self.store.findRecord('timesheet', selectedEvent.id).then(function (timesheet) {
                        self.transitionToRoute('seekers.manage.schedule-workers.index.schedule-detail', timesheet.get('id'), {
                            queryParams: {
                                backTo: 'seekers.manage.calendar'
                            }
                        });
                    });
                }
            }
        }.observes('selectedEvent'),

        reloadModel: function reloadModel() {
            var _this = this;

            this.set('isReload', true);
            Ember.run.scheduleOnce("afterRender", function () {
                _this.set('isReload', false);
            });
        },

        actions: {
            createNewTimesheet: function createNewTimesheet() {
                this.transitionToRoute('book');
            },

            createNewEvent: function createNewEvent() {
                this.get('eventbus').publish("modal-schedule-event-create:on:open:" + this.get('eventCreateChannelId'));
            },

            setWeeklyClick: function setWeeklyClick(bool) {
                this.set('isWeeklyShow', bool);
            },
            onUpdated: function onUpdated() {
                this.reloadModel();
            }
        }
    });
});