define("mycare-ember/me/profiles/someone-setup/payment/route", ["exports", "mycare-ember/mixins/protected_route", "mycare-ember/utils/wizard-step-route", "mycare-ember/mixins/scroll_top", "mycare-ember/utils/function-helpers"], function (exports, _protected_route, _wizardStepRoute, _scroll_top, _functionHelpers) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _wizardStepRoute.default.extend(_protected_route.default, _scroll_top.default, {
        stepName: 'payment',
        parentControllerName: 'me/profiles/someone-setup',

        setupController: function setupController(controller) {
            this._super.apply(this, arguments);
            this.scrollTop();
            _functionHelpers.default.callOrIgnoreWithContext(controller.setup, controller);
        }
    });
});