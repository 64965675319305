define("mycare-ember/browse/profiles/index/controller", ["exports", "mycare-ember/mixins/nav-mode"], function (exports, _navMode) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Controller.extend(_navMode.default, {
        _jobListingService: Ember.inject.service('job-listing'),
        _searchService: Ember.inject.service('search'),
        _markerService: Ember.inject.service('utils/marker'),

        queryParams: ['web', 'location'],
        location: undefined,

        zoom: 13,

        isLoading: false,
        searchRetryCount: 0,

        setup: function setup() {
            var _this = this;

            Ember.$(window).on('resize', function () {
                _this._resize();
            });
            this.set('searchRetryCount', 0);
        },


        isRedirect: function () {
            var self = this;
            if (self.get('web') === 'true') {
                self.mixpanelHomepageSearch(self.get('model.searchLocation'));
                return true;
            }
            return false;
        }.property(),

        markers: function () {
            var self = this;
            var profileMarkers = (self.get('model.searchResult') || []).map(function (item) {
                return self.get('_markerService').createProfileMarker(item, true);
            });
            var expMarkers = (self.get('model.experienceSearchResult') || []).map(function (item) {
                return self.get('_markerService').createExperienceMarker(item, true);
            });
            return [].concat(_toConsumableArray(profileMarkers), _toConsumableArray(expMarkers));
        }.property('model.searchResult.[]', 'model.experienceSearchResult.[]'),

        _resize: function _resize() {
            var resultContainer = Ember.$(".profile-card-container");
            if (window.innerHeight === window.outerHeight) {
                resultContainer.css({
                    height: '100vh',
                    maxHeight: '100vh'
                });
            } else {
                var bodyHeight = window.innerHeight - 108;
                resultContainer.css({
                    height: bodyHeight,
                    maxHeight: bodyHeight
                });
            }
        },


        actions: {
            search: function search(searchParameters) {
                this.set('isLoading', true);
                this.send('searchRoute', searchParameters);
                this.set('isLoading', false);
            },
            changeSearchType: function changeSearchType(type) {
                if (type === "profile") {
                    this.transitionToRoute('browse.profiles', { queryParams: { location: this.get('model.searchLocation') } });
                } else if (type === "job") {
                    this.transitionToRoute('browse.jobs', { queryParams: { location: this.get('model.searchLocation') } });
                }
            },
            onCoordinateChangedEvent: function onCoordinateChangedEvent(latLng, bounds) {
                this.set('isLoading', true);
                this.send('onCoordinateChangedRouteEvent', latLng, bounds);
                this.set('isLoading', false);
            },
            onMapResizedEvent: function onMapResizedEvent() {
                var self = this;
                Ember.$('.marker-hover').hover(function () {
                    var _this2 = this;

                    var aM = self.get('markers').find(function (m) {
                        return String(m.get('id')) === String($(_this2).data('id'));
                    });
                    self.set('activeMarker', aM);
                });
                this._resize();
            },
            redirectToJobListing: function redirectToJobListing() {
                var self = this;
                Ember.$('.modal').modal('hide');
                self.transitionToRoute('seekers.findhelp.job-listings.create');
            },
            toPublishJob: function toPublishJob() {
                Ember.$('#modal-profile').modal('hide');
                this.transitionTo('seekers.findhelp.job-listings.list');
            },
            toCreateJob: function toCreateJob() {
                Ember.$('#modal-profile').modal('hide');
                this.transitionTo('seekers.findhelp.job-listings.create');
            },
            handleProfile: function handleProfile(profileId) {
                var _this3 = this;

                var self = this;
                if (!this.get('session.authData.isAuthenticated')) {
                    Ember.$('#modal-user-not-login').modal('show');
                    return false;
                }
                Ember.$('#modal-user-not-login').modal('hide');
                Ember.RSVP.hash({
                    activeJobs: this.get('_jobListingService.findAllActiveAsync').perform({ personId: this.get('session.authData.currentPersonId') }),
                    inactiveJobs: this.get('_jobListingService.findAllInactiveAsync').perform({ personId: this.get('session.authData.currentPersonId') })
                }).then(function (_ref) {
                    var activeJobs = _ref.activeJobs,
                        inactiveJobs = _ref.inactiveJobs;
                    //jshint ignore:line
                    if (!Ember.isEmpty(activeJobs)) {
                        self.transitionToRoute('browse.profiles.detail', profileId);
                    } else if (!Ember.isEmpty(inactiveJobs)) {
                        _this3.set('modal', {
                            title: 'Your job is unlisted',
                            message: "You'll see their full profile as soon as you've listed your job."
                        });
                        _this3.set('hasDraftListing', true);
                        _this3.set('hasNoListing', false);
                        Ember.$('#modal-profile').modal('show');
                    } else if (Ember.isEmpty(activeJobs)) {
                        _this3.set('modal', {
                            title: 'Create a listing',
                            message: "You’ll see their full profile as soon as you’ve created a job."
                        });
                        _this3.set('hasNoListing', true);
                        _this3.set('hasDraftListing', false);
                        Ember.$('#modal-profile').modal('show');
                    }
                });
                return false;
            },
            onNavBack: function onNavBack() {
                if (Ember.isNone(this.get('backTo'))) {
                    this.transitionToRoute('browse.profiles.index');
                } else {
                    if (this.get('backTo') === 'true') {
                        this.transitionToRoute('browse.profiles.index');
                    } else {
                        this.get('eventbus').publish('global:on:changeRoute', this.get('backTo'), this.get('backToOpts'));
                    }
                }
            }
        }
    });
});