define("mycare-ember/components/mc-image-cropper/step-three/component", ["exports", "mycare-ember/utils/function-helpers", "mycare-ember/utils/mc-component"], function (exports, _functionHelpers, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({

        _httpService: Ember.inject.service('utils/http'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('onEnter', undefined);
            this.initVar('onChangeStep', undefined);
            this.initVar('file', undefined);
            this.initVar('_isLoading', false);
        },
        willSetupOnce: function willSetupOnce() {
            _functionHelpers.default.callOrIgnore(this.get('onEnter'));
        },


        actions: {
            uploadImage: function uploadImage() {
                var _this = this;

                var self = this;
                this.set('_isLoading', true);
                var data = this.get('file').replace(/^data:image\/png;base64,/, '');
                var imageUpload = {
                    imageUpload: {
                        data: data,
                        fileName: 'fileName.png'
                    }
                };
                this.get('_httpService.withTokenAsync').perform(function (client) {
                    var url = _this.get('env').getApp('API_HOST') + '/api/imageUploader';
                    return client.post(url, imageUpload);
                }).then(function (response) {
                    _functionHelpers.default.callOrIgnore(_this.get('onCompleted'), response.data.images.url);
                    self.set('_isLoading', false);
                }).catch(function () {
                    self.set('_isLoading', false);
                });
            },
            back: function back() {
                _functionHelpers.default.callOrIgnore(this.get('onChangeStep'), 2);
            }
        }
    });
});