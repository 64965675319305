define("mycare-ember/components/mc-worker-requirement-editor/component", ["exports", "mycare-ember/utils/mc-modal-component", "mycare-ember/utils/function-helpers"], function (exports, _mcModalComponent, _functionHelpers) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend({
        _referenceModelService: Ember.inject.service('reference-models'),

        init: function init() {
            var _this = this;

            //_componentId needs to be declared before super
            this.initVar('_componentId', 'mc-worker-requirement-editor');
            this._super.apply(this, arguments);
            this.initVar('channelId', undefined);
            this.initVar('worker', undefined);
            this.initVar('onSaved', undefined);
            this.initVar('_options', undefined);
            this.initVar('_equipments', []);
            this.get('_referenceModelService.getRequirementsAsync').perform().then(function (_allRequirement) {
                var initialValues = _this.get('worker.requirements');
                var options = _this.get('_options') ? _this.get('_options') : _allRequirement;
                var _requirements = options.map(function (item) {
                    item.set('isChecked', (initialValues || []).includes(item));
                    return item;
                });
                _this.set('_requirements', _requirements);
            });
        },
        _getSelectedValues: function _getSelectedValues() {
            return this.get('_requirements').filter(function (item) {
                return item.get('isChecked') === true;
            });
        },


        actions: {
            onSave: function onSave() {
                var _this2 = this;

                var selectedValues = this._getSelectedValues();
                if (selectedValues.every(function (item) {
                    return _this2.get('worker.requirements').includes(item);
                })) {
                    this._close();
                    return;
                }
                _functionHelpers.default.callOrIgnore(this.get('onSaved'), selectedValues);
                this._close();
            }
        }
    });
});