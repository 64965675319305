define("mycare-ember/components/mc-multi-select/component", ["exports", "mycare-ember/utils/function-helpers", "mycare-ember/utils/common", "mycare-ember/utils/mc-component"], function (exports, _functionHelpers, _common, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        _modalHelper: Ember.inject.service('utils/modal-helper'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('componentList', []);
            this.initVar('selectedItems', []);
            this.initVar('initialValues', []);
            this.initVar('showSelection', true);
            this.initVar('options', []);
            this.initVar('isEditMode', false);
            this.initVar('onChanged', undefined);
            this.initVar('_modalChannelId', _common.default.createGuid());
        },


        didInsertElement: Ember.on('didInsertElement', function () {
            this.get('_modalHelper').onShown(this.get('_modalChannelId'), function () {
                //Disable fast scrolling css, otherwise this css will block the modal for this modal
                Ember.$('body').removeClass('fast-scroll');
            });
            this.get('_modalHelper').onHide(this.get('_modalChannelId'), function () {
                // FunctionUtils.callOrIgnore(this.get('onChanged'), this.get('selectedItems'));
            });
            this.get('_modalHelper').onHidden(this.get('_modalChannelId'), function () {
                Ember.$('body').addClass('fast-scroll');
            });
            this.set('selectedItems', []); //Do not remove this line or face consequences|headaches
            this._reloadMultiSelect();
        }),

        didReceiveAttrs: Ember.on('didReceiveAttrs', function () {
            if (Ember.isNone(this.get('_modalChannelId'))) {
                this.set('_modalChannelId', new Date().getTime().toString());
            }
        }),

        _reloadMultiSelect: function () {
            var _this = this;

            var self = this;
            if (!Ember.isEmpty(self.get('options'))) {
                self.set('componentList', self.get('options'));
                if (self.get('initialValues.length') > 0) {
                    Ember.RSVP.all(self.get('initialValues').map(function (item) {
                        return Ember.RSVP.resolve(item);
                    })).then(function (initialValues) {
                        if (_this.isDestroyed) {
                            return;
                        }
                        initialValues.forEach(function (selectedItem) {
                            self.get('componentList').forEach(function (item) {
                                if (item.get('id') === selectedItem.get('id')) {
                                    item.set('isChecked', true);
                                    if (!self.get('selectedItems').includes(selectedItem)) {
                                        self.get('selectedItems').pushObject(selectedItem);
                                    }
                                } else {
                                    item.set('isChecked', false);
                                }
                            });
                        });
                    }).then(function () {
                        if (_this.isDestroyed) {
                            return;
                        }
                        _this.set('_originalSelectedItems', _this.get('selectedItems'));
                    });
                } else {
                    self.get('componentList').forEach(function (item) {
                        item.set('isChecked', false);
                    });
                }
            }
        }.observes('options.length'),

        _reloadSelectedValues: function _reloadSelectedValues() {
            var self = this;
            self.set('isEnableUpdate', false);
            self.get('componentList').forEach(function (item) {
                item.set('isChecked', false);
            });
            self.get('selectedItems').forEach(function (selectedItem) {
                self.get('componentList').forEach(function (item) {
                    if (!Ember.isNone(selectedItem) && item.get('id') === selectedItem.get('id')) {
                        item.set('isChecked', true);
                    }
                });
            });
            self.set('isEnableUpdate', true);
        },

        _update: Ember.observer('componentList.@each.isChecked', function () {
            var self = this;
            if (self.get('isEnableUpdate') === true) {
                self.get('selectedItems').clear();
                var list = self.get('componentList').filterBy('isChecked', true);
                self.get('selectedItems').pushObjects(list);
            }
        }),

        actions: {
            complete: function complete() {
                _functionHelpers.default.callOrIgnore(this.get('onChanged'), this.get('selectedItems'));
                this.get('_modalHelper').closeModal(this.get('_modalChannelId'));
            },
            toggleSelectionModal: function toggleSelectionModal() {
                this.get('_modalHelper').openModal(this.get('_modalChannelId'));
                this._reloadSelectedValues();
            },


            removeItem: function removeItem(id) {
                var self = this;
                self.get('componentList').forEach(function (item) {
                    if (item.get('id') === id) {
                        item.set('isChecked', !item.get('isChecked'));
                        self.get('selectedItems').removeObject(item);
                    }
                });
                _functionHelpers.default.callOrIgnore(this.get('onChanged'), this.get('selectedItems'));
            }
        }
    });
});