define('mycare-ember/utils/mc-modal-component', ['exports', 'mycare-ember/mixins/validator', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/common'], function (exports, _validator, _mcComponent, _common) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend(_validator.default, {

        _modalHelper: Ember.inject.service('utils/modal-helper'),

        init: function init() {
            this._super.apply(this, arguments);
            this.set('_modalChannelId', _common.default.createGuid());
            Ember.Logger.assert(!Ember.isNone(this.get('_componentId')), '_componentId is required for McModalComponent');
        },
        didInsertElement: function didInsertElement() {
            var _this = this;

            var channelId = this.get('_modalChannelId');
            var componentId = this.get('_componentId');

            this.get('_modalHelper').onHide(channelId, function () {
                _this._onBeforeClose();
                _this.get('eventbus').publish(componentId + ':event:onHide:' + channelId);
            });

            this.get('_modalHelper').onHidden(channelId, function () {
                _this._onAfterClosed();
                _this.get('eventbus').publish(componentId + ':event:onHidden:' + channelId);
            });
            this._setupEvents();
        },
        _setupEvents: function _setupEvents() {
            var _this2 = this;

            var channelId = this.get('channelId');
            var componentId = this.get('_componentId');

            var openEvent = componentId + ':on:open:' + channelId;
            this.get('eventbus').subscribe(openEvent, function (options) {
                Ember.RSVP.resolve(_this2._onBeforeOpen(options)).then(function () {
                    _this2._open();
                    _this2._onAfterOpened(options);
                });
            }, false);

            this.get('eventbus').subscribe(componentId + ':on:close:' + channelId, function () {
                _this2._close();
            }, false);
        },
        _open: function _open() {
            this.get('_modalHelper').openModal(this.get('_modalChannelId'));
        },
        _close: function _close() {
            this.get('_modalHelper').closeModal(this.get('_modalChannelId'));
        },
        _onBeforeOpen: function _onBeforeOpen() {},
        _onAfterOpened: function _onAfterOpened() {},
        _onBeforeClose: function _onBeforeClose() {},
        _onAfterClosed: function _onAfterClosed() {}
    });
});