define('mycare-ember/components/mc-auth/navbar/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/common'], function (exports, _mcComponent, _common) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('isShowSignup', undefined);
            this.initVar('isShowLogin', true);

            this.set('_signupTypeModalChannelId', _common.default.createGuid());
        },


        actions: {
            signup: function signup() {
                this.get('eventbus').publish('mc-auth/signup-type-modal:on:open:' + this.get('_signupTypeModalChannelId'));
            },
            login: function login() {
                this.get('eventbus').publish('global:on:changeRoute', 'index');
            }
        }
    });
});