define('mycare-ember/models/contract-detail', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        contract: _emberData.default.attr('string'),
        contractBody: _emberData.default.attr('string'),
        clientAgreedOn: _emberData.default.attr('string'),
        contractorAgreedOn: _emberData.default.attr('string')
    });
});