define('mycare-ember/seekers/manage/schedule-workers/index/new/note/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        descriptionLimit: 350,
        descriptionLeft: 350,
        duplicateVisitError: [],
        notes: [],
        newVisitsController: Ember.inject.controller('seekers/manage/schedule-workers/index/new-timesheet'),

        setup: function setup() {
            this.set('description', '');
            this.set('duplicateVisitError', []);
        },

        watchNotMessage: function () {
            this.set('descriptionLeft', this.get('descriptionLimit') - this.get('description.length'));
        }.observes('description'),

        actions: {
            addNote: function addNote() {
                var self = this;
                if (self.validNote()) {
                    var note = Ember.Object.create({
                        description: self.get('description'),
                        isShow: true
                    });
                    self.get('notes').pushObject(note);
                    self.set('description', '');
                }
            },

            saveNote: function saveNote() {
                var self = this;
                if (self.validNote()) {
                    self.set('selectedNote.isShow', true);
                    self.set('selectedNote', '');
                    self.set('description', '');
                }
            },

            editNote: function editNote(note) {
                var self = this;
                self.set('selectedNote', note);
                var description = note.get('description');
                self.set('description', description);
                note.set('isShow', false);
            },

            removeNote: function removeNote(note) {
                var self = this;
                self.set('selectedNote', '');
                self.set('description', '');
                self.get('notes').removeObject(note);
            },

            next: function next() {
                var self = this;
                if (Ember.isEmpty(self.get('description')) === false && Ember.isEmpty(self.get('selectedNote')) === true) {
                    var note = Ember.Object.create({
                        description: self.get('description'),
                        isShow: false
                    });
                    self.get('notes').pushObject(note);
                }
                self.set('newVisitsController.notes', self.get('notes'));
                self.get('newVisitsController').creditCardRequiredProcess();
            },
            back: function back() {
                this.transitionToRoute('seekers.manage.schedule-workers.index.new.activity');
            }
        },

        validNote: function validNote() {
            var self = this;
            self.set('noteError', '');
            if (Ember.isEmpty(self.get('description'))) {
                self.set('noteError', 'Description is required.');
                return false;
            }
            return true;
        }
    });
});