define('mycare-ember/models/job-application', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        person: _emberData.default.belongsTo('person', { async: true }),
        jobApplicationStatus: _emberData.default.belongsTo('job-application-status-type', { async: true }),
        jobListing: _emberData.default.belongsTo('job-listing', { async: true }),
        appliedOn: _emberData.default.attr('string'),
        modifiedOn: _emberData.default.attr('string'),
        body: _emberData.default.attr('string'),
        files: _emberData.default.hasMany('file', { async: true }),
        thread: _emberData.default.belongsTo('thread', { async: true }),
        isOpportunity: _emberData.default.attr('boolean')
    });
});