define('mycare-ember/components/mc-file-viewer/component', ['exports', 'mycare-ember/utils/mc-modal-component'], function (exports, _mcModalComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend({
        init: function init() {
            this.initVar('_componentId', 'mc-file-viewer');
            this._super.apply(this, arguments);
        }
    });
});