define('mycare-ember/helpers/json', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.json = json;
    function json(params /*, hash*/) {
        return Ember.String.htmlSafe(JSON.stringify(params));
    }

    exports.default = Ember.Helper.helper(json);
});