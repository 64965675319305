define("mycare-ember/components/mc-dashboard/worker/timesheet-component/component", ["exports", "mycare-ember/mixins/mixpanel", "mycare-ember/utils/mc-component"], function (exports, _mixpanel, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend(_mixpanel.default, {
        _timeSheetService: Ember.inject.service('timesheet'),

        selectedTimesheetSorting: ['actualStartsOn:asc'],
        _sortedSelectedTimesheet: Ember.computed.sort('selectedTimesheet', 'selectedTimesheetSorting'),

        timesheetSorting: ['date:asc'],
        _groupedUnsubmittedSortedTimesheets: Ember.computed.sort('_groupedUnsubmittedTimesheets', 'timesheetSorting'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('isReady', undefined);
            this.initVar('day', moment.utc().toISOString());
            this.initVar('_pageCurrentAmount', 0);
            this.initVar('_currentPage', 0);
            this.initVar('_pageSize', 5);

            this.createSubscriptions([{ publisher: this.get('_timeSheetService'), key: 'VISIT_SUBMITTED', callable: this._setupData }, { publisher: this.get('_timeSheetService'), key: 'VISIT_REMOVED', callable: this._setupData }, { publisher: this.get('_timeSheetService'), key: 'VISIT_ACCEPTED', callable: this._setupData }, { publisher: this.get('_timeSheetService'), key: 'VISIT_DECLINED', callable: this._setupData }]);
        },


        _reloadOnNotification: function () {
            this._setupData(true);
        }.observes('session.authData.fireUser.hasNewNotification'),

        _hasNoTimesheets: function () {
            return Ember.isEmpty(this.get('selectedTimesheet')) && Ember.isEmpty(this.get('_unsubmittedTimesheets'));
        }.property('selectedTimesheet.length', '_unsubmittedTimesheets.length'),

        today: function () {
            return moment().format('D MMMM YYYY');
        }.property(),

        _setupData: function _setupData(reload) {
            var _this = this;

            Ember.RSVP.all([this.setTodayTimesheets(reload), this.setUnsubmittedTimesheets(reload), this.setFutureTimesheets(reload)]).then(function () {
                _this.set('isReady', true);
                //filter out today timesheets from unsubmitted ones
                var _unsubmittedTimesheets = (_this.get('_unsubmittedTimesheets') || Ember.A()).filter(function (t) {
                    var startDate = moment.utc(t.get('actualStartsOn'));
                    var now = moment();
                    return startDate.isBefore(now);
                });
                _unsubmittedTimesheets = _.groupBy(_unsubmittedTimesheets.toArray(), function (t) {
                    return moment(moment.utc(t.get('actualStartsOn')).toDate()).format('dddd D MMM YYYY');
                });

                var groupTimesheets = Ember.A([]);
                Object.keys(_unsubmittedTimesheets).forEach(function (t) {
                    groupTimesheets.pushObject({
                        title: t,
                        date: _unsubmittedTimesheets[t].objectAt(0).get('actualStartsOn'),
                        timesheets: _unsubmittedTimesheets[t]
                    });
                });
                _this.set('_groupedUnsubmittedTimesheets', groupTimesheets);
            });
        },
        didReceiveAttrs: function didReceiveAttrs() {
            this._setupData();
        },


        hasNoUpcomingVisits: function () {
            return Ember.isEmpty(this.get('dayOneTimesheets.timesheets')) && Ember.isEmpty(this.get('dayTwoTimesheets.timesheets')) && Ember.isEmpty(this.get('dayThreeTimesheets.timesheets'));
        }.property('dayOneTimesheets.timesheets.[]', 'dayTwoTimesheets.timesheets.[]', 'dayThreeTimesheets.timesheets.[]'),

        setTodayTimesheets: function setTodayTimesheets() {
            var _this2 = this;

            var reload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

            return this.get("_timeSheetService.getTodayTimesheetsAsync").perform({
                profileId: this.get('session.authData.currentPersonId'),
                isWorker: true,
                reload: reload
            }).then(function (timesheets) {
                timesheets = timesheets.filter(function (t) {
                    var startDate = moment.utc(t.get('actualStartsOn'));
                    var now = moment();
                    return startDate.isAfter(now);
                });
                _this2.set('todayTimesheets', timesheets);
                _this2.setSelectedTimesheet(timesheets, 0, _this2.get('_pageSize'));
                _this2.setPageCurrentAmount();
                _this2.setTodayTimesheetsPagination(timesheets);
            });
        },

        setUnsubmittedTimesheets: function setUnsubmittedTimesheets() {
            var _this3 = this;

            var reload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

            return this.get("_timeSheetService.getUnsubmittedTimesheetAsync").perform({
                profileId: this.get('session.authData.currentPersonId'),
                isWorker: true,
                reload: reload
            }).then(function (timesheets) {
                _this3.set('_unsubmittedTimesheets', timesheets);
            });
        },

        setFutureTimesheets: function setFutureTimesheets() {
            var _this4 = this;

            var reload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

            var promiseList = [];
            for (var i = 1; i <= 3; i++) {
                var startTime = moment().add(i, 'days').startOf('day').toISOString();
                var endTime = moment().add(i, 'days').endOf('day').toISOString();
                var promise = this.get("_timeSheetService.getTimeSheetsByRangeAsync").perform({
                    profileId: this.get('session.authData.currentPersonId'),
                    startDate: startTime,
                    endDate: endTime,
                    isWorker: true,
                    reload: reload
                });
                promiseList.push(promise);
            }
            return Ember.RSVP.all(promiseList).then(function (responses) {
                var dayOfTimesheets = [];
                responses.forEach(function (response, index) {
                    var dayOfTimesheet = Ember.Object.create({
                        timesheets: response,
                        date: moment().add(index + 1, 'days').toISOString(),
                        dateString: moment().add(index + 1, 'days').format('D MMMM')
                    });
                    dayOfTimesheets.pushObject(dayOfTimesheet);
                });
                _this4.set('dayOneTimesheets', dayOfTimesheets.objectAt(0));
                _this4.set('dayTwoTimesheets', dayOfTimesheets.objectAt(1));
                _this4.set('dayThreeTimesheets', dayOfTimesheets.objectAt(2));
            });
        },

        setTodayTimesheetsPagination: function setTodayTimesheetsPagination(timesheets) {
            this.set('timesheetSize', timesheets.get('length'));
            if (timesheets.get('length') > this.get('_pageSize')) {
                this.set('doesShowPage', true);
            }
        },
        setSelectedTimesheet: function setSelectedTimesheet(timesheet, start, end) {
            var sliced = timesheet.slice(start, end);
            this.set('selectedTimesheet', sliced);
        },
        setPageCurrentAmount: function setPageCurrentAmount(page) {
            if (this.isDestroyed) {
                return;
            }
            var val = 1;
            if (Ember.isEmpty(page) === false) {
                val = page.get('id');
            }
            this.set('_pageCurrentAmount', val * this.get('_pageSize') < this.get('todayTimesheets.length') ? val * this.get('_pageSize') : this.get('todayTimesheets.length'));
        },


        actions: {
            selectPage: function selectPage(page) {
                this.set('_currentPage', page.get('value'));
                this.setSelectedTimesheet(this.get('todayTimesheets'), (page.get('id') - 1) * this.get('_pageSize'), page.get('id') * this.get('_pageSize'));
                this.setPageCurrentAmount(page);
            },
            reload: function reload() {
                this._setupData();
            }
        }
    });
});