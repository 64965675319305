define('mycare-ember/seekers/my-account/account-detail/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        auth: Ember.inject.controller('auth'),
        isLoadingCreditCards: false,
        errors: {},

        setup: function setup() {
            var self = this;
            self.setIsPrivateAccount();
            self.store.findRecord('person', self.get('auth.profileId')).then(function (person) {
                self.set('profilePerson', person);
            });
        },

        setIsPrivateAccount: function setIsPrivateAccount() {
            var self = this;
            self.set('isPrivateAccount', false);
            if (self.get('model.accountTypeName').toLowerCase() === self.get('env').getApp('PRIVATE_ACCOUNT').toLowerCase()) {
                self.set('isPrivateAccount', true);
            }
        }
    });
});