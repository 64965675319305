define('mycare-ember/mixins/group-byTimesheet', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    transactionGroupByTimeSheet: function transactionGroupByTimeSheet(transactions) {
      var groupResult = [];
      var object;
      transactions.forEach(function (item) {
        if (item.get('timesheet.id') !== 0) {
          object = groupResult.findBy('value', item.get('timesheet'));
          if (!object) {
            object = Ember.Object.create({
              value: item.get('timesheet'),
              items: [],
              transactionType: item.get('transactionType.name')
            });
            groupResult.pushObject(object);
          }
          object.items.pushObject(item);
        } else {
          object = Ember.Object.create({
            value: item.get('timesheet'),
            items: [item],
            transactionType: item.get('transactionType.name')
          });
          groupResult.pushObject(object);
        }
      });

      return groupResult;
    }
  });
});