define('mycare-ember/carers/manage/my-schedule/visits/index/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ['date', 'reload'],

        actions: {
            onWeekChanged: function onWeekChanged(changes) {
                var _this = this;

                Ember.run.scheduleOnce('afterRender', this, function () {
                    var newDate = moment(changes.startDate).toISOString();
                    if (newDate !== _this.get('date')) {
                        _this.set('date', newDate);
                        _this.set('_isLoading', true);
                        _this.send('onRouteWeekChanged', changes);
                    }
                });
            }
        }
    });
});