define('mycare-ember/register/worker-register/looking-for/controller', ['exports', 'mycare-ember/mixins/mixpanel', 'mycare-ember/mixins/validator', 'mycare-ember/utils/Validators'], function (exports, _mixpanel, _validator, _Validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_mixpanel.default, _validator.default, {
        auth: Ember.inject.controller('auth'),
        referenceDataService: Ember.inject.service('reference-models'),
        employmentTypes: Ember.computed.alias('referenceDataService.employmentTypes'),

        formData: {
            hourlyRates: [],
            services: [],
            description: undefined
        },

        onPrev: function onPrev() {
            return true;
        },

        willWizardEnter: function willWizardEnter(stepInfo, data) {
            this.set('wizardData', data);
            this.set('wizardData.lookingForForm', data.backupDataFn().lookingForForm);
            if (Ember.isNone(data) || data.get('state.phone-verification.completed') !== true) {
                return 'phone-verification';
            }
            this._setupValidations();
        },
        setup: function setup() {
            var _this = this;

            this._setupInitialValues();
            this._setupFormData().then(function () {
                return _this._setupValidations();
            });
        },
        _setupFormData: function _setupFormData() {
            var _this2 = this;

            var hourlyRatePromise = this.get('referenceDataService.getHourlyRatesAsync').perform().then(function (rates) {
                var existingHourlyRates = _this2.get('formData.hourlyRates');
                var hourlyRates = rates.map(function (item) {
                    var isChecked = !Ember.isNone(existingHourlyRates.find(function (i) {
                        return i.get('id') === item.get('id');
                    }));
                    return Ember.Object.create({
                        record: item,
                        isChecked: isChecked
                    });
                });
                _this2.set('hourlyRates', hourlyRates);
            });

            var servicesPromise = this.store.findAll('service', { reload: true }).then(function (rates) {
                if (_this2.isDestroyed) {
                    return;
                }
                var existingServices = _this2.get('formData.services');
                var services = rates.map(function (item) {
                    var isChecked = !Ember.isNone(existingServices.find(function (i) {
                        return i.get('id') === item.get('id');
                    }));
                    return Ember.Object.create({
                        record: item,
                        isChecked: isChecked
                    });
                });
                _this2.set('services', services);
            });

            return Ember.RSVP.all([hourlyRatePromise, servicesPromise]);
        },
        _setupInitialValues: function _setupInitialValues() {
            var account = this.get('wizardData.account');
            if (!account) {
                return;
            }
            this.set('formData.hourlyRates', account.get('hourlyRates'));
            this.set('formData.services', account.get('services'));
            this.set('formData.description', account.get('description'));
        },
        _setupValidations: function _setupValidations() {
            this._resetValidation();
            this.addValidation('hourlyRates.@each.isChecked', 'hourlyRates', 'Hourly rate', [this._validateHourlyRate]);
            this.addValidation('services.@each.isChecked', 'services', 'Service', [this._validateServices]);
            this.addValidation('formData.description', 'description', 'This field', [_Validators.default.isRequired]);
        },
        _validateHourlyRate: function _validateHourlyRate(name) {
            var checkedItems = this.get('hourlyRates').filter(function (rate) {
                return rate.get('isChecked') === true;
            });
            var isValid = !Ember.isEmpty(checkedItems);
            return { result: isValid, message: name + ' is required' };
        },
        _validateServices: function _validateServices(name) {
            var checkedItems = this.get('services').filter(function (rate) {
                return rate.get('isChecked') === true;
            });
            var isValid = !Ember.isEmpty(checkedItems);
            return { result: isValid, message: name + ' is required' };
        },
        onNext: function onNext() {
            var _this3 = this;

            if (!this._validate()) {
                return false;
            }

            var account = this.get('wizardData.account');
            account.set('hourlyRates', this.get('hourlyRates').filter(function (emp) {
                return emp.get('isChecked') === true;
            }).map(function (emp) {
                return emp.get('record');
            }));

            account.set('services', this.get('services').filter(function (emp) {
                return emp.get('isChecked') === true;
            }).map(function (emp) {
                return emp.get('record');
            }));

            account.set('description', this.get('formData.description'));

            return account.save().then(function () {
                return true;
            }).catch(function (e) {
                if (e.errors && e.errors[0] && e.errors[0].status === "401") {
                    _this3.transitionToRoute('logout');
                    return;
                }
                throw e;
            });
        },


        actions: {
            submit: function submit() {
                this.send('next');
            }
        }
    });
});