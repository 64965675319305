define('mycare-ember/components/date-picker/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({

        inputStyle: function () {
            var self = this;
            if (self.get('showInput') === true) {
                return Ember.String.htmlSafe('');
            }
            return Ember.String.htmlSafe("display: none");
        }.property('showInput'),

        didInsertElement: function didInsertElement() {
            var self = this;
            var nowDate = new Date();
            var today = '';
            if (self.get('isDisablePreviousDate') === true) {
                today = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate(), 0, 0, 0, 0);
            }
            //Initialise date picker
            var input = Ember.$('#' + self.get('componentId'));
            input.datepicker({
                autoclose: true,
                //Do not change this date format if you can. If you have to do, please check all date related code with momentjs.
                startDate: today,
                format: 'dd/mm/yyyy',
                todayHighlight: true,
                orientation: 'auto top'
            });
            if (self.get('disabled')) {
                input.attr('disabled', 'disabled');
            }

            //Call back when date selection is changed
            input.on("changeDate", function (event) {
                //update the property bind with 'selection'
                self.set("selection", event.format());
            });

            //this is requried to make sure Model and Datepicker are in sync.
            input.datepicker('setDate', self.get('selection'));
        },
        didReceiveAttrs: function didReceiveAttrs() {
            var input = Ember.$('#' + this.get('componentId'));
            input.datepicker('setDate', this.get('selection'));
        },
        didUpdateAttrs: function didUpdateAttrs() {
            var input = Ember.$('#' + this.get('componentId'));
            input.datepicker('setDate', this.get('selection'));
        },


        actions: {
            click: function click() {
                var self = this;
                var input = Ember.$('#' + self.get('componentId'));
                input.show();
                input.datepicker('show');

                if (Ember.isEmpty(self.get('showInput')) || self.get('showInput') === false) {
                    input.hide();
                }
            }
        },

        /**
         * Reset date picker with selected values when selection was made.
         */
        reset: function () {
            var self = this;
            var input = Ember.$('#' + self.get('componentId'));
            input.datepicker({
                setDate: self.get('selection')
            });

            if (Ember.isEmpty(self.get('selection'))) {
                input.datepicker('setDate', null);
            }
        }.observes('selection'),

        toggleDisabled: function () {
            var self = this;
            var input = Ember.$('#' + self.get('componentId'));
            if (self.get('disabled')) {
                input.attr('disabled', 'disabled');
            } else {
                input.removeAttr("disabled");
            }
        }.observes('disabled')
    });
});