define('mycare-ember/components/mc-worker-profile/availability-box/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('worker', null);
            this.initVar('_availabilityEditorChannelId', new Date().getTime());
        },


        actions: {
            editAvailability: function editAvailability() {
                this.get('eventbus').publish('mc-worker-profile/availability:on:open:' + this.get('_availabilityEditorChannelId'), this.get('worker'));
            }
        }
    });
});