define('mycare-ember/components/mc-spin-button/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        tagName: '',

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('isButtonLoading', false);
            this.initVar('isLoadingWithDisable', false);
            this.initVar('tag', 'button');
        },


        setupClass: function () {
            if (this.get('customIcon')) {
                this.set('spinnerClass', this.get('customIcon'));
            }
            if (this.get('isDisabled') === true) {
                this.set('isLoadingWithDisable', true);
            }
        }.on('init'),

        seIsDisabled: function () {
            if (this.get('isLoadingWithDisable') === false) {
                this.set('isDisabled', this.get('isButtonLoading'));
            }
        }.observes('isButtonLoading'),

        actions: {
            showButtonLoading: function showButtonLoading() {
                if (!this.get('isButtonLoading')) {
                    this.sendAction('action', this.get('param'));
                }
            }
        }
    });
});