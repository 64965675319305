define('mycare-ember/services/utils/mixpanel', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    exports.default = Ember.Service.extend({

        env: Ember.inject.service('utils/env'),

        mixpanelShare: function mixpanelShare(_ref) {
            var shareType = _ref.shareType,
                shareProperties = _ref.shareProperties;

            mixpanel.track('Share a ' + shareType, _extends({
                "Share Type": shareType
            }, shareProperties));
        },

        mixpanelAnonymousViewProfile: function mixpanelAnonymousViewProfile(profileId) {
            mixpanel.track('View a profile anonymously', {
                "Profile Id": profileId
            });
        },
        mixpanelAnonymousViewJobListing: function mixpanelAnonymousViewJobListing(jobListingId) {
            mixpanel.track('View a job listing anonymously', {
                "Joblisting Id": jobListingId
            });
        },
        mixpanelConversionByJobListing: function mixpanelConversionByJobListing(personId, jobListingId) {
            mixpanel.track('Signed up to apply for job', {
                "Joblisting Id": jobListingId,
                "Worker Id": personId
            });
        },
        mixpanelConversionByProfile: function mixpanelConversionByProfile(organiserId, workerId) {
            mixpanel.track('Signed up to view worker profile', {
                "Worker Id": workerId,
                "Organiser Id": organiserId
            });
        },


        mixpanelCreateAgreementButtonClicked: function mixpanelCreateAgreementButtonClicked(fromHire) {
            console.log("Mixpanel Create agreement clicked");
            mixpanel.track('Create Agreement Button Clicked', {
                "From Hire Screen": fromHire
            });
            mixpanel.people.increment("# of Create Agreement Button Clicks");
        },

        mixpanelLogin: function mixpanelLogin(person) {
            console.log("Mixpanel Login sent");
            var self = this;
            mixpanel.people.increment("# of Logins");
            mixpanel.init(this.get('env').get('mixpanel').token, {
                'loaded': function loaded() {
                    self.incrementSuperProperty("# of Logins");
                }
            });
            mixpanel.people.set({ "Last Login": new Date().toISOString() });
            mixpanel.register({ "Last Login": new Date().toISOString() });

            mixpanel.track('Login');

            if (!Ember.isNone(person)) {
                mixpanel.identify(person.get('id'));
            }
        }
    });
});