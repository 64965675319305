define("mycare-ember/components/mc-nav/authenticated/notification/component", ["exports", "mycare-ember/utils/mc-component"], function (exports, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        tagName: 'li',
        classNames: ['dropdown', 'dropdown-messages'],
        _notificationService: Ember.inject.service('notification'),
        _httpService: Ember.inject.service('utils/http'),
        router: Ember.inject.service('-routing'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('loggedInUserId', undefined);
            this.initVar('iconOnly', false);
            this._getNotifications();

            this._setup();
        },


        _getNotifications: function _getNotifications() {
            var _this = this;

            return this.get('_notificationService.getNotificationsAsync').perform().then(function (_ref) {
                var notifications = _ref.notifications;

                Ember.run(function () {
                    _this.set('_notifications', notifications);
                });
            });
        },

        _setup: function _setup() {
            var _this2 = this;

            this.get('eventbus').subscribe('notification:event:receivedNew', function (_ref2) {
                var notifications = _ref2.notifications;

                _this2.set('_notifications', notifications);
            }, false);
        },
        _setNotificationsAsViewed: function _setNotificationsAsViewed() {
            var _this3 = this;

            return this._getNotifications().then(function () {
                return _this3.get('_notificationService.setAsViewedAsync').perform();
            }).then(function () {
                _this3.get('_notifications').map(function (noti) {
                    return noti.set('isViewed', true);
                });
            });
        },


        actions: {
            seeDetail: function seeDetail(noti) {
                this.get('_notificationService.setAsReadAsync').perform({ noti: noti });
                this.get('router').transitionTo(noti.get('url') + "?backTo=notifications.index&reload=true");
            },
            setAsViewed: function setAsViewed() {
                this._setNotificationsAsViewed();
            },
            setAsViewedIconOnly: function setAsViewedIconOnly() {
                this._setNotificationsAsViewed();
                if (!Ember.isNone(this.get('_notifications'))) {
                    this.get('eventbus').publish('global:on:changeRoute', 'notifications.index');
                }
            }
        }
    });
});