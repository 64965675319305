define("mycare-ember/mixins/scroll_top", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        activate: function activate() {
            this._super();
            this.scrollTop();
        },

        scrollTop: function scrollTop() {
            if (this.get('disabledScrollTop')) return;
            var element = document.getElementById("scrollTop");
            element.scrollIntoView(true);
            window.scrollBy(0, -55);
        }
    });
});