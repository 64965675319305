define('mycare-ember/components/mc-timesheet/status-label/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({

        _timesheetService: Ember.inject.service('timesheet'),

        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.initVar('isWorker', undefined);
            this.initVar('status', undefined);
            this.initVar('displayName', undefined);
            this.initVar('labelClass', undefined);
            this.initVar('timesheet', undefined);

            // disabled update from observer so that ui looks like submitted straight away
            this.set('_disabledUpdate', false);

            this.createSubscriptions([{
                publisher: this.get('_timesheetService'),
                key: 'VISIT_SUBMITTING',
                callable: function callable(_ref) {
                    var timesheet = _ref.timesheet;

                    if (_this.get('timesheet.id') !== timesheet.get('id')) return;
                    _this.set('_disabledUpdate', true);
                    _this.set('displayName', 'Pending approval');
                    _this.set('labelClass', 'yellow');
                }
            }, {
                publisher: this.get('_timesheetService'),
                key: 'VISIT_SUBMIT_FAILED',
                callable: function callable(_ref2) {
                    var timesheet = _ref2.timesheet;

                    if (_this.get('timesheet.id') !== timesheet.get('id')) return;
                    _this.set('_disabledUpdate', false);
                    _this.setDisplayName();
                }
            }, {
                publisher: this.get('_timesheetService'),
                key: 'VISIT_SUBMITTED',
                callable: function callable(_ref3) {
                    var timesheet = _ref3.timesheet;

                    if (_this.get('timesheet.id') !== timesheet.get('id')) return;
                    _this.set('_disabledUpdate', false);
                    _this.setDisplayName();
                }
            }, {
                publisher: this.get('_timesheetService'),
                key: 'VISIT_REMOVING',
                callable: function callable(_ref4) {
                    var timesheet = _ref4.timesheet;

                    if (_this.get('timesheet.id') !== timesheet.get('id')) return;
                    _this.set('_disabledUpdate', true);
                    _this.set('displayName', 'Removed');
                    _this.set('labelClass', 'red');
                }
            }, {
                publisher: this.get('_timesheetService'),
                key: 'VISIT_REMOVE_FAILED',
                callable: function callable(_ref5) {
                    var timesheet = _ref5.timesheet;

                    if (_this.get('timesheet.id') !== timesheet.get('id')) return;
                    _this.set('_disabledUpdate', false);
                    _this.setDisplayName();
                }
            }, {
                publisher: this.get('_timesheetService'),
                key: 'VISIT_REMOVED',
                callable: function callable(_ref6) {
                    var timesheet = _ref6.timesheet;

                    if (_this.get('timesheet.id') !== timesheet.get('id')) return;
                    _this.set('_disabledUpdate', false);
                    _this.setDisplayName();
                }
            }, {
                publisher: this.get('_timesheetService'),
                key: 'VISIT_ACCEPTING',
                callable: function callable(_ref7) {
                    var timesheet = _ref7.timesheet;

                    if (_this.get('timesheet.id') !== timesheet.get('id')) return;
                    _this.set('_disabledUpdate', true);
                    _this.set('displayName', 'Accepted');
                    _this.set('labelClass', 'blue');
                }
            }, {
                publisher: this.get('_timesheetService'),
                key: 'VISIT_ACCEPT_FAILED',
                callable: function callable(_ref8) {
                    var timesheet = _ref8.timesheet;

                    if (_this.get('timesheet.id') !== timesheet.get('id')) return;
                    _this.set('_disabledUpdate', false);
                    _this.setDisplayName();
                }
            },
            /* {
                 publisher: this.get('_timesheetService'),
                 key: 'VISIT_ACCEPTED',
                 callable: ({timesheet}) => {
                     if(!timesheet) return;
                     if (this.get('timesheet.id') !== timesheet.get('id')) return;
                     this.set('_disabledUpdate', false);
                     this.setDisplayName();
                 }
             },*/
            {
                publisher: this.get('_timesheetService'),
                key: 'VISIT_DECLINING',
                callable: function callable(_ref9) {
                    var timesheet = _ref9.timesheet;

                    if (_this.get('timesheet.id') !== timesheet.get('id')) return;
                    _this.set('_disabledUpdate', true);
                    _this.set('displayName', 'Declined');
                    _this.set('labelClass', 'red');
                }
            }, {
                publisher: this.get('_timesheetService'),
                key: 'VISIT_DECLINE_FAILED',
                callable: function callable(_ref10) {
                    var timesheet = _ref10.timesheet;

                    if (_this.get('timesheet.id') !== timesheet.get('id')) return;
                    _this.set('_disabledUpdate', false);
                    _this.setDisplayName();
                }
            }, {
                publisher: this.get('_timesheetService'),
                key: 'VISIT_DECLINED',
                callable: function callable(_ref11) {
                    var timesheet = _ref11.timesheet;

                    if (_this.get('timesheet.id') !== timesheet.get('id')) return;
                    _this.set('_disabledUpdate', false);
                    _this.setDisplayName();
                }
            }, {
                publisher: this.get('_timesheetService'),
                key: 'VISIT_APPROVING',
                callable: function callable(_ref12) {
                    var timesheet = _ref12.timesheet;

                    if (_this.get('timesheet.id') !== timesheet.get('id')) return;
                    _this.set('_disabledUpdate', true);
                    _this.set('displayName', 'Approved');
                    _this.set('labelClass', 'green');
                }
            }, {
                publisher: this.get('_timesheetService'),
                key: 'VISIT_APPROVE_FAILED',
                callable: function callable(_ref13) {
                    var timesheet = _ref13.timesheet;

                    if (_this.get('timesheet.id') !== timesheet.get('id')) return;
                    _this.set('_disabledUpdate', false);
                    _this.setDisplayName();
                }
            }, {
                publisher: this.get('_timesheetService'),
                key: 'VISIT_APPROVED',
                callable: function callable(_ref14) {
                    var timesheet = _ref14.timesheet;

                    if (_this.get('timesheet.id') !== timesheet.get('id')) return;
                    _this.set('_disabledUpdate', false);
                    _this.setDisplayName();
                }
            }]);
            this.setDisplayName();
        },


        setDisplayName: function () {
            var _this2 = this;

            if (this.get('_disabledUpdate')) return;
            Ember.RSVP.resolve(this.get('timesheet.timesheetStatuses')).then(function (statuses) {
                return statuses.objectAt(0);
            }).then(function (status) {
                _this2.set('currentStatus', status);
                return status.get('timesheetStatusType');
            }).then(function (statusType) {
                switch (statusType.get('name').toLowerCase()) {
                    case "scheduled":
                        if (moment(_this2.get('timesheet.actualStartsOn')).isBefore(moment())) {
                            _this2.set('displayName', _this2.get('isWorker') ? 'To submit' : 'Scheduled');
                            _this2.set('labelClass', _this2.get('isWorker') ? 'blue' : 'blue');
                        } else {
                            _this2.set('displayName', 'Scheduled');
                            _this2.set('labelClass', 'blue');
                        }
                        break;
                    case "accepted":
                        if (moment(_this2.get('timesheet.actualStartsOn')).isBefore(moment())) {
                            _this2.set('displayName', _this2.get('isWorker') ? 'To submit' : 'Accepted');
                            _this2.set('labelClass', _this2.get('isWorker') ? 'blue' : 'blue');
                        } else {
                            _this2.set('displayName', 'Accepted');
                            _this2.set('labelClass', 'blue');
                        }
                        break;
                    case "approved":
                        _this2.set('displayName', 'Approved');
                        _this2.set('labelClass', 'green');
                        break;
                    case "queried":
                        _this2.set('displayName', 'Queried');
                        _this2.set('labelClass', 'red');
                        break;
                    case "pending":
                        _this2.set('displayName', 'Pending approval');
                        _this2.set('labelClass', 'yellow');
                        break;
                    case "review":
                        _this2.set('displayName', _this2.get('isWorker') ? 'Pending approval' : 'To approve');
                        _this2.set('labelClass', _this2.get('isWorker') ? 'yellow' : 'yellow');
                        break;
                    case "paid":
                        _this2.set('displayName', statusType.get('name'));
                        _this2.set('labelClass', 'green');
                        break;
                    case "removed":
                        _this2.set('displayName', 'Cancelled');
                        _this2.set('labelClass', 'red');
                        break;
                    case "declined":
                        _this2.set('displayName', 'Cancelled');
                        _this2.set('labelClass', 'red');
                        break;
                    default:
                        _this2.set('displayName', statusType.get('name'));
                        _this2.set('labelClass', 'blue');
                        break;
                }
            }).catch(function () {});
        }.observes('timesheet.timesheetStatuses.[]')
    });
});