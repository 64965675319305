define('mycare-ember/components/text-area', ['exports', 'ember-cli-text-support-mixins/components/text-area'], function (exports, _textArea) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _textArea.default;
    }
  });
});