define('mycare-ember/me/profiles/route', ['exports', 'mycare-ember/mixins/protected_route'], function (exports, _protected_route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_protected_route.default, {
        beforeModel: function beforeModel(transition) {
            this.isAuthenticated(transition);
        }
    });
});