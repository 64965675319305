define('mycare-ember/components/mc-worker-moj-warning/component', ['exports', 'mycare-ember/utils/mc-modal-component', 'mycare-ember/mixins/validator', 'mycare-ember/utils/Validators', 'mycare-ember/utils/function-helpers'], function (exports, _mcModalComponent, _validator, _Validators, _functionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend(_validator.default, {

        _modalHelper: Ember.inject.service('utils/modal-helper'),

        init: function init() {
            this.set('_componentId', 'mc-worker-moj-warning');
            this._super.apply(this, arguments);
            this.initVar('onContinued', undefined);

            this.set('_formData', {
                isAgree: false
            });
            this.set('_preventModalHideEvent', false);

            this.setupValidations();
        },
        didInsertElement: function didInsertElement() {
            var _this = this;

            this._super.apply(this, arguments);
            this.get('_modalHelper').onHidden(this.get('_modalChannelId'), function () {
                if (!_this.get('_preventModalHideEvent')) {
                    _functionHelpers.default.callOrIgnore(_this.get('onContinued'), false, _this.get('workerId'));
                }
            });
        },


        isDisabledContinue: function () {
            return !this.get('_formData.isAgree');
        }.property('_formData.isAgree'),

        setupValidations: function setupValidations() {
            this._resetValidation();
            this.addValidation('_formData.isAgree', 'isAgree', 'Consent', [_Validators.default.consent]);
        },
        _onBeforeOpen: function _onBeforeOpen() {
            this.set('_formData', {
                isAgree: false
            });
            this.setupValidations();
            this.set('_preventModalHideEvent', false);
        },


        actions: {
            onSelected: function onSelected() {
                this.toggleProperty('_formData.isAgree');
            },
            continueBooking: function continueBooking() {
                if (!this._validate()) return;
                this.set('_preventModalHideEvent', true);
                _functionHelpers.default.callOrIgnore(this.get('onContinued'), this.get('_formData.isAgree'), this.get('worker.id'));
                this._close();
            }
        }

    });
});