define('mycare-ember/mixins/creditcard-add-process', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({

        customTitle: '',
        creditCardError: '',
        isAddingCreditCard: false,

        addCreditCardProcess: function addCreditCardProcess(account, afterFunction, errorHandle) {
            var self = this;
            self.set('isAddingCreditCard', true);
            Ember.$('#modal-credit-card-add').on('hidden.bs.modal', function () {
                self.set('isAllowGetCreditCard', false);
                self.set('isAddingCreditCard', false);
                self.set('selectedContract', '');
                self.set('creditCardError', '');
            });
            self.store.createRecord('credit-card', { account: account }).save().then(function (creditCard) {
                self.set('creditCardCreateUrl', creditCard.get('url'));
                // reload iframe
                Ember.$('#payment-content').attr('src', function (i, val) {
                    return val;
                });
                Ember.$('#modal-credit-card-add').modal('show');
                self.set('isAllowGetCreditCard', true);
                self.processCreditCardRegister(creditCard.get('id'), afterFunction, errorHandle);
                self.set('isAddingCreditCard', false);
            });
        },

        processCreditCardRegister: function processCreditCardRegister(creditCardId, afterFunction, errorHandle) {
            var self = this;
            if (self.get('isAllowGetCreditCard') === true) {
                Ember.$.ajaxPrefilter(function (options) {
                    options.headers = {
                        Authorization: 'bearer ' + localStorage.getItem(self.get('env').getApp('AUTH_TOKEN_KEY'))
                    };
                });
                Ember.$.ajax({
                    url: self.get('env').getApp('API_HOST') + '/api/creditCards/' + creditCardId,
                    success: function success(data) {
                        var creditCard = data.creditCards;
                        if (Ember.isEmpty(creditCard.cardNumber) === false) {
                            self.store.findRecord('credit-card', creditCard.id, { reload: true }).then(function (creditCard) {
                                afterFunction(creditCard);
                            });
                        } else {
                            var AFunction = function AFunction() {
                                // self.set('isAllowGetCreditCard', true);
                                self.processCreditCardRegister(creditCard.id, afterFunction, errorHandle);
                            };
                            Ember.run.later(AFunction, 1000);
                        }
                    },
                    error: function error(response) {
                        self.set('isAllowGetCreditCard', false);
                        if (response.responseJSON && response.responseJSON.HttpError) {
                            self.set('creditCardError', response.responseJSON.HttpError.Message);
                            errorHandle(response.responseJSON.HttpError.Message);
                        }
                    }
                });
            }
        },

        stopGetCreditCard: function stopGetCreditCard() {
            this.set('isAllowGetCreditCard', false);
        }

    });
});