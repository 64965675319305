define('mycare-ember/models/person', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        availabilities: _emberData.default.hasMany('availability', { async: true }),
        availability: _emberData.default.attr('string'),
        isPublished: _emberData.default.attr('boolean'),
        title: _emberData.default.attr('string'),
        firstName: _emberData.default.attr('string'),
        manageRedirect: _emberData.default.attr('boolean'),
        lastName: _emberData.default.attr('string'),
        numberOfVerifications: _emberData.default.attr('number'),
        avatar: _emberData.default.belongsTo('image', { async: true }),
        businessRole: _emberData.default.attr('string'),
        isCovidVaccinationVerified: _emberData.default.attr('boolean'),
        hasAvatar: _emberData.default.attr('boolean'),
        email: _emberData.default.attr('string'),
        organisationName: _emberData.default.attr('string'),
        uniqueId: _emberData.default.attr('string'),
        interests: _emberData.default.attr('string'),
        profileType: _emberData.default.attr('string'),
        description: _emberData.default.attr('string'),
        phoneNumber: _emberData.default.attr('string'),
        createdOn: _emberData.default.attr('string'),
        modifiedOn: _emberData.default.attr('string'),
        location: _emberData.default.attr('string'),
        latitude: _emberData.default.attr('string'),
        longitude: _emberData.default.attr('string'),
        joinReason: _emberData.default.attr('string'),
        respondRatio: _emberData.default.attr('number'),
        numberOfHires: _emberData.default.attr('number'),
        hourlyRates: _emberData.default.hasMany('hourly-rate', { async: true }),
        login: _emberData.default.attr('number'),
        videos: _emberData.default.hasMany('video', { async: true }),
        images: _emberData.default.hasMany('image', { async: true }),
        recommendations: _emberData.default.hasMany('recommendation', { async: true, inverse: 'person' }),
        experiences: _emberData.default.hasMany('experience', { async: true }),
        qualifications: _emberData.default.hasMany('qualification', { async: true }),
        employmentTypes: _emberData.default.hasMany('employment-type', { async: true }),
        languages: _emberData.default.hasMany('language', { async: true }),
        requirements: _emberData.default.hasMany('requirement', { async: true }),
        services: _emberData.default.hasMany('service', { async: true }),
        clientServices: _emberData.default.hasMany('service', { async: true }),
        favouritePeople: _emberData.default.hasMany('profile', { async: true }),
        favouriteJobListings: _emberData.default.hasMany('job-listing', { async: true }),
        relationships: _emberData.default.hasMany('relationship', { async: true, inverse: 'relatedPerson' }),
        relatedWith: _emberData.default.hasMany('relationship', { async: true, inverse: 'person' }),
        circleMembers: _emberData.default.hasMany('person-circle', { async: true }),
        jobApplications: _emberData.default.hasMany('job-application', { async: true }),
        jobListings: _emberData.default.hasMany('job-listing', { async: true, inverse: 'person' }),
        threads: _emberData.default.hasMany('thread', { async: true }),
        equipment: _emberData.default.hasMany('equipment', { async: true }),
        files: _emberData.default.hasMany('file', { async: true }),
        subscriptionType: _emberData.default.attr('string'),
        isValidSubscriber: _emberData.default.attr('boolean'),
        addresses: _emberData.default.hasMany('address', { async: true }),
        accounts: _emberData.default.hasMany('account', { async: true }),
        creditCardList: _emberData.default.hasMany('credit-card', { async: true }),
        firstAddress: Ember.computed.alias('addresses.firstObject'),
        isPhoneNumberVerified: _emberData.default.attr('boolean'),
        isEmailVerified: _emberData.default.attr('boolean'),
        isAddressVerified: _emberData.default.attr('boolean'),
        trusts: _emberData.default.hasMany('trust', { async: true }),
        dateOfBirth: _emberData.default.attr('string'),
        gender: _emberData.default.attr('string'),
        untilExpire: _emberData.default.attr('string'),
        clientContracts: _emberData.default.hasMany('contract', { async: true, inverse: 'clientPerson' }),
        contracts: _emberData.default.hasMany('contract', { async: true, inverse: 'contractorPerson' }),
        myShareCode: _emberData.default.attr('string'),
        referrersCode: _emberData.default.attr('string'),
        isGovernmentPay: _emberData.default.attr('boolean'),
        isPrivatePay: _emberData.default.attr('boolean'),
        registrations: _emberData.default.hasMany('registration', { async: true }),
        supportTypes: _emberData.default.hasMany('support-type', { async: true }),
        isShareable: _emberData.default.attr('boolean'),
        hasSubmittedVist: _emberData.default.attr('boolean'),
        organisers: _emberData.default.hasMany('person', { async: true, inverse: 'organisers' }),
        name: function () {
            if (Ember.isEmpty(this.get('lastName'))) {
                return this.get('firstName');
            }
            return this.get('firstName') + ' ' + this.get('lastName');
        }.property('firstName', 'lastName'),

        isMale: function () {
            return this.get('gender') !== 'Female';
        }.property('gender'),

        shareUrl: function () {
            if (this.get('env').isMobile()) {
                return 'https://app.mycare.co.nz/browse/profiles/' + this.get('id');
            }
            var browserHost = window.location.host;
            if (browserHost.match(/localhost/) !== null) browserHost = 'test.mycare.co.nz';
            return window.location.protocol + '//' + browserHost + '/browse/profiles/' + this.get('id');
        }.property('id'),

        shareTitle: function () {
            return 'Check out my profile on Mycare: ' + this.get('description');
        }.property('firstName'),

        isBusiness: function () {
            return this.get('businessRole') !== "";
        }.property('businessRole'),

        isBusinessMain: function () {
            return this.get('businessRole') === 'mainbusiness';
        }.property('businessRole'),

        getBusinessRoleName: function () {
            return this.get('businessRole') === 'mainbusiness' ? 'Business organiser' : "Site organiser";
        }.property('businessRole')
    });
});