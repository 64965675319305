define("mycare-ember/carers/my-account/account-detail/summary/route", ["exports", "mycare-ember/mixins/protected_route"], function (exports, _protected_route) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_protected_route.default, {
        beforeModel: function beforeModel(transition) {
            this.isAuthenticated(transition);
        },


        setupController: function setupController(controller, model) {
            controller.set('account', model);
            controller.setup();
        },
        actions: {
            willTransition: function willTransition() {
                this.controller.set('perHourIncome', 20);
                this.controller.set('hoursPerWeek', 8);
            }
        }
    });
});