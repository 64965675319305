define('mycare-ember/models/inappropriate-content', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        text: _emberData.default.attr('string'),
        entityType: _emberData.default.attr('number'),
        entityId: _emberData.default.attr('number'),
        reportingPerson: _emberData.default.belongsTo('person', { async: true })
    });
});