define('mycare-ember/components/mc-nav/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('mode', undefined);
            this.initVar('backTo', undefined);
            this.initVar('showLeftMenu', true);
            this.initVar('isShowSearch', true);
            this.initVar('showLeftMenuDesktopOnly', false);
            this.initVar('showLeftMenuMobileOnly', false);
            this.initVar('isShowJoblistingNav', false);
            this.initVar('isShowProfilesNav', false);
            this.initVar('isShowBookingNav', false);
            this.initVar('showJobListingFavourite', false);
            this.initVar('showWorkerFavourite', false);
            this.initVar('isShowEditListingNav', false);
            this.initVar('jobListingId', undefined);
        },
        didReceiveAttrs: function didReceiveAttrs() {
            var _this = this;

            this.get('eventbus').subscribe('global:event:isAuthenticated', function (person) {
                return _this.onAuthenticated(person);
            });
        },
        onAuthenticated: function onAuthenticated() {
            if (this.isDestroyed) {
                return;
            }
            this.set('isAuthenticated', true);
        }
    });
});