define("mycare-ember/index/route", ["exports", "mycare-ember/mixins/profile-auto-select", "mycare-ember/mixins/scroll_top"], function (exports, _profileAutoSelect, _scroll_top) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_scroll_top.default, _profileAutoSelect.default, {

        livechat: Ember.inject.service('utils/livechat'),

        beforeModel: function beforeModel(transition) {
            var self = this;
            // Ember.$('#body').addClass('home');
            var authController = self.controllerFor('auth');
            if (this.get('session.authData.isAuthenticated')) {
                return this.profileAutoSelect(authController.get('previousTransition')).then(function () {
                    return transition.abort();
                });
            }
        },

        setupController: function setupController() {
            this._super.apply(this, arguments);
            this.get('livechat').activateLiveChatAlwaysOn();
        },


        actions: {
            willTransition: function willTransition() {
                this.get('livechat').deactivateLiveChat();
            }
        }
    });
});