define('mycare-ember/components/mc-month-year-picker/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/common', 'mycare-ember/utils/function-helpers'], function (exports, _mcComponent, _common, _functionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);

            this.initVar('maxYear', 120);
            this.initVar('minYear', 0);
            this.initVar('initialValue', undefined);
            this.initVar('error', undefined);
            this.initVar('onSelected', undefined);
            this.initVar('isDisabed', undefined);

            this.initVar('_yearOptions', undefined);
            this.initVar('_monthOptions', undefined);
            this.initVar('_selectedMonthOptions', undefined);
            this.initVar('_selectedYearOptions', undefined);

            this.initVar('_selectedMonthValue', undefined);
            this.initVar('_selectedYearValue', undefined);

            this._setupMonthOptions();
            this._setupYearOptions();
        },
        didReceiveAttrs: function didReceiveAttrs() {
            this._setupInitialValue();
        },
        _setupMonthOptions: function _setupMonthOptions() {
            var monthOptions = _common.default.createSelectOptions({
                models: _.map(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'], function (item, index) {
                    return Ember.Object.create({ id: index, value: item });
                }),
                labelKey: 'value',
                modelName: 'month',
                prompt: 'Month',
                isIncludeNew: false
            });
            this.initVar('_monthOptions', monthOptions);
        },
        _setupYearOptions: function _setupYearOptions() {
            var currentYear = moment().year() - this.get('minYear');
            var yearOptions = _common.default.createSelectOptions({
                models: _.map(_.range(this.get('maxYear')), function (value, index) {
                    return Ember.Object.create({ id: index, value: currentYear - value });
                }),
                labelKey: 'value',
                modelName: 'year',
                prompt: 'Year',
                isIncludeNew: false
            });
            this.initVar('_yearOptions', yearOptions);
        },
        _setupInitialValue: function _setupInitialValue() {
            var momentInitialDate = this.get('initialValue');
            if (Ember.isEmpty(momentInitialDate) || typeof momentInitialDate !== 'string') {
                return;
            }
            if (typeof momentInitialDate === 'string') {
                momentInitialDate = moment.utc(this.get('initialValue'));
            }
            var month = momentInitialDate.month();
            var year = momentInitialDate.year();

            this.set('_selectedMonthOption', this.get('_monthOptions').find(function (opt) {
                return opt.get('record.id') === month;
            }));
            this.set('_selectedYearOption', this.get('_yearOptions').find(function (opt) {
                return opt.get('record.value') === year;
            }));
            // this.set('_selectedMonthValue', this.get('_selectedMonthOption.record.id'));
            // this.set('_selectedYearValue', this.get('_selectedYearOption.record.value'));
        },


        _watchSelectedValues: function () {
            var year = this.get('_selectedYearValue');
            var month = this.get('_selectedMonthValue');
            if (!Ember.isNone(year) && !Ember.isNone(month)) {
                if (month === -1 || year === -1) {
                    _functionHelpers.default.callOrIgnore(this.get('onSelected'), null);
                } else {
                    var date = moment();
                    date.set({ year: year, month: month });
                    _functionHelpers.default.callOrIgnore(this.get('onSelected'), date);
                }
            } else {
                _functionHelpers.default.callOrIgnore(this.get('onSelected'), null);
            }
        }.observes('_selectedMonthValue', '_selectedYearValue'),

        actions: {
            onMonthSelected: function onMonthSelected(value) {
                this.set('_selectedMonthValue', value.get('record.id'));
            },
            onYearSelected: function onYearSelected(value) {
                this.set('_selectedYearValue', value.get('record.value'));
            }
        }
    });
});