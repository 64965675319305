define("mycare-ember/components/mc-share/component", ["exports", "mycare-ember/utils/mc-modal-component", "mycare-ember/utils/common"], function (exports, _mcModalComponent, _common) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend({

        _inviteService: Ember.inject.service('invite'),
        _jobListingService: Ember.inject.service('job-listing'),

        init: function init() {
            var _this = this;

            this.set('_componentId', 'mc-share');
            this._super.apply(this, arguments);
            this.set('_emailChannelId', _common.default.createGuid());
            this.set('_id', _common.default.createGuid());
            this.set('_copyButtonId', _common.default.createGuid());
            this.set('_copyTarget', 'target');
            this.set('_isCopied', false);
            this.set('_showMojWarning', false);
            this.set('_isLoading', false);
            this.set('_jobListing', undefined);
            this.set('_isMobile', this.get('env').isMobile());

            this.initVar('jobListing', undefined);
            this.initVar('isEnabledEmailShare', false);
            this.initVar('shareUrl', false);
            this.initVar('shareTitle', undefined);
            this.initVar('modalTitle', undefined);
            this.initVar('quote', undefined);
            this.initVar('hashTag', undefined);
            this.initVar('picture', undefined);
            this.initVar('onShared', undefined);
            this.initVar('isWorker', false);
            this.initVar('channelId', 'global');

            this.get('eventbus').subscribe("mc-share-email:event:closed:" + this.get('_emailChannelId'), function () {
                _this._onBeforeOpen();
                _this._open();
                _this._onAfterOpened();
            });

            if (this.get('jobListingId')) {
                this.get('_jobListingService.findByIdAsync').perform({ id: this.get('jobListingId') }).then(function (jobListing) {
                    _this.set('_jobListing', jobListing);
                    if (jobListing.get('isRequiredMoj') && !jobListing.get('isShareable')) {
                        _this.set('_showMojWarning', true);
                    }
                });
            }
        },


        actions: {
            shareByEmail: function shareByEmail() {
                this._close();
                this.get('eventbus').publish("mc-share-email:on:open:" + this.get('_emailChannelId'));
            },
            continue: function _continue() {
                var _this2 = this;

                if (this.get('jobListingId')) {
                    this.set('_isLoading', true);
                    this.get('_jobListingService.findByIdAsync').perform({ id: this.get('jobListingId') }).then(function (jobListing) {
                        jobListing.set('isShareable', true);
                        return jobListing.save();
                    }).then(function (jobListing) {
                        _this2.set('_jobListing', jobListing);
                        _this2.set('_showMojWarning', false);
                        Ember.run.later(function () {
                            _this2._setupCopyLink();
                        }, 500);
                    }).finally(function () {
                        _this2.set('_isLoading', false);
                    });
                }
            }
        },

        _onAfterOpened: function _onAfterOpened() {
            var _this3 = this;

            Ember.run.scheduleOnce('afterRender', this, function () {
                _this3._setupCopyLink();
            });
        },


        _setupCopyLink: function () {
            var _this4 = this;

            var btn = Ember.$('#' + this.get('_copyButtonId'));
            btn.click(function (e) {
                e.preventDefault();
                var inp = document.createElement('input');
                _this4.$(btn).parent().append(inp);
                inp.value = _this4.get('shareUrl');
                inp.select();
                document.execCommand('copy', false);
                inp.remove();
                _this4.set('_isCopied', true);
            });
        }.observes('_showMojWarning'),

        _onBeforeOpen: function _onBeforeOpen(options) {
            this.set('_isCopied', false);
            if (!options) return;
            this.set('isEnabledEmailShare', options.isEnabledEmailShare);
            this.set('jobListingId', options.jobListingId);
            this.set('shareUrl', options.shareUrl);
            this.set('shareTitle', options.shareTitle);
            this.set('modalTitle', options.modalTitle);
            this.set('quote', options.quote);
        }
    });
});