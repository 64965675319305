define('mycare-ember/components/mc-modal/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/function-helpers'], function (exports, _mcComponent, _functionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        _modalHelper: Ember.inject.service('utils/modal-helper'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('onOpenEventChannel', null);
            this.initVar('channelId', undefined);
            this.initVar('classNames', undefined);
            this.initVar('backdrop', false);
            this.initVar('keyboard', false);
            this.initVar('footer', {
                footer: true
            });
            this.initVar('body', {
                body: true
            });
            this.initVar('_isOpened', false);
            this.initVar('isSmall', false);
            this.initVar('_shouldRender', false);
        },
        keyDown: function keyDown(event) {
            if (event.keyCode == 27) {
                this._close();
            }
        },
        _setupModalEvents: function _setupModalEvents() {
            var _this = this;

            var channelId = this.get('channelId');
            var $modal = this.$('#' + channelId);
            if (!$modal) {
                return;
            }

            $modal.on('loaded.bs.modal', function () {
                _this.get('eventbus').publish('mc-modal:on:loaded:' + channelId);
            });

            $modal.on('show.bs.modal', function () {
                _this.get('eventbus').publish('mc-modal:on:show:' + channelId);
            });

            $modal.on('shown.bs.modal', function () {
                Ember.$('body').removeClass('fast-scroll');
                _this.get('eventbus').publish('mc-modal:on:shown:' + channelId);
                if (_this.isDestroyed) {
                    return;
                }
                _this.set('_isOpened', true);
            });

            $modal.on('hide.bs.modal', function () {
                _this.get('eventbus').publish('mc-modal:on:hide:' + channelId);
            });

            $modal.on('hidden.bs.modal', function () {
                Ember.$('body').addClass('fast-scroll');
                _this.get('eventbus').publish('mc-modal:on:hidden:' + channelId);
                if (_this.isDestroyed) {
                    return;
                }
                _this.set('_isOpened', false);
            });
        },
        didReceiveAttrs: function didReceiveAttrs() {
            if (Ember.isNone(this.get('channelId'))) {
                console.error('mc-modal: channelId is required');
            }
            this._setupEvents();
        },
        willDestroyElement: function willDestroyElement() {
            this._close();
            window.removeEventListener('keypress', this._escapeKeyCallback);
        },
        _setupEvents: function _setupEvents() {
            var _this2 = this;

            var channelId = this.get('channelId');
            if (!channelId) {
                return;
            }

            this.get('eventbus').subscribe('mc-modal:on:open:' + channelId, function () {
                _this2._open();
            }, false);

            this.get('eventbus').subscribe('mc-modal:on:close:' + channelId, function () {
                _this2._close();
            }, false);
        },
        _renderLazy: function _renderLazy(afterRender) {
            var _this3 = this;

            if (!this.get('_shouldRender')) {
                this.set('_shouldRender', true);
                Ember.run.scheduleOnce('afterRender', function () {
                    _this3._setupModalEvents();
                    _functionHelpers.default.callOrIgnoreWithContext(afterRender, _this3);
                });
            } else {
                _functionHelpers.default.callOrIgnoreWithContext(afterRender, this);
            }
        },
        _open: function _open() {
            var _this4 = this;

            if (this.isDestroyed) {
                return;
            }
            this._renderLazy(function () {
                // if(this.get('_isOpened') === true){
                //     return;
                // }
                // this.set('_isOpened', true);
                var $modal = _this4.$('#' + _this4.get('channelId'));
                if (!$modal) {
                    return;
                }
                // $modal.css('overflow', 'scroll');
                $modal.modal('show');
            });
        },
        _close: function _close() {
            var $modal = this.$('#' + this.get('channelId'));
            if (this.get('_isOpened') === false || !$modal) {
                return;
            }
            this.set('_isOpened', false);
            $modal.modal('hide');
            Ember.$('body').removeClass('fast-scroll');
        },


        actions: {
            close: function close() {
                this._close();
            },
            cancel: function cancel() {}
        }

    });
});