define("mycare-ember/components/mc-worker-profile/info/editor/component", ["exports", "mycare-ember/mixins/validator", "mycare-ember/utils/Validators", "mycare-ember/utils/common", "mycare-ember/utils/mc-component"], function (exports, _validator, _Validators, _common, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend(_validator.default, {
        _profileService: Ember.inject.service('profile'),
        _modalHelper: Ember.inject.service('utils/modal-helper'),
        _fileService: Ember.inject.service('file'),
        _geoService: Ember.inject.service('utils/geo'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('worker', undefined);
            this.initVar('channelId', _common.default.createGuid());
            this.initVar('_modalChannelId', _common.default.createGuid());
            this.initVar('_cropperChannelId', _common.default.createGuid());
            this.initVar('_employmentTypes', []);
            this.initVar('_formData', {});
            this.initVar('_isSaving', false);
            this.initVar('_isLoadImageCropper', true);
            this.initVar('_bithdateMaxYear', 16);
        },
        willSetupOnce: function willSetupOnce() {
            this._setupEvents();
        },
        _setupEvents: function _setupEvents() {
            var _this = this;

            var channelId = this.get('channelId');
            var openEvent = "mc-worker-profile/info/editor:on:open:" + channelId;
            this.get('eventbus').subscribe(openEvent, function (worker) {
                if (worker) {
                    _this.set('worker', worker);
                }
                _this._setupFormData(worker);
                _this._open();
            }, false);

            this.get('eventbus').subscribe("mc-worker-profile/info/editor:on:close:" + channelId, function () {
                _this._close();
            }, false);
        },
        _setupFormData: function _setupFormData(worker) {
            this.set('_formData', Ember.Object.create({
                firstName: worker.get('firstName'),
                lastName: worker.get('lastName'),
                location: worker.get('location'),
                dateOfBirth: worker.get('dateOfBirth'),
                description: worker.get('description'),
                isPublished: worker.get('isPublished'),
                avatar: Ember.Object.create({
                    url: undefined,
                    fileName: undefined
                }),
                employmentTypes: []
            }));

            this._setupValidation();
        },
        _validateLocation: function _validateLocation() {
            var isValid = true;
            if (this.get('_formData.location') !== this.get('rawLocationValue') || Ember.isEmpty(this.get('rawLocationValue'))) {
                isValid = false;
            }

            return {
                result: isValid,
                message: 'Location is required'
            };
        },
        _setupValidation: function _setupValidation() {
            this.addValidation('_formData.firstName', 'firstName', 'Firstname', [_Validators.default.isRequired, _Validators.default.noSpecialCharacters]);
            this.addValidation('_formData.lastName', 'lastName', 'LastName', [_Validators.default.isRequired, _Validators.default.noSpecialCharacters]);
            this.addValidation('_formData.dateOfBirth', 'dateOfBirth', 'Date of birth', [_Validators.default.isRequired]);
            this.addValidation('_formData.location', 'location', 'Location', [this._validateLocation]);
        },
        _validateHourlyRate: function _validateHourlyRate(name) {
            var checkedItems = this.get('_formData.hourlyRates').filter(function (rate) {
                return rate.get('isChecked') === true;
            });
            var isValid = !Ember.isEmpty(checkedItems);
            return { result: isValid, message: name + " is required" };
        },
        _open: function _open() {
            this.get('_modalHelper').openModal(this.get('_modalChannelId'));
        },
        _close: function _close() {
            this.get('_modalHelper').closeModal(this.get('_modalChannelId'));
        },


        actions: {
            onDateOfBirthSelected: function onDateOfBirthSelected(momentDate) {
                this.set('_formData.dateOfBirth', momentDate ? momentDate.toISOString() : null);
            },
            onAvatarUploadCancelled: function onAvatarUploadCancelled() {
                this.set('_isLoadImageCropper', false);
                this._open();
            },
            onAvatarUploadCompleted: function onAvatarUploadCompleted(fileUrl) {
                this.set('_isLoadImageCropper', false);
                this._open();
                this.get('_profileService.uploadAvatarAsync').perform({ personId: this.get('worker.id'), avatarUrl: fileUrl });
            },
            uploadAvatar: function uploadAvatar() {
                var _this2 = this;

                this.set('_isLoadImageCropper', true);
                Ember.run.later(function () {
                    _this2.get('eventbus').publish("mc-image-cropper:on:open:" + _this2.get('_cropperChannelId'));
                }, 100);
                this._close();
            },
            save: function save() {
                var _this3 = this;

                if (this._validate()) {
                    var promises = [];
                    if (!Ember.isNone(this.get('_formData.avatar.url'))) {
                        this.set('_formData.avatar.fileName', this.get('worker.id'));
                        promises.push(this.get('_fileService.createImageAsync').perform(this.get('_formData.avatar')));
                    }

                    Ember.RSVP.all(promises).then(function () {
                        return _this3.get('_geoService.decodeAsync').perform({ location: _this3.get('_formData.location') }).then(function (_ref) {
                            var lat = _ref.lat,
                                lng = _ref.lng;

                            _this3.set('worker.location', _this3.get('_formData.location'));
                            _this3.set('worker.latitude', lat);
                            _this3.set('worker.longitude', lng);
                        }).then(function () {
                            _this3.set('_isSaving', true);
                            _this3.set('worker.firstName', _this3.get('_formData.firstName'));
                            _this3.set('worker.lastName', _this3.get('_formData.lastName'));
                            _this3.set('worker.dateOfBirth', _this3.get('_formData.dateOfBirth'));

                            if (!Ember.isNone(_this3.get('_formData.avatar.url'))) {
                                _this3.set('worker.avatar', _this3.get('_formData.avatar'));
                            }
                            _this3.set('worker.employmentTypes', _this3.get('_formData.employmentTypes').filter(function (emp) {
                                return emp.get('isChecked') === true;
                            }).map(function (emp) {
                                return emp.get('record');
                            }));
                            _this3.set('worker.description', _this3.get('_formData.description'));
                            _this3.get('worker').save().then(function () {
                                _this3.set('_isSaving', false);
                                _this3._close();
                            });
                        });
                    });
                }
            }
        }
    });
});