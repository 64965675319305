define('mycare-ember/components/mc-worker-picker/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/function-helpers'], function (exports, _mcComponent, _functionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({

        _profileService: Ember.inject.service('profile'),
        _workerSorting: ['isFavourite:desc', 'firstName:asc'],
        _sortedWorkers: Ember.computed.sort('workers', '_workerSorting'),
        _sortedFilteredWorkers: Ember.computed.sort('_filteredWorkers', '_workerSorting'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('workers', undefined);
            this.initVar('currentPersonId', undefined);
            this.initVar('onSelected', undefined);
            this.initVar('isEnabledSearch', false);
            this.initVar('placeholder', 'Select a worker');
            this.initVar('initialWorker', undefined);
            this.initVar('_filteredWorkers', undefined);
            this.initVar('_workerName', undefined);
            this.initVar('_dropdownLabel', undefined);
            this.initVar('_formData', {
                search: undefined
            });

            this.setWorkersFavouritesStatus();
            if (!Ember.isEmpty(this.get('initialWorker'))) {
                this.set('_workerName', this.get('initialWorker.firstName'));
            }
            this.watchInitialWorker();
            this.watchDropdownLabel();
        },


        watchDropdownLabel: function () {
            this.set('_dropdownLabel', this.get('_workerName') ? this.get('_workerName') : this.get('placeholder'));
        }.observes('_workerName', 'placeholder'),

        watchInitialWorker: function () {
            if (!Ember.isNone(this.get('initialWorker'))) {
                this.set('_workerName', this.get('initialWorker.firstName'));
            }
        }.observes('initialWorker'),

        didInsertElement: function didInsertElement() {
            this.$('.dropdown').on({
                "click": function click(event) {
                    if ($(event.target).closest('.dropdown-toggle').length) {
                        $(this).data('closable', true);
                    } else {
                        $(this).data('closable', false);
                    }
                },
                "hide.bs.dropdown": function hideBsDropdown() {
                    var hide = $(this).data('closable');
                    $(this).data('closable', true);
                    return hide;
                }
            });
        },
        setWorkersFavouritesStatus: function setWorkersFavouritesStatus() {
            var _this = this;

            var promises = this.get('workers').map(function (worker) {
                return _this.get('_profileService.isFavouriteAsync').perform({
                    personId: _this.get('session.authData.currentPersonId'),
                    workerId: worker.get('id')
                }).then(function (isFavourite) {
                    worker.set('isFavourite', isFavourite);
                });
            });
            return Ember.RSVP.all(promises).then(function () {
                _this.set('_filteredWorkers', _this.get('workers'));
                _this.watchSearch();
            });
        },


        watchSearch: function () {
            var _this2 = this;

            var filtered = this.get('_sortedWorkers').filter(function (opt) {
                return (opt.get('firstName') || '').toLowerCase().match((_this2.get('_formData.search') || '').toLowerCase());
            });
            this.set('_filteredWorkers', filtered);
        }.observes('_formData.search'),

        actions: {
            addFavourite: function addFavourite(worker) {
                var _this3 = this;

                this.get('_profileService.addOrRemoveFavouritesAsync').perform({
                    personId: this.get('session.authData.currentPersonId'),
                    workerId: worker.get('id')
                }).then(function () {
                    return _this3.setWorkersFavouritesStatus();
                });
                return false;
            },
            selectWorker: function selectWorker(worker) {
                this.set('_selectedWorker', worker);
                this.set('_workerName', worker.get('firstName'));
                _functionHelpers.default.callOrIgnore(this.get('onSelected'), worker);
                this.$('.btn-dropdown').dropdown('toggle');
            }
        }
    });
});