define("mycare-ember/carers/my-account/account-detail/index/controller", ["exports", "mycare-ember/mixins/manage/account/transactions", "mycare-ember/utils/common"], function (exports, _transactions, _common) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_transactions.default, {
        auth: Ember.inject.controller('auth'),
        isRedirect: true,
        isWorker: true,
        _bankIrdFormComponentId: _common.default.createGuid(),
        _bookVisitpopoverChannelId: _common.default.createGuid(),

        profileId: function () {
            return this.get('auth.profileId');
        }.property(),

        setup: function setup() {
            var self = this;
            self.set('selectedPageSize', self.get('pageSizes').objectAt(0));
            self.set('currentPageSize', self.get('pageSizes').objectAt(0).get('size'));
            self.set('pageSize', self.get('pageSizes').objectAt(0).get('size'));
            self.set('offSet', 0);

            if (Ember.isEmpty(self.get('startTime')) && Ember.isEmpty(self.get('endTime'))) {
                self.set('isLoading', true);
                self.store.query('transaction', {
                    accountId: self.get('model.id'),
                    pageSize: self.get('pageSize'),
                    offSet: self.get('offSet')
                }, { reload: true }).then(function (transactions) {
                    if (transactions.get('length') < self.get('pageSize')) {
                        self.set('isShowLoadMoreButton', false);
                    }
                    self.set('isLoading', false);
                    self.set('totalRowSize', transactions.get('meta').total);
                    self.set('transactions', transactions.toArray());
                    self.setupLabelTime();
                    self.set('isAbleToGetTransaction', true);
                });
            }
        },


        checkBankAccount: function checkBankAccount() {
            var self = this;
            var bankAccounts = self.get('model.bankAccounts');
            var validBankAccounts = bankAccounts.filter(function (bankAccount) {
                return !Ember.isNone(bankAccount.get('id'));
            });
            if (validBankAccounts.get('length') === 0 && self.get('isRedirect') === true) {
                self.set('isRedirect', false);
                self.transitionToRoute('carers.my-account.account-detail.bank-accounts');
            }
        },

        setupLabelTime: function setupLabelTime() {
            this.set('firstTransaction', this.get('transactions.firstObject'));
            this.set('lastTransaction', this.get('transactions.lastObject'));
            this.set('endTimeLabel', moment(this.get('firstTransaction.createdOn')).tz('Pacific/Auckland').format('DD/M/YYYY'));
            this.set('startTimeLabel', moment(this.get('lastTransaction.createdOn')).tz('Pacific/Auckland').format('DD/M/YYYY'));
        },


        actions: {
            showReportModal: function showReportModal() {
                this.transitionToRoute('carers.my-account.account-detail.reports');
            },
            updateBankAccount: function updateBankAccount() {
                this.get('eventbus').publish("mc-bank-ird-form:on:open:" + this.get('_bankIrdFormComponentId'));
            }
        }

    });
});