define('mycare-ember/seekers/manage/schedule-workers/index/new/contract/route', ['exports', 'mycare-ember/utils/mc-protected-route'], function (exports, _mcProtectedRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcProtectedRoute.default.extend({
        actions: {
            willTransition: function willTransition(transition) {
                var self = this;
                var contractController = self.controllerFor('seekers/manage/schedule-workers/index/new/contract');
                contractController.set('leavingTransition', transition);
                if (contractController.get('isAllowToRedirect') === false) {
                    Ember.$('#modal-stop-route-change').modal('show');
                    transition.abort();
                    if (window.history) {
                        window.history.forward();
                    }
                } else {
                    Ember.$('#modal-stop-route-change').modal('hide');
                    Ember.$('#modal-agree-and-create-visits').modal('hide');
                    return true;
                }
            }
        }
    });
});