define('mycare-ember/models/invoice', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        date: _emberData.default.attr('string'),
        description: _emberData.default.attr('string'),
        fullName: _emberData.default.attr('string'),
        gst: _emberData.default.attr('number'),
        net: _emberData.default.attr('number'),
        total: _emberData.default.attr('number'),
        invoiceNumber: _emberData.default.attr('string'),

        getGst: function () {
            return -this.get('gst');
        }.property('gst'),

        getTotal: function () {
            return -this.get('total');
        }.property('total'),

        getNet: function () {
            return -this.get('net');
        }.property('net')
    });
});