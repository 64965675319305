define("mycare-ember/components/mc-slider/multi/component", ["exports", "mycare-ember/utils/common", "mycare-ember/utils/mc-component"], function (exports, _common, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        // customIcon is an attribute to create font awesome icon
        tagName: '',
        isRendered: false,
        init: function init() {
            this._super();
            this.set('componentId', _common.default.createGuid());
        },

        didRender: function didRender() {
            var self = this;
            var nonLinearSlider = document.getElementById(this.get('componentId'));
            if (nonLinearSlider && self.get('isRendered') === false) {
                noUiSlider.create(nonLinearSlider, {
                    connect: true,
                    behaviour: 'tap',
                    start: [self.get('min'), self.get('max')],
                    range: {
                        // Starting at 500, step the value by 500,
                        // until 4000 is reached. From there, step by 1000.
                        'min': [self.get('min')],
                        '10%': [16, 0.5],
                        '50%': [28, 1],
                        'max': [self.get('max')]
                    }
                });
                nonLinearSlider.noUiSlider.on('update', function (values) {
                    self.sendAction('setMin', values[0]);
                    self.sendAction('setMax', values[1]);
                });
                self.set('isRendered', true);
            }
        },

        watchIsDisabled: function () {
            var nonLinearSlider = document.getElementById(this.get('componentId'));
            if (this.get('isDisabled') === true) {
                nonLinearSlider.setAttribute('disabled', true);
            } else {
                nonLinearSlider.removeAttribute('disabled');
            }
        }.observes('isDisabled')
    });
});