define('mycare-ember/register/worker-register/availability/controller', ['exports', 'mycare-ember/mixins/mixpanel', 'mycare-ember/mixins/validator', 'mycare-ember/utils/Validators'], function (exports, _mixpanel, _validator, _Validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_mixpanel.default, _validator.default, {
        auth: Ember.inject.controller('auth'),
        referenceDataService: Ember.inject.service('reference-models'),
        employmentTypes: Ember.computed.alias('referenceDataService.employmentTypes'),
        availabilities: Ember.computed.alias('referenceDataService.availabilities'),
        _worker: undefined,

        hasWhenOrAvailabilities: true,

        formData: {
            hourlyRates: [],
            services: [],
            description: undefined
        },

        onPrev: function onPrev() {
            return true;
        },

        willWizardEnter: function willWizardEnter(stepInfo, data) {
            this.set('wizardData', data);
            this.set('_worker', data.account);
            if (Ember.isNone(data) || data.get('state.looking-for.completed') !== true) {
                return 'looking-for';
            }
            this._setupValidations();
        },
        setup: function setup() {
            var _this = this;

            this._setupInitialValues();
            this.get('referenceDataService.getAvailabilitiesAsync').perform().then(function (availabilities) {
                if (_this.get('_worker.availabilities.length') === 0) {
                    _this.get('_worker.availabilities').pushObject(availabilities.objectAt(0));
                }
            });
        },
        _setupFormData: function _setupFormData() {},
        _setupInitialValues: function _setupInitialValues() {
            var account = this.get('wizardData.account');

            if (!account) {
                return;
            }
        },
        _setupValidations: function _setupValidations() {
            this._resetValidation();
            this.addValidation('_worker.availabilities.[]', 'availabilities', 'Availabilities', [this._validateAvailabilities]);
        },
        _validateAvailabilities: function _validateAvailabilities(name) {
            var checkedItems = this.get('_worker.availabilities');
            var isValid = checkedItems.get('length') > 0;
            return { result: isValid, message: 'Please select one availability.' };
        },


        isWatchValidated: function () {
            this.setHasWhenOrAvailabilities();
        }.observes('_worker.{availabilities.[],when}'),

        setHasWhenOrAvailabilities: function setHasWhenOrAvailabilities() {
            this.set('hasWhenOrAvailabilities', this.get('_worker.availabilities.length') > 0 || !Ember.isEmpty(this.get('_worker.when')));
        },
        onNext: function onNext() {
            var _this2 = this;

            if (!this.hasWhenOrAvailabilities) {
                return false;
            }

            var account = this.get('wizardData.account');

            return account.save().then(function () {
                return true;
            }).catch(function (e) {
                if (e.errors && e.errors[0] && e.errors[0].status === "401") {
                    _this2.transitionToRoute('logout');
                    return;
                }
                throw e;
            });
        },


        actions: {
            submit: function submit() {
                this.send('next');
            }
        }
    });
});