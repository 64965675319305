define("mycare-ember/controllers/partials/modal-inappropriate-content", ["exports", "mycare-ember/mixins/scroll_top"], function (exports, _scroll_top) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_scroll_top.default, {
    auth: Ember.inject.controller('auth'),
    errors: [],

    actions: {
      flagInappropriate: function flagInappropriate() {
        var self = this;
        if (self.validateForm()) {
          self.set('isLoading', true);
          self.store.findRecord('person', self.get('auth.loggedInUserId')).then(function (person) {
            self.store.createRecord('inappropriate-content', {
              text: self.get('inappropriateText'),
              entityType: self.get('model.type'),
              entityId: self.get('model.id'),
              reportingPerson: person
            }).save().then(function () {
              self.set('isLoading', false);
              Ember.$('.modal').modal('hide');
            });
          });
        }
      }
    },

    validateForm: function validateForm() {
      var self = this;
      self.get('errors').removeObjects(self.get('errors'));
      var isValid = true;
      Ember.$('.has-error').removeClass('has-error');

      if (!self.get('inappropriateText')) {
        self.get('errors').pushObject('Message is required');
        Ember.$('#inappropriateText').addClass('has-error');
        isValid = false;
      }

      if (!isValid) {
        self.scrollTop();
      }

      return isValid;
    }
  });
});