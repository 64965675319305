define('mycare-ember/services/utils/cordova-badge', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        isCordova: function isCordova() {
            var isCordovaApp = !!window.cordova;
            return isCordovaApp;
        },
        clear: function clear() {
            if (this.isCordova()) {
                // cordova.plugins.notification.badge.clear();
            }
        },
        setBadgeNumber: function setBadgeNumber(number) {
            if (this.isCordova()) {
                // cordova.plugins.notification.badge.set(number);
            }
        },
        badgeDecrease: function badgeDecrease(number, func) {
            if (this.isCordova()) {
                // cordova.plugins.notification.badge.decrease(number, func);
            }
        },
        badgeIncrease: function badgeIncrease(number, func) {
            if (this.isCordova()) {
                // cordova.plugins.notification.badge.increase(number, func);
            }
        }
    });
});