define('mycare-ember/mixins/email-verify-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    seekerVerifyEmail: function seekerVerifyEmail() {
      var self = this;
      self.store.findRecord('person', self.controllerFor('auth').get('loggedInUserId')).then(function (person) {
        if (!person || person.get('isEmailVerified') === false) {
          self.transitionTo('seekers.findhelp.job-listings.list');
        }
        Ember.run.later(function () {
          Ember.$('#modal-verify-email').modal('show');
        }, 500);
      });
    }
  });
});