define('mycare-ember/me/profiles/index/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    exports.default = Ember.Controller.extend({
        redirectService: Ember.inject.service('utils/redirect'),
        queryParams: ['autoRedirect'],
        autoRedirect: false,
        isEditProfile: false,

        actions: {
            toggleEditProfile: function toggleEditProfile(bool) {
                if (!Ember.isEmpty(bool)) {
                    this.set('isEditProfile', bool);
                } else {
                    this.set('isEditProfile', !this.get('isEditProfile'));
                }
            },

            createProfileForSomeone: function createProfileForSomeone() {
                this.transitionToRoute('me.profiles.someone-setup');
            },

            createProfileForSite: function createProfileForSite() {
                this.transitionToRoute('me.profiles.site-setup');
            },

            handleSwitchProfile: function handleSwitchProfile(name, personId, firstName, redirectKey) {
                var transition = this.get('redirectService').getByKey(redirectKey);
                if (transition === null) {
                    this.send('switchProfile', name, personId, firstName, redirectKey);
                    return;
                }
                var targetRoute = (typeof transition === 'undefined' ? 'undefined' : _typeof(transition)) === 'object' ? transition.targetName : transition;
                if (name.toLowerCase() === 'worker' && targetRoute.match('seekers')) {
                    this.send('switchProfile', name, personId, firstName);
                } else {
                    this.send('switchProfile', name, personId, firstName, redirectKey);
                }
            }
        }
    });
});