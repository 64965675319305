define('mycare-ember/components/mc-notification/component', ['exports', 'mycare-ember/utils/mc-component', 'npm:responsive-toolkit'], function (exports, _mcComponent, _npmResponsiveToolkit) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({

        _notificationSevice: Ember.inject.service('notification'),
        _httpService: Ember.inject.service('utils/http'),
        router: Ember.inject.service('-routing'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('isSeeker', false);
            this.initVar('_pageSize', 20);
            this.initVar('_offset', 0);
            this.initVar('_notifications', []);
            this.initVar('loggedInUserId', undefined);
            this.initVar('_isLoading', false);
            this.initVar('_isMobile', _npmResponsiveToolkit.default.is("xs"));
        },
        didReceiveAttrs: function didReceiveAttrs() {
            this._fetchNotifications(this.get('_pageSize'), 0);
        },
        _fetchNotifications: function _fetchNotifications(pageSize, offset) {
            var _this = this;

            this.set('_isLoading', true);
            this.get('_notificationSevice.getNotificationsAsync').perform({
                pageSize: pageSize,
                offset: offset,
                loggedInUserId: this.get('loggedInUserId')
            }).then(function (_ref) {
                var notifications = _ref.notifications,
                    total = _ref.total;

                notifications.forEach(function (noti) {
                    if (!_this.get('_notifications').includes(noti)) {
                        _this.get('_notifications').pushObject(noti);
                    }
                });
                _this.set('_total', total);
                _this.set('_pageSize', pageSize);
                _this.set('_isLoading', false);
            });
        },


        actions: {
            onPageSelected: function onPageSelected(page) {
                this.set('_offset', parseInt(page.name) * parseInt(this.get('_pageSize')));
                this._fetchNotifications(this.get('_pageSize'), this.get('_offset'));
            },
            loadMoreDown: function loadMoreDown() {
                this.set('_offset', this.get('_offset') + this.get('_pageSize'));
                if (this.get('_offset') < this.get('_total')) {
                    this._fetchNotifications(this.get('_pageSize'), this.get('_offset'));
                }
            },
            markAllAsRead: function markAllAsRead() {
                var _this2 = this;

                this.get('_httpService.withTokenAsync').perform(function (client) {
                    return client.put(_this2.get('env').getApp('API_HOST') + '/api/notifications/status?type=read');
                }).then(function () {
                    _this2._fetchNotifications(_this2.get('_pageSize'), 0);
                });
            },
            read: function read(noti) {
                var _this3 = this;

                return this.get('_notificationSevice.setAsReadAsync').perform({ noti: noti }).then(function () {
                    _this3.get('router').transitionTo(noti.get('url') + '?backTo=notifications.index&reload=true');
                });
            }
        }
    });
});