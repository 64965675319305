define('mycare-ember/book/mobile-nav/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        wizardService: Ember.inject.service('utils/booking-wizard'),

        isNoteStepDisabled: function () {
            return this.get('wizardService.isRequiredCreditCard') && !this.get('wizardService.isVisitedPayment') || !this.get('wizardService.isRequiredCreditCard') && !this.get('wizardService.isVisitedActivities');
        }.property('wizardService.{isVisitedActivities,isRequiredCreditCard,isVisitedPayment}'),

        isActivitiesDisabled: function () {
            var isSeeker = this.get('session.authData.isCurrentlySeeker');
            if (isSeeker) {
                return !this.get('wizardService.isVisitedExpenses');
            } else {
                return !this.get('wizardService.isVisitedTime');
            }
        }.property('wizardService.{isVisitedExpenses,isVisitedTime}', 'session.authData.isCurrentlySeeker')
    });
});