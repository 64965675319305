define("mycare-ember/controllers/partials/modal-profile-manage-experience", ["exports", "mycare-ember/mixins/mixpanel"], function (exports, _mixpanel) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_mixpanel.default, {
    currentItem: null,
    editingItem: null,
    isEditMode: false,
    title: 'Add experience',
    errors: [],
    isLoading: false,

    setupPresentCheckbox: function () {
      var self = this;
      if (self.get('isEditMode')) {
        if (!self.get('currentItem.endedOn')) {
          self.set('toggleEndDate', true);
        } else {
          self.set('toggleEndDate', false);
        }
      }
    }.observes('currentItem.endedOn'),

    setEndDate: function () {
      var self = this;
      if (self.get('toggleEndDate')) {
        Ember.$(Ember.$('#experienceEndedOn')).find('select').addClass('has-disabled');
        Ember.$(Ember.$('#experienceEndedOn')).find('select').prop('selectedIndex', 0);
        self.set('currentItem.endedOn', null);
      } else {
        Ember.$(Ember.$('#experienceEndedOn')).find('select').removeClass('has-disabled');
      }
    }.observes('toggleEndDate'),

    actions: {
      save: function save() {
        var self = this;
        self.set('isSaving', true);
        var experience = self.get('currentItem');
        if (self.validateForm(experience)) {
          if (!self.get('isEditMode')) {
            experience = self.store.createRecord('experience', {
              title: self.get('currentItem.title'),
              organisation: self.get('currentItem.organisation'),
              description: self.get('currentItem.description'),
              startedOn: self.get('currentItem.startedOn'),
              endedOn: self.get('currentItem.endedOn'),
              person: self.get('currentItem.person')
            });
          }
          experience.save().then(function () {
            Ember.$('.modal').modal('hide');
            self.set('isSaving', false);
          }, function () {
            toastr.error('There was an error saving your experience, please try again');
            self.set('isSaving', false);
          });
        } else {
          self.set('isSaving', false);
        }
      }
    },

    validateForm: function validateForm(experience) {
      var self = this;
      var isValid = true;
      self.get('errors').clear();
      Ember.$('.has-error').removeClass('has-error');

      if (!experience.get('title')) {
        isValid = false;
        Ember.$('#experienceTitle').addClass('has-error');
        self.get('errors').pushObject('Title is required');
      }

      if (!experience.get('organisation')) {
        isValid = false;
        Ember.$('#where').addClass('has-error');
        self.get('errors').pushObject('Organisation is required');
      }

      if (!experience.get('startedOn')) {
        isValid = false;
        Ember.$('#experienceStartedOn').find('select').addClass('has-error');
        Ember.$('#experienceEndedOn').find('select').addClass('has-error');
        self.get('errors').pushObject('Time period is required');
      } else if (!self.get('toggleEndDate') && !experience.get('endedOn')) {
        isValid = false;
        Ember.$('#experienceEndedOn').find('select').addClass('has-error');
        self.get('errors').pushObject('End date is required');
      }

      if (!self.get('toggleEndDate') && experience.get('startedOn') > experience.get('endedOn')) {
        isValid = false;
        Ember.$('#experienceEndedOn').find('select').addClass('has-error');
        self.get('errors').pushObject('End date should be greater than start date');
      }

      return isValid;
    }
  });
});