define("mycare-ember/register/seeker-register/payment-type/controller", ["exports", "mycare-ember/utils/common", "mycare-ember/mixins/validator", "mycare-ember/utils/Validators"], function (exports, _common, _validator, _Validators) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_validator.default, {

        _formChannelId: _common.default.createGuid(),
        _accountService: Ember.inject.service('account'),
        _referenceModelService: Ember.inject.service('reference-models'),
        _isLoading: false,
        auth: Ember.inject.controller('auth'),

        formData: {
            paymentOptions: {
                isGovernmentPay: undefined,
                isPrivatePay: undefined
            }
        },

        willWizardEnter: function willWizardEnter(stepInfo, data) {
            if (Ember.isNone(data) || Ember.isNone(data.get('signingFor')) || data.get("state.who.completed") !== true) {
                return 'who';
            }
        },


        chosenPerson: function () {
            var signingFor = this.get('wizardData.signingFor');
            var wizardDataKey = signingFor === 'yourself' ? 'account' : 'lovedOne';
            return this.get("wizardData." + wizardDataKey);
        }.property('wizardData.signingFor'),

        setup: function setup() {
            var _this = this;

            this.store.findAll('support-type').then(function (supportTypes) {
                _this.set('_supportTypeOptions', supportTypes);
                _this._resetValidation();
                return _this._setupInitialFormState().then(function () {
                    _this._setupValidations();
                });
            });
        },
        _setupInitialFormState: function _setupInitialFormState() {
            var _this2 = this;

            this.set('formData.supportTypes', undefined);
            this.set('formData.initialSupportTypes', undefined);
            this.set('formData.paymentOptions.isGovernmentPay', undefined);
            return Ember.RSVP.hash({
                supportTypesOptions: this.get('_referenceModelService.getSupportTypesAsync').perform(),
                supportTypes: Ember.RSVP.resolve(this.get('chosenPerson.supportTypes'))
            }).then(function (_ref) {
                var supportTypesOptions = _ref.supportTypesOptions,
                    supportTypes = _ref.supportTypes;

                _this2.set('supportTypesOptions', supportTypesOptions);
                supportTypes = _this2.get('formData.supportTypes') || supportTypesOptions.filter(function (sp) {
                    return (_this2.get('wizardData.payment.supportTypes') || []).includes(sp.get('id'));
                }) || supportTypes;
                _this2.set('formData.supportTypes', supportTypes);
                _this2.set('formData.initialSupportTypes', supportTypes);
                _this2.set('formData.paymentOptions.isGovernmentPay', !Ember.isNone(_this2.get('formData.paymentOptions.isGovernmentPay')) ? _this2.get('formData.paymentOptions.isGovernmentPay') : _this2.get('wizardData.payment.paymentOptions.isGovernmentPay'));
                _this2.set('formData.paymentOptions.isPrivatePay', !Ember.isNone(_this2.get('formData.paymentOptions.isPrivatePay')) ? _this2.get('formData.paymentOptions.isPrivatePay') : _this2.get('wizardData.payment.paymentOptions.isPrivatePay'));
            });
        },
        _setupValidations: function _setupValidations() {
            this._resetValidation();
            this.addValidation('formData.supportTypes.[]', 'supportTypes', 'Support type', [_Validators.default.isRequireWithMessage('At least one support type is required')]);
            this.addValidation('formData.paymentOptions.isGovernmentPay', 'paymentOptions', 'Payment options', [this.validatePaymentOptions]);
            this.addValidation('formData.paymentOptions.isPrivatePay', 'paymentOptions', 'Payment options', [this.validatePaymentOptions]);
        },
        validatePaymentOptions: function validatePaymentOptions(name) {
            return {
                result: this.get('formData.paymentOptions.isGovernmentPay') === true || this.get('formData.paymentOptions.isPrivatePay') === true,
                message: name + " are required"
            };
        },


        onPrev: 'client-details',

        onNext: function onNext() {
            if (!this._validate()) {
                return false;
            }
            var parentController = this.get('stepInfo.parent');
            parentController.backupWizardData({
                payment: {
                    supportTypes: this.get('formData.supportTypes').map(function (t) {
                        return t.get('id');
                    }),
                    paymentOptions: this.get('formData.paymentOptions')
                }
            });
            return this._processForm().then(function () {
                return true;
            });
        },
        _processForm: function _processForm() {
            var _this3 = this;

            var signingFor = this.get('wizardData.signingFor');
            var wizardDataKey = signingFor === 'yourself' ? 'account' : 'lovedOne';
            var person = this.get("wizardData." + wizardDataKey);
            var payload = this.get('formData');

            var parentController = this.get('stepInfo.parent');
            parentController.backupWizardData({
                payment: {
                    supportTypes: this.get('formData.supportTypes').map(function (t) {
                        return t.get('id');
                    }),
                    paymentOptions: this.get('formData.paymentOptions')
                }
            });
            return Ember.RSVP.resolve(payload.supportTypes).then(function (supportTypes) {
                var supportTypesToSave = supportTypes || [];
                person.set('supportTypes', supportTypesToSave.toArray());
                person.set('isGovernmentPay', payload.paymentOptions.isGovernmentPay);
                person.set('isPrivatePay', payload.paymentOptions.isPrivatePay);
                if (person.get('isGovernmentPay') === true && supportTypesToSave.find(function (sp) {
                    return sp.get('id') === "1";
                })) {
                    _this3.set('wizardData.isFundingDetailRequired', true);
                } else {
                    _this3.set('wizardData.isFundingDetailRequired', false);
                }
                return person;
            });
        },


        _updateBackupDataForPaymentOptions: function () {
            var parentController = this.get('stepInfo.parent');
            parentController.backupWizardData({
                payment: {
                    supportTypes: this.get('formData.supportTypes').map(function (t) {
                        return t.get('id');
                    }),
                    paymentOptions: this.get('formData.paymentOptions')
                }
            });
        }.observes('formData.paymentOptions.{isPrivatePay,isGovernmentPay}'),

        actions: {
            onSupportTypeSelected: function onSupportTypeSelected(params, items) {
                this.set('formData.supportTypes', items);
            }
        }
    });
});