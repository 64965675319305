define("mycare-ember/seekers/findhelp/job-listings/detail/index/route", ["exports", "mycare-ember/mixins/seeker_only", "mycare-ember/mixins/protected_route"], function (exports, _seeker_only, _protected_route) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_protected_route.default, _seeker_only.default, {
        beforeModel: function beforeModel() {
            this.transitionTo('seekers.findhelp.job-listings.edit');
        }

    });
});