define("mycare-ember/components/mc-popover-option/component", ["exports", "mycare-ember/utils/mc-component", "mycare-ember/utils/function-helpers", "mycare-ember/utils/common"], function (exports, _mcComponent, _functionHelpers, _common) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        tagName: '',
        sortOrder: '',

        didReceiveAttrs: function didReceiveAttrs() {
            this.set('parentId', _common.default.createGuid());
            this.set('buttonId', _common.default.createGuid());
        },
        didRender: function didRender() {
            Ember.$('#' + this.get('parentId')).on('hide.bs.dropdown', function () {
                return false;
            });
        },


        watchSortOrder: function () {
            if (this.get('sortOrder') !== 'hideSelection') {
                _functionHelpers.default.callOrIgnore(this.get('onSetSelectOrder'), this.get('sortOrder'));
            } else {
                _functionHelpers.default.callOrIgnore(this.get('onSetSelectOrder'), '');
            }
        }.observes('sortOrder'),

        actions: {
            open: function open() {
                this.set('_shouldRender', true);
                Ember.$('.dropdown.open').removeClass('open');
                Ember.$('#' + this.get('parentId')).addClass('open');
            },
            close: function close() {
                Ember.$('.dropdown.open').removeClass('open');
            },
            clearSelectedOptions: function clearSelectedOptions() {
                this.set('sortOrder', 'hideSelection');
            },
            apply: function apply() {
                Ember.$('.dropdown.open').removeClass('open');
            }
        }
    });
});