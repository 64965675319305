define('mycare-ember/seekers/findhelp/dashboard/controller', ['exports', 'mycare-ember/mixins/mixpanel'], function (exports, _mixpanel) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_mixpanel.default, {
        _workerService: Ember.inject.service('worker'),
        mixpanelService: Ember.inject.service('utils/mixpanel'),

        actions: {
            onApplicantBookEvent: function onApplicantBookEvent(applicantId) {
                var _this = this;

                this.get('_workerService.getBookablePersonById').perform({
                    profileId: this.get('session.authData.currentPersonId'),
                    workerId: applicantId
                }).then(function (person) {
                    _this.transitionToRoute('book', {
                        queryParams: {
                            bookedPersonId: person.get('id')
                        }
                    });
                    _this.get('mixpanelService').mixpanelCreateAgreementButtonClicked(true);
                });
            }
        }
    });
});