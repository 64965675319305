define("mycare-ember/components/mc-file-manager/component", ["exports", "mycare-ember/utils/mc-component"], function (exports, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        files: Ember.computed.sort('person.files', '_sortProps'),
        _errors: [],
        _profileService: Ember.inject.service('profile'),
        _fileService: Ember.inject.service('file'),

        _fileUploadUrl: function () {
            return this.get('env').getApp('API_HOST') + '/api/FileUpload';
        }.property(),

        _sortProps: ['uploadedOn:desc'],

        _isUploading: function () {
            return this.get('percent') > 0;
        }.property('percent'),

        _uploadPercent: function () {
            return parseInt(this.get('percent')) + '%';
        }.property('percent'),

        _uploadcomplete: function () {
            var percent = this.get('percent') + '%';
            return Ember.String.htmlSafe("width: " + percent);
        }.property('percent'),

        init: function init() {
            this.initVar('person', null);
            this._super.apply(this, arguments);
        },


        actions: {
            deleteFile: function deleteFile(file) {
                bootbox.confirm('<h3 class="mt-0">Are you sure you want to delete?</h3>', function (result) {
                    if (result) {
                        file.deleteRecord();
                        file.save().catch(function () {
                            toastr.error('There was an error deleting your file ' + file.get("fileName") + ', please try again');
                        });
                    }
                });
            },
            updateFile: function updateFile(file, bool) {
                file.set('isVisible', bool);
                file.save().then(function (file) {
                    if (bool) {
                        toastr.info('File is visible to clients.');
                    } else {
                        toastr.info('File is not visible to clients.');
                    }
                });
            },


            upload: function upload(obj) {
                var _this = this;

                var self = this;
                this.get('_fileService.createFileAsync').perform({
                    fileUrl: obj.url,
                    fileName: obj.fileName,
                    personId: self.get('person.id')
                }).then(function (file) {
                    _this.get('person.files').pushObject(file);
                    //this.get('person').save();
                }, function () {
                    toastr.error('There was a problem adding your file, please try again');
                });
            },

            showUploadModal: function showUploadModal() {
                Ember.$('.uploader').click();
            },

            checkFile: function checkFile(text) {
                this.get('filesUploadErrors').pushObject(text);
            },

            emptyErrors: function emptyErrors() {
                this.set('filesUploadErrors', []);
            }
        }
    });
});