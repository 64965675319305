define("mycare-ember/seekers/findhelp/job-listings/detail/preview/route", ["exports", "mycare-ember/mixins/seeker_only", "mycare-ember/utils/mc-protected-route"], function (exports, _seeker_only, _mcProtectedRoute) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcProtectedRoute.default.extend(_seeker_only.default, {
        jobListingService: Ember.inject.service('job-listing'),

        model: function model(params, transition) {
            var jobListingId = transition.params['seekers.findhelp.job-listings.detail'].jobListingId;
            return this.get('jobListingService.findByIdAsync').perform({ id: jobListingId });
        }
    });
});