define('mycare-ember/services/utils/livechat', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        session: Ember.inject.service('session'),
        env: Ember.inject.service('utils/env'),

        activateLiveChatAlwaysOn: function activateLiveChatAlwaysOn() {
            var _this = this;

            if (window.$zopim && window.$zopim.livechat) {
                window.$zopim.livechat.window.onHide(null);
                window.$zopim.livechat.button.show();
                console.log('live chat activated (alwayson)');
            } else {
                Ember.run.later(function () {
                    _this.activateLiveChatAlwaysOn();
                }, 100);
            }
        },
        activateLiveChat: function activateLiveChat() {
            var _this2 = this;

            if (window.$zopim && window.$zopim.livechat) {
                window.$zopim.livechat.window.onHide(function () {
                    _this2.deactivateLiveChat();
                });
                window.$zopim.livechat.button.show();
                console.log('live chat activated');
            } else {
                Ember.run.later(function () {
                    _this2.activateLiveChat();
                }, 100);
            }
        },
        openLivechat: function openLivechat() {
            var _this3 = this;

            if (window.$zopim && window.$zopim.livechat) {
                window.$zopim.livechat.window.onHide(function () {
                    _this3.deactivateLiveChat();
                });
                window.$zopim.livechat.window.show();
                var loggedInUser = this.get('session.authData.loggedInUser');
                if (loggedInUser) {
                    window.$zopim.livechat.setName(loggedInUser.get('firstName') + ' ' + loggedInUser.get('lastName'));
                    window.$zopim.livechat.setEmail(loggedInUser.get('email'));
                    window.$zopim.livechat.setPhone(loggedInUser.get('phoneNumber'));
                    window.$zopim.livechat.addTags(['app', this.get('session.authData.isSeeker') ? 'seeker' : 'worker']);
                }
            } else {
                Ember.run.later(function () {
                    _this3.openLivechat();
                }, 100);
            }
        },
        deactivateLiveChat: function deactivateLiveChat() {
            var _this4 = this;

            if (window.$zopim && window.$zopim.livechat) {
                window.$zopim.livechat.hideAll();
                console.log('live chat deactivated');
            } else {
                Ember.run.later(function () {
                    _this4.deactivateLiveChat();
                }, 100);
            }
        }
    });
});