define('mycare-ember/components/mc-booking/booking-error/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('httpErrorResponse', undefined);
            this.initVar('bookedPersonName', undefined);
            this.initVar('weekStartDate', undefined);
            this.initVar('duplicateTimesheets', undefined);

            this.set('_errorType', 'unkown');
        },
        didReceiveAttrs: function didReceiveAttrs() {
            this.set('_weekStartDate', this.get('weekStartDate').toISOString());
            this.setErrorType();
        },
        setErrorType: function setErrorType() {
            var error = this.get('httpErrorResponse');
            if (error && error.errors && error.errors[0] && error.errors[0].status === '400') {
                this.set('_errorType', 'duplicate');
            }
        },


        _errorMessage: function () {
            switch (this.get('_errorType')) {
                case 'duplicate':
                    return '\n                    You already have a visit with <strong>' + this.get('bookedPersonName') + '</strong> at this time. <br>\n                    Please reschedule if you want to change the time.\n                ';
                default:
                    return '\n                    There is a problem booking your visit with <strong>' + this.get('bookedPersonName') + '</strong>. \n                    Please contract Mycare.\n                ';
            }
        }.property('_errorType')
    });
});