define('mycare-ember/me/account/reset-password/route', ['exports', 'mycare-ember/mixins/protected_route', 'mycare-ember/utils/mc-route'], function (exports, _protected_route, _mcRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _mcRoute.default.extend(_protected_route.default, {
    beforeModel: function beforeModel(transition) {
      this.isAuthenticated(transition);
    }
  });
});