define("mycare-ember/components/mc-search-bar/check-boxes/component", ["exports", "mycare-ember/utils/function-helpers", "mycare-ember/utils/mc-component"], function (exports, _functionHelpers, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('options', null);
            this.initVar('onSelected', null);
            this.initVar('actionParam', null);
        },
        didReceiveAttrs: function didReceiveAttrs() {
            if (!Ember.isEmpty(this.get('options'))) {
                // this._setupFormData();
                this.toggleProperty('_isInitialised');
            }
        },


        actions: {
            onSelected: function onSelected(item) {
                item.toggleProperty('isChecked');
                var selectedItems = this.get('options').filter(function (item) {
                    return item.get('isChecked') === true;
                });
                _functionHelpers.default.callOrIgnore(this.get('onSelected'), this.get('actionParam'), selectedItems);
            }
        }
    });
});