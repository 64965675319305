define('mycare-ember/components/mc-booking/new-contract-modal/component', ['exports', 'mycare-ember/utils/mc-modal-component', 'mycare-ember/utils/function-helpers'], function (exports, _mcModalComponent, _functionHelpers) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend({
        init: function init() {
            this.set('_componentId', 'mc-new-contract-modal');
            this._super.apply(this, arguments);
            this.initVar('channelId', 'global');
            this.initVar('onSaved', undefined);
            this.set('_contract', undefined);
            this.set('_showMore', false);
            this.set('_bookingType', false);
        },
        _onBeforeOpen: function _onBeforeOpen(_ref) {
            var contract = _ref.contract,
                bookingType = _ref.bookingType;

            this.set('_contract', contract);
            this.set('_bookingType', bookingType);
            this.set('_rate', bookingType === 'hourly' ? parseFloat(contract.get('hourlyRate')) : parseFloat(contract.get('nightlyRate')));
            this.set('_mycareFee', parseFloat(this.get('_rate')) * parseFloat(contract.get('getServiceFee')));
            this.set('_total', this.get('_rate') + this.get('_mycareFee'));
        },


        actions: {
            onSaved: function onSaved() {
                _functionHelpers.default.callOrIgnore(this.get('onSaved'), { contract: this.get('_contract') });
                this._close();
            },
            toggleShowMore: function toggleShowMore() {
                this.toggleProperty('_showMore');
            }
        }
    });
});