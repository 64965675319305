define("mycare-ember/components/mc-search-bar/filter/component", ["exports", "mycare-ember/utils/common", "mycare-ember/utils/mc-component", "mycare-ember/utils/function-helpers"], function (exports, _common, _mcComponent, _functionHelpers) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        tagName: '',

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('parentId', _common.default.createGuid());
            this.initVar('buttonId', _common.default.createGuid());
            this.initVar('isMojRequired', undefined);
            this.initVar('isMale', undefined);
        },
        didRender: function didRender() {
            Ember.$('#' + this.get('parentId')).on('hide.bs.dropdown', function () {
                return false;
            });
            this.set('isComponentReady', true);
        },


        actions: {
            open: function open() {
                Ember.$('.dropdown.open').removeClass('open');
                Ember.$('#' + this.get('parentId')).addClass('open');
            },
            applyValue: function applyValue() {
                _functionHelpers.default.callOrIgnore(this.get('onSetFilter'), {
                    isMojRequired: this.get('isMojRequired'),
                    isMale: this.get('isMale')
                });
                this.set('isUpdated', true);
                Ember.$('.dropdown.open').removeClass('open');
            },
            cancelValue: function cancelValue() {
                this.set('isMojRequired', undefined);
                this.set('isMale', undefined);
                _functionHelpers.default.callOrIgnore(this.get('onSetFilter'), {
                    isMojRequired: this.get('isMojRequired'),
                    isMale: this.get('isMale')
                });
                this.set('isUpdated', false);
                Ember.$('.dropdown.open').removeClass('open');
            }
        }
    });
});