define("mycare-ember/helpers/short-string", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function makeDescriptionShort(data) {
    var description = data[0];
    var strLength = data[1];
    if (description !== undefined && description !== null) {
      if (description.length > strLength) {
        description = description.substring(0, strLength - 1) + "... ";
      }
      return description;
    } else {
      return description;
    }
  }

  exports.makeDescriptionShort = makeDescriptionShort;
  exports.default = Ember.Helper.helper(makeDescriptionShort);
});