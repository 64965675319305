define("mycare-ember/controllers/partials/manage/timesheet/modal-timesheet-entry-activities", ["exports", "mycare-ember/mixins/activity-type-create-mixin"], function (exports, _activityTypeCreateMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_activityTypeCreateMixin.default, {
    auth: Ember.inject.controller('auth'),
    timesheetController: Ember.inject.controller('seekers/manage/schedule-workers/index/schedule-detail'),
    initialTypes: [],
    isInitialTypesReady: false,

    setup: function setup() {
      var _this = this;

      var initialTypes = Ember.A([]);
      Ember.RSVP.resolve(this.get('model.activities')).then(function (activities) {
        _this.set('activities', activities);
        var activityTypes = activities.map(function (act) {
          return Ember.RSVP.resolve(act.get('activityType'));
        });
        return Ember.RSVP.all(activityTypes).then(function (result) {
          initialTypes.pushObjects(result);
        });
      }).then(function () {
        _this.set('initialTypes', initialTypes);
        _this.set('isInitialTypesReady', true);
      });
    },

    actions: {
      onSelectedActivities: function onSelectedActivities(activitites) {
        this.set('selectedActivities', activitites);
      },


      update: function update() {
        var _this2 = this;

        this.get('selectedActivities').forEach(function (item) {
          if (!_this2.get('initialTypes').includes(item)) {
            var activity = _this2.store.createRecord('activity', {
              activityType: item,
              timesheet: _this2.get('model')
            });
            activity.save();
          }
        });
        this.get('activities').forEach(function (activity) {
          Ember.RSVP.resolve(activity.get('activityType')).then(function (activityType) {
            if (!_this2.get('selectedActivities').includes(activityType) && Ember.isNone(activity.get('completedOn'))) {
              activity.deleteRecord();
              activity.save();
            } else {
              if (!Ember.isNone(activity.get('completedOn'))) {
                _this2.get('selectedActivities').pushObject(activity);
              }
            }
          });
        });
      }
    }
  });
});