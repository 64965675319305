define('mycare-ember/models/comment', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        commentBody: _emberData.default.attr('string'),
        circleMember: _emberData.default.belongsTo('person-circle', { async: true }),
        circleMemberFirstName: _emberData.default.attr('string'),
        circleMemberAvatar: _emberData.default.belongsTo('image', { async: true }),
        createdOn: _emberData.default.attr('string'),
        modifiedOn: _emberData.default.attr('string'),
        post: _emberData.default.belongsTo('post', { async: true })
    });
});