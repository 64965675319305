define("mycare-ember/seekers/manage/schedule-workers/index/visits-list/accepted/controller", ["exports", "mycare-ember/mixins/mixpanel"], function (exports, _mixpanel) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_mixpanel.default, {
        queryParams: ['startDate', 'endDate', 'reload'],

        actions: {
            toAddVisits: function toAddVisits() {
                this.transitionToRoute('book', {
                    queryParams: {
                        date: this.get('startDate')
                    }
                });
            }
        }
    });
});