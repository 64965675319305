define('mycare-ember/models/expense', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        description: _emberData.default.attr('string'),
        amount: _emberData.default.attr('number'),
        quantity: _emberData.default.attr('number'),
        timesheet: _emberData.default.belongsTo('timesheet', { async: true }),
        expenseType: _emberData.default.belongsTo('expense-type', { async: true }),
        createdPerson: _emberData.default.belongsTo('person', { async: true })
    });
});