define("mycare-ember/controllers/partials/manage/date-selected", ["exports", "mycare-ember/mixins/get-date-of-week"], function (exports, _getDateOfWeek) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_getDateOfWeek.default, {
        selectedDate: '',
        periodStartsOn: '',
        periodEndsOn: '',

        watchSelectedDate: function () {
            var self = this;
            self.setStartAndEndOfWeek(moment(self.get('selectedDate')).utc());
        }.observes('selectedDate'),

        setStartAndEndOfWeek: function setStartAndEndOfWeek(date) {
            var dateOfToday = new Date();
            if (date) {
                dateOfToday = new Date(date);
            }
            var dateOfMonday = this.getMonday(dateOfToday);
            var dateOfSunday = this.getSunday(dateOfToday);
            this.set('periodStartsOn', dateOfMonday);
            this.set('periodEndsOn', dateOfSunday);
        }
    });
});