define("mycare-ember/seekers/manage/schedule-workers/index/new-timesheet/controller", ["exports", "mycare-ember/mixins/account-pending-balance", "mycare-ember/mixins/get-date-of-week"], function (exports, _accountPendingBalance, _getDateOfWeek) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_accountPendingBalance.default, _getDateOfWeek.default, {
        timesheets: [],
        selectedContract: '',
        selectedAccount: '',
        notes: [],
        activities: [],
        bookingSteps: Ember.A(['dateTime', 'activities', 'note']),
        auth: Ember.inject.controller('auth'),
        contractController: Ember.inject.controller('seekers/manage/schedule-workers/index/new/contract'),
        addPaymentController: Ember.inject.controller('seekers/manage/schedule-workers/index/new/add-payment'),
        newTimesheetIndexController: Ember.inject.controller('seekers/manage/schedule-workers/index/new/index'),
        activityController: Ember.inject.controller('seekers/manage/schedule-workers/index/new/activity'),
        noteController: Ember.inject.controller('seekers/manage/schedule-workers/index/new/note'),
        timesheetService: Ember.inject.service('timesheet'),
        workerService: Ember.inject.service('worker'),
        isCreditCardRequired: false,
        duplicateVisitError: [],

        setup: function setup() {
            var self = this;
            self.set('payGap', '');
            this.set('duplicateVisitError', []);
        },
        clearData: function clearData() {
            this.get('timesheets').clear();
            this.set('selectedContract', '');
            this.get('notes').clear();
            this.set('hourlyRate', '');
            this.set('tempDescription', '');
            this.get('activities').clear();
            this.set('payGap', '');
            this.get('newTimesheetIndexController').clearData();
            // this.get('activityController').setup();
            this.get('noteController').setup();
            this.set('isDoneActivities', false);
            this.set('isDoneNotes', false);
        },


        watchSelectedContract: function () {
            var self = this;
            self.set('isCreditCardRequired', false);
            // check credit card
            self.store.findRecord('person', self.get('auth.profileId')).then(function (person) {
                return person.get('accounts');
            }).then(function (accounts) {
                return Ember.RSVP.all(accounts.map(function (acc) {
                    return Ember.RSVP.resolve(acc.get('accountType'));
                })).then(function () {
                    return accounts.filter(function (acc) {
                        return acc.get('accountType.id') !== '4';
                    }).objectAt(0);
                });
            }).then(function (account) {
                return Ember.RSVP.resolve(account.get('creditCards')).then(function (cc) {
                    return { account: account, creditCards: cc };
                });
            }).then(function (_ref) {
                var account = _ref.account,
                    creditCards = _ref.creditCards;

                var validCreditCards = creditCards.filter(function (creditCard) {
                    return !Ember.isEmpty(creditCard.get('cardNumber'));
                });
                if (validCreditCards.get('length') === 0 && account.get('availableBalance') <= 0) {
                    self.addAddCreditCardStep();
                }
            });
        }.observes('selectedContract'),

        creditCardRequiredProcess: function creditCardRequiredProcess() {
            var self = this;
            if (self.get('isCreditCardRequired') === true) {
                self.get('addPaymentController').set('creditCardError', 'You need to add a card before we can book your visits. If you have any questions please call Mycare on 09 887 9777.');
                self.transitionToRoute('seekers.manage.schedule-workers.index.new.add-payment');
            } else {
                self.createTimeSheetsOrContract();
            }
        },

        createTimeSheetsOrContract: function createTimeSheetsOrContract() {
            var self = this;
            //update local private account detail
            self.set('isDoneActivities', false);
            self.set('isDoneNotes', false);
            if (Ember.isEmpty(self.get('selectedContract')) === true) {
                self.newContract();
            } else {
                if (Ember.isEmpty(self.get('payGap')) === false && self.get('payGap') > 0) {
                    self.createTimesheets();
                } else {
                    self.timesheetsSave();
                }
            }
        },
        newContract: function newContract() {
            var self = this;
            self.set('isSaving', true);
            var createTime = moment().utc().toISOString();
            var contractStartsOn = moment(self.getMonday(self._getTimesheetStartTime())).startOf('day').toISOString();
            self.store.findRecord('person', self.get('auth.profileId')).then(function (profilePerson) {
                self.store.findRecord('person', self.get('auth.loggedInUserId')).then(function (loggedInUser) {
                    var contractName = self.get('selectedWorker.firstName') + ' - ' + self.get('selectedAccount.accountTypeName');
                    var contract = self.store.createRecord('contract', {
                        clientPerson: profilePerson,
                        createdBy: loggedInUser,
                        contractName: contractName,
                        contractorName: self.get('selectedWorker.firstName'),
                        clientName: loggedInUser.get('firstName'),
                        contractorPerson: self.get('selectedWorker'),
                        contractStartsOn: contractStartsOn,
                        hourlyRate: self.get('hourlyRate'),
                        nightlyRate: self.get('nightlyRate'),
                        clientSignedOn: createTime,
                        createdOn: createTime,
                        account: self.get('selectedAccount'),
                        fundingSource: self.get('selectedAccount.accountType.name'),
                        taxRate: self.get('selectedAccount.accountType.taxRate'),
                        gstRate: self.get('selectedAccount.accountType.gstRate'),
                        workerServiceFee: self.get('selectedAccount.accountType.workerServiceFee'),
                        clientServiceFee: self.get('selectedAccount.accountType.clientServiceFee')
                    });

                    self.set('contractController.model', { contract: contract });
                    self.set('isSaving', false);
                    self.transitionToRoute('seekers.manage.schedule-workers.index.new.contract');
                });
            });
        },
        createTimesheets: function createTimesheets() {
            var self = this;
            self.set('isSaving', true);
            if (Ember.isEmpty(self.get('payGap')) === false && self.get('payGap') > 0) {
                self.get('selectedContract.account').then(function (account) {
                    var successFunction = function successFunction() {
                        self.set('isSaving', false);
                        self.timesheetsSave();
                    };
                    var errorFunction = function errorFunction() {
                        self.set('isSaving', false);
                        self.transitionToRoute('seekers.manage.schedule-workers.index.new.add-payment');
                        self.set('addPaymentController.customTitle', 'We were unable to create your visits');
                        self.set('addPaymentController.creditCardError', 'Seems there is an error with your card.');
                    };
                    self.get('timesheetService.canCreateVisitsAsync').perform({
                        accountId: account.get('id'), visitAmount: self.get('totalCost')
                    }).then(successFunction, errorFunction);
                });
            } else {
                self.timesheetsSave();
            }
        },
        timesheetsSave: function timesheetsSave() {
            var self = this;
            self.set('isSaving', true);
            var newTimesheets = self.get('timesheets');
            var contract = self.get('selectedContract');
            newTimesheets.forEach(function (timesheet, timesheetIndex) {
                self.get('workerService.getBookablePersonById').perform({
                    profileId: contract.get('clientPerson.id'),
                    workerId: contract.get('contractorPerson.id')
                }).then(function (applicant) {
                    if (applicant != null) {
                        applicant.set('isWorker', true);
                        applicant.set('isBookable', true);
                    }
                });
                timesheet.set('contract', contract);
                timesheet.set('totalCostClient', 0);
                timesheet.set('totalCostWorker', 0);
                timesheet.set('isPublished', true);
                timesheet.save().then(function (timesheet) {
                    if (Ember.isEmpty(self.get('activities'))) {
                        self.set('isDoneActivities', true);
                    } else {
                        self.get('activities').forEach(function (item, activityIndex) {
                            var activity = self.store.createRecord('activity', {
                                activityType: item,
                                timesheet: timesheet
                            });
                            activity.save().then(function () {
                                if (activityIndex + 1 === self.get('activities.length') && timesheetIndex + 1 === newTimesheets.get('length')) {
                                    self.set('isDoneActivities', true);
                                }
                            });
                        });
                    }
                    if (Ember.isEmpty(self.get('notes'))) {
                        self.set('isDoneNotes', true);
                    } else {
                        self.store.findRecord('person', self.get('auth.profileId')).then(function (person) {
                            var nowDateTime = moment().toISOString();
                            self.get('notes').forEach(function (note, noteIndex) {
                                self.store.createRecord('timesheet-note', {
                                    timesheet: timesheet,
                                    description: note.get('description'),
                                    person: person,
                                    modifiedOn: nowDateTime,
                                    createdOn: nowDateTime
                                }).save().then(function () {
                                    if (noteIndex + 1 === self.get('notes.length') && timesheetIndex + 1 === newTimesheets.get('length')) {
                                        self.set('isDoneNotes', true);
                                    }
                                });
                            });
                        });
                    }
                }).catch(function (error) {
                    self.set('isSaving', false);
                    if (error.errors && error.errors[0] && error.errors[0].status === '400') {
                        self.get('duplicateVisitError').pushObject(true);
                        self.get('noteController.duplicateVisitError').pushObject(true);
                        self.get('contractController.duplicateVisitError').pushObject(true);
                        self.get('addPaymentController.duplicateVisitError').pushObject(true);
                    } else {
                        throw error;
                    }
                });
            });
        },


        watchSaveProcess: function () {
            var self = this;
            if (!Ember.isEmpty(self.get('duplicateVisitError'))) return;
            if (self.get('isDoneActivities') === true && self.get('isDoneNotes') === true) {
                self.set('isSaving', false);
                self.transitionToRoute('seekers.manage.schedule-workers.index', {
                    queryParams: {
                        reload: true
                    }
                });
                self.balanceValidation();
                self.clearData();
            }
        }.observes('isDoneNotes', 'isDoneActivities'),

        balanceValidation: function balanceValidation() {
            var self = this;
            if (Ember.isEmpty(self.get('selectedContract')) === false && self.get('selectedContract.account.accountType.isPrivatePayEnabled') === true) {
                self.processPendingBalance(self.get('selectedContract.account.id'));
            }
        },
        _getContractStartTime: function _getContractStartTime() {
            var self = this;
            // scheduledStartsOn
            var timesheet = self.get('timesheets').objectAt(0);
            return moment(self.getMonday(timesheet.get('scheduledStartsOn'))).startOf('day').toISOString();
        },
        _getTimesheetStartTime: function _getTimesheetStartTime() {
            var self = this;
            var timesheetStartTime = '';
            self.get('timesheets').forEach(function (timesheet) {
                // time
                if (Ember.isEmpty(timesheetStartTime)) {
                    timesheetStartTime = timesheet.get('scheduledStartsOn');
                }
                if (moment(timesheetStartTime).isAfter(timesheet.get('scheduledStartsOn'))) {
                    timesheetStartTime = timesheet.get('scheduledStartsOn');
                }
            });
            return timesheetStartTime;
        },
        addAddCreditCardStep: function addAddCreditCardStep() {
            var self = this;
            self.set('isCreditCardRequired', true);
            var creditCardStep = self.get('bookingSteps').filter(function (step) {
                return step === 'creditCard';
            });
            if (creditCardStep.get('length') === 0) {
                self.get('bookingSteps').pushObject('creditCard');
            }
        },
        removeAddCreditCardStep: function removeAddCreditCardStep() {
            var self = this;
            self.set('isCreditCardRequired', false);
            self.get('bookingSteps').removeObject('creditCard');
        }
    });
});