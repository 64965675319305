define("mycare-ember/seekers/findhelp/dss-form/controller", ["exports", "mycare-ember/mixins/dss-form"], function (exports, _dssForm) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_dssForm.default, {
        queryParams: ['redirectTo', 'jobId'],
        redirectTo: null,
        jobId: null,
        errors: [],

        setup: function setup() {
            this.dssFormSetup();
        },


        actions: {
            submit: function submit() {
                var self = this;
                if (self.validateDssForm() === true) {
                    self.set('isSubmitLoading', true);
                    Ember.RSVP.hash({
                        registrationType: self.store.findRecord('registration-type', 1)
                    }).then(function (_ref) {
                        var registrationType = _ref.registrationType;

                        var registration = self.store.createRecord('registration', {
                            firstName: self.get('model.firstName'),
                            lastName: self.get('model.lastName'),
                            dateOfBirth: self.get('model.dateOfBirth'),
                            gender: self.get('model.gender'),
                            phoneNumber: self.get('model.phoneNumber'),
                            uniqueId: self.get('uniqueId'),
                            organisation: self.get('selectedOrganisation'),
                            fundingTypes: self.get('selectedFundingTypes'),
                            ethnicities: self.get('selectedEthnicity'),
                            disabilities: self.get('selectedDisabilities'),
                            registrationType: registrationType,
                            person: self.get('model')
                        });

                        registration.save().then(function (registration) {
                            var accountId = registration.get('person.accounts').objectAt(0).get('id');
                            self.store.findRecord('account', accountId, { reload: true }).then(function () {
                                self.set('isSubmitLoading', false);
                                if (self.get('redirectTo')) {
                                    self.transitionToRoute(self.get('redirectTo'), self.get('jobId'), {
                                        queryParams: {
                                            publish: true
                                        }
                                    });
                                } else {
                                    self.transitionToRoute('seekers.findhelp.dashboard');
                                }
                            });
                        });
                    });
                }
            },
            back: function back() {
                var self = this;
                if (self.get('redirectTo')) {
                    self.transitionToRoute(this.get('redirectTo'));
                } else {
                    self.transitionToRoute('seekers.findhelp.dashboard');
                }
            }
        }
    });
});