define("mycare-ember/me/account/payment/controller", ["exports", "mycare-ember/mixins/form-checker", "mycare-ember/mixins/defer", "mycare-ember/utils/common", "ember-concurrency"], function (exports, _formChecker, _defer, _common, _emberConcurrency) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_formChecker.default, _defer.default, {
        _store: Ember.inject.service('store'),
        _bankIrdFormComponentId: _common.default.createGuid(),

        actions: {
            updateBankAccount: function updateBankAccount() {
                this.get('eventbus').publish("mc-bank-ird-form:on:open:global", {
                    isShowIrdForm: true,
                    isShowBankAccountForm: true,
                    worker: this.get('model.person'),
                    initialBankAccountId: this.get('model.selectedAccount.preferredBankAccount.id'),
                    initialIrd: this.get('model.selectedAccount.irdNumber')

                });
            }
        }
    });
});