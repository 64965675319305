define('mycare-ember/models/contract-base-detail', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        body: _emberData.default.attr('string'),

        contractBody: function () {
            return this.get('body');
        }.property('body')
    });
});