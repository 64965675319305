define("mycare-ember/utils/base-service", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Service.extend(Ember.Evented, {
        _cacheService: Ember.inject.service('utils/cache-store'),
        _subscribers: [],

        _cache: {},

        init: function init() {
            this._super.apply(this, arguments);
        },
        _triggerEvents: function _triggerEvents(events) {
            var _this = this;

            events.forEach(function (event) {
                _this.trigger.apply(_this, _toConsumableArray(event));
            });
        },
        _triggerEvent: function _triggerEvent() {
            this.trigger.apply(this, arguments);
        }
    });
});