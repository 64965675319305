define("mycare-ember/seekers/manage/schedule-workers/index/new/note/route", ["exports", "mycare-ember/mixins/protected_route", "mycare-ember/mixins/scroll_top"], function (exports, _protected_route, _scroll_top) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_protected_route.default, _scroll_top.default, {
        beforeModel: function beforeModel(transition) {
            var self = this;
            self._super();
            self.isAuthenticated(transition);
            var newTimesheetController = self.controllerFor('seekers/manage/schedule-workers/index/new-timesheet');
            if (Ember.isEmpty(newTimesheetController.get('timesheets')) === true) {
                self.transitionTo('seekers.manage.schedule-workers.index.new');
            }
        },
        setupController: function setupController(controller) {
            controller.setup();
        }
    });
});