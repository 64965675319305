define("mycare-ember/components/mc-charts/work-and-income/component", ["exports", "mycare-ember/utils/mc-component"], function (exports, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('title', undefined);
            this.initVar('baseType', 'bar');
            this.initVar('data', []);
        },


        watchData: function () {
            if (!this.$()) return;
            var ctx = this.$('canvas');
            ctx.remove(); // this is my <canvas> element
            this.$().append('<canvas><canvas>');
            ctx = this.$('canvas');
            var chart = new Chart(ctx, {
                type: this.get('baseType'),
                data: this.get('data'),
                options: {
                    responsive: true,
                    title: {
                        display: !Ember.isNone(this.get('title')),
                        text: this.get('title')
                    },
                    scales: {
                        yAxes: [{
                            position: "left",
                            "id": "y-axis-0",
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                callback: function callback(value) {
                                    return "$" + value;
                                }
                            }
                        }]
                    }
                }
            });
            chart.update();
        }.observes('data')

    });
});