define("mycare-ember/helpers/number-format-plain", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    function roundToTwo(num) {
        return +(Math.round(num + "e+2") + "e-2");
    }

    function numberFormat(data) {
        var number = data[0];
        var fixed = data[1];
        if (Ember.isEmpty(fixed)) {
            fixed = 2;
        }
        return roundToTwo(number).toFixed(fixed);
    }

    exports.numberFormat = numberFormat;
    exports.default = Ember.Helper.helper(numberFormat);
});