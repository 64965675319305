define('mycare-ember/models/person-circle', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        role: _emberData.default.belongsTo('role', { async: true }),
        person: _emberData.default.belongsTo('person', { async: true }),
        circle: _emberData.default.belongsTo('circle', { async: true }),
        posts: _emberData.default.hasMany('post', { async: true }),
        email: _emberData.default.attr('string'),
        isRemoved: _emberData.default.attr('boolean')
    });
});