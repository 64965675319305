define("mycare-ember/register/seeker-register/index/controller", ["exports", "mycare-ember/mixins/mixpanel"], function (exports, _mixpanel) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_mixpanel.default, {

        queryParams: ['email'],

        onNext: function onNext() {},


        suggestedEmail: function () {
            return this.get('email');
        }.property('email'),

        actions: {
            onAccountCreatedEvent: function onAccountCreatedEvent(person) {
                //jhint ignore:line
                this.mixpanelSignUp(person);
                this.set('wizardData.account', person);
                this.send('next');
            }
        }
    });
});