define('mycare-ember/mixins/has-a-profile-protection_route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    beforeModel: function beforeModel() {
      var self = this;
      self._super.apply(self, arguments);
      self.store.findRecord('person', self.controllerFor('auth').get('loggedInUserId')).then(function (person) {
        self.store.findRecord('person', self.controllerFor('auth').get('profileId')).then(function (profilePerson) {
          if (person.get('relationships.length') > 0 && profilePerson.get('isValidSubscriber') === true) {
            self.transitionTo('me.profiles');
          }
        });
      });
    }
  });
});