define('mycare-ember/mixins/redirect-paid-seeker_route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    beforeModel: function beforeModel() {
      var self = this;
      var authController = this.controllerFor('auth');
      self.store.findRecord('person', authController.get('currentPersonId'), { reload: true }).then(function (person) {
        if (person.get('isValidSubscriber') === true) {
          self.transitionTo('seekers.findhelp');
        }
      });
    }
  });
});