define('mycare-ember/models/report', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        reportType: _emberData.default.attr('string'),
        reportLabel: _emberData.default.attr('string'),
        rangeLabel: _emberData.default.attr('string'),
        valuesOneLabel: _emberData.default.attr('string'),
        valuesTwoLabel: _emberData.default.attr('string'),
        range: _emberData.default.attr(),
        valuesOne: _emberData.default.attr(),
        valuesTwo: _emberData.default.attr()
    });
});