define('mycare-ember/adapters/application', ['exports', 'ember-data', 'mycare-ember/config/environment'], function (exports, _emberData, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTAdapter.extend({
        host: _environment.default.APP.API_HOST,
        eventbus: Ember.inject.service('utils/eventbus'),
        session: Ember.inject.service('session'),
        redirectService: Ember.inject.service('utils/redirect'),

        headers: function () {
            if (localStorage.getItem(_environment.default.APP.AUTH_TOKEN_KEY)) {
                return {
                    "Authorization": "Bearer " + localStorage.getItem(_environment.default.APP.AUTH_TOKEN_KEY)
                };
            } else {
                return {};
            }
        }.property().volatile(),

        /**
         * Namespace for the ember app.
         * For example, http://www.example.com/api <= only url after api(namespace) will be routed to ember router.
         */
        namespace: 'api',
        semaphore: undefined,

        shouldReloadAll: function shouldReloadAll() {
            return false;
        },

        shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
            return false;
        },

        handleResponse: function handleResponse(status) {
            if (status === 401) {
                this.get('eventbus').publish('global:on:changeRoute', 'reconnect-session');
            }
            return this._super.apply(this, arguments);
        }
    });
});