define('mycare-ember/mixins/unauthenticated_route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    redirectRouteName: 'index',

    redirect: function redirect() {
      if (this.controllerFor('auth').get('isAuthenticated')) {
        this.transitionTo(this.get('redirectRouteName'));
      }
    },

    beforeModel: function beforeModel() {
      this.redirect();
    }
  });
});