define("mycare-ember/components/time-picker/component", ["exports", "mycare-ember/utils/mc-component", "mycare-ember/utils/function-helpers"], function (exports, _mcComponent, _functionHelpers) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        date: '',
        hours: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
        minutes: ['00', '15', '30', '45'],

        init: function init() {
            this._super.apply(this, arguments);
            this.watchTime();
        },
        didInsertElement: function didInsertElement() {
            var momentTime = moment().utc();
            if (this.get('time') !== 'Invalid date') {
                momentTime = moment(this.get('time'));
            }
            this.setHour(momentTime);
            this.setMinute(momentTime.minute());
            var amPm = momentTime.format('a');
            if (this.get('selectedMinute') === 60) {
                var hourFix = parseInt(this.get('selectedHour')) + 1;
                if (hourFix === 12 && amPm === 'am') {
                    amPm = 'pm';
                } else if (hourFix === 12 && amPm === 'pm') {
                    amPm = 'am';
                }
                this.set('inputValue', hourFix + " : " + "00" + ' ' + amPm);
                return;
            }
            this.set('inputValue', this.get('selectedHour') + " : " + ("0" + this.get('selectedMinute')).slice(-2) + ' ' + amPm);
            this.setNewTime();
        },


        quarters: function () {
            var quarters = [];
            for (var amPm = 0; amPm < 2; amPm++) {
                for (var i = 0; i < 12; i++) {
                    for (var m = 0; m < 12; m++) {
                        var minute = m * 5;
                        if (minute === 0) {
                            minute = '00';
                        }
                        var stringAmPm = 'am';
                        var hour = i;
                        if (i === 0) {
                            hour = 12;
                        }
                        if (amPm === 1) {
                            stringAmPm = 'pm';
                        }
                        var stringOfTime = hour + ' : ' + ("0" + minute).slice(-2) + ' ' + stringAmPm;
                        quarters.pushObject(stringOfTime);
                    }
                }
            }
            return quarters;
        }.property(),

        watchTime: function () {
            var self = this;
            if (self.get('time') !== 'Invalid date') {
                var momentTime = moment(self.get('time'));
                var amPm = moment(self.get('time')).format('a');
                self.setHour(momentTime);
                self.setMinute(momentTime.minute());
                var minute = self.get('selectedMinute');
                if (Number(minute) === 60) {
                    minute = 0;
                }
                self.set('inputValue', self.get('selectedHour') + " : " + ("0" + minute).slice(-2) + ' ' + amPm);
            }
        }.observes('time'),

        /**
         * Reset date picker with selected values when selection was maded.
         */

        watchInputValue: function () {
            var self = this;
            var inputString = self.get('inputValue').replace(":", " ");
            var arr = inputString.split(" ");
            var newArr = [];
            for (var i = 0; i < arr.length; i++) {
                if (arr[i]) {
                    newArr.push(arr[i]);
                }
            }

            var hour = newArr[0];
            this.set('selectedHour', hour);
            var minute = newArr[1];
            this.set('selectedMinute', minute);
            self.set('selectedAmPm', newArr[2]);
            self.onChangeTime();
        }.observes('inputValue'),

        watchIsDisable: function () {
            var self = this;
            self.set('isDisabled', self.get('isDisabled'));
        }.observes('isDisabled'),

        onChangeTime: function () {
            this.setNewTime();
        }.observes('selectedDate'),

        getHour: function getHour() {
            var self = this;
            var hour = 0;
            if (self.get('selectedAmPm') === 'am' && parseInt(self.get('selectedHour')) === 12) {
                return hour;
            } else if (self.get('selectedAmPm') === 'am') {
                hour = parseInt(self.get('selectedHour'));
            } else if (self.get('selectedAmPm') === 'pm' && parseInt(self.get('selectedHour')) === 12) {
                return 12;
            } else if (self.get('selectedAmPm') === 'pm') {
                hour = parseInt(self.get('selectedHour')) + 12;
            }
            return hour;
        },

        setHour: function setHour(time) {
            var self = this;
            var hourMinuteAmPm = time.format('h mm a').split(' ');
            var hour = hourMinuteAmPm[0];
            var amPm = hourMinuteAmPm[2];

            self.set('selectedHour', hour);
            self.set('selectedAmPm', amPm);
        },

        setMinute: function setMinute(time) {
            this.set('selectedMinute', Math.ceil(time / 5) * 5);
        },

        setNewTime: function setNewTime() {
            var self = this;
            var hour = self.getHour();
            var selectedDate = moment(self.get('selectedDate')).startOf('day');
            var selectedTime = selectedDate.set({
                'hour': hour,
                'minute': parseInt(self.get('selectedMinute'))
            });
            self.set('newTime', selectedTime.toISOString());
            _functionHelpers.default.callOrIgnore(this.get('onSet'), selectedTime.toISOString());
        }
    });
});