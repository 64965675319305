define("mycare-ember/components/mc-joblisting-box/card/component", ["exports", "mycare-ember/utils/mc-component", "mycare-ember/utils/common", "npm:responsive-toolkit"], function (exports, _mcComponent, _common, _npmResponsiveToolkit) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        _jobListingService: Ember.inject.service('job-listing'),
        accountService: Ember.inject.service('account'),
        tagName: 'span',

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('jobListing', null);
            this.initVar('mode', 'listed');
            this.initVar('onUpdated', null);
            this.initVar('isLoading', false);

            this.set('_shareModalChannelId', _common.default.createGuid());
        },
        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            this.setIsJobListingCompleted();
        },


        noFixedHours: function () {
            return this.get('_jobListingService').hasFixedHours(this.get('jobListing'));
        }.property('jobListing.getHoursPerWeek'),

        isListedMode: function () {
            return this.get('mode') === 'listed';
        }.property('mode'),

        setIsJobListingCompleted: function () {
            var _this = this;

            this.get('accountService.isAnyPaymentRequiredAsync').perform({ personId: this.get('session.authData.currentPersonId') }).then(function (isRequired) {
                if (_this.isDestroyed) {
                    return;
                }
                return _this.get('_jobListingService').validate(_this.get('jobListing')).then(function (result) {
                    return result.isValid && !isRequired;
                }).then(function (result) {
                    return _this.set('isJobListingCompleted', result);
                });
            });
        }.observes('jobListing', 'session.authData.currentPersonId'),

        actions: {
            goToEdit: function goToEdit() {
                if (_npmResponsiveToolkit.default.is('xs')) {
                    this.get('eventbus').publish("global:on:changeRoute", 'seekers.findhelp.job-listings.mobile-edit-menu', {
                        queryParams: {
                            jobListingId: this.get('jobListing.id')
                        }
                    });
                } else {
                    this.get('eventbus').publish("global:on:changeRoute", 'seekers.findhelp.job-listings.edit.basic-info', this.get('jobListing.id'));
                }
            },
            goToPreviewOrEdit: function goToPreviewOrEdit() {
                if (this.get('isJobListingCompleted')) {
                    this.get('eventbus').publish("global:on:changeRoute", 'seekers.findhelp.job-listings.detail.preview', this.get('jobListing.id'), { queryParams: { backTo: "seekers.findhelp.job-listings" } });
                } else {
                    this.get('eventbus').publish("global:on:changeRoute", 'seekers.findhelp.job-listings.edit.basic-info', this.get('jobListing.id'));
                }
            },
            share: function share() {
                this.get('eventbus').publish("mc-share:on:open:global", {
                    isEnabledEmailShare: true,
                    jobListingId: this.get('jobListing.id'),
                    shareUrl: this.get('jobListing.shareUrl'),
                    shareTitle: this.get('jobListing.title'),
                    modalTitle: 'Share job listing',
                    quote: this.get('jobListing.shareQuote')
                });
            }
        }
    });
});