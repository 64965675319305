define("mycare-ember/models/contract", ["exports", "ember-data", "mycare-ember/mixins/utc-formator"], function (exports, _emberData, _utcFormator) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend(_utcFormator.default, {
        account: _emberData.default.belongsTo('account', { async: true }),
        accountType: _emberData.default.belongsTo('account-type', { async: true }),
        timesheets: _emberData.default.hasMany('timesheet', { async: true }),
        clientPerson: _emberData.default.belongsTo('person', { async: true, inverse: 'clientContracts' }),
        contractorPerson: _emberData.default.belongsTo('person', { async: true, inverse: 'contracts' }),
        contractorSignedOn: _emberData.default.attr('string'),
        isBudgetTypeRequired: _emberData.default.attr('boolean'),
        isDeleteEnabled: _emberData.default.attr('boolean'),
        contractName: _emberData.default.attr('string'),
        contractorName: _emberData.default.attr('string'),
        clientName: _emberData.default.attr('string'),
        organiserName: _emberData.default.attr('string'),
        clientSignedOn: _emberData.default.attr('string'),
        contractStartsOn: _emberData.default.attr('string'),
        contractEndsOn: _emberData.default.attr('string'),
        createdOn: _emberData.default.attr('string'),
        clientServiceFee: _emberData.default.attr('number'),
        workerServiceFee: _emberData.default.attr('number'),
        gstRate: _emberData.default.attr('number'),
        taxRate: _emberData.default.attr('number'),
        fundingSource: _emberData.default.attr('string'),
        createdBy: _emberData.default.belongsTo('person', { async: true, inverse: null }),
        hourlyRate: _emberData.default.attr('number'),
        nightlyRate: _emberData.default.attr('number'),
        contractDetail: _emberData.default.belongsTo('contract-detail', { async: true }),
        messageBody: _emberData.default.attr('string'),
        canWokerBookVisit: _emberData.default.attr('boolean'),
        thread: _emberData.default.belongsTo('thread', { async: true }),

        getServiceFee: function () {
            if (Ember.isEmpty(this.get('clientServiceFee'))) {
                return 0;
            }
            return this.get('clientServiceFee');
        }.property('clientServiceFee'),

        getWorkerFee: function () {
            if (Ember.isEmpty(this.get('workerServiceFee'))) {
                return 0;
            }
            return this.get('workerServiceFee');
        }.property('workerServiceFee'),

        hasWorkerFee: function () {
            return Ember.isEmpty(this.get('workerServiceFee')) === false;
        }.property('workerServiceFee'),

        getTaxRate: function () {
            if (Ember.isEmpty(this.get('taxRate'))) {
                return 0;
            }
            return this.get('taxRate');
        }.property('taxRate'),

        isCloseInFurther: function () {
            return moment().isBefore(moment(this.get('contractEndsOn')));
        }.property('contractEndsOn'),

        isNightlyRateGreaterThanZero: function () {
            return this.get('nightlyRate') > 0;
        }.property('nightlyRate'),

        isEnded: function () {
            if (Ember.isEmpty(this.get('contractEndsOn'))) {
                return false;
            }
            return Ember.isEmpty(this.get('contractEndsOn')) === false && moment().isAfter(moment(this.get('contractEndsOn')));
        }.property('contractEndsOn'),

        isNewContact: function () {
            var self = this;
            return Ember.isEmpty(self.get('contractorSignedOn'));
        }.property('contractorSignedOn', 'contractEndsOn')

    });
});