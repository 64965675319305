define("mycare-ember/components/mc-applicant/suggestion/item/component", ["exports", "mycare-ember/utils/mc-component", "mycare-ember/utils/function-helpers"], function (exports, _mcComponent, _functionHelpers) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        tagName: '',
        _workerService: Ember.inject.service('worker'),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('worker', undefined);
            this.initVar('onRemove', undefined);
            this.initVar('onBook', undefined);
            this.initVar('_isRemoving', undefined);
        },


        actions: {
            book: function book() {
                _functionHelpers.default.callOrIgnoreWithContext(this.get('onBook'), this.get('worker'));
            }
        }
    });
});