define('mycare-ember/controllers/partials/circle-member', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        auth: Ember.inject.controller('auth'),
        detailController: Ember.inject.controller('circles/detail'),

        isValidCircleOrganiser: function () {
            return this.get('detailController.isValidCircleOrganiser');
        }.property(),

        isOwner: function () {
            var loggedInUserId = this.get('auth.loggedInUserId');
            var bool = false;
            var memberid = this.get('model.person.id');
            if (memberid === loggedInUserId) {
                bool = true;
            }
            return bool;
        }.property(),

        actions: {
            deleteMember: function deleteMember(member) {
                this.get('detailController').deleteMember(member);
            }
        }
    });
});