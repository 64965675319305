define('mycare-ember/models/note', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        description: _emberData.default.attr('string'),
        createdOn: _emberData.default.attr('string'),
        modifiedOn: _emberData.default.attr('string'),
        startsOn: _emberData.default.attr('string'),
        endsOn: _emberData.default.attr('string')
    });
});