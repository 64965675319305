define("mycare-ember/seekers/manage/schedule-workers/index/visits-list/controller", ["exports", "mycare-ember/mixins/mixpanel"], function (exports, _mixpanel) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_mixpanel.default, {
        queryParams: ['backTo', 'backToOpts'],
        backTo: null,
        backToOpts: null,

        actions: {
            onNavBack: function onNavBack() {
                if (!Ember.isNone(this.get('backTo'))) {
                    this.transitionToRoute(this.get('backTo'));
                } else {
                    this.transitionToRoute("seekers.manage.calendar");
                }
            },


            toAddVisits: function toAddVisits() {
                this.transitionToRoute('book', {
                    queryParams: {
                        date: this.get('startDate')
                    }
                });
            }
        }
    });
});