define('mycare-ember/components/mc-joblisting-detail/help-required/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('jobListing', null);
        },


        activityGroups: function () {
            var _this = this;

            var groups = Ember.A([[], []]);
            var nextGroupId = 0;
            Ember.RSVP.resolve(this.get('jobListing.activityTypes')).then(function (activityTypes) {
                if (_this.isDestroyed) {
                    return;
                }
                var groupMaxLength = Math.ceil(activityTypes.get('length') / 2);
                activityTypes.sortBy('sortOrder').forEach(function (activity) {
                    var currentGroup = groups.objectAt(nextGroupId);
                    currentGroup.pushObject(activity);
                    if (currentGroup.get('length') >= groupMaxLength) {
                        nextGroupId++;
                    }
                });
            });
            return groups;
        }.property('jobListing.activityTypes.@each')
    });
});