define("mycare-ember/controllers/partials/modal-account-reset-password", ["exports", "mycare-ember/mixins/form-checker", "mycare-ember/mixins/password-checker", "mycare-ember/mixins/defer"], function (exports, _formChecker, _passwordChecker, _defer) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_formChecker.default, _defer.default, _passwordChecker.default, {

    isUpdatingPassword: false,
    passwordErrors: [],
    hasPasswordErrors: function () {
      return this.get('passwordErrors.length') !== 0;
    }.property('passwordErrors.[]'),

    actions: {
      setNewPassword: function setNewPassword() {
        var self = this;

        if (this.isPasswordCorrect(this.get('newPassword'), this.get('confirmPassword')) !== true) {
          this.get('passwordErrors').pushObject(this.isPasswordCorrect(this.get('newPassword'), this.get('confirmPassword')));
        } else {
          self.set('isUpdatingPassword', true);
          var changePassword = {
            "changePassword": {
              "currentPassword": self.get("currentPassword"),
              "newPassword": self.get("newPassword")
            }
          };
          changePassword = JSON.stringify(changePassword);
          var defer = this.getDeferWithToken();
          Ember.$.post(self.get('env').getApp('API_HOST') + '/api/logins/ChangePassword', changePassword).then(function (loginResponse) {
            defer.resolve(loginResponse);
          }, function (error) {
            defer.reject(error);
          });
          defer.promise.then(function () {
            self.set("passwordErrors", []);
            self.set("isUpdatingPassword", false);
          }).catch(function (error) {
            if (!Ember.$.isEmptyObject(error)) {
              self.get('passwordErrors').pushObject("Invalid credentials.");
            }
            self.set("isUpdatingPassword", false);
          }).finally(function () {
            self.set("isUpdatingPassword", false);
          });
        }
      },
      close: function close() {
        this.set("isUpdatingPassword", false);
        this.set('passwordErrors', []);
        this.resetForm();
      }

    },
    resetForm: function resetForm() {
      this.set("currentPassword", "");
      this.set("newPassword", "");
      this.set("confirmPassword", "");
    }
  });
});