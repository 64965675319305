define("mycare-ember/components/mc-charts/base/component", ["exports", "mycare-ember/utils/mc-component"], function (exports, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('title', undefined);
            this.initVar('baseType', 'bar');
            this.initVar('data', []);
        },
        didReceiveAttrs: function didReceiveAttrs() {
            if (!this.$()) return;
            var ctx = this.$('canvas');
            new Chart(ctx, {
                type: this.get('baseType'),
                data: this.get('data'),
                options: {
                    responsive: true,
                    title: {
                        display: !Ember.isNone(this.get('title')),
                        text: this.get('title')
                    },
                    legend: {
                        onClick: function onClick(event, legendItem) {
                            var index = legendItem.datasetIndex;
                            var ci = this.chart;
                            var meta = ci.getDatasetMeta(index);
                            meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
                            ci.options.scales.yAxes[index].display = !ci.options.scales.yAxes[index].display;
                            ci.update();
                        }
                    },
                    scales: {
                        xAxes: [{
                            position: "bottom",
                            "id": "day",
                            gridLines: {
                                display: false
                            }
                        }],
                        yAxes: [{
                            position: "left",
                            "id": "y-axis-0",
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                callback: function callback(value) {
                                    return value.toFixed(2) + 'hr';
                                }
                            }
                        }]
                    }
                }
            });
        }
    });
});