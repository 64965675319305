define("mycare-ember/carers/findwork/profile/controller", ["exports", "mycare-ember/utils/common"], function (exports, _common) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ["backTo", "backToOpts"],
        _availabilityEditorChannelId: _common.default.createGuid(),
        _modalShareChannelId: _common.default.createGuid(),
        _uploaderChannelId: _common.default.createGuid(),
        _profileService: Ember.inject.service('profile'),

        actions: {
            onNavBack: function onNavBack() {
                if (Ember.isNone(this.get('backTo'))) {
                    this.transitionToRoute('carers.findwork.dashboard');
                } else {
                    this.get('eventbus').publish('global:on:changeRoute', this.get('backTo'), this.get('backToOpts'));
                }
            },
            share: function share() {
                var _this = this;

                if (this.get('model.worker.isShareable')) {
                    this.get('eventbus').publish("mc-share:on:open:" + this.get('_modalShareChannelId'));
                    return null;
                } else {
                    this.set('model.worker.isShareable', true);
                    this.get('model.worker').save().then(function () {
                        _this.get('eventbus').publish("mc-share:on:open:" + _this.get('_modalShareChannelId'));
                    });
                }
            },
            uploadAvatar: function uploadAvatar() {
                this.get('eventbus').publish("mc-image-cropper:on:open:" + this.get('_uploaderChannelId'));
            },
            onUploadCompleted: function onUploadCompleted(fileUrl) {
                this.get('_profileService.uploadAvatarAsync').perform({
                    personId: this.get('session.authData.currentPersonId'),
                    avatarUrl: fileUrl });
            }
        }
    });
});