define('mycare-ember/services/utils/file', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        _httpService: Ember.inject.service('utils/http'),
        _env: Ember.inject.service('utils/env'),

        uploadImage: function uploadImage(base64Image) {
            var data = base64Image.replace(/^data:image\/png;base64,/, '');
            var imageUpload = {
                imageUpload: {
                    data: data,
                    fileName: 'fileName.png'
                }
            };
            var url = this.get('_env').getApp('API_HOST') + '/api/imageUploader';
            return this.get('_httpService.withTokenAsync').perform(function (client) {
                return client.post(url, imageUpload);
            }).then(function (response) {
                return response.data.media.url;
            });
        }
    });
});