define("mycare-ember/helpers/date-format", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.timeFormat = undefined;


  function timeFormat(data) {
    var date = data[0],
        optionalFormat = data[1];

    if (Ember.isBlank(date)) {
      return '';
    }

    var format = 'LL';

    if (Ember.typeOf(optionalFormat) === 'string') {
      format = optionalFormat;
    }
    var dateObj = new Date(date);

    if (date === 'today') {
      dateObj = new Date();
    }

    return (0, _moment.default)(dateObj).format(format);
  }

  exports.timeFormat = timeFormat;
  exports.default = Ember.Helper.helper(timeFormat);
});