define('mycare-ember/components/mc-support-type/component', ['exports', 'mycare-ember/utils/mc-component'], function (exports, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('personId', null);
            this.initVar('value', []);
            this.initVar('supportTypes', []);
            this.initVar('initialSupportTypes', []);
            this.initVar('inputId', null);
            this.initVar('errorMessage', null);
            this.initVar('disabledCustomType', true);
            this.initVar('otherSupportType', {
                isEnabled: false,
                value: null
            });
        },
        didInsertElement: function didInsertElement() {
            this.set('otherSupportType.isEnabled', false);
            this.set('otherSupportType.value', null);
        },


        hasErrorMessage: Ember.computed('errorMessage', function () {
            var errorMessage = this.get('errorMessage');
            return errorMessage && errorMessage.length > 0;
        }),

        supportTypesOptions: Ember.computed('supportTypes', function () {
            var _this = this;

            var defaultSupportTypes = Ember.A();
            var promises = this.get('supportTypes').map(function (sp) {
                var isCurrentSupportType = !Ember.isEmpty(_this.get('initialSupportTypes').filterBy('name', sp.get('name')));

                return Ember.RSVP.resolve(sp.get('createdPerson')).then(function (createdPerson) {
                    if (_this.isDestroyed) {
                        return;
                    }
                    defaultSupportTypes.pushObject(Ember.Object.create({
                        displayName: sp.get('name').charAt(0).toUpperCase() + sp.get('name').slice(1),
                        isSelected: isCurrentSupportType,
                        value: sp.get('name'),
                        record: sp,
                        isDefault: createdPerson === null || parseInt(createdPerson) === parseInt(_this.get('personId'))
                    }));
                });
            });

            var result = Ember.RSVP.all(promises).then(function () {
                if (_this.isDestroyed) {
                    return;
                }
                if (Ember.isEmpty(defaultSupportTypes.filterBy('displayName', 'Other')) && _this.get('disabledCustomType') === false) {
                    defaultSupportTypes.pushObject(Ember.Object.create({
                        displayName: 'Other',
                        isSelected: false,
                        value: null,
                        record: null,
                        isDefault: true
                    }));
                }
                return defaultSupportTypes.filterBy('isDefault', true);
            });
            return DS.PromiseObject.create({ promise: result });
        }),

        supportTypeChanged: Ember.observer('supportTypesOptions.@each.isSelected', function () {
            var selectedSupportTypes = this.get('supportTypesOptions.content').filterBy('isSelected', true);

            var otherType = selectedSupportTypes.filterBy('displayName', 'Other');
            this.set('otherSupportType.isEnabled', !Ember.isEmpty(otherType));
            this.set('value', selectedSupportTypes);
            return selectedSupportTypes;
        }),

        otherSupportTypeChanged: function () {
            var otherSupportTypeValue = this.get('otherSupportType.value');
            var otherTypeInValue = this.get('value').filterBy('displayName', 'Other').objectAt(0);
            if (otherSupportTypeValue && !Ember.isEmpty(this.get('value'))) {
                otherTypeInValue.set('value', otherSupportTypeValue);
            } else {
                if (otherTypeInValue) {
                    otherTypeInValue.set('value', '');
                }
            }
        }.observes('otherSupportType.value')
    });
});