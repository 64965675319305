define("mycare-ember/components/mc-map/component", ["exports", "mycare-ember/utils/function-helpers", "mycare-ember/utils/mc-component"], function (exports, _functionHelpers, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _extends = Object.assign || function (target) {
        for (var i = 1; i < arguments.length; i++) {
            var source = arguments[i];

            for (var key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }

        return target;
    };

    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('lat', null);
            this.initVar('lng', null);
            this.initVar('location', null);
            this.initVar('zoom', 13);
            this.initVar('onCoordinateChanged', null);
            this.initVar('onMapResized', null);
            this.initVar('markers', []);
            this.initVar('isEnabledSearch', true);
            this.initVar('isFirstTimeRendering', true);
            this.initVar('activeMarker', null);
            this.initVar('_prevActiveMarker', null);
            this.initVar('highlightColor', null);
        },
        didInsertElement: function didInsertElement() {
            var _this = this;

            this._map = this._getGmap({
                lat: this.get('lat'),
                lng: this.get('lng'),
                zoom: this.get('zoom')
            });

            Ember.$(window).on('resize', function () {
                _this._resizeMap(_this._map);
            });

            this.set('shouldOpenInfoWindowOnHover', this.get('env').getApp('ENABLE_BROWSE_INFOWINDOW_ON_HOVER'));

            GMaps.prototype.markerById = function (id) {
                for (var m = 0; m < this.markers.length; ++m) {
                    if (this.markers[m].get('id') === id) {
                        return this.markers[m];
                    }
                }
                return new google.maps.Marker();
            };
        },
        didRender: function didRender() {
            this._resizeMap(this._map);
            this._setCenter(this.get('lat'), this.get('lng'));
        },
        _drawActiveMarker: function _drawActiveMarker() {
            if (this.isDestroyed) {
                return;
            }
            var drawnMarkers = this._map.markers;
            var activeMarker = this.get('activeMarker');
            var previousActiveMarker = this.get('_prevActiveMarker');

            if (Ember.isNone(activeMarker)) {
                var prevMarkers = Ember.A(drawnMarkers).filter(function (m) {
                    return m.id === previousActiveMarker.get('id');
                });
                this._map.removeMarkers(prevMarkers);
                this.set('_prevActiveMarker', null);
                return;
            }

            var drawnActiveMarkers = Ember.A(drawnMarkers).filter(function (m) {
                return m.id === activeMarker.get('id');
            });
            if (previousActiveMarker) {
                drawnActiveMarkers.pushObject(Ember.A(drawnMarkers).find(function (m) {
                    return m.id === previousActiveMarker.get('id');
                }));
            }
            this._map.removeMarkers(drawnActiveMarkers);
            if (previousActiveMarker) {
                this._map.addMarker(this._createMarker(previousActiveMarker));
            }
            this._map.addMarker(this._createMarker(activeMarker, this.get('highlightColor')));
            var activeMapMarker = Ember.A(this._map.markers).find(function (m) {
                return m.id === activeMarker.get('id');
            });
            activeMapMarker.setZIndex(100000);
            if (this.get('shouldOpenInfoWindowOnHover') === true) {
                google.maps.event.trigger(activeMapMarker, 'click');
            }
            this.set('isEnabledSearch', false);
            this.set('_prevActiveMarker', activeMarker);
        },


        _watchLocation: function () {
            this.set('isEnabledSearch', true);
        }.observes('location'),

        _watchActiveMarker: function () {
            this._drawActiveMarker();
        }.observes('activeMarker'),

        _watchCoordicateChanges: function () {
            if (this.isDestroyed) {
                return;
            }
            var lat = this.get('lat');
            var lng = this.get('lng');

            //Skip if lat or lng is undefined
            if (Ember.isNone(lat) || Ember.isNone(lng)) {
                return;
            }
            this._setCenter(lat, lng);
        }.observes('lat', 'lng'),

        _watchDrawingMarkers: function () {
            if (this.isDestroyed) {
                return;
            }
            this._drawMarkers();
            // if(this.get('isFirstTimeRendering') === true){
            //     this.set('isEnabledSearch', false);
            //     this.set('isFirstTimeRendering', false);
            // }else{
            //     this.set('isEnabledSearch', true);
            // }
        }.observes('markers.[]'),

        _setCenter: function _setCenter(lat, lng) {
            if (this._map) {
                this._map.setCenter(lat, lng);
            }
        },
        _onMapIdle: function _onMapIdle(map) {
            if (this.isDestroyed) {
                return;
            }
            var boundInfo = this._getCurrentBound(map);
            var center = map.getCenter();
            this.set('lat', center.lat());
            this.set('lng', center.lng());
            var latLngInfo = Ember.Object.create({
                lat: center.lat(),
                lng: center.lng()
            });
            if (this.get('isEnabledSearch')) {
                _functionHelpers.default.callOrIgnore(this.get('onCoordinateChanged'), latLngInfo, boundInfo);
            }
        },
        _getCurrentBound: function _getCurrentBound(map) {
            var bounds = map.getBounds();
            var northEast = bounds.getNorthEast();
            var southWest = bounds.getSouthWest();
            return {
                northLat: northEast.lat(),
                southLat: southWest.lat(),
                eastLng: northEast.lng(),
                westLng: southWest.lng()
            };
        },


        _resizeMap: function _resizeMap(map) {
            var mapCanvas = this.$(".map-canvas");
            if (!mapCanvas) {
                return;
            }
            var bodyHeight = window.innerHeight - 108;
            mapCanvas.css({
                height: bodyHeight,
                maxHeight: bodyHeight
            });
            google.maps.event.trigger(map, 'resize');
            this._map.refresh();
            _functionHelpers.default.callOrIgnore(this.get('onMapResized'), bodyHeight);
        },

        _getGmap: function _getGmap(_ref) {
            var _this2 = this;

            var lat = _ref.lat,
                lng = _ref.lng,
                _ref$zoom = _ref.zoom,
                zoom = _ref$zoom === undefined ? 13 : _ref$zoom;
            //jshint ignore:line
            //noinspection JSUnresolvedFunction
            return new GMaps({
                div: '.map-canvas',
                lat: lat,
                lng: lng,
                zoom: zoom,
                maxZoom: 15,
                minZoom: 11,
                zoomControlOptions: {
                    style: google.maps.ZoomControlStyle.SMALL
                },
                mapTypeControl: true,
                mapTypeControlOptions: {
                    style: google.maps.MapTypeControlStyle.DEFAULT,
                    mapTypeIds: [google.maps.MapTypeId.ROADMAP]
                },
                idle: function idle(map) {
                    _this2._onMapIdle(map);
                },
                mouseover: function mouseover() {
                    _this2.set('isEnabledSearch', false);
                },
                mousemove: function mousemove() {
                    _this2.set('isEnabledSearch', false);
                },
                dragstart: function dragstart() {
                    _this2.set('isEnabledSearch', true);
                },
                zoom_changed: function zoom_changed() {
                    _this2.set('isEnabledSearch', true);
                }
            });
        },


        /*eslint-disable */
        _createMarker: function _createMarker(data, color) {
            // var popup = new Popup(
            //     new google.maps.LatLng(parseFloat(data.get('lat')), parseFloat(data.get('lng'))),
            //     data.get('content'));
            // popup.setMap(this._map.map);

            return {
                id: data.id,
                lat: parseFloat(data.get('lat')),
                lng: parseFloat(data.get('lng')),
                title: data.get('title'),
                icon: _extends({}, data.get('icon'), { //jshint ignore:line
                    fillColor: color ? color : data.get('icon.fillColor')
                }), //jshint ignore:line
                infoWindow: {
                    content: data.get('content')
                }
            };
        },

        /*eslint-enable */

        _drawMarkers: function _drawMarkers() {
            var self = this;
            var existingMarkers = this._map.markers;
            this._map.removeMarkers(existingMarkers);
            var markers = [];
            this.get('markers').forEach(function (marker) {
                markers.pushObject(self._createMarker(marker));
            });
            this._map.addMarkers(markers);
        }
    });
});