define("mycare-ember/carers/my-account/index/route", ["exports", "mycare-ember/mixins/protected_route", "mycare-ember/mixins/scroll_top"], function (exports, _protected_route, _scroll_top) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_protected_route.default, _scroll_top.default, {
    beforeModel: function beforeModel(transition) {
      this.isAuthenticated(transition);
    },


    model: function model() {
      var self = this;
      return self.store.findRecord('person', self.controllerFor('auth').get('profileId'), { reload: true });
    },

    setupController: function setupController(controller, model) {
      var self = this;
      model.get('accounts', { reload: true }).then(function (accounts) {
        var selectedAccounts;
        var authCtrl = self.controllerFor('auth');
        if ((authCtrl.get('isWorker') || authCtrl.get('isWorkerOrganiser')) && authCtrl.get('currentProfileRole') === self.get('env').getApp('WORKER_PROFILE_TYPE')) {
          selectedAccounts = accounts.filter(function (account) {
            return account.get('accountType.id') === '4';
          });
        } else {
          selectedAccounts = accounts;
        }
        if (selectedAccounts.get('length') === 1) {
          self.transitionTo('carers.my-account.account-detail.index', selectedAccounts.objectAt(0));
          return;
        }
        controller.set('selectedAccounts', selectedAccounts);
        controller.setup();
      });
    }
  });
});