define('mycare-ember/helpers/comma-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function commaString(data) {
    var string = '';
    var array = data[0];
    var isObject = data[1];
    var label = data[2];
    if (array !== undefined) {
      var length = array.get('length');
      for (var i = 0; i < length; i++) {
        if (isObject) {
          string += array.objectAt(i).get(label);
        } else {
          string += array[i];
        }
        if (i < length - 1) {
          string += ', ';
        }
      }
    }
    return string;
  }

  exports.commaString = commaString;
  exports.default = Ember.Helper.helper(commaString);
});