define("mycare-ember/seekers/findhelp/applicants/index/favourites/controller", ["exports", "mycare-ember/mixins/mixpanel"], function (exports, _mixpanel) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_mixpanel.default, {
        favouriteSort: ['createdOn:asc'],
        sortedFavourite: Ember.computed.sort('model.favourites', 'favouriteSort'),

        actions: {
            onFavouriteRemoved: function onFavouriteRemoved(_ref) {
                var workerId = _ref.workerId;

                this.set('model.favourites', this.get('model.favourites').filter(function (f) {
                    return f.get('id') !== workerId;
                }));
            }
        }
    });
});