define("mycare-ember/components/mc-image-cropper/component", ["exports", "mycare-ember/utils/mc-modal-component", "mycare-ember/utils/function-helpers"], function (exports, _mcModalComponent, _functionHelpers) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend({

        _modalHelper: Ember.inject.service('utils/modal-helper'),

        init: function init() {
            var _this = this;

            //_componentId needs to be declared before super
            this.initVar('_componentId', 'mc-image-cropper');
            this._super.apply(this, arguments);

            this.initVar('description', undefined);
            this.initVar('onCompleted', undefined);
            this.initVar('onCancelled', undefined);
            this.initVar('cropBoxData', { width: 300, height: 300 });
            this.initVar('ratio', 1);
            this.initVar('currentSetup', 'one');

            this.initVar('_selectedFileData', undefined);
            this.initVar('_cropFileDataUrl', undefined);
            this.initVar('_isUploadCompleted', false);
            this.initVar('_stepRegistry', {
                one: true,
                two: false,
                three: false
            });

            this.get('_modalHelper').onHide(this.get('_modalChannelId'), function () {
                _this._toStepOne();
                if (_this.get('_isUploadCompleted') === false) {
                    _functionHelpers.default.callOrIgnore(_this.get('onCancelled'));
                }
            });
        },
        _toStepOne: function _toStepOne() {
            this.set('_stepRegistry', {
                one: true,
                two: false,
                three: false
            });
        },
        _toStepTwo: function _toStepTwo() {
            this.set('_stepRegistry', {
                one: false,
                two: true,
                three: false
            });
        },
        _toStepThree: function _toStepThree() {
            this.set('_stepRegistry', {
                one: false,
                two: false,
                three: true
            });
        },


        _isShowBackButton: function () {
            if (this.get('isShowBackButton') || this.get('_stepRegistry.one') === false) {
                return true;
            }
            return false;
        }.property('_stepRegistry', 'isShowBackButton'),

        actions: {
            onSetupOneEnter: function onSetupOneEnter() {
                this.set('currentStep', 1);
            },
            onSetupTwoEnter: function onSetupTwoEnter() {
                this.set('currentStep', 2);
            },
            onSetupThreeEnter: function onSetupThreeEnter() {
                this.set('currentStep', 3);
            },
            onSetupOneCompleted: function onSetupOneCompleted(file) {
                this.set('_selectedFileData', file);
                this._toStepTwo();
            },
            onSetupTwoCompleted: function onSetupTwoCompleted(fileUrl) {
                this.set('_cropFileDataUrl', fileUrl);
                this._toStepThree();
            },
            onSetupThreeCompleted: function onSetupThreeCompleted(fileUrl) {
                this.set('_isUploadCompleted', true);
                _functionHelpers.default.callOrIgnore(this.get('onCompleted'), fileUrl);
                this._close();
            },
            onChangeStep: function onChangeStep(stepNumber) {
                if (stepNumber === 1) {
                    this._toStepOne();
                } else if (stepNumber === 2) {
                    this._toStepTwo();
                } else if (stepNumber === 3) {
                    this._toStepThree();
                } else {
                    console.error("unkown mc-image-cropper step: " + stepNumber);
                }
            },
            cancel: function cancel() {
                var currentStep = this.get('currentStep');
                if (currentStep === 1) {
                    this._close();
                    _functionHelpers.default.callOrIgnore(this.get('onCancelled'));
                } else {
                    this.send('onChangeStep', currentStep - 1);
                }
            },
            close: function close() {
                this._close();
            }
        }
    });
});