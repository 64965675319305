define('mycare-ember/controllers/partials/manage/account/account-card-seeker', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        actions: {
            clickAction: function clickAction() {
                var self = this;
                self.transitionToRoute('seekers.my-account.account-detail', self.get('model.id'));
            }
        }
    });
});