define('mycare-ember/book/time/time-graph', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Object.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.set('_internalRegistry', {});
        },
        initVar: function initVar(name, defaultValue) {
            if (Ember.isNone(this.get(name))) this.set(name, defaultValue);
        },
        ensureVar: function ensureVar(name) {
            Ember.Logger.assert(this.get(name) !== undefined, 'Attribute \'' + name + '\' is none and it is required for ' + Ember.inspect(this));
        },
        hasWeekRange: function hasWeekRange(weekRangeId) {
            return !Ember.isNone(this.get('_internalRegistry.' + weekRangeId));
        },
        getOrCreateWeekRange: function getOrCreateWeekRange(weekRangeId) {
            var existingRange = this.get('_internalRegistry.' + weekRangeId);
            if (!existingRange) this.set('_internalRegistry.' + weekRangeId, Ember.Object.create({}));
            return this.get('_internalRegistry.' + weekRangeId);
        },
        getOrCreateTimeRange: function getOrCreateTimeRange(weekRangeId, timeRangeId) {
            var weekRange = this.getOrCreateWeekRange(weekRangeId);
            var existingRange = weekRange.get('' + timeRangeId);
            if (!existingRange) weekRange.set('' + timeRangeId, [Ember.Object.create()]);
            return weekRange.get('' + timeRangeId);
        },
        addDayToTimeRangeId: function addDayToTimeRangeId(weekRangeId, timeRangeId, day) {
            var timeRange = this.getOrCreateTimeRange(weekRangeId, timeRangeId);
            timeRange.pushObject(day);
            return timeRange;
        },
        replaceDayToTimeRangeId: function replaceDayToTimeRangeId(weekRangeId, timeRangeId, days) {
            var timeRange = this.getOrCreateTimeRange(weekRangeId, timeRangeId);
            timeRange.clear();
            timeRange.pushObjects(days);
            return timeRange;
        },
        getTimeRanges: function getTimeRanges(weekRangeId) {
            var weekRange = this.getOrCreateWeekRange(weekRangeId);
            var timeRangeKeys = Object.keys(weekRange);
            return timeRangeKeys.map(function (k) {
                return Ember.Object.create({
                    key: k,
                    value: weekRange.get(k)
                });
            }).filter(function (kv) {
                return !Ember.isNone(kv.get('value'));
            });
        },
        deleteTimeRange: function deleteTimeRange(weekRangeId, timeRangeId) {
            var weekRange = this.getOrCreateWeekRange(weekRangeId);
            weekRange.set('' + timeRangeId, undefined);
        },
        deleteWeekRange: function deleteWeekRange(weekRangeId) {
            this.set('_internalRegistry.' + weekRangeId, undefined);
        },
        getAllDays: function getAllDays() {
            var _this = this;

            var result = Object.keys(this.get('_internalRegistry')).map(function (weekRange) {
                return _.flatten(Object.keys(_this.get('_internalRegistry.' + weekRange)).map(function (timeRange) {
                    return _this.get('_internalRegistry.' + weekRange + '.' + timeRange);
                }));
            });
            return _.flatten(result);
        },
        getDaysForWeekRange: function getDaysForWeekRange(weekRangeId) {
            var _this2 = this;

            var result = Object.keys(this.get('_internalRegistry')).filter(function (k) {
                return k === weekRangeId;
            }).map(function (weekRange) {
                return _.flatten(Object.keys(_this2.get('_internalRegistry.' + weekRange)).map(function (timeRange) {
                    return _this2.get('_internalRegistry.' + weekRange + '.' + timeRange);
                }));
            });
            return _.flatten(result);
        }
    });
});