define("mycare-ember/mixins/sort-timesheet-seeker-mixin", ["exports", "mycare-ember/mixins/utc-formator"], function (exports, _utcFormator) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_utcFormator.default, {
        validTimesheetTotal: 0,
        validTimesheetTotalPrice: 0,
        _referenceModelService: Ember.inject.service('reference-models'),

        setSummary: function () {
            var _this = this;

            var self = this;
            self.set('validTimesheetTotal', 0);
            self.set('validTimesheetTotalHours', 0);
            self.set('validTimesheetTotalOvernight', 0);
            self.set('validTimesheetTotalPrice', 0);
            if (self.get('content.length') > 0) {
                this.get('_referenceModelService.getTimesheetStatusTypesAsync').perform().then(function (timesheetStatusTypes) {
                    var draftString = timesheetStatusTypes.objectAt(0).get('name').toLowerCase();
                    var deletedString = timesheetStatusTypes.objectAt(5).get('name').toLowerCase();
                    var validTimesheets = self.get('content').filter(function (timesheet) {
                        var currentStatus = timesheet.get('timesheetStatuses').objectAt(0);
                        if (Ember.isEmpty(currentStatus)) {
                            return false;
                        }
                        var currentStatusString = currentStatus.get('timesheetStatusType.name');
                        if (Ember.isEmpty(currentStatusString)) {
                            return false;
                        }
                        if (currentStatusString.toLowerCase() === draftString) {
                            return false;
                        } else if (currentStatusString.toLowerCase() === deletedString) {
                            return false;
                        }
                        return true;
                    });
                    self.setTotalValidHoursAndPrices(validTimesheets);
                });
            }
            Ember.run.schedule("afterRender", this, function () {
                _this.get('eventbus').publish("mc-popover:on:refresh:" + _this.get('_popoverChannelId'));
            });
        }.observes('content.@each.timesheetStatuses.[]', 'content.@each.scheduledDuration'),

        setTotalValidHoursAndPrices: function setTotalValidHoursAndPrices(validTimesheets) {
            var self = this;
            self.set('validTimesheetTotalHours', 0);
            self.set('validTimesheetTotalPrice', 0.00);
            validTimesheets = validTimesheets.filter(function (timesheet) {
                return Ember.isEmpty(timesheet.get('timesheetStatuses').filter(function (s) {
                    return s.get('timesheetStatusType.id') === '6';
                }));
            });
            validTimesheets.forEach(function (timesheet) {
                if (timesheet.get('isOvernightScheduled') === true) {
                    self.set('validTimesheetTotalOvernight', self.get('validTimesheetTotalOvernight') + 1);
                    self.set('validTimesheetTotalPrice', parseFloat(self.get('validTimesheetTotalPrice')) + parseFloat(timesheet.get('totalCostClient')));
                } else {
                    self.set('validTimesheetTotalHours', self.get('validTimesheetTotalHours') + timesheet.get('scheduledDuration'));
                    self.set('validTimesheetTotalPrice', parseFloat(self.get('validTimesheetTotalPrice')) + parseFloat(timesheet.get('totalCostClient')));
                }
                self.set('validTimesheetTotal', validTimesheets.get('length'));
            });
        },

        sumTotalFromThisWeektoNextSevenDays: function sumTotalFromThisWeektoNextSevenDays(validTimesheets) {
            var self = this;
            var amount = 0;
            validTimesheets.forEach(function (timesheet) {
                if (moment().add(7, 'day').utc().isAfter(moment(timesheet.get('scheduledStartsOn')).utc())) {
                    amount = parseFloat(self.get('estimateTotal')) + parseFloat(timesheet.get('estimateTotal'));
                }
            });
            return amount;
        },
        sumTotalForThisWeek: function sumTotalForThisWeek(validTimesheets) {
            var amount = 0;
            validTimesheets.forEach(function (timesheet) {
                amount = Number(amount) + parseFloat(timesheet.get('estimateTotal'));
            });

            if (amount < 0) {
                amount = 0;
            }
            return amount;
        }
    });
});