define('mycare-ember/components/mc-image-cropper/step-two/component', ['exports', 'mycare-ember/utils/function-helpers', 'mycare-ember/utils/mc-component'], function (exports, _functionHelpers, _mcComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('onEnter', undefined);
            this.initVar('onCompleted', undefined);
            this.initVar('onChangeStep', undefined);
            this.initVar('file', undefined);
            this.initVar('cropBoxData', { width: 150, height: 150 });
            this.initVar('ratio', undefined);

            _functionHelpers.default.callOrIgnore(this.get('onEnter'));
        },
        didInsertElement: function didInsertElement() {
            this._setup();
        },
        _setup: function _setup() {
            var _this = this;

            var $container = this.$('.crop-space');
            var $image = $container.find('img');
            var cropBoxData = this.get('cropBoxData');

            // set center position
            var left = $container.width() / 2 - cropBoxData.width / 2;
            var top = $container.height() / 2 - cropBoxData.height / 2;
            Ember.set(cropBoxData, 'top', top);
            Ember.set(cropBoxData, 'left', left);

            //init cropper
            var ratio = this.get('ratio') || 1;
            $($image[0]).on('ready', function () {
                _this.cropper.setCropBoxData(cropBoxData);
                // make background transparent
                if (_this.$('.cropper-container')) _this.$('.cropper-container').css('backgroundColor', 'transparent');
                if (_this.$('.cropper-wrap-box')) _this.$('.cropper-wrap-box').css('backgroundColor', 'transparent');
                if (_this.$('.cropper-drag-box')) _this.$('.cropper-drag-box').css('backgroundColor', 'transparent');
            });

            this.cropper = new Cropper($image[0], {
                aspectRatio: ratio,
                responsive: true,
                viewMode: 2,
                strict: false,
                guides: false,
                highlight: false,
                dragCrop: false,
                zoomable: false,
                cropBoxMovable: true,
                cropBoxResizable: true,
                background: false,
                restore: true
            });
        },


        actions: {
            rotateRight: function rotateRight() {
                if (this.cropper) {
                    this.cropper.rotate(90);
                }
            },
            crop: function crop() {
                var canvas = this.cropper.getCroppedCanvas(this.get('cropBoxData'));
                var imageData = canvas.toDataURL();
                _functionHelpers.default.callOrIgnore(this.get('onCompleted'), imageData);
            },
            back: function back() {
                _functionHelpers.default.callOrIgnore(this.get('onChangeStep'), 1);
            }
        }
    });
});