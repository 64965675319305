define("mycare-ember/router", ["exports", "mycare-ember/config/environment"], function (exports, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Router = Ember.Router.extend({
        location: _environment.default.locationType
    });

    Router.map(function () {
        this.route('nz-need-you', { path: 'new-zealand-need-you' });

        //todo: temporary solution for auth reset password url, need to remove later
        this.route('auth', { path: '/auth' }, function () {
            this.route('reset-password', { path: '/reset-password' });
        }),

        //registration routes
        this.route('me', { path: '/me' }, function () {
            this.route('profiles', { path: '/profiles' }, function () {
                this.route('someone-setup', { path: '/someone-setup' }, function () {
                    this.route('index', { path: '' });
                    this.route('payment', { path: '/payment' });
                    this.route('done', { path: '/done' });
                });
                this.route('site-setup', { path: '/site-setup' }, function () {
                    this.route('index', { path: '' });
                    this.route('payment', { path: '/payment' });
                    this.route('done', { path: '/done' });
                });
            });

            this.route('account', { path: '/account' }, function () {
                this.route('address', { path: '/address' });
                this.route('reset-password', { path: '/reset-password' });
                this.route('payment', { path: '/payment' });
            });
        });

        this.route('index', { path: '/' });
        this.route('logout', { path: '/logout' });

        //Search routes
        this.route('browse', { path: '/browse' }, function () {
            this.route('jobs', { path: '/jobs' }, function () {
                this.route('index', { path: '' }, function () {
                    this.route('detail', { path: '/:job_listing_id' }, function () {
                        this.route('apply', { path: '/apply' });
                        this.route('draft', { path: '/draft' });
                        this.route('submitted', { path: '/submitted' });
                    });
                });
            });
            this.route('profiles', { path: '/profiles' }, function () {
                this.route('index', { path: '' }, function () {
                    this.route('detail', { path: '/:profile_id' }, function () {
                        this.route('contact', { path: '/contact' });
                        this.route('review', { path: '/review' });
                    });
                });
            });
        });

        this.route('book', { path: 'book' }, function () {
            this.route('index', { path: '' });
            this.route('basic-info', { path: '/basic' });
            this.route('activities', { path: '/activities' });
            this.route('payment', { path: '/payment' });
            this.route('note', { path: '/note' });
            this.route('contract', { path: '/contract' });
            this.route('time');
            this.route('expenses');
            this.route('mobile-nav');
        });

        this.route('circles', { path: '/circles' }, function () {
            this.route('detail', { path: ':circle_id' });
        });

        this.route('threads', { path: '/messages' }, function () {
            this.route('detail', { path: ':thread_id' });
        });

        this.route('notifications', { path: '/notifications' }, function () {});

        this.route('seekers', { path: '/findhelp' }, function () {
            this.route('findhelp', { path: '' }, function () {
                this.route('dashboard', { path: '' });
                this.route('applicants', { path: '/applicants' }, function () {
                    this.route('contract', { path: '/contract/:contract_id' });
                    this.route('index', { path: '' }, function () {
                        this.route('workers', { path: '' });
                        this.route('favourites');
                        this.route('applicants');
                    });
                });
                this.route('job-listings', { path: '/job-listings' }, function () {
                    this.route('list', { path: '/' });
                    this.route('mobile-create-menu', { path: 'mobile-create-menu' });
                    this.route('mobile-edit-menu', { path: 'mobile-edit-menu' });
                    this.route('detail', { path: ':jobListingId' }, function () {
                        this.route('preview', { path: '/preview' });
                    });

                    this.route('create', function () {
                        this.route('basic-info');
                        this.route('price');
                        this.route('time');
                        this.route('location');
                        this.route('categories');
                        this.route('activities');
                        this.route('availability');
                        this.route('privacy');
                        this.route('payment');
                        this.route('experience');
                        this.route('finish');
                    });

                    this.route('edit', { path: ':editJobListingId' }, function () {
                        this.route('basic-info');
                        this.route('price');
                        this.route('time');
                        this.route('location');
                        this.route('categories');
                        this.route('activities');
                        this.route('availability');
                        this.route('privacy');
                        this.route('payment');
                        this.route('experience');
                        this.route('finish');
                    });
                });
                this.route('profile', { path: '/profile' }, function () {
                    this.route('index', { path: ':person_id' }, function () {
                        this.route('edit', { path: '/edit' });
                        this.route('support', { path: '/support' });
                        this.route('credit-card', { path: '/credit-card' });
                        this.route('organisers');
                    });
                });
                this.route('dss-form', { path: '/dss-form' });
            });
            this.route('manage', { path: '/manage' }, function () {
                this.route('schedule-workers', { path: '/visits' }, function () {
                    this.route('index', { path: '' }, function () {
                        this.route('visits-list', { path: '/list' }, function () {
                            this.route('accepted');
                            this.route('declined');
                        });

                        this.route('visits', { path: '' }, function () {
                            this.route('all', { path: '' });
                            this.route('scheduled');
                            this.route('accepted');
                            this.route('queried');
                            this.route('pending');
                            this.route('approved');
                            this.route('paid');
                            this.route('declined');
                            this.route('removed');
                            this.route('removed-declined');
                        });

                        this.route('uncompleted-timesheets', { path: '/uncompleted/:worker_id' });
                        this.route('date', { path: 'date' });
                        this.route('schedule-detail', { path: ':timesheet_id' }, function () {
                            this.route('invoice', { path: '/invoice' });
                        });
                        this.route('expense-breakdown', { path: '/expense-breakdown' }, function () {
                            this.route('index', { path: ':timesheet_id' });
                        });
                        this.route('new', { path: 'new' }, function () {
                            this.route('index', { path: '' });
                            this.route('activity', { path: '/activity' });
                            this.route('note', { path: '/note' });
                            this.route('add-payment', { path: '/add-payment' });
                            this.route('contract', { path: '/contract' });
                        });
                        this.route('today', { path: '/today' });
                    });
                });
                this.route('calendar', { path: '/schedule' });
                this.route('contracts', { path: '/agreements' }, function () {
                    this.route('index', { path: '' }, function () {
                        this.route('index', { path: '' });
                    });
                    this.route('contract', { path: ':contract_id' }, function () {
                        this.route('index', { path: '' });
                    });
                });
                this.route('circles', { path: '/circles' }, function () {
                    this.route('detail', { path: ':circle_id' }, function () {
                        this.route('index', { path: '/' });
                        this.route('edit', { path: 'edit' });
                    });
                });
            });
            this.route('my-account', { path: '/accounts' }, function () {
                this.route('account-detail', { path: ':account_id' }, function () {
                    this.route('index', { path: '' });
                    this.route('reports', { path: '/reports' });
                });
            });
        });

        this.route('carers', { path: '/findwork' }, function () {
            this.route('findwork', { path: '' }, function () {
                this.route('dashboard', { path: '' });
                this.route('clients', { path: '/clients' }, function () {
                    this.route('index', { path: '' }, function () {
                        this.route('clients', { path: '' });
                        this.route('applications');
                        this.route('favourites');
                    });
                });
                this.route('profile', { path: '/profile' });
            });

            this.route('manage', { path: '/manage' }, function () {
                this.route('my-schedule', { path: '/visits' }, function () {
                    this.route('index', { path: '/old' });
                    this.route('today', { path: '/today' });
                    this.route('date', { path: '/date' });
                    this.route('schedule-detail', { path: ':timesheet_id' }, function () {
                        this.route('index', { path: '' });
                        this.route('summary', { path: 'summary' });
                        this.route('incident', { path: '/incident' });
                        this.route('note', { path: '/note' });
                    });
                    this.route('expense-breakdown', { path: '/expense-breakdown' }, function () {
                        this.route('index', { path: ':timesheet_id' });
                    });
                    this.route('invoice', { path: '/invoice/:timesheet_id' });
                    this.route('visits', { path: '' }, function () {
                        this.route('all', { path: '' });
                        this.route('scheduled');
                        this.route('submit');
                        this.route('pending');
                        this.route('approved');
                        this.route('accept');
                        this.route('removed-declined');
                        this.route('paid');
                    });
                });

                this.route('calendar', { path: '/schedule' });

                this.route('recent', { path: '/recent' });

                this.route('worker-contracts', { path: '/agreements' }, function () {
                    this.route('contract', { path: ':contract_id' });
                });

                this.route('circles', { path: '/circles' }, function () {
                    this.route('detail', { path: ':circle_id' }, function () {
                        this.route('index', { path: '/' });
                    });
                });
            });

            this.route('my-account', { path: '/accounts' }, function () {
                this.route('account-detail', { path: ':account_id' }, function () {
                    this.route('index', { path: '' });
                    this.route('payout', { path: '/payout/:transaction_id' });
                    this.route('reports', { path: '/reports' });
                    this.route('summary', { path: '/summary' });
                });
            });
        });

        this.route('account', { path: '/account' }, function () {
            this.route('confirm-email', { path: '/confirm-email' });
            this.route('reset-password', { path: '/reset-password' });
            //todo: backend will provide the correct url, remove this later
            this.route('reset-password', { path: '/resetPassword' });
            this.route('confirm-email', { path: '/confirmEmail' });
            this.route('new-member', { path: '/addMember' });
            this.route('forgot-password', { path: '/forgot-password' });
            this.route('external-login');
        });

        this.route('register', { path: '/register' }, function () {
            this.route('seeker-register', { path: '/seeker' }, function () {
                this.route('index', { path: '' });
                this.route('who', { path: '/who' });
                this.route('client-details', { path: '/client-details' });
                this.route('payment-type', { path: '/payment-type' });
                this.route('funding-details', { path: '/funding-details' });
                this.route('account-details', { path: '/account-details' });
                this.route('phone-verification', { path: '/phone-verification' });
                this.route('done', { path: '/done' });
            });
            this.route('worker-register', { path: '/worker' }, function () {
                this.route('index', { path: '/' });
                this.route('personal-details', { path: '/personal-details' });
                this.route('phone-verification', { path: '/phone-verification' });
                this.route('address', { path: '/address' });
                this.route('looking-for', { path: '/looking-for' });
                this.route('availability', { path: '/availability' });
                this.route('done', { path: '/done' });
            });
        });
        this.route('pagenotfound', { path: '*path' });
        this.route('job-expired');
        this.route('reconnect-session');
    });

    exports.default = Router;
});