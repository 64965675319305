define("mycare-ember/mixins/timesheet-calendar", ["exports", "mycare-ember/mixins/get-date-of-week"], function (exports, _getDateOfWeek) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_getDateOfWeek.default, {
        auth: Ember.inject.controller('auth'),
        // editEntryController: Ember.inject.controller('partials/manage/timesheet/modal-timesheet-edit'),
        manageDateController: Ember.inject.controller('partials/manage/date-selected'),
        application: Ember.inject.controller('application'),
        isAddNewButton: false,
        isLoading: false,
        selectedWeekday: '',

        watchSchedulePickerControllerTime: function (values, date) {
            var reload = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

            var self = this;
            self.set('events', []);
            self.store.findRecord('person', self.get('auth.profileId')).then(function (person) {
                self.set('profilePerson', person);
            });
            if (!Ember.isEmpty(self.get('manageDateController.selectedDate'))) {
                self.getTimesheetsByDate(self.get('manageDateController.selectedDate'), reload);
                self.getEventsByDate(self.get('manageDateController.selectedDate'), reload);
                var tempDate = moment(this.get('manageDateController.selectedDate')).add(7, 'days').utc().toISOString();
                this.set('isShowNewTimeSheet', this.get('isScheduleView') && moment().utc().isBefore(tempDate));
            }
        }.observes('manageDateController.selectedDate'),

        getTimesheetsByDate: function getTimesheetsByDate(date) {
            var reload = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

            var self = this;
            self.set('isLoading', true);
            var profileId = self.get('auth.profileId');
            var monday = moment(self.getMonday(date)).startOf("day").utc().toISOString();
            var sunday = moment(self.getSunday(date)).endOf("day").utc().toISOString();
            self.store.query('timesheet', {
                personId: profileId,
                isWorker: self.get('isWorker'),
                periodStartsOn: monday,
                periodEndsOn: sunday,
                reload: reload
            }).then(function (timesheets) {
                self.set('isLoading', false);
                return Ember.RSVP.resolve(timesheets);
            }).then(function (timesheets) {
                self.set('content', timesheets.toArray());
            });
        },

        getEventsByDate: function getEventsByDate(date) {
            var reload = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

            var self = this;
            self.set('isLoading', true);
            var profileId = self.get('auth.profileId');
            var monday = moment(self.getMonday(date)).startOf("day").utc().toISOString();
            var sunday = moment(self.getSunday(date)).endOf("day").utc().toISOString();
            self.store.query('event', {
                personId: profileId,
                periodStartsOn: monday,
                periodEndsOn: sunday,
                reload: reload
            }).then(function (events) {
                self.set('isLoading', false);
                self.set('events', events);
            });
        }

    });
});