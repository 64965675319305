define('mycare-ember/controllers/partials/manage/index/timesheet-seeker-index-event-view', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        auth: Ember.inject.controller('auth'),
        manageIndexController: Ember.inject.controller('seekers/manage/index/index'),
        actions: {
            showEventModal: function showEventModal() {
                var self = this;
                self.store.findRecord('event', self.get('model.eventId')).then(function (event) {
                    self.set('eventEditController.event', event);
                    self.set('eventEditController.timesheet', self.get('model'));
                    self.get('eventEditController').setup();
                    Ember.$('#modal-schedule-event-edit').modal('show');
                });
            }
        }
    });
});