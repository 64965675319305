define("mycare-ember/controllers/partials/footer", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        init: function init() {
            var footerHeight = 0,
                bodyHeight = 0,
                footerTop = 0,
                body = Ember.$("#body"),
                footer = Ember.$("#footer");

            function positionFooter() {
                footerHeight = footer.height();
                footerTop = Ember.$(window).scrollTop() + Ember.$(window).height() - footerHeight + "px";
                bodyHeight = body.height();
                var windowHeight = Ember.$(window).height();

                if (bodyHeight < windowHeight) {
                    footer.css({
                        position: "absolute"
                    }).css({
                        top: footerTop
                    }).css({
                        width: "100%"
                    });
                } else {
                    footer.css({
                        position: "static"
                    });
                }
            }

            positionFooter();

            Ember.$(window).bind("load resize", function () {
                positionFooter();
            });
        }
    });
});