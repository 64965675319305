define("mycare-ember/seekers/findhelp/job-listings/route", ["exports", "mycare-ember/mixins/seeker_only", "mycare-ember/utils/mc-protected-route"], function (exports, _seeker_only, _mcProtectedRoute) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcProtectedRoute.default.extend(_seeker_only.default, {
        jobListingService: Ember.inject.service('job-listing'),
        _profileService: Ember.inject.service('profile'),

        model: function model() {
            return this.get('_profileService.getPersonByIdAsync').perform({ personId: this.get('session.authData.currentPersonId') });
        },


        actions: {
            didTransition: function didTransition() {
                Ember.$("body").addClass("page-job-listings");
            }
        }
    });
});