define('mycare-ember/services/utils/redirect', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    exports.default = Ember.Service.extend({
        env: Ember.inject.service('utils/env'),
        logger: Ember.inject.service('utils/logger'),
        isListening: true,
        previousTransitions: {},
        isDisabledWorkerRedirect: true,
        blackList: ['me.profiles', 'me.account', 'seekers.my-account.account-detail.index'],

        isBlacklisted: function isBlacklisted(transition) {
            return !Ember.isNone(this.get('blackList').find(function (l) {
                return !transition || l === transition.targetName;
            }));
        },
        hasKey: function hasKey(key) {
            return this.get('previousTransitions.' + key) !== null && this.get('previousTransitions.' + key) !== undefined;
        },
        store: function store(key, transition, transitionOpt) {

            var profileRedirectKey = this.get('env').getApp('REDIRECT_PROFILE_KEY');
            if (!this.get('isListening') && profileRedirectKey === key) {
                this.get('logger').info('INFO:redirect-service: service is not listening, ignoring \'' + key + '(' + transition.targetName + ')\'');
                return;
            }
            var transitionRouteName = (typeof transition === 'undefined' ? 'undefined' : _typeof(transition)) === 'object' ? transition.targetName : transition;
            if (key === profileRedirectKey && this.get('isDisabledWorkerRedirect') && transitionRouteName.match(/carers/) !== null) {
                this.get('logger').info('INFO:redirect-service: worker redirect is disabled for redirect key (' + profileRedirectKey + '), ignoring \'' + key + '(' + transitionRouteName + ')\'');
            } else {
                if (this.isBlacklisted(transition)) {
                    this.get('logger').info('INFO:redirect-service: redirect key (' + transitionRouteName + ') is blacklisted, ignoring...\'');
                } else {
                    this.set('previousTransitions.' + key, transition);
                    this.get('logger').info('INFO:redirect-service: Stored transition with key, \'' + key + '(' + transitionRouteName + ')\'');
                }
            }
            this.set('transitionOpt', transitionOpt);
        },
        getRouteName: function getRouteName(key) {
            var transitionByKey = this.get('previousTransitions.' + key);
            if (transitionByKey !== undefined && transitionByKey !== null) {
                if ((typeof transitionByKey === 'undefined' ? 'undefined' : _typeof(transitionByKey)) === 'object') {
                    return transitionByKey.targetName;
                }
                return transitionByKey;
            }
            return null;
        },
        getByKey: function getByKey(key) {
            var transitionByKey = this.get('previousTransitions.' + key);
            if (transitionByKey !== undefined && transitionByKey !== null) {
                return transitionByKey;
            }
            return null;
        },
        getPersonId: function getPersonId() {
            var redirectKey = this.getRedirectKey();
            if (redirectKey && this.hasKey(redirectKey)) {
                var transition = this.getByKey(redirectKey);
                if ((typeof transition === 'undefined' ? 'undefined' : _typeof(transition)) === 'object') {
                    return transition.queryParams.personId;
                }
                return null;
            }
            return null;
        },
        getRedirectKey: function getRedirectKey() {
            var redirectKey = null;
            var beforeLoginKey = this.get('env').getApp('REDIRECT_BEFORELOGIN_KEY');
            if (this.hasKey(beforeLoginKey)) {
                redirectKey = beforeLoginKey;
            } else {
                var profileKey = this.get('env').getApp('REDIRECT_PROFILE_KEY');
                if (this.hasKey(profileKey)) {
                    redirectKey = profileKey;
                }
            }
            return redirectKey;
        },
        consume: function consume(key) {
            this.get('logger').info('INFO:redirect-service: Looking for transition with key, \'' + key + '\'');
            var transitionByKey = this.get('previousTransitions.' + key);
            if (transitionByKey !== undefined && transitionByKey !== null) {
                if (typeof transitionByKey === 'string') {

                    this.get('logger').info('INFO:redirect-service: Found transition with key, \'' + key + '(' + transitionByKey + ')\'');
                    if (this.get('transitionOpt')) {
                        this.get('router').transitionTo(transitionByKey, this.get('transitionOpt'));
                    } else {
                        this.get('router').transitionTo(transitionByKey);
                    }
                } else {
                    this.get('logger').info('INFO:redirect-service: Found transition with key, \'' + key + '(' + transitionByKey.targetName + ')\'');
                    transitionByKey.retry();
                }
                this.set('previousTransitions.' + key, null);
                this.set('transitionOpt', null);
                this.get('logger').info('INFO:redirect-service: Transition with key, \'' + key + '\' was consumed');
            } else {
                this.get('logger').info('INFO:redirect-service: Cannot find transition with key, \'' + key + '\'');
            }
        },
        clearAll: function clearAll() {
            this.set('previousTransitions', {});
            this.get('logger').info('INFO:redirect-service: reset');
        }
    });
});