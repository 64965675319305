define("mycare-ember/models/account-type", ["exports", "ember-data"], function (exports, _emberData) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        name: _emberData.default.attr('string'),
        isPrivatePayEnabled: _emberData.default.attr('boolean'),
        minimumAccountBalance: _emberData.default.attr('number'),
        clientServiceFee: _emberData.default.attr('number', { default: 0 }),
        workerServiceFee: _emberData.default.attr('number', { default: 0 }),
        gstRate: _emberData.default.attr('number'),
        taxRate: _emberData.default.attr('number'),
        bookingThreshold: _emberData.default.attr('number'),

        getServiceFee: function () {
            if (Ember.isEmpty(this.get('clientServiceFee'))) {
                return 0;
            }
            return this.get('clientServiceFee');
        }.property('clientServiceFee'),

        hasServiceFee: function () {
            return Ember.isEmpty(this.get('clientServiceFee')) === false;
        }.property('clientServiceFee'),

        isCapInUse: function () {
            return this.get('bookingThreshold') > 0;
        }.property('bookingThreshold')
    });
});