define("mycare-ember/seekers/findhelp/job-listings/create/index/route", ["exports", "mycare-ember/utils/mc-route", "mycare-ember/mixins/seeker_only", "npm:responsive-toolkit"], function (exports, _mcRoute, _seeker_only, _npmResponsiveToolkit) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcRoute.default.extend(_seeker_only.default, {
        _wizardService: Ember.inject.service('utils/seeker-listing-wizard'),
        beforeModel: function beforeModel() {
            if (_npmResponsiveToolkit.default.is('xs')) {
                this.set('_wizardService.mode', 'create');
                this.transitionTo('seekers.findhelp.job-listings.mobile-create-menu');
            } else {
                this.transitionTo('seekers.findhelp.job-listings.create.basic-info');
            }
        }
    });
});