define("mycare-ember/components/mc-payment-options/component", ["exports", "mycare-ember/utils/mc-component"], function (exports, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        errorMessage: null,
        hasErrorMessage: Ember.computed('errorMessage', function () {
            var errorMessage = this.get('errorMessage');
            return errorMessage && errorMessage.length > 0;
        }),

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('value', Ember.Object.create({
                isGovernmentPay: undefined,
                isPrivatePay: undefined
            }));
            this.initVar('inputId', undefined);
            this.initVar('inputClass', undefined);
            this.initVar('initialValues', {});
            this.initVar('isInitialised', false);
            this.initVar('errorMessage', undefined);
            this.initVar('paymentOptions', [Ember.Object.create({
                displayName: 'Privately',
                value: 'private',
                isSelected: false
            }), Ember.Object.create({
                displayName: 'Government funding',
                value: 'government',
                isSelected: false
            })]);

            this._setup();
        },


        _setup: function () {
            var _this = this;

            var initialValues = null;
            this.get('paymentOptions').forEach(function (opt) {
                if (opt.get('value') === 'government') {
                    initialValues = _this.get('initialValues.isGovernmentPay');
                    opt.set('isSelected', initialValues);
                    _this.set('value.isGovernmentPay', initialValues);
                }
                if (opt.get('value') === 'private') {
                    initialValues = _this.get('initialValues.isPrivatePay');
                    opt.set('isSelected', initialValues);
                    _this.set('value.isPrivatePay', initialValues);
                }
            });
        }.observes('initialValues.{isGovernmentPay,isPrivatePay}'),

        paymentOptionsChanged: function () {
            var _this2 = this;

            this.get('paymentOptions').forEach(function (opt) {
                if (opt.get('value') === 'government') {
                    _this2.set('value.isGovernmentPay', opt.get('isSelected'));
                }
                if (opt.get('value') === 'private') {
                    _this2.set('value.isPrivatePay', opt.get('isSelected'));
                }
            });
        }.observes('paymentOptions.@each.isSelected')
    });
});