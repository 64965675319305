define('mycare-ember/components/ui/mc-accordian/component', ['exports', 'mycare-ember/utils/mc-component', 'mycare-ember/utils/common'], function (exports, _mcComponent, _common) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('accordianId', _common.default.createGuid());
            this.initVar('onGroupOpen', undefined);
        }
    });
});