define('mycare-ember/mixins/register/isEnableRegisterProcess', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    isEnableRegisterProcess: function isEnableRegisterProcess() {
      var self = this;
      self.store.findRecord('person', self.controllerFor('auth').get('loggedInUserId')).then(function (person) {
        if (person.get('relationships.length') > 0) {
          self.transitionTo('/');
        }
      });
    },
    isEnableWorkerRegisterProcess: function isEnableWorkerRegisterProcess() {
      var self = this;
      if (localStorage.getItem(this.get('env').getApp('WORKER_SIGN_UP_STAGE')) !== 'true') {
        self.transitionTo('/');
      }
    }
  });
});