define("mycare-ember/components/star-rating/component", ["exports", "mycare-ember/utils/common", "mycare-ember/utils/mc-component"], function (exports, _common, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        tagName: '',

        setup: function () {
            var self = this;
            self.set('selectComponentId', _common.default.createGuid());
            var isFullStar = self.get('isFullStar') ? self.get('isFullStar') : true;
            var isReadOnly = self.get('isReadOnly') ? self.get('isReadOnly') : false;
            Ember.run.scheduleOnce('afterRender', self, function () {
                var ratingComponent = Ember.$('#' + self.get('selectComponentId'));
                ratingComponent.rateYo({
                    fullStar: isFullStar,
                    readOnly: isReadOnly
                }).on("rateyo.set", function (e, data) {
                    self.set('rating', data.rating);
                });
                /* set the option `multiColor` to show Multi Color Rating */
                ratingComponent.rateYo("option", "multiColor", true);
            });
        }.on('init'),

        watchRating: function () {
            var self = this;
            if (Ember.isEmpty(self.get('rating')) === false) {
                var ratingComponent = Ember.$('#' + self.get('selectComponentId'));
                ratingComponent.rateYo("rating", self.get('rating'));
            }
        }.observes('rating')
    });
});