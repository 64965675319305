define('mycare-ember/carers/manage/my-schedule/invoice/controller', ['exports', 'mycare-ember/mixins/nav-mode'], function (exports, _navMode) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_navMode.default, {
        auth: Ember.inject.controller('auth'),
        backToOpts: null,

        backTo: function () {
            this.set('backToOpts', this.get('model.timesheetId'));
            return 'carers.manage.my-schedule.schedule-detail';
        }.property()
    });
});