define('mycare-ember/models/file', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        url: _emberData.default.attr('string'),
        fileName: _emberData.default.attr('string'),
        uploadedByPerson: _emberData.default.attr('string'),
        uploadedOn: _emberData.default.attr('string'),
        isVisible: _emberData.default.attr('boolean'),

        isImage: function () {
            var validFormats = ['jpg', 'jpeg', 'png', 'gif'];
            var fileNameParts = (this.get('fileName') || '').split('.');
            return fileNameParts.length === 0 || validFormats.includes(fileNameParts[fileNameParts.length - 1].toLowerCase());
        }.property('fileName')
    });
});