define("mycare-ember/components/mc-mobile-joblisting-nav/component", ["exports", "mycare-ember/utils/mc-component"], function (exports, _mcComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        _wizardService: Ember.inject.service('utils/seeker-listing-wizard'),

        isEditModel: function () {
            return this.get('mode') === 'edit';
        }.property('mode'),

        actions: {
            openNav: function openNav() {
                if (location.pathname.match(/job-listings\/mobile-create-menu/) || location.pathname.match(/job-listings\/mobile-edit-menu/)) {
                    this.get('eventbus').publish('global:on:changeRoute', 'seekers.findhelp.job-listings.list');
                } else if (location.pathname.match(/\d/)) {
                    this.get('eventbus').publish('global:on:changeRoute', 'seekers.findhelp.job-listings.mobile-edit-menu');
                } else {
                    this.get('eventbus').publish('global:on:changeRoute', 'seekers.findhelp.job-listings.mobile-create-menu');
                }
            }
        }

    });
});