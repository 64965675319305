define("mycare-ember/mixins/invite-mixin", ["exports", "mycare-ember/mixins/form-checker", "mycare-ember/mixins/scroll_top"], function (exports, _formChecker, _scroll_top) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_formChecker.default, _scroll_top.default, {
        auth: Ember.inject.controller('auth'),
        facebook: Ember.inject.controller('facebook'),
        inviteService: Ember.inject.service('invite'),
        inviteEmailList: null,
        emailInviteError: [],
        copyToClipboard: function copyToClipboard(id) {
            var inp = document.getElementById(id);
            // is element selectable?
            if (inp && inp.select) {
                // select text
                inp.select();
                try {
                    // copy text
                    document.execCommand('copy');
                    inp.blur();
                } catch (err) {
                    alert('please press Ctrl/Cmd+C to copy');
                }
            }
        },

        inviteByEmails: function inviteByEmails(emails) {
            var self = this;
            if (self.checkEmails(emails)) {
                this.get('inviteService').get('sendReferalAsync').perform({
                    personId: self.get('auth.profileId'),
                    emails: emails
                }).then(function () {
                    self.set('inviteEmailList', null);
                });
            }
        },
        checkEmails: function checkEmails(emails) {
            this.get('emailInviteError').clear();
            if (Ember.isEmpty(emails)) {
                this.get('emailInviteError').pushObject('Emails required.');
                return false;
            } else {
                var emailArr = emails.split(",");
                var bool = true;
                for (var i = 0; i < emailArr.length; i++) {
                    var emailText = emailArr[i];
                    if (this.isEmail(emailText) === false) {
                        bool = false;
                        this.get('emailInviteError').pushObject('Email format is not correct.');
                        break;
                    }
                }
                return bool;
            }
        },


        actions: {
            shareWithFacebook: function shareWithFacebook(code) {
                var self = this;
                self.scrollTop();
                Ember.$('.modal').modal('hide');
                self.store.findRecord('person', self.get('auth.profileId')).then(function (person) {
                    var quote = "I've signed up for work at Mycare. Use this code: " + code + " to create your free worker profile and we'll both get $10 when you complete your profile.";
                    self.get('facebook.SDK').then(function (FB) {
                        FB.ui({
                            method: 'share',
                            mobile_iframe: true,
                            quote: quote,
                            href: 'https://#/register/worker?code=' + person.get('myShareCode'),
                            hashtag: '#JoinMyCare',
                            picture: 'https://www.mycare.co.nz/img/elderly-man.jpg'
                        }, function () {});
                    });
                });
            }
        }
    });
});